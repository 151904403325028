import React, { FC, useEffect, useState } from "react";
import {
  UserType,
  connectedAccountType,
} from "../types/getUsersListResponseType";
import { KTSVG, toAbsoluteUrl } from "../../../../_metronic/helpers";
import { GoogleAccountType } from "../../google/types/googleResponseType";
import { ConnectGoogleType } from "../types/getUserDetailsResponseType";
import {
  actions,
  getUserDetails,
  googleAuthorizeURL,
  googleAuthorizeUser,
  googleDisconnectAccount,
} from "../redux";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { getGoogleAccountsList } from "../../google/redux/GoogleAPI";
import ShowGetListAccounts from "./ShowGetListAccounts";
import { useGlobalModalContext } from "../../../sharedComponents/modals/ModalContext";
import {
  errorToast,
  successToast,
} from "../../../sharedComponents/toasts/Toasts";

interface Props {
  userDetails: UserType;
  getUserDetailsAPI: () => void;
}

const ConnectedUserAccounts: FC<Props> = ({
  userDetails,
  getUserDetailsAPI,
}) => {
  // state
  const [user, setUser] = useState<UserType>(userDetails);
  const [loading, setLoading] = useState(false);
  const [googleList, setGoogleList] = useState<GoogleAccountType[]>([]);
  const [googleModal, setGoogleModal] = useState(false);
  const [googleButton, setGoogleButton] = useState(false);
  const [googleAccounts, setGoogleAccounts] = useState<connectedAccountType[]>(
    []
  );

  // context
  const intl = useIntl();
  const dispatch = useDispatch();
  const { showDeleteConfirmModal, hideDeleteConfirmModal } =
    useGlobalModalContext();
  let googleAuthApp: Window | null = window;

  const getUserListDetailsAPI = () => {
    setLoading(true);
    getUserDetails(userDetails.id)
      .then(({ data: { user } }) => {
        setUser(user);
        setGoogleButton(true);
        dispatch(actions.setUserDetails(user));
        dispatch(actions.setGoogleAccount(user.connected_accounts.google));
        setGoogleAccounts(user.connected_accounts.google);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleGoogleConnect = () => {
    setLoading(true);
    getGoogleAccountsList()
      .then(({ data: { googleDetails } }) => {
        setGoogleList(googleDetails);
        setGoogleModal(true);
      })
      .catch(() => {
        errorToast(
          intl.formatMessage({ id: "GOOGLE_CONNECT_FAILURE_MESSAGE" })
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getUserListDetailsAPI();
  }, []);

  const handleGoogleClose = (googleAccount: GoogleAccountType) => {
    setLoading(true);
    setGoogleModal(false);
    if (googleAccount && googleAccount.id) {
      googleAuthorizeURL(googleAccount.id).then(
        ({ data: { googleDetails } }) => {
          googleAuthApp = window.open(
            googleDetails,
            "GoogleWindow",
            "width=600,height=600,left=500,top=100"
          );
          window.addEventListener("storage", (e) => {
            if (e.key === "googleAuth") {
              setLoading(true);
              googleAuthorizeUser(
                googleAccount.id,
                userDetails.id,
                e.newValue
              ).then(({ data: { googleDetails } }) => {
                sessionStorage.setItem("gId", googleDetails.id.toString());
                sessionStorage.setItem("uId", userDetails.id.toString());
                localStorage.removeItem("code");
                const data = [];
                data.push(googleDetails);
                setGoogleAccounts([...googleAccounts, ...data]);
                dispatch(
                  actions.setLinkedInAccount([...googleAccounts, ...data])
                );
                setGoogleButton(true);
                setLoading(false);
                successToast(
                  intl.formatMessage({
                    id: "GOOGLE_ACCOUNT_AUTHORIZE_SUCCESS_MESSAGE",
                  })
                );
              });
            }
          });

          const checkClosed = setInterval(() => {
            if (googleAuthApp?.closed) {
              setLoading(false);
              clearInterval(checkClosed);
            }
          }, 1000);
        }
      );
    } else {
      setLoading(false);
    }
  };

  const handleDisconnectGoogle = (google_id: number) => {
    hideDeleteConfirmModal();
    setLoading(true);
    googleDisconnectAccount(google_id, user.id)
      .then((data) => {
        const accounts = googleAccounts.filter((data) => data.id !== google_id);
        dispatch(actions.setGoogleAccount(accounts));
        localStorage.setItem("googleAuth", "");
        successToast(
          intl.formatMessage({ id: "GOOGLE_DISCONNECT_SUCCESS_MESSAGE" })
        );
      })
      .catch((err) => {
        errorToast(
          intl.formatMessage({ id: "GOOGLE_DISCONNECT_FAILURE_MESSAGE" })
        );
      })
      .finally(() => {
        setLoading(false);
        getUserListDetailsAPI();
      });
  };

  return (
    <>
      {googleModal && (
        <ShowGetListAccounts
          show={googleModal}
          list={googleList}
          closeModal={(data) => {
            if (data) {
              handleGoogleClose(data);
            } else {
              setGoogleModal(false);
            }
          }}
        />
      )}
      <div className="card card-mt">
        <div
          className="card-header card-px border-0 cursor-pointer"
          role="button"
          data-bs-toggle="collapse"
          data-bs-target="#kt_account_connected_accounts"
          aria-expanded="true"
          aria-controls="kt_account_connected_accounts"
        >
          <div className="card-title m-0">
            <h3 className="fw-bolder m-0">
              {intl.formatMessage({ id: "USER_CONNECTED_ACCCOUNTS" })}
            </h3>
          </div>
        </div>

        <div id="kt_account_connected_accounts" className="collapse show">
          <div className="card-body border-top card-p">
            <div>
              <div className="d-flex flex-stack">
                <div className="d-flex align-items-center">
                  <img
                    src={toAbsoluteUrl(
                      "/media/icons/duotune/communication/google-icon.svg"
                    )}
                    className="w-30px me-6"
                    alt="google icon"
                  />

                  <div className="d-flex flex-column">
                    <a
                      href="#"
                      className="fs-5 text-dark text-hover-primary fw-bolder"
                    >
                      {intl.formatMessage({
                        id: "CONNECTED_ACCOUNTS_GOOGLE_TITLE",
                      })}
                    </a>
                    {/* <div className='fs-6 fw-bold text-gray-400'>Integrate Projects Discussions</div> */}
                  </div>
                </div>

                {googleButton && googleAccounts.length === 0 ? (
                  <div className="d-flex justify-content-end">
                    <button
                      className="btn btn-sm btn-primary-icon btn-light-primary btn-active-light-primary fw-bold"
                      type="button"
                      data-bs-toggle="tooltip"
                      onClick={() => {
                        if (googleList?.length === 1) {
                        } else {
                          handleGoogleConnect();
                        }
                      }}
                    >
                      {intl.formatMessage({ id: "CONNECT_BUTTON" })}
                    </button>
                  </div>
                ) : null}
              </div>
              {googleButton && googleAccounts && googleAccounts.length > 0
                ? googleAccounts.map((google) => (
                    <div className="d-flex flex-stack">
                      <div className="d-flex align-items-center fs-6 fw-bold text-gray-400 mt-2 p-5">
                        <img
                          alt="profile"
                          className="me-2 h-25px w-25px"
                          src={google.profile_picture_url}
                          style={{ borderRadius: "20px" }}
                        />
                        <div>
                          {" "}
                          <div className="fw-bold text-gray-800">
                            {google.username}
                          </div>
                          <span className="badge badge-light  fw-bold text-gray-800 ">
                            {google.app_name}
                          </span>
                        </div>
                      </div>
                      <div className="d-flex justify-content-end align-items-end">
                        <button
                          className="btn btn-sm btn-danger-icon btn-light-danger btn-active-light-danger me-1 fw-bold"
                          type="button"
                          data-bs-toggle="tooltip"
                          onClick={() => {
                            showDeleteConfirmModal(
                              intl.formatMessage({
                                id: "GOOGLE_DISCONNECT_CONFIRM_MESSAGE",
                              }),
                              () => {
                                handleDisconnectGoogle(google.id);
                              }
                            );
                          }}
                        >
                          {intl.formatMessage({ id: "DISCONNECT_BUTTON" })}
                        </button>
                        {/* <button
                          className="btn btn-sm btn-primary-icon btn-light-primary btn-active-light-primary me-1 fw-bold"
                          type="button"
                          data-bs-toggle="tooltip"
                          onClick={() => {}}
                        >
                          {intl.formatMessage({ id: "SYNC_BUTTON" })}
                        </button> */}
                      </div>
                    </div>
                  ))
                : null}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ConnectedUserAccounts;
