import React, { FC, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { Tooltip } from "react-tooltip";
import clsx from "clsx";

// styles
import "react-tooltip/dist/react-tooltip.css";

import InfoIcon from "../../../../_metronic/assets/icons/info.svg";
import SVG from "react-inlinesvg";

// components
import {
  errorToast,
  successToast,
} from "../../../sharedComponents/toasts/Toasts";
import DropDown from "../../../sharedComponents/dropdown/Dropdown";
import PipelineDetailsModal from "../../deals/components/PipelineDetailsModal";
import { RootState } from "../../../../setup";
import { enablePermissionForCrud } from "../../../utils/PermisisionEnabledForResource";

// Types
import { RoleType } from "../../RolesAndPermissions/types/getRolesResponseType";

// apis
import {
  getPipelinesList,
  updatePipelineStatus,
  updatePipelines,
} from "../redux/PipelinesAPI";
import { getDealPipelines } from "../../deals/redux/DealsAPI";
import { actions as PipelineActions } from "../redux/PipelinesRedux";
import { actions as DealActions } from "../../deals/redux/DealsRedux";
import { PipelineType } from "../types/PipelinesResponseType";

interface Props {
  pipeline: PipelineType;
  getPipelineDetailsById: () => void;
}

const PipelineGeneralDetails: FC<Props> = ({
  pipeline,
  getPipelineDetailsById,
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const PIPELINE_TRANSITION_TYPE_OPTIONS = JSON.parse(
    intl.formatMessage({ id: "PIPELINE_TRANSITION_TYPE_OPTIONS" })
  ) as {
    id: number;
    name: string;
    title: string;
  }[];
  const PIPELINE_STATUS_TYPE_OPTIONS = JSON.parse(
    intl.formatMessage({ id: "PIPELINE_STATUS_TYPE_OPTIONS" })
  ) as {
    id: number;
    name: string;
    title: string;
  }[];
  const [publishType, draftType, deactivateType] = PIPELINE_STATUS_TYPE_OPTIONS;

  const userRoleDetails = useSelector<RootState>(
    // @ts-ignore
    (state) => state.auth.roleDetails
  ) as RoleType;
  //@ts-ignore
  const crudPermission: any = enablePermissionForCrud(
    userRoleDetails,
    "pipelines"
  );

  const [openPipelineModal, setOpenPipelineModal] = useState(false);
  const [pipelineTransition, setPipelineTransition] = useState(
    pipeline?.transition_type || ""
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [phasesWithNoOneTabs, setPhasesWithNoOneTabs] = useState<any[]>([]);
  const [showErrorModal, setShowErrorModal] = useState(false);

  const updateContentPipelines = () => {
    getDealPipelines()
      .then(({ data: { pipelines } }) => {
        dispatch(DealActions.setDealPipelines(pipelines));
      })
      .catch((error) => console.error(error?.response?.data?.message));
  };

  const handlePipelineList = () => {
    getPipelinesList()
      .then(({ data: { pipelines } }) => {
        dispatch(PipelineActions.setPipelinesList(pipelines));
      })
      .catch((error) => console.error(error?.response?.data?.message))
      .finally(() => {
        updateContentPipelines();
      });
  };

  const handlePipelineStatus = (status: string) => {
    setLoading(true);
    updatePipelineStatus(pipeline?.id, status)
      .then(() => {
        handlePipelineList();
        successToast(
          intl.formatMessage({
            id: "PROJECTS_PIPELINE_STATUS_SUCCES_MESSAGE",
          })
        );
      })
      .catch(() => {
        errorToast(
          intl.formatMessage({
            id: "PROJECTS_PIPELINE_STATUS_FAILURE_MESSAGE",
          })
        );
      })
      .finally(() => {
        getPipelineDetailsById?.();
        setLoading(false);
      });
  };

  const handlePipelineTransition = (value: string) => {
    updatePipelines(
      pipeline?.id,
      pipeline?.name,
      value ?? pipelineTransition,
      pipeline?.status
    )
      .then(() => {
        successToast(
          intl.formatMessage({
            id: "PROJECTS_PIPELINE_NAME_SUCCES_MESSAGE",
          })
        );
      })
      .catch(() => {
        errorToast(
          intl.formatMessage({
            id: "PROJECTS_PIPELINE_NAME_FAILURE_MESSAGE",
          })
        );
      })
      .finally(() => {
        getPipelineDetailsById?.();
      });
  };

  useEffect(() => {
    setPhasesWithNoOneTabs(
      pipeline?.phases.filter(
        (phase) =>
          phase?.tabs?.length === 0 &&
          phase?.condition !== "start" &&
          phase?.condition !== "end" &&
          phase?.name
      )
    );
  }, [pipeline]);

  return (
    <div className={`card card-mt ${!crudPermission?.edit && "pe-none"}`}>
      <div className="card-header card-px">
        <div className="card-title">
          <h2>
            {intl.formatMessage({
              id: "PIPELINE_DETAILS_GENERAL_DETAILS_TTILE",
            })}
          </h2>
        </div>
      </div>

      <div className="card-body card-p">
        <div className="row mx-0">
          <div className="col-12 p-0">
            <label className="tab-field-label">
              {intl.formatMessage({
                id: "PIPELINE_DETAILS_TRANSITION_TYPE",
              })}
            </label>
          </div>
          <div className="d-flex col-9 ps-0">
            {/* Transition Type */}
            <div className="d-flex flex-row align-items-center w-100 w-lg-50">
              <div className="w-100">
                <div className="d-flex flex-row align-items-center transitionDropdown">
                  <DropDown
                    hideSearch
                    id="users-titles-dropdown"
                    items={PIPELINE_TRANSITION_TYPE_OPTIONS}
                    displayFunc={(item) => {
                      // @ts-ignore
                      return item.title;
                    }}
                    displayValue={
                      PIPELINE_TRANSITION_TYPE_OPTIONS.find(
                        (TITLES_OPTION) =>
                          TITLES_OPTION.name === pipelineTransition
                      )?.title || ""
                    }
                    onSelectItem={(item) => {
                      setPipelineTransition(item.name);
                      handlePipelineTransition(item.name);
                    }}
                    selectedItem={PIPELINE_TRANSITION_TYPE_OPTIONS.find(
                      (TITLES_OPTION) =>
                        TITLES_OPTION.name === pipelineTransition
                    )}
                  />
                </div>
              </div>

              {openPipelineModal && (
                <PipelineDetailsModal
                  show={openPipelineModal}
                  closeModal={() => {
                    setOpenPipelineModal(false);
                  }}
                  pipeline_id={pipeline?.id}
                />
              )}
              <div
                className="d-flex align-items-center justify-content-center"
                style={{
                  position: "relative",
                  cursor: "pointer",
                  zIndex: "11",
                }}
                onClick={() => {
                  setOpenPipelineModal(true);
                }}
              >
                <a
                  data-tooltip-id="my-pipeline-tooltip"
                  data-tooltip-content={intl.formatMessage({
                    id: "PIPELINE_INFO",
                  })}
                  data-tooltip-place="top"
                >
                  <SVG src={InfoIcon} className="svg-icon mx-2" />
                  <Tooltip
                    id="my-pipeline-tooltip"
                    place="top"
                    style={{
                      backgroundColor: "white",
                      color: "black",
                      zIndex: 999,
                      height: "fit-content",
                    }}
                    border="1px solid green"
                  />
                </a>
              </div>
            </div>
          </div>
          {/* button group / preview, pipeline status */}
          <div className="d-flex col-3 align-items-center justify-content-end p-0">
            <>
              {pipeline?.status &&
                crudPermission?.edit &&
                (pipeline?.status === draftType.name ? (
                  <button
                    disabled={phasesWithNoOneTabs.length > 0}
                    className="btn btn-md bg-success text-white"
                    style={{ minWidth: "120px" }}
                    onClick={(e) => {
                      // @ts-ignore
                      handlePipelineStatus(publishType.name);
                    }}
                  >
                    {loading ? (
                      <span
                        className="indicator-progress"
                        style={{ display: "block" }}
                      >
                        {intl.formatMessage({ id: "USERS_LOADING_MESSAGE" })}
                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                      </span>
                    ) : (
                      publishType.title
                    )}
                  </button>
                ) : pipeline?.status === publishType.name ? (
                  <button
                    disabled={phasesWithNoOneTabs.length > 0}
                    className="btn btn-md bg-danger text-white"
                    style={{ minWidth: "120px" }}
                    onClick={(e) => {
                      // @ts-ignore
                      handlePipelineStatus(deactivateType.name);
                    }}
                  >
                    {loading ? (
                      <span
                        className="indicator-progress"
                        style={{ display: "block" }}
                      >
                        {intl.formatMessage({ id: "USERS_LOADING_MESSAGE" })}
                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                      </span>
                    ) : (
                      deactivateType.title
                    )}
                  </button>
                ) : pipeline?.status === deactivateType.name ? (
                  <button
                    disabled={phasesWithNoOneTabs.length > 0}
                    className="btn btn-md bg-success text-white"
                    style={{ minWidth: "120px" }}
                    onClick={(e) => {
                      // @ts-ignore
                      handlePipelineStatus(publishType.name);
                    }}
                  >
                    {loading ? (
                      <span
                        className="indicator-progress"
                        style={{ display: "block" }}
                      >
                        {intl.formatMessage({ id: "USERS_LOADING_MESSAGE" })}
                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                      </span>
                    ) : (
                      publishType.title
                    )}
                  </button>
                ) : null)}
              {phasesWithNoOneTabs.length > 0 && (
                <span
                  // data-tooltip-id="my-pipeline-tooltip"
                  // data-tooltip-place="top"
                  // data-tooltip-content={intl.formatMessage({
                  //   id: "PIPELINE_PHASES_HAS_ONE_TABS_INFO_MESSAGE",
                  // })}
                  className="cursor-pointer"
                  onClick={() => {
                    setShowErrorModal(!showErrorModal);
                  }}
                >
                  <SVG
                    src={InfoIcon}
                    className="svg-icon ms-2"
                    fill="#f1416c"
                  />
                  {/* <Tooltip
                      id="my-pipeline-tooltip"
                      place="top"
                      style={{
                        backgroundColor: "white",
                        color: "black",
                        zIndex: 999,
                        height: "fit-content",
                      }}
                      border="1px solid red"
                    /> */}
                </span>
              )}
              {/* {pipelineStatus &&
								crudPermission?.edit &&
								PIPELINE_STATUS_TYPE_OPTIONS.map((status) => {
									if (status.name !== pipelineStatus)
										return (
											<div className="ms-4">
												<button
													className={clsx(
														"btn btn-md",
														status.id === 1
															? " bg-success text-white"
															: status.id === 2
																? "bg-secondary"
																: "bg-danger text-white"
													)}
													onClick={(e) => {
														// @ts-ignore
														setPipelineStatus(status.name);
														handlePipelineStatus(status.name);
													}}
												>
													{status.title}
												</button>
											</div>
										);
								})} */}
            </>
          </div>
          {showErrorModal && phasesWithNoOneTabs.length > 0 && (
            <div className="col-12 p-0">
              <div className="alert alert-danger d-flex flex-wrap flex-column mb-0 mt-5 text-break">
                {intl.formatMessage({
                  id: "PIPELINE_PHASES_HAS_ONE_TABS_INFO_MESSAGE_1",
                })}{" "}
                {phasesWithNoOneTabs?.map((tab) => tab.name).join(", ")}{" "}
                {intl.formatMessage({
                  id: "PIPELINE_PHASES_HAS_ONE_TABS_INFO_MESSAGE_2",
                })}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PipelineGeneralDetails;
