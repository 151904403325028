import React, { FC, useEffect, useState } from "react";
import moment from "moment";
import Html from "react-pdf-html";
import {
  Page,
  Text,
  Document,
  StyleSheet,
  View,
  Image,
} from "@react-pdf/renderer";

import { registerFonts } from "../../../../utils/AddFontsToPdf";
import { formatPriceLocale } from "../../../../utils/PriceFormatLocale";

import OrderPreviewLang from "../../../../../_metronic/i18n/messages/orderLanguage";

import { LayoutListType } from "../../../salesMasterData/SalesLayout/types/LayoutResponsetypes";
import { OfferType } from "../types/OfferTypes";
import { SaleItemType } from "../types/SaleTypes";

registerFonts();
interface Props {
  layoutFooterDetails?: LayoutListType;
  offer: OfferType;
  logoURL?: string;
  lang: "de" | "en" | "es" | "fr" | "ja";
  minus?: boolean;
}

const styles = StyleSheet.create({
  body: {
    fontFamily: "Roboto",
  },
  header: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
  },
  content: {
    fontSize: "10px",
    display: "flex",
    flexDirection: "column",
  },
  pageNumber: {
    fontSize: "10px",
    textAlign: "center",
  },
  marker: {
    position: "absolute",
    width: "5mm",
    borderTop: "1.5px",
    borderColor: "#000",
  },
  mark1: {
    top: "87mm",
    left: 0,
  },
  mark2: {
    top: "148.5mm",
    left: 0,
  },
  mark3: {
    bottom: "105mm",
    left: 0,
  },
  headerItem: {
    display: "flex",
    flexDirection: "row",
  },
  menu: {
    fontWeight: "bold",
  },
  menuItem: {
    fontWeight: "bold",
    color: "#00000090",
    alignSelf: "flex-start",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
  footerText: {
    flex: 1,
    fontSize: "10px",
    textAlign: "left",
  },
  table: {
    fontSize: "10px",
    marginTop: "5mm",
  },
  tableRow: {
    flexDirection: "row",
    display: "flex",
    alignItems: "stretch",
    width: "100%",
  },
  tableCol: {
    borderStyle: "solid",
    borderColor: "#bfbfbf",
    borderWidth: 0,
    borderBottomWidth: 1,
    flexDirection: "row",
    display: "flex",
    alignItems: "center",
    width: "100%",
    paddingVertical: 6,
  },
  tableCellHeader: {
    margin: "auto",
    fontWeight: "bold",
  },
  tableCell: {
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    justifyContent: "center",
  },
});

const OfferPDF: FC<Props> = ({
  offer,
  layoutFooterDetails,
  logoURL,
  lang,
  minus,
}) => {
  const [offerDetails, setOfferDetails] = useState(offer);

  const generateLangObject = (lang: string) => {
    const result: any = {};

    Object.keys(OrderPreviewLang).forEach((key) => {
      //@ts-ignore
      result[key] = OrderPreviewLang[key][lang];
    });

    return result;
  };

  const langObject = generateLangObject(lang);
  const [taxedProducts, setTaxedProducts] = useState({}) as any;
  const [discountedProducts, setDiscountedProducts] = useState({}) as any;

  const stripHtml = (html: string) => {
    let tmp = document.createElement("DIV");
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || "";
  };

  useEffect(() => {
    setOfferDetails(offer);
    calculateTaxes();
    calculateDiscounts();
  }, [offer]);

  const getInternalInitialValues = (internalItems: any) => {
    let arr: any[] = [];
    internalItems?.forEach((item: any) => {
      arr.push({
        id: item?.id,
        title: item?.product_title,
        description: item?.product_description || "",
        quantity: item?.quantity || 1,
        unit_price: item?.unit_price
          ? (item?.unit_price / 100).toFixed(2)
          : null,
        tax_percentage: item?.tax_percentage || null,
        discount_percentage: item?.discount_percentage,
      });
    });

    return arr;
  };

  const items = [
    ...getInternalInitialValues(offerDetails?.sales_details?.sale_items),
  ]?.sort((a, b) => a.id - b.id);

  const getProductPrice = (item: SaleItemType) => {
    if (!item.unit_price) {
      return 0;
    }

    const tax_behaviour = "inclusive";

    let initialPrice =
      Number(item.unit_price) * Number(item.quantity ? item.quantity : 1);
    let discountAmount = item.discount_percentage
      ? initialPrice * (Number(item.discount_percentage) / 100)
      : 0;
    let discountedPrice = initialPrice - discountAmount;

    let taxAmount = item.tax_percentage
      ? discountedPrice * (Number(item.tax_percentage) / 100)
      : 0;

    return discountedPrice + (tax_behaviour === "inclusive" ? 0 : taxAmount);
  };

  const getNetProductPrice = (item: SaleItemType) => {
    if (!item.unit_price) {
      return 0;
    }

    let initialPrice =
      Number(item.unit_price) * Number(item.quantity ? item.quantity : 1);

    let taxPercentage = Number(item.tax_percentage) / 100;
    let netPrice = initialPrice / (1 + taxPercentage);

    return Number(netPrice.toFixed(2));
  };

  const getTotalAmount = (items: SaleItemType[]) => {
    let result = 0;
    items?.forEach((item) => {
      result += getProductPrice(item);
    });
    return Number(result.toFixed(2));
  };

  const getNetTotalAmount = (items: SaleItemType[]) => {
    let result = 0;
    items.forEach((item) => {
      result += getNetProductPrice(item);
    });
    return Number(result.toFixed(2));
  };

  const calculateDiscounts = () => {
    const discounts: any = {};
    offerDetails?.sales_details?.sale_items?.forEach((item) => {
      if (Boolean(item.discount_percentage)) {
        // @ts-ignore
        if (!discounts[item.discount_percentage]) {
          // @ts-ignore
          discounts[item.discount_percentage] = 0;
        }

        // let initialPrice = getNetProductPrice(item);
        let initialPrice =
          (Number(item.unit_price) / 100) *
          Number(item.quantity ? item.quantity : 1);

        let discountAmount = item.discount_percentage
          ? initialPrice * (Number(item.discount_percentage) / 100)
          : 0;
        // @ts-ignore
        discounts[item.discount_percentage] =
          // @ts-ignore
          discounts[item.discount_percentage] + discountAmount;
      }
    });
    setDiscountedProducts(discounts);
  };

  const calculateTaxes = () => {
    const taxes: any = {};
    offerDetails?.sales_details?.sale_items?.forEach((item) => {
      // @ts-ignore
      if (Boolean(item.tax_percentage)) {
        // @ts-ignore
        if (!taxes[item.tax_percentage]) {
          // @ts-ignore
          taxes[item.tax_percentage] = 0;
        }
        // let initialPrice = getNetProductPrice(item);
        let initialPrice =
          (Number(item.unit_price) / 100) *
          Number(item.quantity ? item.quantity : 1);

        let taxPercentage = Number(item.tax_percentage) / 100;
        let netPrice = initialPrice / (1 + taxPercentage);

        let taxAmount = item.tax_percentage
          ? netPrice * (Number(item.tax_percentage) / 100)
          : 0;
        // @ts-ignore
        taxes[item.tax_percentage] =
          // @ts-ignore
          taxes[item.tax_percentage] + taxAmount;
      }
    });
    setTaxedProducts(taxes);
  };

  return (
    <Document>
      <Page
        size="A4"
        style={[
          styles.body,
          {
            fontFamily: layoutFooterDetails?.font
              ? layoutFooterDetails?.font === "Segeo UI"
                ? "Roboto"
                : layoutFooterDetails?.font
              : "Roboto",
          },
        ]}
      >
        <View
          fixed
          style={[
            styles.header,
            {
              backgroundColor:
                layoutFooterDetails?.header_background_color || "white",
              justifyContent:
                layoutFooterDetails?.headers_logo_position === "right"
                  ? "flex-end"
                  : layoutFooterDetails?.headers_logo_position === "left"
                    ? "flex-start"
                    : "center",
              height: "27mm",
              alignItems: "center",
            },
          ]}
        >
          {logoURL ? (
            <Image
              source={logoURL}
              style={{
                width: "auto",
                height:
                  layoutFooterDetails?.headers_logo_size === "large"
                    ? "52px"
                    : layoutFooterDetails?.headers_logo_size === "small"
                      ? "30px"
                      : "42px",
                paddingHorizontal: "10mm",
              }}
            />
          ) : null}
        </View>
        <View style={{ paddingHorizontal: "20mm", flex: 1 }}>
          <View style={{ height: "56.46mm" }}>
            <View
              style={{
                fontSize: "10px",
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-start",
                justifyContent: "space-between",
              }}
            >
              <View
                style={{
                  height: "45mm",
                  width: "86mm",
                  maxWidth: "86mm",
                  marginTop: "17.7mm",
                }}
              >
                {offerDetails?.sales_details?.to_company_name ? (
                  <View style={styles.headerItem}>
                    <Text style={[styles.menuItem]}>
                      {offerDetails?.sales_details?.to_company_name + ","}
                    </Text>
                  </View>
                ) : null}
                {offerDetails?.sales_details?.to_firstname ||
                offerDetails?.sales_details?.to_lastname ? (
                  <View style={styles.headerItem}>
                    <Text style={styles.menuItem}>
                      {offerDetails?.sales_details?.to_firstname +
                        " " +
                        offerDetails?.sales_details?.to_lastname +
                        ","}
                    </Text>
                  </View>
                ) : null}
                {offerDetails?.sales_details?.to_address ||
                offerDetails?.sales_details?.to_city ||
                offerDetails?.sales_details?.to_zipcode ||
                offerDetails?.sales_details?.to_country_name ? (
                  <View
                    style={{ display: "flex", flexDirection: "row", gap: 2 }}
                  >
                    <View style={{ fontWeight: "bold", color: "#00000090" }}>
                      {offerDetails?.sales_details?.to_address &&
                        offerDetails?.sales_details?.to_address?.split(
                          ","
                        )?.[0] && (
                          <Text>
                            {offerDetails?.sales_details?.to_address
                              ?.split(",")?.[0]
                              ?.trim() +
                              (offerDetails?.sales_details?.to_city ||
                              offerDetails?.sales_details?.to_zipcode ||
                              offerDetails?.sales_details?.to_country_name
                                ? ","
                                : ".")}
                          </Text>
                        )}
                      {offerDetails?.sales_details?.to_address &&
                        offerDetails?.sales_details?.to_address?.split(
                          ","
                        )?.[1] && (
                          <Text>
                            {offerDetails?.sales_details?.to_address
                              ?.split(",")?.[1]
                              ?.trim() +
                              (offerDetails?.sales_details?.to_city ||
                              offerDetails?.sales_details?.to_zipcode ||
                              offerDetails?.sales_details?.to_country_name
                                ? ","
                                : ".")}
                          </Text>
                        )}
                      {(offerDetails?.sales_details?.to_city ||
                        offerDetails?.sales_details?.to_zipcode) && (
                        <Text>
                          {(offerDetails?.sales_details?.to_zipcode
                            ? offerDetails?.sales_details?.to_zipcode + " "
                            : "") +
                            (offerDetails?.sales_details?.to_city
                              ? offerDetails?.sales_details?.to_city
                              : "") +
                            (offerDetails?.sales_details?.to_zipcode ||
                            offerDetails?.sales_details?.to_country_name
                              ? ","
                              : ".")}
                        </Text>
                      )}
                      {offerDetails?.sales_details?.to_country_name && (
                        <Text>
                          {offerDetails?.sales_details?.to_country_name + "."}
                        </Text>
                      )}
                    </View>
                  </View>
                ) : null}
              </View>
              <View
                style={{
                  height: "63mm",
                  width: "75mm",
                  maxWidth: "75mm",
                  marginTop: "5mm",
                }}
              >
                <View
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  {/* Company Name */}
                  <View style={{ display: "flex", flexDirection: "row" }}>
                    <Text
                      style={[
                        styles.menu,
                        { textAlign: "right", width: "40%", paddingRight: 5 },
                      ]}
                    >
                      {langObject?.company_name}:
                    </Text>
                    <Text
                      style={[
                        styles.menuItem,
                        { textAlign: "left", width: "60%" },
                      ]}
                    >
                      {offerDetails?.sales_details?.from_company_name}
                    </Text>
                  </View>
                  {/* Processor Name */}
                  <View style={{ display: "flex", flexDirection: "row" }}>
                    <Text
                      style={[
                        styles.menu,
                        { textAlign: "right", width: "40%", paddingRight: 5 },
                      ]}
                    >
                      {langObject?.name}:
                    </Text>
                    <Text
                      style={[
                        styles.menuItem,
                        { textAlign: "left", width: "60%" },
                      ]}
                    >
                      {offerDetails?.sales_details?.from_contact_person}
                    </Text>
                  </View>
                  {/* Company Email */}
                  <View style={{ display: "flex", flexDirection: "row" }}>
                    <Text
                      style={[
                        styles.menu,
                        { textAlign: "right", width: "40%", paddingRight: 5 },
                      ]}
                    >
                      {langObject?.email}:
                    </Text>
                    <Text
                      style={[
                        styles.menuItem,
                        { textAlign: "left", width: "60%" },
                      ]}
                    >
                      {offerDetails?.sales_details?.from_email}
                    </Text>
                  </View>
                  {/* langObject?.date */}
                  <View style={{ display: "flex", flexDirection: "row" }}>
                    <Text
                      style={[
                        styles.menu,
                        { textAlign: "right", width: "40%", paddingRight: 5 },
                      ]}
                    >
                      {langObject?.date}:
                    </Text>
                    {/* Invoice Date */}
                    {offerDetails?.offer_date && (
                      <Text
                        style={[
                          styles.menuItem,
                          { textAlign: "left", width: "60%" },
                        ]}
                      >
                        {lang === "de"
                          ? moment(offerDetails?.offer_date).format(
                              "DD.MM.yyyy"
                            )
                          : moment(offerDetails?.offer_date).format(
                              "YYYY-MM-DD"
                            )}
                      </Text>
                    )}
                  </View>
                  {/* Invoice Final Number */}
                  {offerDetails?.final_offer_number && (
                    <View style={{ display: "flex", flexDirection: "row" }}>
                      <Text
                        style={[
                          styles.menu,
                          { textAlign: "right", width: "40%", paddingRight: 5 },
                        ]}
                      >
                        {langObject?.offer_number}:
                      </Text>
                      <Text
                        style={[
                          styles.menuItem,
                          { textAlign: "left", width: "60%" },
                        ]}
                      >
                        {offerDetails?.final_offer_number}
                      </Text>
                    </View>
                  )}
                </View>
              </View>
            </View>
          </View>

          <View style={styles.content}>
            {/* Subject */}
            {offerDetails?.subject ? (
              <Text style={{ fontWeight: "bold" }}>
                {offerDetails?.subject}
              </Text>
            ) : null}
            {/* Header Notes */}
            {offerDetails?.offer_header_notes ? (
              <Text style={{ marginTop: "5mm" }}>
                {offerDetails?.offer_header_notes}
              </Text>
            ) : null}

            {/* Body */}
            {items?.length === 0 ? (
              <Text style={{ textAlign: "center", paddingVertical: "20px" }}>
                {langObject?.no_items}
              </Text>
            ) : (
              <View style={styles.table}>
                <View
                  style={[
                    styles.tableRow,
                    {
                      borderBottom: 1,
                      borderBottomColor: "black",
                      paddingVertical: 6,
                    },
                  ]}
                >
                  <View
                    style={{
                      width: "30%",
                    }}
                  >
                    <Text
                      style={[
                        styles.tableCellHeader,
                        {
                          width: "100%",
                          textAlign: "left",
                        },
                      ]}
                    >
                      {langObject?.item}
                    </Text>
                  </View>
                  <View
                    style={{
                      width: "10%",
                    }}
                  >
                    <Text style={styles.tableCellHeader}>
                      {langObject?.quantity}
                    </Text>
                  </View>
                  <View
                    style={{
                      width: "15%",
                    }}
                  >
                    <Text style={styles.tableCellHeader}>
                      {langObject?.unit_price}
                    </Text>
                  </View>
                  <View
                    style={{
                      width: "15%",
                    }}
                  >
                    <Text style={styles.tableCellHeader}>
                      {langObject?.tax_percent}
                    </Text>
                  </View>
                  <View
                    style={{
                      width: "15%",
                    }}
                  >
                    <Text style={styles.tableCellHeader}>
                      {langObject?.discount_percent}
                    </Text>
                  </View>
                  <View style={{ width: "15%" }}>
                    <Text style={styles.tableCellHeader}>
                      {langObject?.total}
                    </Text>
                  </View>
                </View>
                {items &&
                  items?.length > 0 &&
                  items?.map((item, index) => {
                    return (
                      <View style={styles.tableRow} key={index}>
                        <View style={[styles.tableCol]}>
                          {/* Product */}
                          <View
                            style={[
                              styles.tableCell,
                              {
                                textAlign: "left",
                                width: "30%",
                                alignSelf: "stretch",
                              },
                            ]}
                          >
                            {/* Product Title */}
                            <Text style={{ fontWeight: "semibold" }}>
                              {item?.title}
                            </Text>
                            {/* Product Description */}
                            <Text
                              style={{
                                fontSize: "8px",
                                maxLines: 2,
                                textOverflow: "ellipsis",
                              }}
                            >
                              {stripHtml(item?.description)}
                            </Text>
                          </View>
                          {/* Quantity */}
                          <View
                            style={[
                              styles.tableCell,
                              {
                                textAlign: "center",
                                alignSelf: "stretch",
                                width: "10%",
                              },
                            ]}
                          >
                            <Text>{item?.quantity}</Text>
                          </View>
                          {/* Unit Price */}
                          <View
                            style={[
                              styles.tableCell,
                              {
                                textAlign: "center",
                                alignSelf: "stretch",
                                width: "15%",
                              },
                            ]}
                          >
                            <Text>
                              {formatPriceLocale(lang, item?.unit_price)}
                            </Text>
                          </View>
                          {/* Tax Percentage */}
                          <View
                            style={[
                              styles.tableCell,
                              {
                                textAlign: "center",
                                alignSelf: "stretch",
                                width: "15%",
                              },
                            ]}
                          >
                            <Text>{item?.tax_percentage}</Text>
                          </View>
                          {/* Discount Percentage */}
                          <View
                            style={[
                              styles.tableCell,
                              {
                                textAlign: "center",
                                width: "15%",
                                alignSelf: "stretch",
                              },
                            ]}
                          >
                            <Text>{item?.discount_percentage}</Text>
                          </View>
                          {/* Product Total */}
                          <View
                            style={[
                              styles.tableCell,
                              {
                                textAlign: "right",
                                alignSelf: "stretch",
                                width: "15%",
                              },
                            ]}
                          >
                            <Text style={{ paddingRight: 4 }}>
                              {minus ? "-" : null}{" "}
                              {getProductPrice(item)?.toLocaleString(lang, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}{" "}
                              €
                            </Text>
                          </View>
                        </View>
                      </View>
                    );
                  })}
              </View>
            )}

            {/* Net Amount */}
            {items?.length !== 0 ? (
              <View
                style={{
                  marginVertical: "3mm",
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <View
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    flexDirection: "row",
                    fontSize: "10px",
                    width: "100%",
                  }}
                >
                  <Text
                    style={{
                      width: "15%",
                      textAlign: "right",
                      fontWeight: "semibold",
                      fontSize: "10px",
                    }}
                  >
                    {langObject?.sub_total}:
                  </Text>
                  <Text
                    style={{
                      width: "20%",
                      fontWeight: "bold",
                      textAlign: "right",
                      fontSize: "10px",
                    }}
                  >
                    {minus ? "-" : null}{" "}
                    {getNetTotalAmount(items)?.toLocaleString(lang, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}{" "}
                    €
                  </Text>
                </View>
              </View>
            ) : null}

            {/* Taxes */}
            {items?.length !== 0 ? (
              <>
                {Object.keys(taxedProducts)?.map((taxItem, index) => {
                  if (taxItem !== "0") {
                    return (
                      <View
                        key={`tax-item-${index}`}
                        style={{
                          marginVertical: "3mm",
                          width: "100%",
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        <View
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-end",
                            flexDirection: "row",
                            fontSize: "10px",
                            width: "100%",
                          }}
                        >
                          <Text
                            style={{
                              width: "15%",
                              textAlign: "right",
                              fontWeight: "semibold",
                              fontSize: "10px",
                            }}
                          >
                            Tax ({taxItem}%):
                          </Text>
                          <Text
                            style={{
                              width: "20%",
                              fontWeight: "bold",
                              textAlign: "right",
                              fontSize: "10px",
                            }}
                          >
                            +
                            {taxedProducts[taxItem]?.toLocaleString(lang, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}{" "}
                            €
                          </Text>
                        </View>
                      </View>
                    );
                  }
                })}
              </>
            ) : null}

            {/* Discount */}
            {items?.length !== 0 ? (
              <>
                {Object.keys(discountedProducts)?.map((discountItem, index) => {
                  if (discountItem !== "0") {
                    return (
                      <View
                        key={`discount-item-${index}`}
                        style={{
                          marginVertical: "3mm",
                          width: "100%",
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        <View
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-end",
                            flexDirection: "row",
                            fontSize: "10px",
                            width: "100%",
                          }}
                        >
                          <Text
                            style={{
                              width: "15%",
                              textAlign: "right",
                              fontWeight: "semibold",
                              fontSize: "10px",
                            }}
                          >
                            Discount ({discountItem}%):
                          </Text>
                          <Text
                            style={{
                              width: "20%",
                              fontWeight: "bold",
                              textAlign: "right",
                              fontSize: "10px",
                            }}
                          >
                            -
                            {discountedProducts[discountItem]?.toLocaleString(
                              lang,
                              {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }
                            )}{" "}
                            €
                          </Text>
                        </View>
                      </View>
                    );
                  }
                })}
              </>
            ) : null}

            {/* Gross Amount */}
            {items?.length !== 0 ? (
              <View
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <View
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    flexDirection: "row",
                    fontSize: "11px",
                    width: "100%",
                  }}
                >
                  <Text
                    style={{
                      width: "15%",
                      textAlign: "right",
                      fontWeight: "semibold",
                      fontSize: "11px",
                    }}
                  >
                    {langObject?.total}:
                  </Text>
                  <Text
                    style={{
                      width: "20%",
                      fontWeight: "bold",
                      textAlign: "right",
                      fontSize: "11px",
                    }}
                  >
                    {minus ? "-" : null}{" "}
                    {getTotalAmount(items)?.toLocaleString(lang, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}{" "}
                    €
                  </Text>
                </View>
              </View>
            ) : null}

            {/* Footer Notes */}
            {offerDetails?.offer_footer_notes ? (
              <View
                style={{
                  marginVertical: "5mm",
                  flex: 1,
                }}
              >
                <Text>{offerDetails?.offer_footer_notes}</Text>
              </View>
            ) : null}
          </View>
        </View>
        {/* Footer */}
        <View
          fixed
          style={{
            backgroundColor: layoutFooterDetails?.footer_background_color
              ? layoutFooterDetails?.footer_background_color
              : "#ffffff",
            color: layoutFooterDetails?.footer_text_color
              ? layoutFooterDetails?.footer_text_color
              : "#000000",
            fontSize: "10px",
            position: "relative",
            bottom: 0,
            paddingHorizontal: "20mm",
            marginTop: "5.5mm",
            paddingTop: "4mm",
            paddingBottom: layoutFooterDetails?.show_created_date_in_footer
              ? "0mm"
              : "4mm",
          }}
        >
          {layoutFooterDetails?.show_pages_in_footer ? (
            <Text
              style={[
                styles.pageNumber,
                {
                  color: "#000000",
                  position: "absolute",
                  right: "25mm",
                  top: "-18px",
                },
              ]}
              render={({ pageNumber, totalPages }) =>
                `${langObject?.sales_pdf_page} ${pageNumber} ${langObject?.sales_pdf_page_of} ${totalPages}`
              }
            />
          ) : null}
          <View
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              gap: "3mm",
            }}
          >
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                alignContent: "flex-start",
              }}
            >
              {layoutFooterDetails?.footers_text_1 &&
                (layoutFooterDetails?.footers_count || 1) >= 1 && (
                  <Html style={styles.footerText}>
                    {layoutFooterDetails?.footers_text_1}
                  </Html>
                )}
              {layoutFooterDetails?.footers_text_2 &&
                (layoutFooterDetails?.footers_count || 1) >= 2 && (
                  <Html style={styles.footerText}>
                    {layoutFooterDetails?.footers_text_2}
                  </Html>
                )}
              {layoutFooterDetails?.footers_text_3 &&
                (layoutFooterDetails?.footers_count || 1) >= 3 && (
                  <Html style={styles.footerText}>
                    {layoutFooterDetails?.footers_text_3}
                  </Html>
                )}
              {layoutFooterDetails?.footers_text_4 &&
                (layoutFooterDetails?.footers_count || 1) === 4 && (
                  <Html style={styles.footerText}>
                    {layoutFooterDetails?.footers_text_4}
                  </Html>
                )}
            </View>
            {layoutFooterDetails?.show_created_date_in_footer && (
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  paddingBottom: "4mm",
                }}
              >
                {layoutFooterDetails?.show_created_date_in_footer &&
                  offerDetails?.sales_details?.created_at && (
                    <Text
                      style={{ justifyContent: "flex-start", fontSize: "10px" }}
                    >
                      {lang === "de"
                        ? moment(
                            offerDetails?.sales_details?.created_at
                          ).format("DD.MM.yyyy")
                        : moment(
                            offerDetails?.sales_details?.created_at
                          ).format("YYYY-MM-DD")}
                    </Text>
                  )}
              </View>
            )}
          </View>
        </View>

        <View fixed style={[styles.marker, styles.mark1]}></View>
        <View fixed style={[styles.marker, styles.mark2]}></View>
        <View fixed style={[styles.marker, styles.mark3]}></View>
      </Page>
    </Document>
  );
};

export default OfferPDF;
