import { FC, useState } from "react";
// import {useIntl} from 'react-intl'

// custom
import RolesList from "./RolesList";

interface Props {}

const UserRolesAndResources: FC<Props> = () => {
  // const intl = useIntl()

  // state
  const [tabActiveIndex, setTabActiveIndex] = useState<number>(0);

  const onTabChange = (index: number) => {
    if (index !== tabActiveIndex) {
      setTabActiveIndex(index);
    }
  };

  return <RolesList />;
};

export default UserRolesAndResources;
