/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../setup";
import { useDispatch } from "react-redux";
import DropDown from "../../../sharedComponents/dropdown/Dropdown";
import { useIntl } from "react-intl";
import { GroupType } from "../../masterdata/types/GetGroupsListResponseType";
import { Button, Modal } from "react-bootstrap";
import { actions } from "../redux";

import { LabelType } from "../../masterdata/types/GetLabelsListResponseType";
// import {
//   IndustryGroupType,
// } from '../../partnersMasterData/types/getIndustryGroupsResponseType'
import { CountryType } from "../../masterdata/types/GetCountriesListResponseTypes";
import clsx from "clsx";
import { CustomersFilterType } from "../types/getCustomersListResponseType";
import CloseButton from "../../../sharedComponents/Buttons/CloseButton";

interface Props {
  show: boolean;
  closeModal: () => void;
  loading?: boolean;
  type?: string;
  setSelectFilters?: any;
  selectFilters?: any;
  setIsFilterOpen?: any;
}

const CustomersListFilterModal: FC<Props> = ({
  show,
  closeModal,
  loading,
  type,
  setSelectFilters,
  selectFilters,
  setIsFilterOpen,
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const filterData = useSelector<RootState>(
    //@ts-ignore
    ({ customerManagement }) => customerManagement.filters
  ) as CustomersFilterType;

  // selectors
  const filters = type === "select" ? selectFilters : filterData;

  // const products = useSelector<RootState>(
  //   (state: any) => state.productsListDetails.productsList
  // ) as any;
  // const subscriptions = useSelector<RootState>(
  //   (state: any) => state.subscriptionListDetails.subscriptionsList
  // ) as any;

  const countriesList = useSelector<RootState>(
    ({ masterdata }) => masterdata.countriesList
  ) as CountryType[];
  const labelsList = useSelector<RootState>(
    ({ masterdata }) => masterdata.labelsList
  ) as LabelType[];
  // const industryGroupsList = useSelector<RootState>(
  //   ({masterdata}) => masterdata.industryGroupsList
  // ) as IndustryGroupType[]

  // state
  // const [isBlocked, setIsBlocked] = useState(filters.is_blocked || false);
  const [selectedLabels, setSelectedLabels] = useState<LabelType[]>(
    filters.labels || []
  );
  const [selectedCountry, setSelectedCountry] = useState<CountryType>(
    filters.country
  );
  // const [selectedProduct, setSelectedProduct] = useState<number>(
  //   filters.product_id
  // );
  // const [selectedSubscription, setSelectedSubscription] = useState<number>(
  //   filters.subscription_id
  // );
  const [city, setCity] = useState<string>(filters.city || "");
  const [zipCode, setZipCode] = useState<string>(filters.zip_code || "");

  const handleOnSelectItem = (
    item: GroupType,
    type: "sector" | "group" | "category" | "tag" | "label"
  ) => {
    const selectedItemsArr = selectedLabels;
    const index = selectedItemsArr.findIndex(
      (selectedItem) => selectedItem.id === item.id
    );

    // if doesnt exist push item
    if (index === -1) {
      type === "label" && setSelectedLabels(() => [...selectedLabels, item]);
    }
    // if already exists remove item
    else {
      type === "label" &&
        setSelectedLabels((prevState) => {
          prevState.splice(index, 1);
          return [...prevState];
        });
    }
  };

  // const flattenIndustryGroups = (industryGroups: IndustryGroupType[] = []) => {
  //   const sectors: any = []
  //   industryGroups.map((industryGroup) => {
  //     if (industryGroup?.sectors?.length > 0) {
  //       sectors.push({
  //         id: industryGroup.id,
  //         name: industryGroup.name,

  //         isGroupLabel: true,
  //       })
  //     }
  //     ;(industryGroup.sectors || []).map((industryGroupSector) => {
  //       // sectors.push(industryGroupSector.name + '(' + industryGroup.name + ')')
  //       sectors.push({
  //         id: industryGroupSector.id,
  //         name: industryGroupSector.name,
  //       })
  //     })
  //   })
  //   return sectors
  // }

  // const industrySectors = flattenIndustryGroups(industryGroupsList)

  const handleResetFilters = () => {
    //@ts-ignore
    setSelectedCountry(null);
    //@ts-ignore
    // setSelectedProduct(null);
    //@ts-ignore
    // setSelectedSubscription(null);
    setSelectedLabels([]);
    // setIsBlocked(false);
    setCity("");
    setZipCode("");
  };
  return (
    <Modal
      show={show}
      centered
      dialogClassName="medium-size-modal"
      className="overflow-visible kt-modal"
      contentClassName={loading ? "pe-none" : ""}
      backdrop="static"
    >
      <Modal.Header>
        <Modal.Title>
          {intl.formatMessage({ id: "CONTACTS_FILTER" })}
        </Modal.Title>
        <div className="d-flex justify-content-end align-items-center">
          <button
            className="btn btn-sm btn-primary me-2"
            onClick={() => {
              // type === "select"
              //   ? setSelectFilters({})
              //   : dispatch(actions.setFilters({} as CustomersFilterType));
              //type === "select" ? handleResetFilters() : closeModal();
              handleResetFilters();
            }}
          >
            {intl.formatMessage({ id: "RESET_BUTTON" })}
          </button>
          <CloseButton
            onClose={() => {
              type === "select" && setIsFilterOpen(false);
              closeModal();
            }}
          />
        </div>
      </Modal.Header>
      <div className="">
        <Modal.Body className="overflow-inherit">
          {/* city  & zipcode*/}
          <div className="row">
            {/* city */}
            <div className="fv-row tab-field col-lg-6">
              <label className="form-label tab-field-label">
                {intl.formatMessage({
                  id: "PARTNERS_GENERAL_DETAILS_FIELD_CITY",
                })}
              </label>
              <input
                placeholder={""}
                className={clsx(
                  "form-control form-control-lg form-control-solid"
                )}
                onChange={(e) => {
                  setCity(e.target.value);
                }}
                autoComplete="off"
                value={city}
              />
            </div>

            {/* zip code */}
            <div className="fv-row tab-field col-lg-6">
              <label className="form-label tab-field-label">
                {intl.formatMessage({
                  id: "PARTNERS_GENERAL_DETAILS_FIELD_ZIPCODE",
                })}
              </label>
              <input
                placeholder={""}
                className={clsx(
                  "form-control form-control-lg form-control-solid"
                )}
                onChange={(e) => {
                  setZipCode(e.target.value);
                }}
                autoComplete="off"
                value={zipCode}
              />
            </div>
          </div>

          {/* country */}
          <div className="tab-field">
            <label className="form-label tab-field-label">
              {intl.formatMessage({
                id: "PARTNERS_LIST_TABLE_FILTER_COUNTRY_FIELD",
              })}
            </label>
            <DropDown
              id="countriesDropDown"
              items={countriesList}
              selectedItem={selectedCountry}
              // @ts-ignore
              onSelectItem={(item: CountryType) => {
                setSelectedCountry(item);
              }}
              displayValue={selectedCountry?.name || ""}
              // @ts-ignore
              displayFunc={(item: CountryType) => {
                return item.emoji + " " + item.name;
              }}
            />
          </div>

          {/* Labels */}
          <div className="tab-field mb-0">
            <label className="form-label tab-field-label">
              {intl.formatMessage({
                id: "PARTNERS_LIST_TABLE_FILTER_LABELS_FIELD",
              })}
            </label>
            <DropDown
              id="partners-filter-labels-dropdown"
              hideSearch
              multiSelect
              items={labelsList}
              displayFunc={(item) => item.name}
              onSelectItem={(item) => {
                //   @ts-ignore
                handleOnSelectItem(item, "label");
              }}
              selectedItems={selectedLabels}
            />
          </div>

          {/* <div className="tab-field">
              <label className="form-label tab-field-label">
                {intl.formatMessage({
                  id: "PARTNERS_LIST_TABLE_FILTER_PRODUCTS_FIELD",
                })}
              </label>
              <DropDown
                id="countriesDropDown"
                //@ts-ignore
                items={products}
                selectedItem={products?.find(
                  (item: any) => item.id === selectedProduct
                )}
                // @ts-ignore
                onSelectItem={(item: any) => {
                  setSelectedProduct(item.id);
                }}
                displayValue={
                  products?.find((item: any) => item.id === selectedProduct)
                    ?.name || ""
                }
                // @ts-ignore
                displayFunc={(item: any) => {
                  return item.name;
                }}
              />
            </div> */}

          {/* <div className="tab-field">
              <label className="form-label tab-field-label">
                {intl.formatMessage({
                  id: "PARTNERS_LIST_TABLE_FILTER_SUBSCRIPTIONS_FIELD",
                })}
              </label>
              <DropDown
                id="subscriptionsDropDown"
                //@ts-ignore
                items={subscriptions}
                selectedItem={subscriptions?.find(
                  (item: any) => item.id === selectedSubscription
                )}
                // @ts-ignore
                onSelectItem={(item: any) => {
                  setSelectedSubscription(item.id);
                }}
                displayValue={
                  subscriptions?.find(
                    (item: any) => item.id === selectedSubscription
                  )?.name || ""
                }
                // @ts-ignore
                displayFunc={(item: any) => {
                  return item.name;
                }}
              />
            </div> */}

          {/* Blocked */}
          {/* <div className="d-flex align-items-center">
              <label className="form-label tab-field-label">
                {intl.formatMessage({
                  id: "CUSTOMER_MANAGEMENT_TABLE_FILTER_FIELD_BLOCKED",
                })}
              </label>
              <div className="form-check form-switch form-switch-sm form-check-custom form-check-solid mx-8">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="topPost"
                  value=""
                  checked={isBlocked}
                  style={{ width: "45px", height: "22px" }}
                  onChange={(e) => {
                    setIsBlocked(e.target.checked);
                  }}
                />
              </div>
            </div> */}
        </Modal.Body>
      </div>
      <Modal.Footer className="justify-content-center">
        <Button
          variant="secondary"
          onClick={() => {
            type === "select" && setIsFilterOpen(false);
            closeModal();
          }}
        >
          {intl.formatMessage({ id: "CLOSE_BUTTON" })}
        </Button>
        <Button
          className="btn btn-primary"
          onClick={() => {
            // if (
            //   (selectedLabels && selectedLabels.length > 0) ||
            //   (selectedSectors && selectedSectors.length > 0) ||
            //   selectedCountry ||
            //   city ||
            //   zipCode
            // ) {
            type === "select"
              ? setSelectFilters({
                  // is_blocked: isBlocked,
                  zip_code: zipCode,
                  city: city,
                  country: selectedCountry,
                  labels: selectedLabels,
                  // product_id: selectedProduct,
                  // subscription_id: selectedSubscription,
                })
              : dispatch(
                  actions.setFilters({
                    // is_blocked: isBlocked,
                    zip_code: zipCode,
                    city: city,
                    country: selectedCountry,
                    labels: selectedLabels,
                    // product_id: selectedProduct,
                    // subscription_id: selectedSubscription,
                  })
                );
            // }
            closeModal();
            type === "select" && setIsFilterOpen(false);
          }}
        >
          <span className="indicator-label">
            {intl.formatMessage({ id: "SUBMIT_BUTTON" })}
          </span>
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CustomersListFilterModal;
