import { useIntl } from "react-intl";
import { TeamType, TeamUserType } from "../types/TeamsResponseType";
import React, { FC, useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useFullName } from "../../../utils/getFullName";
import { deleteTeamUser, getTeamByID } from "../redux/TeamsAPI";
import SimpleLoader from "../../../sharedComponents/Loader/SimpleLoader";
import { useGlobalModalContext } from "../../../sharedComponents/modals/ModalContext";
import { SVGICON } from "../../../../_metronic/helpers";
import DeleteIcon from "../../../../_metronic/assets/icons/delete.svg";
import notFound from "../../../../_metronic/assets/icons/notFound.png";
import Checkbox from "../../../sharedComponents/Checkbox/Checkbox";
import { UserType } from "../../userManagement/types/getUsersListResponseType";
import _ from "lodash";
import { useHistory } from "react-router-dom";
import { deleteConfirmAlert } from "../../../sharedComponents/Alert";
import NoItemsFound from "../../../sharedComponents/NoItemsFound/NoItemsFound";
import CloseButton from "../../../sharedComponents/Buttons/CloseButton";

interface Props {
  show: boolean;
  closeModal: () => void;
  selectedTeam: TeamType | undefined;
  getTeamsListAPI: () => void;
  isSelectionModal?: boolean;
}

const TeamUsersModal: FC<Props> = ({
  show,
  selectedTeam,
  closeModal,
  getTeamsListAPI,
  isSelectionModal,
}) => {
  const {
    showDeleteConfirmModal,
    setDeleteModalLoading,
    hideDeleteConfirmModal,
  } = useGlobalModalContext();
  const intl = useIntl();
  const history = useHistory();
  // state
  const [loading, setLoading] = useState(false);
  const [contacts, setContacts] = useState<TeamUserType[]>(
    selectedTeam?.team_users || []
  );
  const [checkedCustomers, setCheckedCustomers] = useState<TeamUserType[]>([]);
  const [checkAllItems, setCheckAllItems] = useState<boolean>(false);

  useEffect(() => {
    if (selectedTeam) {
      setContacts(selectedTeam?.team_users);
    }
  }, [selectedTeam]);

  useEffect(() => {
    // if (selectedTeam && selectedTeam.id) {
    //   getTeamDetailsById();
    // }
  }, []);

  const onCheckedChange = (customer: TeamUserType) => {
    let checkedCustomersCopy = _.clone(checkedCustomers);
    const index = checkedCustomersCopy.findIndex(
      (checkedCustomer) => checkedCustomer.id === customer.id
    );
    if (index > -1) {
      checkedCustomersCopy.splice(index, 1);
    } else {
      checkedCustomersCopy.push(customer);
    }
    setCheckedCustomers(checkedCustomersCopy);
  };

  const isChecked = (customer: TeamUserType) => {
    const index = checkedCustomers.findIndex(
      (checkedCustomer) => checkedCustomer.id === customer.id
    );
    if (index > -1) {
      return true;
    }
    return false;
  };

  const deleteUserHandle = () => {
    const ids = checkedCustomers.map((checkedTeam) => {
      return checkedTeam.id;
    });
    // @ts-ignore
    deleteTeamUser(selectedTeam?.id, ids)
      .then((data) => {})
      .catch((e) => {})
      .finally(() => {
        getTeamsListAPI();
        // getTeamDetailsById();
      });
  };

  const deleteUser = (id: number) => {
    // setDeleteModalLoading(true);

    // @ts-ignore
    deleteTeamUser(selectedTeam?.id, [id])
      .then((data) => {})
      .catch((e) => {})
      .finally(() => {
        // setDeleteModalLoading(false);
        // hideDeleteConfirmModal();
        getTeamsListAPI();
        // getTeamDetailsById();
      });
  };

  const onAllItemsCheckChange = () => {
    const updatedCheckAllItems = !checkAllItems;
    setCheckAllItems(updatedCheckAllItems);
    if (updatedCheckAllItems) {
      setCheckedCustomers(contacts);
    } else {
      setCheckedCustomers([]);
    }
  };

  return (
    <div>
      <Modal
        show={show}
        centered
        dialogClassName="large-size-modal"
        className="kt-modal"
        backdrop="static"
        id="MailContactsModel"
        onHide={closeModal}
      >
        <Modal.Header>
          <div>
            <h3>{intl.formatMessage({ id: "TEAMS_USERS_TITLE" })}</h3>
          </div>

          <div className="d-flex align-items-center">
            {checkedCustomers.length > 0 && (
              <>
                <span className="text-dark text-hover-primary fs-6 fw-bolder mx-4">
                  {checkedCustomers.length}{" "}
                  {intl.formatMessage({ id: "SELECTED" })}
                </span>
                <button
                  className="btn  btn-danger"
                  onClick={() => {
                    deleteConfirmAlert(
                      intl.formatMessage({
                        id: "TEAM_USERS_DELETE_CONFIRM_MESSAGE",
                      }),
                      (data) => {
                        if (data === "Yes") deleteUserHandle();
                      }
                    );
                    // showDeleteConfirmModal(
                    //   intl.formatMessage({
                    //     id: "TEAM_USERS_DELETE_CONFIRM_MESSAGE",
                    //   }),
                    //   () => {
                    //     deleteUserHandle();
                    //   }
                    // );
                  }}
                >
                  <span className="indicator-label">
                    {intl.formatMessage({ id: "DELETE_BUTTON" })}
                  </span>
                </button>
              </>
            )}
            <CloseButton onClose={closeModal} />
          </div>
        </Modal.Header>

        <div className="overflow-auto">
          <Modal.Body className="overflow-inherit">
            <div>
              <table className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer mb-0">
                <thead>
                  <tr className="text-start text-muted fw-bolder fs-7 text-uppercase gs-0">
                    <th className="w-25px">
                      {/* <Checkbox
                          onChange={onAllItemsCheckChange}
                          checked={checkAllItems}
                          disabled={false}
                        /> */}
                    </th>
                    <th className="min-w-125px">
                      {intl.formatMessage({
                        id: "TEAM_USERS_NAME_TITLE",
                      })}
                    </th>
                    <th className="min-w-125px">
                      {intl.formatMessage({
                        id: "TEAM_USERS_ROLE_TITLE",
                      })}
                    </th>

                    <th className="min-w-100px"></th>
                  </tr>
                </thead>
                <tbody>
                  <>
                    {/* {contacts?.length === 0 && loading && (
                        <td
                          valign="top"
                          colSpan={7}
                          className="dataTables_empty"
                        >
                          <div className="d-flex flex-column flex-center">
                            <SimpleLoader />
                          </div>
                        </td>
                      )} */}

                    {contacts?.length === 0 && (
                      <NoItemsFound languageKey="NO_ITEMS_FOUND" />
                    )}

                    {contacts &&
                      contacts?.length > 0 &&
                      contacts?.map((contact: TeamUserType, i) => {
                        return (
                          <tr key={i}>
                            <td>
                              {isSelectionModal && isSelectionModal ? null : (
                                <div className="form-check form-check-sm form-check-custom form-check-solid me-2">
                                  <Checkbox
                                    onChange={() => {
                                      onCheckedChange(contact);
                                    }}
                                    checked={isChecked(contact)}
                                  />
                                </div>
                              )}
                            </td>
                            <td>
                              <div className="d-flex align-items-center">
                                <div className="symbol symbol-circle symbol-50px overflow-hidden me-3">
                                  <div className="symbol-label">
                                    <div className="symbol-label fs-3 bg-light-danger text-danger text-uppercase cursor-pointer">
                                      {contact?.first_name?.[0]}
                                      {contact?.last_name?.[0]}
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="d-flex justify-content-start flex-column"
                                  onClick={() => {
                                    history.push(`/system/user/edituser`, {
                                      userDetails: contact,
                                    });
                                  }}
                                >
                                  <span
                                    className="text-gray-800 fw-bold text-hover-primary text-capitalize mb-1"
                                    role="button"
                                  >
                                    {contact?.first_name} {contact?.last_name}
                                  </span>
                                  <span className="text-gray-600 fw-bold">
                                    {contact?.email}
                                  </span>
                                </div>
                              </div>
                            </td>

                            <td className="mw-200px">
                              <div className="badge badge-light fw-bolder me-2 mb-2 text-uppercase badge-light-success">
                                {contact?.role?.[0]}
                              </div>
                            </td>
                            {isSelectionModal && isSelectionModal ? null : (
                              <td className="">
                                <div className="d-flex justify-content-end ">
                                  <button
                                    className="btn btn-icon btn-light btn-active-light-danger btn-sm"
                                    onClick={(e) => {
                                      deleteConfirmAlert(
                                        intl.formatMessage({
                                          id: "TEAM_USERS_DELETE_CONFIRM_MESSAGE",
                                        }),
                                        (data) => {
                                          if (data === "Yes")
                                            deleteUser(contact?.id);
                                        }
                                      );
                                      // showDeleteConfirmModal(
                                      //   intl.formatMessage({
                                      //     id: "TEAM_USERS_DELETE_CONFIRM_MESSAGE",
                                      //   }),
                                      //   () => {
                                      //      deleteUser(contact?.id);
                                      //   }
                                      // );
                                    }}
                                  >
                                    <SVGICON
                                      src={DeleteIcon}
                                      className="svg-icon-3"
                                    />
                                  </button>
                                </div>
                              </td>
                            )}
                          </tr>
                        );
                      })}
                  </>
                </tbody>
              </table>
            </div>
          </Modal.Body>
        </div>
        <Modal.Footer className="justify-content-center">
          <Button variant="secondary" onClick={closeModal}>
            {intl.formatMessage({ id: "CLOSE_BUTTON" })}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default TeamUsersModal;
