import clsx from "clsx";
import { Formik } from "formik";
import _ from "lodash";
import React, { FC, useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import * as Yup from "yup";
// @ts-ignore
import DragSortableList from "react-drag-sortable";
import notFound from "../../../../_metronic/assets/icons/notFound.png";
import Constants from "../../../config/Constants";

// images
import DeleteIcon from "../../../../_metronic/assets/icons/delete.svg";
import DragIcon from "../../../../_metronic/assets/icons/drag.svg";
import EditIcon from "../../../../_metronic/assets/icons/edit.svg";
import PlusIcon from "../../../../_metronic/assets/icons/plus.svg";
import SearchIcon from "../../../../_metronic/assets/icons/search.svg";

// custom
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../setup";
import { SVGICON } from "../../../../_metronic/helpers";
import Pagination from "../../../sharedComponents/pagination/Pagination";
import { actions } from "../redux";
import {
  createNewContactType,
  deleteContactType,
  getContactTypesList,
  massDeleteContactTypes,
  sortContactTypes,
  updateContactType,
} from "../redux/ContactTypesAPI";
import { GroupType } from "../types/GetGroupsListResponseType";
import { sortGroupsParams } from "../types/request";
import { useGlobalModalContext } from "../../../sharedComponents/modals/ModalContext";
import { enablePermissionForCrud } from "../../../utils/PermisisionEnabledForResource";
import { RoleType } from "../../RolesAndPermissions/types/getRolesResponseType";
import ScrollToTop from "../../../sharedComponents/ScrollToTop/ScrollToTop";
import NoItemsFound from "../../../sharedComponents/NoItemsFound/NoItemsFound";
import {
  errorToast,
  successToast,
} from "../../../sharedComponents/toasts/Toasts";
import CloseButton from "../../../sharedComponents/Buttons/CloseButton";

interface ModelComponentProps {
  ModalProps: ContactTypeModalProps;
  loading: boolean;
  closeModal: () => void;
  onFormSubmit: (
    type: string,
    contactType: GroupType,
    values: FormValuesType,
    setStatus: (msg: any) => void
  ) => void;
}

interface ContactTypeModalProps {
  show: boolean;
  type?: string;
  contactType?: GroupType;
}

interface FormValuesType {
  contactTypeName?: string | undefined;
}

interface SortedObject {
  contactType: GroupType;
}

const ContactTypesModal: FC<ModelComponentProps> = ({
  ModalProps,
  closeModal,
  loading,
  onFormSubmit,
}) => {
  const intl = useIntl();
  const { show, type, contactType } = ModalProps;
  const initialValues = {
    contactTypeName: type === "edit" ? contactType?.name : "",
  };

  const userRoleDetails = useSelector<RootState>(
    // @ts-ignore
    (state) => state.auth.roleDetails
  ) as RoleType;
  //@ts-ignore
  const crudPermission: any = enablePermissionForCrud(
    userRoleDetails,
    "contacts-types"
  );
  const AddNewContactTypeSchema = Yup.object().shape({
    contactTypeName: Yup.string()
      .max(60, intl.formatMessage({ id: "MASTERDATA_60SYMBOLS_REQUIRED" }))
      .required(
        intl.formatMessage({
          id: "MASTERDATA_CONTACT_TYPE_CONTACT_NAME_REQUIRED",
        })
      ),
  });

  return (
    <Modal
      show={show}
      centered
      contentClassName={loading ? "pe-none" : ""}
      dialogClassName={"medium-size-modal"}
      className="kt-modal"
      backdrop="static"
      onHide={closeModal}
    >
      <Modal.Header>
        <Modal.Title>
          {type === "edit"
            ? intl.formatMessage({ id: "MASTERDATA_CONTACT_TYPE_EDIT_TITLE" })
            : intl.formatMessage({
                id: "MASTERDATA_CONTACT_TYPE_EDIT_NEW_TITLE",
              })}
        </Modal.Title>
        <CloseButton onClose={closeModal} />
      </Modal.Header>
      <Formik
        initialValues={initialValues}
        validationSchema={AddNewContactTypeSchema}
        onSubmit={(values, { setStatus, setSubmitting }) => {
          // @ts-ignore
          onFormSubmit(type, contactType, values, setStatus);
        }}
      >
        {({
          errors,
          values,
          touched,
          handleChange,
          handleBlur,
          setFieldValue,
          handleSubmit,
          status,
        }) => (
          <>
            <div className="overflow-auto">
              <Modal.Body
                className={`overflow-inherit ${
                  !crudPermission?.edit && type === "edit" && "pe-none"
                }`}
              >
                {status && (
                  <div className="mb-10 alert alert-danger">
                    <div className="alert-text font-weight-bold">{status}</div>
                  </div>
                )}
                <div className="tab-field mb-0">
                  <label className="d-flex align-items-center tab-field-label">
                    <span className="required">
                      {intl.formatMessage({
                        id: "MASTERDATA_CONTACT_TYPE_CONTACT_NAME_FIELD_NAME",
                      })}
                    </span>
                  </label>

                  <input
                    placeholder={intl.formatMessage({
                      id: "MASTERDATA_CONTACT_TYPE_CONTACT_NAME_PLACEHOLDER",
                    })}
                    // {...formik.getFieldProps('contactTypeName')}
                    className={clsx(
                      "form-control form-control-lg form-control-solid"
                    )}
                    name="contactTypeName"
                    autoComplete="off"
                    onChange={handleChange("contactTypeName")}
                    value={values.contactTypeName}
                  />
                  {touched.contactTypeName && errors.contactTypeName && (
                    <div className="text-danger">
                      <span role="alert">{errors.contactTypeName}</span>
                    </div>
                  )}
                </div>
              </Modal.Body>
            </div>

            <Modal.Footer className="justify-content-center">
              <Button variant="secondary" onClick={closeModal}>
                {intl.formatMessage({ id: "CLOSE_BUTTON" })}
              </Button>
              {((crudPermission?.edit && type === "edit") ||
                (crudPermission?.create && type === "new")) && (
                <Button
                  variant="primary"
                  onClick={() => {
                    handleSubmit();
                  }}
                >
                  {!loading && intl.formatMessage({ id: "SUBMIT_BUTTON" })}
                  {loading && (
                    <span
                      className="indicator-progress"
                      style={{ display: "block" }}
                    >
                      {intl.formatMessage({
                        id: "MASTERDATA_CONTACT_TYPE_LOADING_MESSAGE",
                      })}{" "}
                      <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                    </span>
                  )}
                </Button>
              )}
            </Modal.Footer>
          </>
        )}
      </Formik>
    </Modal>
  );
};

const ContactDetails: React.FC = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const contactTypesList = useSelector<RootState>(
    ({ masterdata }) => masterdata.contactTypesList
  ) as GroupType[];

  const userRoleDetails = useSelector<RootState>(
    // @ts-ignore
    (state) => state.auth.roleDetails
  ) as RoleType;
  //@ts-ignore
  const crudPermission: any = enablePermissionForCrud(
    userRoleDetails,
    "contacts-types"
  );

  // state
  const [loading, setLoading] = useState(false);
  const [displayContactTypesList, setDisplayContactTypesList] =
    useState(contactTypesList);
  const [showContactTypesModal, setShowContactTypesModal] =
    useState<ContactTypeModalProps>({
      show: false,
    });
  const [checkedContactTypes, setCheckedContactTypes] = useState<GroupType[]>(
    []
  );
  const [searchText, setSearchText] = useState<string>("");
  const [checkAllContactTypes, setCheckAllContactTypes] =
    useState<boolean>(false);
  const [activePage, setActivePage] = useState<number>(1);
  const [itemsPerPage, setItemsPerPage] = useState(
    Constants.defaultItemsPerPageCount
  );
  const {
    showDeleteConfirmModal,
    hideDeleteConfirmModal,
    setDeleteModalLoading,
  } = useGlobalModalContext();

  const getContactTypesListAPI = () => {
    getContactTypesList()
      .then(({ data: { data } }) => {
        dispatch(actions.setContactTypesList(data));
      })
      .catch((e) => {
        setLoading(false);
        // console.log(`ERROR: getContactTypesListAPI`, e)
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const updateDisplayContactTypesList = () => {
    const updatedContactTypesList = contactTypesList.filter((contactType) => {
      if (
        (contactType.name || "")
          .toLocaleLowerCase()
          .includes((searchText || "").toLocaleLowerCase())
      ) {
        return true;
      }
      return false;
    });
    // save updatedlist to display
    setDisplayContactTypesList(updatedContactTypesList);
  };

  useEffect(() => {
    setLoading(true);
    // get contactTypes API call
    getContactTypesListAPI();
  }, []);

  const onPageClick = (page: number) => {
    setActivePage(page);
  };

  useEffect(() => {
    updateDisplayContactTypesList();

    return () => {
      setDisplayContactTypesList(contactTypesList);
    };
  }, [contactTypesList, searchText]);

  useEffect(() => {
    if (
      checkAllContactTypes &&
      checkedContactTypes.length !== contactTypesList.length
    ) {
      setCheckAllContactTypes(false);
    }
    if (
      checkedContactTypes.length > 0 &&
      checkedContactTypes.length === contactTypesList.length
    ) {
      setCheckAllContactTypes(true);
    }
  }, [checkedContactTypes]);

  const closeContactTypesModal = () => {
    setShowContactTypesModal({
      show: false,
      type: "",
      contactType: undefined,
    });
  };

  const onSearchTextChange = (text: string) => {
    setSearchText((text || "").trim());
  };

  const onFormSubmit = (
    type: string,
    contactType: GroupType,
    values: FormValuesType,
    setStatus: (msg: any) => void
  ) => {
    if (type === "new") {
      if (values.contactTypeName) {
        setLoading(true);
        createNewContactType(values.contactTypeName)
          .then(() => {
            closeContactTypesModal();
            successToast(
              intl.formatMessage({
                id: "MASTERDATA_CONTACT_TYPES_CREATE_SUCCESS_MESSAGE",
              })
            );
          })
          .catch((err) => {
            err.response?.data?.errors?.name
              ? setStatus(err.response.data?.errors?.name)
              : setStatus(
                  intl.formatMessage({
                    id: "MASTERDATA_CONTACT_TYPE_CREATE_FAILURE_MESSAGE",
                  })
                );
          })
          // .catch((e) => {
          //   closeContactTypesModal();
          //   errorAlert(
          //     intl.formatMessage({
          //       id: "MASTERDATA_CONTACT_TYPE_CREATE_FAILURE_MESSAGE",
          //     })
          //   );
          // })
          .finally(() => {
            // recall  get contactTypes list API
            getContactTypesListAPI();
            setLoading(false);
          });
      }
    } else {
      if (values.contactTypeName) {
        setLoading(true);
        updateContactType(contactType.id, values.contactTypeName)
          .then(() => {
            closeContactTypesModal();
            successToast(
              intl.formatMessage({
                id: "MASTERDATA_CONTACT_TYPE_EDIT_SUCCESS_MESSAGE",
              })
            );
          })
          .catch((e) => {
            closeContactTypesModal();
            errorToast(
              intl.formatMessage({
                id: "MASTERDATA_CONTACT_TYPE_EDIT_FAILURE_MESSAGE",
              })
            );
          })
          .finally(() => {
            // recall  get contactTypes list API
            getContactTypesListAPI();
            setLoading(false);
          });
      }
    }
  };

  const deleteExistingContactType = (id: number) => {
    setLoading(true);
    setDeleteModalLoading(true);
    deleteContactType(id)
      .then(() => {
        successToast(
          intl.formatMessage({
            id: "MASTERDATA_CONTACT_TYPE_DELETE_SUCCESS_MESSAGE",
          })
        );
      })
      .catch(() => {
        errorToast(
          intl.formatMessage({
            id: "MASTERDATA_CONTACT_TYPE_DELETE_FAILURE_MESSAGE",
          })
        );
      })
      .finally(() => {
        setDeleteModalLoading(false);
        hideDeleteConfirmModal();
        closeContactTypesModal();
        setCheckAllContactTypes(false);
        setCheckedContactTypes([]);
        // recall  get contactTypes list API
        getContactTypesListAPI();
        setLoading(false);
      });
  };

  const onContactTypesSortChange = (sortedList: Array<SortedObject>) => {
    const newSortedContactTypesArr: sortGroupsParams = [];
    sortedList.forEach((sortedListItem, i) => {
      newSortedContactTypesArr.push({
        id: sortedListItem.contactType.id,
        newposition: startIndex + i + 1,
      });
    });
    // sort existing contactTypes API Call
    sortContactTypes(newSortedContactTypesArr)
      .then(() => {})
      .catch(() => {})
      .finally(() => {
        getContactTypesListAPI();
      });
  };

  //
  const onContactTypeCheckChange = (contactType: GroupType) => {
    let checkedContactTypesCopy = _.clone(checkedContactTypes);
    // check if already exists in the checked list
    const index = checkedContactTypesCopy.findIndex(
      (checkedContactType) => checkedContactType.id === contactType.id
    );
    // if exists remove
    if (index > -1) {
      checkedContactTypesCopy.splice(index, 1);
    }
    // if doesnt exist push to checked contactTypes
    else {
      checkedContactTypesCopy.push(contactType);
    }
    setCheckedContactTypes(checkedContactTypesCopy);
  };

  const isContactTypeChecked = (contactType: GroupType) => {
    const index = checkedContactTypes.findIndex(
      (checkedContactType) => checkedContactType.id === contactType.id
    );
    if (index > -1) {
      return true;
    }
    return false;
  };

  const deleteMultipleContactTypes = () => {
    setDeleteModalLoading(true);
    const ids = checkedContactTypes.map((checkedContactType) => {
      return checkedContactType.id;
    });
    massDeleteContactTypes(ids)
      .then(() => {
        successToast(
          intl.formatMessage({
            id: "MASTERDATA_CONTACT_TYPE_MASS_DELETE_SUCCESS_MESSAGE",
          })
        );
      })
      .catch(() => {
        errorToast(
          intl.formatMessage({
            id: "MASTERDATA_CONTACT_TYPE_MASS_DELETE_FAILURE_MESSAGE",
          })
        );
      })
      .finally(() => {
        setDeleteModalLoading(false);
        hideDeleteConfirmModal();
        getContactTypesListAPI();
        setCheckedContactTypes([]);
      });
  };

  const onAllContactTypesCheckChange = () => {
    const updatedCheckAllContactTypes = !checkAllContactTypes;
    setCheckAllContactTypes(updatedCheckAllContactTypes);
    if (updatedCheckAllContactTypes) {
      setCheckedContactTypes(contactTypesList);
    } else {
      setCheckedContactTypes([]);
    }
  };

  const ContactTypeItem = (contactType: GroupType, index: number) => {
    const {
      showDeleteConfirmModal,
      hideDeleteConfirmModal,
      setDeleteModalLoading,
    } = useGlobalModalContext();
    return (
      <div className="card list-item no-drag" key={index}>
        <div className="d-flex flex-row align-items-center no-drag">
          {crudPermission?.delete && (
            <div className="form-check form-check-sm form-check-custom form-check-solid no-drag">
              <input
                onChange={(e) => {
                  onContactTypeCheckChange(contactType);
                }}
                className="form-check-input widget-9-check"
                type="checkbox"
                checked={isContactTypeChecked(contactType)}
              />
            </div>
          )}
          {/* {crudPermission?.edit && (
            <div className="mx-5">
              {/* <button className='btn btn-icon btn-light  btn-sm'> */}
              {/* <SVGICON
                src={DragIcon}
                className="svg-icon-2 svg-hover-primary"
              /> */}
              {/* </button> */}
            {/* </div> */}

          <div
            className={`fw-bold mx-5 no-drag`}
            role="button"
            onClick={() => {
              setShowContactTypesModal({
                show: true,
                type: "edit",
                contactType: contactType,
              });
            }}
          >
            {contactType.name}
          </div>
        </div>

        <div>
          {crudPermission?.edit && (
            <button
              className="btn btn-icon btn-light btn-active-light-primary btn-sm me-2 no-drag"
              onClick={() => {
                setShowContactTypesModal({
                  show: true,
                  type: "edit",
                  contactType: contactType,
                });
              }}
            >
              <SVGICON src={EditIcon} className="svg-icon-3" />
            </button>
          )}
          {crudPermission?.delete && (
            <button
              className="btn btn-icon btn-light btn-active-light-primary btn-sm no-drag"
              onClick={() => {
                showDeleteConfirmModal(
                  intl.formatMessage({
                    id: "MASTERDATA_CONTACT_TYPE_DELETE_CONFIRM_MESSAGE",
                  }),
                  () => {
                    deleteExistingContactType(contactType.id);
                  }
                );
              }}
            >
              <SVGICON src={DeleteIcon} className="svg-icon-3" />
            </button>
          )}
        </div>
      </div>
    );
  };

  // get paginated records
  const startIndex = itemsPerPage * (activePage - 1);
  const paginatedContactTypes =
    displayContactTypesList && displayContactTypesList.length > 10
      ? displayContactTypesList.slice(startIndex, startIndex + itemsPerPage)
      : displayContactTypesList;

  useEffect(() => {
    if (
      paginatedContactTypes.length === 0 &&
      displayContactTypesList.length > 0
    ) {
      setActivePage((prev) => prev - 1);
    }
  }, [paginatedContactTypes]);

  useEffect(() => {
    ScrollToTop();
  }, [activePage]);

  // draggable contactTypes list
  const list = paginatedContactTypes
    ? paginatedContactTypes.map((contactType, i) => {
        return {
          content: (
            <div
              className={searchText || !crudPermission?.edit ? "no-drag" : ""}
            >
              {ContactTypeItem(contactType, i)}
            </div>
          ),
          contactType: contactType,
        };
      })
    : [];

  return (
    <div>
      {/* @ts-ignore */}
      <ContactTypesModal
        ModalProps={showContactTypesModal}
        closeModal={closeContactTypesModal}
        loading={loading}
        onFormSubmit={onFormSubmit}
      />

      {/* search contactTypes */}
      <div className="card card-search d-flex flex-row justify-content-between">
        <div className="d-flex flex-row search-container">
          {crudPermission?.delete && (
            <div className="form-check form-check-sm form-check-custom form-check-solid ">
              <input
                onChange={onAllContactTypesCheckChange}
                className="form-check-input widget-9-check"
                type="checkbox"
                checked={checkAllContactTypes}
              />
            </div>
          )}
          <div className="d-flex align-items-center position-relative ms-6">
            <span className="position-absolute" style={{ left: "10px" }}>
              <SVGICON className="svg-icon svg-icon-1" src={SearchIcon} />
            </span>
            <input
              type="text"
              className="form-control form-control-md form-control-solid min-w-150px min-w-md-200px ps-11"
              placeholder={intl.formatMessage({
                id: "MASTERDATA_CONTACT_TYPE_SEARCH_PLACEHOLDER",
              })}
              onChange={(e) => {
                onSearchTextChange(e.target.value);
              }}
            />
          </div>
        </div>

        {/* add new contactType button */}
        <div className="d-flex align-items-center">
          {checkedContactTypes.length > 0 ? (
            <>
              <span className="text-dark text-hover-primary fs-6 fw-bolder mx-4">
                {checkedContactTypes.length}{" "}
                {intl.formatMessage({ id: "SELECTED" })}
              </span>
              <button
                className="btn btn-danger d-flex align-items-center"
                onClick={() => {
                  showDeleteConfirmModal(
                    intl.formatMessage({
                      id: "MASTERDATA_CONTACT_TYPE_SELECTED_DELETE_CONFIRM_MESSAGE",
                    }),
                    () => {
                      deleteMultipleContactTypes();
                    }
                  );
                }}
              >
                <i className={"bi bi-trash3-fill fs-6"}></i>
                {intl.formatMessage({ id: "DELETE_BUTTON" })}
              </button>
            </>
          ) : (
            <>
              {crudPermission?.create && (
                <button
                  className="btn  btn-primary"
                  onClick={() => {
                    setShowContactTypesModal({
                      show: true,
                      type: "new",
                    });
                  }}
                >
                  <SVGICON src={PlusIcon} className="svg-icon-2" />
                  {intl.formatMessage({
                    id: "MASTERDATA_CONTACT_TYPE_CREATE_NEW_BUTTON",
                  })}
                </button>
              )}
            </>
          )}
        </div>
      </div>

      <DragSortableList
        items={list}
        // @ts-ignore
        // onSort={(sortedList, dropEvent) => {
        //   onContactTypesSortChange(sortedList);
        // }}
        // type="vertical"
      />
      {/* no data */}
      {!loading && displayContactTypesList.length === 0 && (
        <table className="d-flex justify-content-center align-items-center mt-2">
          <tbody>
            <NoItemsFound languageKey="NO_ITEMS_FOUND" />
          </tbody>
        </table>
      )}
      {displayContactTypesList.length > 0 && (
        <div className="card list-item-pagination card-mb">
          <Pagination
            totalPages={Math.ceil(
              displayContactTypesList.length / itemsPerPage
            )}
            activePage={
              Math.ceil(displayContactTypesList.length / itemsPerPage) === 1
                ? 1
                : activePage
            }
            onPageClick={onPageClick}
            noCard
            itemsPerPage={itemsPerPage}
            showItemsPerPage
            setItemsPerPage={(count) => {
              setItemsPerPage(count);
              setActivePage(1);
            }}
          />
        </div>
      )}
    </div>
  );
};

export default ContactDetails;
