import clsx from "clsx";
import React, { FC } from "react";

// styles
import "./Loader.scss";

interface Props {
  fullLoader?: boolean;
  height?: string;
  isBackground?: boolean
}

const SimpleLoader: FC<Props> = ({ fullLoader, height, isBackground }) => {
  return (
    <>
      <div
        style={{ minHeight: height }}
        className={clsx(
          "d-flex flex-row align-items-center justify-content-center card-p",
          fullLoader ? "spinner-centre" : "", isBackground ? "loaderBg" : ""
        )}
      >
        <div
          className={clsx(
            "spinner-grow text-primary me-1",
            fullLoader ? "spinner-size-md" : "spinner-size-sm"
          )}
          role="status"
        >
          <span className="sr-only">Loading...</span>
        </div>
        <div
          className={clsx(
            "spinner-grow text-primary me-1",
            fullLoader ? "spinner-size-md" : "spinner-size-sm"
          )}
          role="status"
        >
          <span className="sr-only">Loading...</span>
        </div>
        <div
          className={clsx(
            "spinner-grow text-primary me-1",
            fullLoader ? "spinner-size-md" : "spinner-size-sm"
          )}
          role="status"
        >
          <span className="sr-only">Loading...</span>
        </div>
        <div
          className={clsx(
            "spinner-grow text-primary",
            fullLoader ? "spinner-size-md" : "spinner-size-sm"
          )}
          role="status"
        >
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    </>
  );
};

export default SimpleLoader;
