import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useHistory, useLocation } from "react-router-dom";
import {
  ProductActivityType,
  ProductType,
} from "../types/ProductsResponseType";
import { getByProductID, getProductActivities } from "../redux/ProductsAPI";
import { SVGICON } from "../../../../_metronic/helpers";
import FilemanagerIcon from "../../../../_metronic/assets/icons/filemanager.svg";
import Tabs from "../../../sharedComponents/tabs/Tabs";
import ProductStock from "./ProductStock";
import ProductGeneralDetails from "./ProductGeneralDetails";
import ProductTypeSelect from "./ProductTypesSelect";
import ProductAssembleInstructions from "./ProductAssembleInstructions";
import ProductManufactur from "./ProductManufactur";
import ProductActivity from "./ProductActivity";
import ProductDocuments from "./ProductDocuments";
import ScrollToTop from "../../../sharedComponents/ScrollToTop/ScrollToTop";

const EditProducts = () => {
  const { state = {} } = useLocation();
  const history = useHistory();
  const intl = useIntl();

  // @ts-ignore
  const productInformation: ProductType = state.productDetails || {};
  // state
  const [product, setProduct] = useState<ProductType>(productInformation);
  const [productActivities, setProductActivities] =
    useState<ProductActivityType[]>();

  const [tabActiveIndex, setTabActiveIndex] = useState<number>(0);

  const onTabChange = (index: number) => {
    if (index !== tabActiveIndex) {
      setTabActiveIndex(index);
    }
  };

  useEffect(() => {
    setProduct(productInformation);
  }, [productInformation]);

  const getProductDetailsAPI = () => {
    if (productInformation?.id) {
      getByProductID(productInformation?.id).then(({ data }) => {
        const { productDetails } = data || {};

        setProduct(productDetails);
        history.replace({ state: { productDetails: productDetails } });
      });
    }
  };

  const getProductActivityDetailsAPI = () => {
    getProductActivities(product.id).then(({ data }) => {
      const { product_activities } = data || {};
      setProductActivities(product_activities);
    });
  };

  useEffect(() => {
    if (tabActiveIndex === 1) {
      getProductActivityDetailsAPI();
    }
  }, [tabActiveIndex]);

  useEffect(() => {
    ScrollToTop();
  }, []);

  return (
    <>
      <div className="card card-px list-item pb-0 card-my">
        <Tabs
          tabs={[
            intl.formatMessage({ id: "PRODUCTS_TAB1" }),
            intl.formatMessage({ id: "PRODUCTS_TAB2" }),
            intl.formatMessage({ id: "PRODUCTS_TAB3" }),
          ]}
          activeIndex={tabActiveIndex}
          onActiveIndexChange={(index) => {
            onTabChange(index);
          }}
        />
      </div>
      <div className="card-mb">
        {tabActiveIndex === 0 && (
          <div className="row mx-0 g-25px">
            <div className="col-md-5 kt-card">
              <ProductStock
                product={product}
                getProductDetailsAPI={getProductDetailsAPI}
              />
              <ProductManufactur
                product={product}
                getProductDetailsAPI={getProductDetailsAPI}
              />
            </div>
            <div className="col-md-7 kt-card">
              <ProductGeneralDetails
                product={product}
                getProductDetailsAPI={getProductDetailsAPI}
              />

              <ProductTypeSelect
                product={product}
                getProductDetailsAPI={getProductDetailsAPI}
              />
              <ProductAssembleInstructions
                product={product}
                getProductDetailsAPI={getProductDetailsAPI}
              />
            </div>
          </div>
        )}
        {tabActiveIndex === 1 && (
          <div className="row mx-0 g-25px">
            <div className="col-12 p-0">
              <ProductActivity
                product={product}
                getProductDetailsAPI={getProductDetailsAPI}
                activities={productActivities}
                getProductActivityDetailsAPI={getProductActivityDetailsAPI}
              />
            </div>
          </div>
        )}

        {tabActiveIndex === 2 && (
          <div className="row mx-0 g-25px">
            <div className="col-12 p-0">
              <ProductDocuments
                product={product}
                getProductDetailsAPI={getProductDetailsAPI}
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default EditProducts;
