import React, { useEffect, useRef } from "react";
import { useIntl } from "react-intl";

export function ConnnectedGoogleAuthCheck() {
  const ref = useRef(true);
  const intl = useIntl();

  useEffect(() => {
    if (ref.current) {
      const search = window.location.search;
      const searchURLparams = new URLSearchParams(search);
      const code = searchURLparams.get("code");
      localStorage.setItem("googleAuth", code || "");
      ref.current = true;
      window.close();
    } else {
      ref.current = false;
    }
  });
  return (
    <div className="d-flex flex-column justify-content-center fw-bold">
      <div>{intl.formatMessage({ id: "GOOGLE_AUTH_CHECK_CONNECTING" })}</div>
      <div>
        {intl.formatMessage({ id: "GOOGLE_AUTH_CHECK_CONNECTING_MESSAGE" })}
      </div>
    </div>
  );
}
