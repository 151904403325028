import React, { useEffect } from "react";
import { AsideDefault } from "./components/aside/AsideDefault";
import { HeaderWrapper } from "./components/header/HeaderWrapper";
import { Content } from "./components/Content";
import { PageDataProvider } from "./core";
import { useLocation } from "react-router-dom";
import { MenuComponent } from "../assets/ts/components";
import DeviceToolbar from "./components/toolbar/DeviceToolbar";

const MasterLayout: React.FC = ({ children }) => {
  const location = useLocation();
  const bgMainBackground = "#071B2D";
  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization();
    }, 500);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization();
    }, 500);
  }, [location.key]);

  return (
    <PageDataProvider>
      <div
        className="d-flex flex-row flex-column-fluid"
        style={{
          backgroundColor: bgMainBackground,
        }}
      >
        <AsideDefault />
        <div
          className="wrapper d-flex flex-column flex-row-fluid"
          id="kt_wrapper"
        >
          <HeaderWrapper />
          {location.pathname?.includes("device") && <DeviceToolbar />}
          <div
            id="kt_content"
            className="bodyScollContent"
            style={{
              backgroundColor: "#F5F5F5",
              borderRadius: "20px",
            }}
          >
            <div id="kt_post" className="card-mx">
              <Content>{children}</Content>
            </div>
          </div>
          {/* <Footer /> */}
        </div>
      </div>

      {/* begin:: Drawers */}
      {/* <ActivityDrawer /> */}
      {/* <ExploreMain /> */}

      {/* end:: Drawers */}

      {/* begin:: Modals */}
      {/* <Main />
      <InviteUsers />
      <UpgradePlan /> */}
      {/* end:: Modals */}
      {/* <ScrollTop /> */}
    </PageDataProvider>
  );
};

export { MasterLayout };
