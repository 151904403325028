import React, { FC, useState } from "react";
import { Modal } from "react-bootstrap";
import { useIntl } from "react-intl";
import { FieldType } from "../types/FieldsReponseTypes";
import Fields from "./Fields";
import CloseButton from "../../../sharedComponents/Buttons/CloseButton";

export interface FieldSelectModalProps {
  field_id: number;
  is_required: number;
}

interface Props {
  show: boolean;
  closeModal: () => void;
  onSelectedFields: (fields: FieldSelectModalProps[]) => void;
  isSelectionModal?: boolean;
  selectedFields?: FieldType[] | undefined | any;
}

const SelectFieldModal: FC<Props> = ({
  show,
  closeModal,
  onSelectedFields,
  isSelectionModal,
  selectedFields,
}) => {
  const intl = useIntl();
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const bodyBackgroundColor = "#F5F5F5";

  return (
    <Modal
      show={show}
      centered
      dialogClassName="extra-large-size-modal"
      className={`kt-modal kt-modal-selection ${isFilterOpen ? "d-none" : "d-block"}`}
      backdrop="static"
      onHide={closeModal}
      contentClassName="overflow-auto"
    >
      <Modal.Header>
        <Modal.Title>
          {intl.formatMessage({ id: "SELECT_FIELDS_MODAL_TITLE" })}
        </Modal.Title>
        <CloseButton onClose={closeModal} />
      </Modal.Header>
      <Modal.Body className="overflow-inherit">
        <Fields
          onSelectedFields={onSelectedFields}
          isSelectionModal={isSelectionModal}
          selectedFields={selectedFields}
        />
      </Modal.Body>
    </Modal>
  );
};

export default SelectFieldModal;
