import React, { FC, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useIntl } from "react-intl";
import { CalendarEventType } from "./Calendar";
import { SVGICON } from "../../../../_metronic/helpers";
import CalendarIcon from "../../../../_metronic/assets/icons/calendar.svg";
import Location from "../../../../_metronic/assets/icons/location.svg";
import DeleteIcon from "../../../../_metronic/assets/icons/delete.svg";
import EditIcon from "../../../../_metronic/assets/icons/edit.svg";
import moment from "moment";
import AddEventModal from "./AddEventModal";
import { useGlobalModalContext } from "../../../sharedComponents/modals/ModalContext";
import { deleteEvent } from "../redux/CalendarAPI";
import { UserType } from "../../userManagement/types/getUsersListResponseType";
import {
  errorToast,
  successToast,
} from "../../../sharedComponents/toasts/Toasts";
import CloseButton from "../../../sharedComponents/Buttons/CloseButton";
import clsx from "clsx";

interface Props {
  show: boolean;
  closeModal: () => void;
  event: CalendarEventType;
  getAllEvents: () => void;
  user: UserType;
}

const EventDetailsModal: FC<Props> = ({
  show,
  closeModal,
  event,
  getAllEvents,
  user,
}) => {
  const intl = useIntl();

  const {
    showDeleteConfirmModal,
    hideDeleteConfirmModal,
    setDeleteModalLoading,
  } = useGlobalModalContext();

  //   state
  const [showEditModal, setShowModal] = useState<{
    show: boolean;
    event: CalendarEventType;
  }>({
    show: false,
    event,
  });
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const handleDeleteEvent = () => {
    setDeleteModalLoading(true);
    deleteEvent(
      user.id,
      user.connected_accounts.google[0]?.id || null,
      event.id
    )
      .then(() => {
        successToast(
          intl.formatMessage({ id: "CALENDAR_EVENT_DELETE_SUCCESS_MESSAGE" })
        );
      })
      .catch(() => {
        errorToast(
          intl.formatMessage({ id: "CALENDAR_EVENT_DELETE_FAILURE_MESSAGE" })
        );
      })
      .finally(() => {
        setDeleteModalLoading(false);
        setShowDeleteModal(false);
        hideDeleteConfirmModal();
        getAllEvents();
        closeModal();
      });
  };

  return (
    <>
      {showEditModal.show && (
        <AddEventModal
          show={showEditModal.show}
          closeModal={() => {
            setShowModal({
              ...showEditModal,
              show: false,
            });
          }}
          getAllEvents={() => {
            closeModal();
            getAllEvents();
          }}
          type="edit"
          event={event}
        />
      )}
      <Modal
        show={show}
        centered
        dialogClassName={"medium-size-modal"}
        backdrop="static"
        className={clsx(
          "kt-modal",
          showEditModal.show || showDeleteModal ? "d-none" : "d-block"
        )}
        onHide={closeModal}
      >
        <Modal.Header>
          <Modal.Title>
            {intl.formatMessage({ id: "CALENDAR_EVENT_EDIT_TITLE" })}
          </Modal.Title>
          <div className="d-flex justify-content-end align-items-center">
            <div className="d-flex">
              <button
                className="btn btn-sm btn-icon btn-light btn-active-light-primary me-2"
                onClick={() => {
                  setShowModal({
                    show: true,
                    event,
                  });
                }}
              >
                <SVGICON src={EditIcon} className="svg-icon-3" />
              </button>
              <button
                className="btn btn-sm btn-icon btn-light btn-active-light-danger me-2"
                onClick={() => {
                  setShowDeleteModal(true);
                  showDeleteConfirmModal(
                    intl.formatMessage({
                      id: "CALENDAR_EVENT_DELETE_CONFIRM_MESSAGE",
                    }),
                    () => {
                      handleDeleteEvent();
                    },
                    intl.formatMessage({ id: "COMMON_YES_DELETE" }),
                    () => setShowDeleteModal(false)
                  );
                }}
              >
                <SVGICON src={DeleteIcon} className="svg-icon-3" />
              </button>
            </div>
            <CloseButton onClose={closeModal} />
          </div>
        </Modal.Header>
        <form
          className="form w-100 overflow-auto"
          onSubmit={() => {}}
          noValidate
          id="kt_add_category_form"
        >
          <Modal.Body className="overflow-inherit">
            <div>
              <div className="d-flex flex-column">
                <div className="d-flex tab-field">
                  <SVGICON src={CalendarIcon} className="svg svg-icon-2 me-4" />
                  <div className="w-100" style={{ height: "auto" }}>
                    <h2 className="text-break">{event.title}</h2>
                    <span className="text-break">{event.summary}</span>
                  </div>
                </div>
                <div className="d-flex flex-column tab-field">
                  <div>
                    <span className="bullet bullet-dot h-10px w-10px bg-success me-6"></span>
                    <span className="tab-field-label">
                      {intl.formatMessage({
                        id: "GOOGLE_EVENT_STARTS",
                      })}{" "}
                      {moment(event.start).format("MMMM Do YYYY, h:mm a")}
                    </span>
                  </div>

                  <div>
                    <span className="bullet bullet-dot h-10px w-10px bg-danger me-6"></span>
                    <span className="tab-field-label">
                      {intl.formatMessage({
                        id: "GOOGLE_EVENT_ENDS",
                      })}{" "}
                      {moment(event.end).format("MMMM Do YYYY, h:mm a")}
                    </span>
                  </div>
                </div>

                <div
                  className="d-flex tab-field"
                  style={{ marginLeft: "-2px" }}
                >
                  <SVGICON src={Location} className="me-5" />
                  <div>
                    <span className="text-muted fs-6">
                      {event.location ? event.location : "No location added"}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>

          <Modal.Footer className="justify-content-center">
            <Button
              variant="secondary"
              onClick={() => {
                closeModal();
              }}
            >
              {intl.formatMessage({ id: "CLOSE_BUTTON" })}
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

export default EventDetailsModal;
