import clsx from "clsx";
import { useFormik } from "formik";
import React, { FC, useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import * as Yup from "yup";
import notFound from "../../../../_metronic/assets/icons/notFound.png";
import { useIntl } from "react-intl";
import {
  addTeamUser,
  createTeam,
  deleteMultipleTeams,
  getTeams,
  sortTeam,
  updateTeam,
} from "../redux/TeamsAPI";
import { TeamType } from "../types/TeamsResponseType";

import { SVGICON } from "../../../../_metronic/helpers";
import DragIcon from "../../../../_metronic/assets/icons/drag.svg";
import PlusIcon from "../../../../_metronic/assets/icons/plus.svg";
import SearchIcon from "../../../../_metronic/assets/icons/search.svg";
import { sortGroupsParams } from "../../masterdata/types/request";
import _ from "lodash";
// @ts-ignore
import DragSortableList from "react-drag-sortable";
import TeamListActionsDropdown from "./TeamActionDropdown";
import SelectedUsersModal from "./SelectUsersModal";
import { UserType } from "../../userManagement/types/getUsersListResponseType";
import TeamUsersModal from "./TeamUsersModal";
import SimpleLoader from "../../../sharedComponents/Loader/SimpleLoader";
import { Tooltip } from "react-tooltip";
import { useGlobalModalContext } from "../../../sharedComponents/modals/ModalContext";
import Pagination from "../../../sharedComponents/pagination/Pagination";
import Constants from "../../../config/Constants";
import { enablePermissionForCrud } from "../../../utils/PermisisionEnabledForResource";
import { RootState } from "../../../../setup";
import { useSelector } from "react-redux";
import { RoleType } from "../../RolesAndPermissions/types/getRolesResponseType";
import ScrollToTop from "../../../sharedComponents/ScrollToTop/ScrollToTop";
import NoItemsFound from "../../../sharedComponents/NoItemsFound/NoItemsFound";
import {
  errorToast,
  successToast,
} from "../../../sharedComponents/toasts/Toasts";
import CloseButton from "../../../sharedComponents/Buttons/CloseButton";

interface ModelComponentProps {
  ModalProps: TeamModalProps;
  closeModal: () => void;
  getTeamsListAPI: () => void;
}

interface TeamModalProps {
  show: boolean;
  type?: string;
  team?: TeamType;
}

interface SortedObject {
  team: TeamType;
}

interface TeamsSelectProps {
  type?: string;
  onSelectTeams?: (teams: TeamType[]) => void;
  isSelectionModal?: boolean;
  onHideModal?: (hide: boolean) => void;
}

const TeamsModal: FC<ModelComponentProps> = ({
  ModalProps,
  closeModal,
  getTeamsListAPI,
}) => {
  const { show, type, team } = ModalProps;
  //@ts-ignore
  const [currPage, setCurrPage] = useState<GroupType | undefined>(team);
  const userRoleDetails = useSelector<RootState>(
    // @ts-ignore
    (state) => state.auth.roleDetails
  ) as RoleType;
  //@ts-ignore
  const crudPermission: any = enablePermissionForCrud(userRoleDetails, "teams");

  const intl = useIntl();
  const AddNewteamSchema = Yup.object().shape({
    name: Yup.string()
      .max(60, intl.formatMessage({ id: "MASTERDATA_60SYMBOLS_REQUIRED" }))
      .required(intl.formatMessage({ id: "MASTERDATA_TEAM_NAME_REQUIRED" })),
  });
  // state
  const [loading, setLoading] = useState(false);

  const initialValues = {
    name: type === "edit" ? currPage?.name : "",
  };

  useEffect(() => {
    if (type === "new") {
      setCurrPage(undefined);
    }
  }, [type, team]);

  useEffect(() => {
    setCurrPage(team);
  }, [team]);

  const formik = useFormik({
    initialValues,
    validationSchema: AddNewteamSchema,
    enableReinitialize: true,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      if (type === "new") {
        if (values.name) {
          setLoading(true);

          createTeam(values.name)
            .then(() => {
              closeModal();
              successToast(
                intl.formatMessage({
                  id: "TEAMS_CREATED_SUCCESS_MESSAGE",
                })
              );
            })
            .catch((err) => {
              err.response?.data?.errors?.name
                ? setStatus(err.response.data?.errors?.name)
                : setStatus(
                    intl.formatMessage({
                      id: "TEAMS_CREATED_FAILURE_MESSAGE",
                    })
                  );
            })
            .finally(() => {
              // recall  get categories list API
              getTeamsListAPI();
              setLoading(false);
            });
        }
      } else {
        if (values.name && team) {
          setLoading(true);

          updateTeam(team.id, values.name)
            .then(() => {
              closeModal();
              successToast(
                intl.formatMessage({
                  id: "TEAMS_UPDATED_SUCCESS_MESSAGE",
                })
              );
            })
            .catch((err) => {
              err.response?.data?.errors?.name
                ? setStatus(err.response.data?.errors?.name)
                : setStatus(
                    intl.formatMessage({
                      id: "TEAMS_UPDATED_FAILURE_MESSAGE",
                    })
                  );
            })
            .finally(() => {
              // recall  get categories list API
              getTeamsListAPI();
              setLoading(false);
            });
        }
      }
    },
  });

  return (
    <Modal
      show={show}
      contentClassName={loading ? "pe-none" : ""}
      dialogClassName={"medium-size-modal"}
      className="kt-modal"
      centered
      backdrop="static"
      onHide={closeModal}
    >
      <Modal.Header>
        <Modal.Title>
          {type === "edit"
            ? intl.formatMessage({ id: "TEAMS_EDIT_MODAL_TITLE" })
            : intl.formatMessage({
                id: "TEAMS_NEW_MODAL_TITLE",
              })}
        </Modal.Title>
        <CloseButton onClose={closeModal} />
      </Modal.Header>

      <form
        className="form w-100 overflow-auto"
        onSubmit={formik.handleSubmit}
        noValidate
        id="kt_add_category_form"
      >
        <Modal.Body className="overflow-inherit">
          {formik.status && (
            <div className="mb-10 alert alert-danger">
              <div className="alert-text font-weight-bold">{formik.status}</div>
            </div>
          )}
          <div className="tab-field mb-0">
            <label className="d-flex align-items-center tab-field-label">
              <span className="required">
                {intl.formatMessage({
                  id: "MASTERDATA_CATEGORY_NAME_FIELD_NAME",
                })}
              </span>
            </label>
            <input
              {...formik.getFieldProps("name")}
              className={clsx(
                "form-control form-control-lg form-control-solid"
              )}
              name="name"
              autoComplete="off"
            />
            {formik.touched.name && formik.errors.name && (
              <div className="text-danger mt-2">
                <span role="alert">{formik.errors.name}</span>
              </div>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <Button variant="secondary" onClick={closeModal}>
            {intl.formatMessage({ id: "CLOSE_BUTTON" })}
          </Button>
          {((crudPermission?.edit && type === "edit") ||
            (crudPermission?.create && type === "new")) && (
            <Button
              variant="primary"
              type="submit"
              disabled={formik.isSubmitting}
            >
              {!loading && intl.formatMessage({ id: "SUBMIT_BUTTON" })}
              {loading && (
                <span
                  className="indicator-progress"
                  style={{ display: "block" }}
                >
                  {intl.formatMessage({
                    id: "MASTERDATA_CATEGORY_LOADING_MESSAGE",
                  })}{" "}
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              )}
            </Button>
          )}
        </Modal.Footer>
      </form>
    </Modal>
  );
};

const TeamsList: FC<TeamsSelectProps> = ({
  type,
  onSelectTeams,
  isSelectionModal,
  onHideModal,
}) => {
  const intl = useIntl();
  const {
    showDeleteConfirmModal,
    hideDeleteConfirmModal,
    setDeleteModalLoading,
  } = useGlobalModalContext();
  // state
  const [displayTeamsList, setDisplayTeamsList] = useState<TeamType[]>([]);
  const [teamList, setTeamList] = useState<TeamType[]>([]);
  const [showTeamsModal, setShowTeamsModal] = useState<TeamModalProps>({
    show: false,
  });
  const [checkedTeams, setCheckedTeams] = useState<TeamType[]>([]);
  const [searchText, setSearchText] = useState<string>("");
  const [checkAllTeams, setCheckAllTeams] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const userRoleDetails = useSelector<RootState>(
    // @ts-ignore
    (state) => state.auth.roleDetails
  ) as RoleType;
  //@ts-ignore
  const crudPermission: any = enablePermissionForCrud(userRoleDetails, "teams");
  const [showUsersModal, setShowUsersModal] = useState<{
    show: boolean;
    team: undefined | TeamType;
  }>({
    show: false,
    team: undefined,
  });
  const [showAllUsersModal, setShowAllUsersModal] = useState<{
    show: boolean;
    team: undefined | TeamType;
  }>({
    show: false,
    team: undefined,
  });
  const [itemsPerPage, setItemsPerPage] = useState(
    Constants.defaultItemsPerPageCount
  );
  const [activePage, setActivePage] = useState<number>(1);

  const getTeamsListAPI = () => {
    // setIsLoading(true);
    getTeams()
      .then(({ data: { teams } }) => {
        setTeamList(teams);
        setDisplayTeamsList(teams);
        if (showAllUsersModal?.team?.id) {
          const selectedTeam = teams.filter(
            (team) => team.id === showAllUsersModal.team?.id
          );
          setShowAllUsersModal({
            ...showAllUsersModal,
            team: selectedTeam[0],
          });
        }
      })
      .catch(() => {})
      .finally(() => {
        setIsLoading(false);
        setCheckAllTeams(false);
        setCheckedTeams([]);
      });
  };

  const updateDisplayTeamsList = () => {
    const cloneCopy = _.clone(teamList);
    const updatedTeamsList =
      cloneCopy &&
      cloneCopy.filter((category) => {
        if (
          (category.name || "")
            .toLocaleLowerCase()
            .includes((searchText || "").toLocaleLowerCase())
        ) {
          return true;
        }
        return false;
      });
    // save updatedlist to display
    setDisplayTeamsList(updatedTeamsList);
  };

  useEffect(() => {
    setIsLoading(true);
    // get Teams API call
    getTeamsListAPI();
  }, []);

  useEffect(() => {
    updateDisplayTeamsList();

    if (searchText.length === 0) {
      setDisplayTeamsList(teamList);
    }

    // return () => {
    //   setDisplayTeamsList(TeamsList);
    // };
  }, [searchText]);

  useEffect(() => {
    if (
      checkAllTeams &&
      displayTeamsList &&
      checkedTeams.length !== displayTeamsList.length
    ) {
      setCheckAllTeams(false);
    }
    if (
      checkedTeams.length > 0 &&
      displayTeamsList &&
      checkedTeams.length === displayTeamsList.length
    ) {
      setCheckAllTeams(true);
    }
  }, [checkedTeams]);

  const closeTeamsModal = () => {
    setShowTeamsModal({
      show: false,
      type: "new",
      team: undefined,
    });
  };
  const onSearchTextChange = (text: string) => {
    setSearchText((text || "").trim());
  };

  const onTeamsSortChange = (sortedList: Array<SortedObject>) => {
    const newSortedTeamsArr: sortGroupsParams = [];
    sortedList.forEach((sortedListItem, i) => {
      newSortedTeamsArr.push({
        id: sortedListItem.team.id,
        newposition: startIndex + i + 1,
      });
    });
    // sort existing Teams API Call
    sortTeam(newSortedTeamsArr)
      .then(() => {})
      .catch(() => {})
      .finally(() => {
        getTeamsListAPI();
      });
  };

  //
  const onCategoryCheckChange = (team: TeamType) => {
    let checkedTeamsCopy = _.clone(checkedTeams);
    // check if already exists in the checked list
    const index = checkedTeamsCopy.findIndex(
      (checkedTeam) => checkedTeam.id === team.id
    );
    // if exists remove
    if (index > -1) {
      checkedTeamsCopy.splice(index, 1);
    }
    // if doesnt exist push to checked Teams
    else {
      checkedTeamsCopy.push(team);
    }
    setCheckedTeams(checkedTeamsCopy);
  };

  const isCategoryChecked = (team: TeamType) => {
    const index = checkedTeams.findIndex(
      (checkedTeam) => checkedTeam.id === team.id
    );
    if (index > -1) {
      return true;
    }
    return false;
  };

  const deleteMultipleTeamsAPI = () => {
    setDeleteModalLoading(true);
    const ids = checkedTeams.map((checkedTeam) => {
      return checkedTeam.id;
    });
    deleteMultipleTeams(ids)
      .then(() => {
        successToast(
          intl.formatMessage({
            id: "TEAMS_MASS_DELETE_SUCCESS_MESSAGE",
          })
        );
      })
      .catch(() => {
        errorToast(
          intl.formatMessage({
            id: "TEAMS_MASS_DELETE_FAILURE_MESSAGE",
          })
        );
      })
      .finally(() => {
        setDeleteModalLoading(false);
        hideDeleteConfirmModal();
        getTeamsListAPI();
        setCheckedTeams([]);
      });
  };

  const onAllTeamsCheckChange = () => {
    const updatedCheckAllTeams = !checkAllTeams;
    setCheckAllTeams(updatedCheckAllTeams);
    if (updatedCheckAllTeams) {
      setCheckedTeams(displayTeamsList);
    } else {
      setCheckedTeams([]);
    }
  };

  const addUserHandle = (users: UserType[]) => {
    const ids = users.map((checkedTeam) => {
      return checkedTeam.id;
    });
    const existingUsersIds = showUsersModal?.team?.team_users.map(
      (checkedTeam) => {
        return checkedTeam.id;
      }
    );
    // @ts-ignore
    const finalIds = [...ids, ...existingUsersIds];
    // console.log(finalIds);
    // @ts-ignore
    addTeamUser(showUsersModal.team?.id, finalIds)
      .then((data) => {
        successToast(
          intl.formatMessage({ id: "TEAMS_ADD_USER_SUCCESS_MESSAGE" })
        );
      })
      .catch((e) => {
        errorToast(
          intl.formatMessage({ id: "TEAMS_ADD_USER_FAILURE_MESSAGE" })
        );
      })
      .finally(() => {
        getTeamsListAPI();
      });
  };

  const onPageClick = (page: number) => {
    setActivePage(page);
  };

  useEffect(() => {
    ScrollToTop();
  }, [activePage]);

  const TeamItem = (team: TeamType, index: number) => {
    const displaySectors = (team?.team_users || []).slice(0, 4);
    const remainingSectors =
      (team?.team_users || []).length - displaySectors.length;
    return (
      <>
        {showUsersModal.show && team.id === showUsersModal.team?.id && (
          <SelectedUsersModal
            show={showUsersModal.show}
            closeModal={() =>
              setShowUsersModal({
                show: false,
                team: undefined,
              })
            }
            onSelectUsers={(customers) => {
              setShowUsersModal({
                ...showUsersModal,
                show: false,
              });
              addUserHandle(customers);
            }}
            isSelectionModal={true}
            selectedContacts={team.team_users}
          />
        )}
        {showAllUsersModal.show && team.id === showAllUsersModal.team?.id && (
          <TeamUsersModal
            show={showAllUsersModal.show}
            closeModal={() => {
              setShowAllUsersModal({
                ...showAllUsersModal,
                show: false,
              });
              onHideModal?.(false);
            }}
            selectedTeam={showAllUsersModal.team}
            getTeamsListAPI={getTeamsListAPI}
            isSelectionModal={isSelectionModal}
          />
        )}
        <div
          className={clsx("card list-item no-drag", isSelectionModal && "modal-item")}
          key={index}
        >
          <div className="d-flex flex-row align-items-center no-drag">
            {crudPermission?.delete && (
              <div className="form-check form-check-sm form-check-custom form-check-solid no-drag">
                <input
                  onChange={(e) => {
                    onCategoryCheckChange(team);
                  }}
                  className="form-check-input widget-9-check"
                  type="checkbox"
                  checked={isCategoryChecked(team)}
                />
              </div>
            )}
            {/* {crudPermission?.edit && (
              <div className="mx-5">
                <SVGICON
                  src={DragIcon}
                  className="svg-icon-2 svg-icon-hover-primary"
                />
              </div>
            )} */}

            <div
              className={`fw-bold mx-5 no-drag`}
              role="button"
              onClick={() => {
                setShowTeamsModal({
                  show: true,
                  type: "edit",
                  team: team,
                });
              }}
            >
              {team.name}
            </div>
          </div>

          <div className="d-flex justify-content-around no-drag">
            {/* Users */}
            <div className="me-2">
              {crudPermission?.edit && (
                <td
                  className="mw-250px d-flex justify-content-center align-items-center no-drag"
                  style={{
                    border: "none",
                  }}
                >
                  <div className="symbol-group symbol-hover flex-nowrap">
                    {team?.team_users &&
                      team?.team_users?.length > 0 &&
                      team?.team_users?.slice(0, 4).map((data, i) => {
                        return (
                          <React.Fragment key={i}>
                            <div
                              className={`symbol symbol-35px symbol-circle toolip_container_${data.id}`}
                              style={{ position: "relative" }}
                              onMouseOver={() => {
                                const tooltipEle = document.getElementById(
                                  `role-user-tooltip-${data.first_name}${i}`
                                );
                                if (tooltipEle) {
                                  tooltipEle.style.display = "flex";
                                }
                              }}
                              onMouseOut={() => {
                                const tooltipEle = document.getElementById(
                                  `role-user-tooltip-${data.first_name}${i}`
                                );
                                if (tooltipEle) {
                                  tooltipEle.style.display = "none";
                                }
                              }}
                            >
                              <span className="symbol-label text-inverse-warning fw-bold bg-color-sybmol no-drag">
                                {data.first_name[0]}
                              </span>
                            </div>
                            <Tooltip
                              style={{
                                height: "fit-content",
                              }}
                              anchorSelect={`.toolip_container_${data.id}`}
                            >
                              {data?.first_name ? (
                                <div>
                                  {(data?.first_name ? data.first_name : "") +
                                    " " +
                                    (data?.last_name ? data.last_name : "")}
                                </div>
                              ) : (
                                <div>{data?.username}</div>
                              )}
                            </Tooltip>
                          </React.Fragment>
                        );
                      })}
                    {remainingSectors > 0 && (
                      <div className="d-flex align-items-center mt-3  text-primary fw-bold no-drag">
                        <span className="me-2"></span>
                        <em
                          role={"button"}
                          onClick={() => {
                            setShowAllUsersModal({
                              show: true,
                              team: team,
                            });
                            onHideModal?.(true);
                          }}
                        >
                          {`...  `}
                          {`and ${remainingSectors} more`}
                        </em>
                      </div>
                    )}
                  </div>
                </td>
              )}
            </div>
            {type && type === "select" ? (
              ""
            ) : (
              // {/* Add user button */}
              <>
                {crudPermission?.edit && (
                  <div className="me-2">
                    <button
                      className="btn btn-sm btn-icon btn-light btn-active-light-primary min-w-100px no-drag"
                      onClick={() => {
                        setShowUsersModal({
                          show: true,
                          team: team,
                        });
                      }}
                    >
                      {intl.formatMessage({ id: "TEAMS_ADD_USER_BUTTON" })}
                    </button>
                  </div>
                )}
              </>
            )}
            {/* to see all users icon */}
            {crudPermission?.edit && (
              <div>
                <button
                  className="btn btn-sm btn-icon btn-light btn-active-light-primary me-2 no-drag"
                  onClick={(e) => {
                    setShowAllUsersModal({
                      show: true,
                      team: team,
                    });
                    onHideModal?.(true);
                  }}
                >
                  <i className="bi bi-people-fill fs-4 text-gray-500"></i>
                </button>
              </div>
            )}
            {type && type === "select" ? (
              ""
            ) : (
              // {/* Action dropdown */}
              <div className="no-drag">
                <TeamListActionsDropdown
                  team={team}
                  getTeamsListAPI={getTeamsListAPI}
                  onEdit={(data: TeamModalProps) => {
                    setShowTeamsModal(data);
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </>
    );
  };

  // get paginated records
  const startIndex = itemsPerPage * (activePage - 1);
  const paginatedFields =
    displayTeamsList && displayTeamsList.length > 10
      ? displayTeamsList.slice(startIndex, startIndex + itemsPerPage)
      : displayTeamsList;

  useEffect(() => {
    if (paginatedFields.length === 0 && displayTeamsList.length > 0) {
      setActivePage((prev) => prev - 1);
    }
  }, [paginatedFields]);

  // draggable categories list
  const list = paginatedFields
    ? paginatedFields.map((team, i) => {
        return {
          content: (
            <div
              className={searchText || !crudPermission?.edit ? "no-drag" : ""}
            >
              {TeamItem(team, i)}
            </div>
          ),
          team: team,
        };
      })
    : [];

  return (
    <>
      {showTeamsModal.show && (
        <TeamsModal
          ModalProps={showTeamsModal}
          closeModal={closeTeamsModal}
          getTeamsListAPI={getTeamsListAPI}
        />
      )}
      <div>
        {/* search categories */}
        <div
          className={clsx(
            "card card-search d-flex flex-row justify-content-between",
            isSelectionModal && "modal-item"
          )}
        >
          <div className="d-flex flex-row search-container">
            {crudPermission?.delete && (
              <div className="form-check form-check-sm form-check-custom form-check-solid ">
                <input
                  onChange={onAllTeamsCheckChange}
                  className="form-check-input widget-9-check"
                  type="checkbox"
                  checked={checkAllTeams}
                />
              </div>
            )}
            <div className="d-flex align-items-center position-relative ms-6">
              <span className="position-absolute" style={{ left: "10px" }}>
                <SVGICON className="svg-icon svg-icon-1" src={SearchIcon} />
              </span>
              <input
                type="text"
                className="form-control form-control-md form-control-solid min-w-150px min-w-md-200px ps-11"
                placeholder={intl.formatMessage({
                  id: "MASTERDATA_CATEGORY_SEARCH_PLACEHOLDER",
                })}
                onChange={(e) => {
                  onSearchTextChange(e.target.value);
                }}
              />
            </div>
          </div>

          {/* add new category button */}
          <div className="d-flex align-items-center">
            {checkedTeams.length > 0 ? (
              <>
                <span className="text-dark text-hover-primary fs-6 fw-bolder mx-4">
                  {checkedTeams.length} {intl.formatMessage({ id: "SELECTED" })}
                </span>
                {type && type === "select" ? (
                  <button
                    className="btn btn-primary d-flex align-items-center"
                    onClick={() => {
                      onSelectTeams?.(checkedTeams);
                    }}
                  >
                    {/* <i className={"bi bi-trash3-fill fs-6"}></i> */}
                    {intl.formatMessage({ id: "APPLY_BUTTON" })}
                  </button>
                ) : (
                  <button
                    className="btn btn-danger d-flex align-items-center"
                    onClick={() => {
                      showDeleteConfirmModal(
                        intl.formatMessage({
                          id: "MASTERDATA_FIELD_DELETE_CONFIRM_MESSAGE",
                        }),
                        () => {
                          deleteMultipleTeamsAPI();
                        }
                      );
                    }}
                  >
                    <i className={"bi bi-trash3-fill fs-6"}></i>
                    {intl.formatMessage({ id: "DELETE_BUTTON" })}
                  </button>
                )}
              </>
            ) : type && type === "select" ? (
              ""
            ) : (
              <>
                {crudPermission?.create && (
                  <button
                    className="btn  btn-primary"
                    onClick={() => {
                      setShowTeamsModal({
                        show: true,
                        type: "new",
                      });
                    }}
                  >
                    <SVGICON src={PlusIcon} className="svg-icon-2" />
                    {intl.formatMessage({
                      id: "TEAMS_ADD_NEW_TEAM_TITLE",
                    })}
                  </button>
                )}
              </>
            )}
          </div>
        </div>

        <DragSortableList
          items={list}
          // draggable={crudPermission?.edit}
          // // @ts-ignore
          // onSort={(sortedList, dropEvent) => {
          //   onTeamsSortChange(sortedList);
          // }}
          // type="vertical"
        />
      </div>
      {/* no data */}
      {!isLoading && displayTeamsList.length === 0 && (
        <table className="d-flex justify-content-center align-items-center mt-2">
          <tbody>
            <NoItemsFound languageKey="NO_ITEMS_FOUND" />
          </tbody>
        </table>
      )}

      {isLoading && displayTeamsList.length === 0 && (
        <table className="d-flex justify-content-center align-items-center mt-2">
          <tbody>
            <tr>
              <td valign="top" colSpan={7} className="dataTables_empty">
                <div className="d-flex flex-column flex-center">
                  <SimpleLoader />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      )}

      {displayTeamsList.length > 0 && (
        <div
          className={clsx(
            "card list-item-pagination",
            isSelectionModal ? "modal-item" : "card-mb"
          )}
        >
          <Pagination
            totalPages={Math.ceil(displayTeamsList.length / itemsPerPage)}
            activePage={
              Math.ceil(displayTeamsList.length / itemsPerPage) === 1
                ? 1
                : activePage
            }
            onPageClick={onPageClick}
            noCard
            itemsPerPage={itemsPerPage}
            showItemsPerPage
            setItemsPerPage={(count) => {
              setItemsPerPage(count);
              setActivePage(1);
            }}
          />
        </div>
      )}
    </>
  );
};

export default TeamsList;
