import React, { FC, useContext, useEffect, useState } from "react";
import { Tooltip } from "react-tooltip";
import { useIntl } from "react-intl";
import SVG from "react-inlinesvg";
import moment from "moment";

// styles
import "react-tooltip/dist/react-tooltip.css";

import { KTSVG, SVGICON } from "../../../../_metronic/helpers";
import Constants from "../../../config/Constants";
import Pagination from "../../../sharedComponents/pagination/Pagination";
import SimpleLoader from "../../../sharedComponents/Loader/SimpleLoader";
import { useLang } from "../../../../_metronic/i18n/Metronici18n";

// svg
import ImageIcon from "../../../../_metronic/assets/icons/imageIcon.svg";
import VideoIcon from "../../../../_metronic/assets/icons/video.svg";
import AudioIcon from "../../../../_metronic/assets/icons/audio.svg";
import PDFIcon from "../../../../_metronic/assets/icons/pdf.svg";
import FileIcon from "../../../../_metronic/assets/icons/defaultFile.svg";
import CSVIcon from "../../../../_metronic/assets/icons/Csv.svg";
import WORDIcon from "../../../../_metronic/assets/icons/word.svg";
import PPTIcon from "../../../../_metronic/assets/icons/ppt.svg";
import PPTXIcon from "../../../../_metronic/assets/icons/pptx.svg";
import InfoIcon from "../../../../_metronic/assets/icons/info.svg";
import XLSX from "../../../../_metronic/assets/icons/xlsx.svg";
import TXTIcon from "../../../../_metronic/assets/icons/Txt.svg";

import { FieldLogsContext } from "./context";
import { imageURLType } from "../../products/types/ProductsResponseType";
import { DealType, PhaseLogType } from "../types/DealResponseType";
import { getFile } from "../../filemanager/redux";
import { getPhaseLogs } from "../redux/DealsAPI";
import { bytesToSize } from "../../../utils/BytesToSize";
import ProductFilePreviewModal from "../../products/components/ProductFilePreviewModal";
// import Lightbox from 'lightbox-react';
// import 'lightbox-react/style.css';

interface Props {
  deal: DealType;
}

const DealLogs: FC<Props> = ({ deal }) => {
  const lang = useLang();
  const intl = useIntl();
  const local = useLang();

  // state
  const [phaseLogs, setPhaseLogs] = useState<PhaseLogType[]>([]);
  const { fieldLogsInEditPage } = useContext<any>(FieldLogsContext);
  const { pipelineName } = useContext<any>(FieldLogsContext);
  const [activePage, setActivePage] = useState<number>(1);
  const [showModal, setShowModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewLoading, setPreviewLoading] = useState<string | undefined>();
  const [imageFiles, setImageFiles] = useState<any[]>([]);
  const [previewFileURL, setPreviewFileURL] = useState<any>();
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [previewFileExtension, setPreviewFileExtension] = useState<any>();
  const [showPreviewModal, setShowPreviewModal] = useState<boolean>(false);
  const allowedFieldTypes = [
    "radio",
    "date",
    "product",
    "checkbox",
    "dropdown",
    "link",
    "longtext",
  ];

  const [itemsPerPage, setItemsPerPage] = useState(
    Constants.defaultItemsPerPageCount
  );
  const [loadig, setLoading] = useState(false);

  const onPageClick = (page: number) => {
    setActivePage(page);
  };
  useEffect(() => {
    setLoading(true);
    getPhaseLogs(deal.id)
      .then(({ data: { logs } }) => {
        setPhaseLogs(logs);
      })
      .catch((err) => {
        if (err?.code === 210) {
          // If No Data
          setPhaseLogs([]);
        } else {
          console.error(err?.response?.data?.message);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const mergeAndSortLogs = (
    phaseLogs: PhaseLogType[],
    fieldLogsInEditPage: any
  ) => {
    const mergedLogs = [...phaseLogs, ...fieldLogsInEditPage];

    mergedLogs.forEach((log) => {
      const sanitizedCreatedAt = log?.created_at?.split(".")[0] + "Z";

      const date = new Date(sanitizedCreatedAt);
      // @ts-ignore
      if (isNaN(date)) {
        console.error(`Invalid Date: ${sanitizedCreatedAt}`);
      }
    });

    mergedLogs.sort((a, b) => {
      const dateA = new Date(a?.created_at?.split(".")[0] + "Z").getTime();
      const dateB = new Date(b?.created_at?.split(".")[0] + "Z").getTime();
      return dateB - dateA;
    });

    return mergedLogs;
  };

  const sortedLogs = mergeAndSortLogs(phaseLogs, fieldLogsInEditPage);

  const startIndex = itemsPerPage * (activePage - 1);
  const paginatedLogs: any =
    sortedLogs && sortedLogs.length > 10
      ? sortedLogs.slice(startIndex, startIndex + itemsPerPage)
      : sortedLogs;

  useEffect(() => {
    if (paginatedLogs.length === 0 && phaseLogs.length > 0) {
      setActivePage((prev) => prev - 1);
    }
  }, [paginatedLogs]);

  // useEffect(()=>{
  //   const filteredImages = paginatedLogs?.map((log:any)=>{
  //     if(log.field_type==="uploader"){
  //       console.log(paginatedLogs,'log')
  //       return log?.fileData?.filter(isImageFile)
  //     }
  //     return []
  //   })
  //   setImageFiles(filteredImages);
  // },[])

  // console.log(imageFiles,'filtered images')

  useEffect(() => {
    // Filter the image files from the paginatedLogs
    const filteredImages = paginatedLogs?.flatMap((log: any) => {
      if (log?.field_type === "uploader" && log?.fileData) {
        return log.fileData.filter(isImageFile);  // Filter only image files
      }
      return []; // If not uploader or no fileData, return empty array
    }) || [];
  
    // Set the filtered image files only once, avoiding the infinite loop
    setImageFiles(filteredImages);
  }, [paginatedLogs])

  const handleShowModal = (file: React.SetStateAction<null>) => {
    setSelectedFile(file);
    setShowModal(true);
  };

  const getIconPath = (fieldType: any) => {
    switch (fieldType) {
      case "checkbox":
        return "/media/icons/duotune/logs/checkbox.svg";
      case "text":
        return "/media/icons/duotune/logs/text.svg";
      case "dropdown":
        return "/media/icons/duotune/logs/dropdown.svg";
      case "date":
        return "/media/icons/duotune/logs/date.svg";
      case "radio":
        return "/media/icons/duotune/logs/radio.svg";
      case "uploader":
        return "/media/icons/duotune/logs/contact.svg";
      case "Number":
        return "/media/icons/duotune/logs/number.svg";
      default:
        return "/media/icons/duotune/communication/com009.svg";
    }
  };

  const isImageFile = (file: any) => {
    const imageExtensions = [
      "jpeg",
      "jpg",
      "png",
      "gif",
      "bmp",
      "tiff",
      "tif",
      "heif",
      "heic",
      "webp",
      "psd",
      "svg",
      "eps",
      "ai",
      "ico",
      "cr2",
      "cr3",
      "nef",
      "arw",
      "raf",
      "orf",
      "rw2",
      "pef",
      "exr",
      "indd",
      "jp2",
      "j2k",
    ];

    const fileExtension = file?.type?.toLowerCase();
    return imageExtensions.includes(fileExtension);
  };

  const getIconForFileType = (file: any) => {
    switch (file?.type) {
      case "pdf":
        return PDFIcon;
      case "mp4":
      case "m4v":
        return VideoIcon;
      case "mp3":
        return AudioIcon;
      case "txt":
        return TXTIcon;
      case "xlsx":
        return XLSX;
      case "doc":
      case "docx":
        return WORDIcon;
      case "csv":
        return CSVIcon;
      case "ppt":
        return PPTIcon;
      case "pptx":
        return PPTXIcon;
      case "jpeg":
      case "jpg":
      case "png":
      case "gif":
      case "bmp":
      case "tiff":
      case "tif":
      case "heif":
      case "heic":
      case "webp":
      case "psd":
      case "svg":
      case "eps":
      case "ai":
      case "ico":
      case "cr2":
      case "cr3":
      case "nef":
      case "arw":
      case "raf":
      case "orf":
      case "rw2":
      case "pef":
      case "exr":
      case "indd":
      case "jp2":
      case "j2k":
        return ImageIcon;
      case "odt":
        return FileIcon;
      default:
        return FileIcon;
    }
  };

  function getFileExtensionFromUrl(url: string) {
    const match = url?.match(/\.([^.]+)$/);
    return match ? match[1] : null;
  }

  const handlePreviewURL = (fileUrl: imageURLType) => {
    //@ts-ignore
    setPreviewLoading(fileUrl.file_path); //@ts-ignore
    const fileExtension = getFileExtensionFromUrl(fileUrl.file_path);
    setPreviewFileExtension(fileExtension);
    getFile(fileUrl.id)
      .then((res: { data: { fileDetails: { file_url: any } } }) => {
        // console.log(response.data);
        let fileObjURL = (res && res.data.fileDetails.file_url) || undefined;
        // handleTaskFilePreview(true);
        setPreviewFileURL(fileObjURL);
        setShowPreviewModal(true);
        // if (isImageFile(fileUrl)) {
        //   setIsOpen(true);
        // } else {
        //   setShowPreviewModal(true);
        // }
      })
      .catch((err: any) => {})
      .finally(() => {
        setPreviewLoading(undefined);
      });
  };

  const handleImageClick = (index: number) => {
    setCurrentImageIndex(index);
    setIsOpen(true);
  };

  const closePreviewModal = () => {
    setShowPreviewModal(false);
    // handleTaskFilePreview(false);
  };

  const generateLogMessage = (log: {
    field_name: any;
    pipelines_phases_from_name?: any;
    pipelines_phases_to_name?: any;
    created_at?: moment.MomentInput;
    recorded_by_name?:
      | boolean
      | React.ReactChild
      | React.ReactFragment
      | React.ReactPortal
      | null
      | undefined;
    action?: any;
  }) => {
    //@ts-ignore
    const { field_name, field_type, action, fileData } = log;
    //@ts-ignore
    if (allowedFieldTypes.includes(field_type)) {
      let message;
      if (action?.oldValue === null) {
        message = (
          <span className="fw-500 fs-5 d-block text-start ps-0">
            <strong>{field_name}</strong>{" "}
            {intl.formatMessage({
              id: "LOGS_ADD_MESSAGE",
            })}{" "}
          </span>
        );
      } else if (
        action?.newValue === "[]" ||
        action?.newValue === "<p><br></p>"
      ) {
        message = (
          <span className="fw-500 fs-5 d-block text-start ps-0">
            <strong>{field_name}</strong>{" "}
            {intl.formatMessage({
              id: "DEAL_LOGS_FIELD_REMOVED",
            })}{" "}
          </span>
        );
      } else {
        message = (
          <span className="fw-500 fs-5 d-block text-start ps-0">
            <strong>{field_name}</strong>{" "}
            {intl.formatMessage({
              id: "COMMON_LOGS_UPDATED",
            })}{" "}
          </span>
        );
      }

      return message;
    } else {
      if (!action.oldValue && action.newValue !== null) {
        let message = (
          <span className="fw-500 fs-5 d-block text-start ps-0">
            <strong>{field_name}</strong>{" "}
            {intl.formatMessage({
              id: "DEAL_LOGS_FIELD_ADDED",
            })}{" "}
            <strong>{action.newValue}</strong>
          </span>
        );

        if (fileData?.length > 0 && action?.newValue) {
          message = (
            <span className="fw-500 fs-5 d-block text-start ps-0">
              <strong>{fileData?.length}</strong>{" "}
              {intl.formatMessage({
                id: "DEAL_LOGS_FILE_UPLOADED",
              })}{" "}
              <strong>{field_name}</strong>
            </span>
          );
        }

        return message;
      } else {
        let message;
        if (action?.newValue !== "[]" && field_type !== "uploader") {
          message = (
            <span className="fw-500 fs-5 d-block text-start ps-0">
              <strong>{field_name}</strong>{" "}
              {intl.formatMessage({
                id: "DEAL_LOGS_FIELD_UPDATED",
              })}{" "}
              <strong>{action.oldValue}</strong>{" "}
              {intl.formatMessage({
                id: "COMMON_LOGS_TO",
              })}{" "}
              <strong>{action.newValue}</strong>
            </span>
          );
        } else {
          message = (
            <span className="fw-500 fs-5 d-block text-start ps-0">
              <strong>{field_name}</strong>{" "}
              {intl.formatMessage({
                id: "DEAL_LOGS_FIELD_REMOVED",
              })}
            </span>
          );
        }

        if (fileData?.length > 0) {
          message = (
            <span className="fw-500 fs-5 d-block text-start ps-0">
              <strong>{fileData?.length}</strong>{" "}
              {intl.formatMessage({
                id: "DEAL_LOGS_FILES_UPLOADED",
              })}{" "}
              <strong>{field_name}</strong>
            </span>
          );
        }
        return message;
      }
    }
  };


  return (
    <div className="card">
      <div className="card-header p-0 border-0" style={{ minHeight: 0 }}>
        <div className="card-title m-0 d-flex">
          <div>
            <span>{deal.name}</span>
            <a data-tooltip-id="my-pipeline-tooltip" data-tooltip-place="top">
              <SVG src={InfoIcon} className="svg-icon mx-2" />
              <Tooltip
                id="my-pipeline-tooltip"
                place="top"
                style={{
                  color: "black",
                  backgroundColor: "#e9ecef",
                  zIndex: 999,
                  whiteSpace: "normal",
                  wordWrap: "break-word",
                  height: "fit-content",
                }}
                border="1px solid green"
              >
                <div>
                  <span className="fs-8">
                    {intl.formatMessage({
                      id: "DEAL_LOGS_TITLE",
                    })}{" "}
                    {pipelineName}{" "}
                    {intl.formatMessage({
                      id: "DEAL_LOGS_TITLE_PIPELINE",
                    })}
                  </span>
                </div>
              </Tooltip>
            </a>
          </div>
        </div>
      </div>
      <div className="card-body px-0 card-pt pb-0">
        {loadig && (
          <tr className="odd d-flex align-items-center justify-content-center">
            <td valign="top" colSpan={7} className="dataTables_empty">
              <div className="d-flex flex-column flex-center">
                <SimpleLoader />
              </div>
            </td>
          </tr>
        )}
        <div className="timeline">
          {paginatedLogs.length > 0 ? (
            <>
              {paginatedLogs?.map(
                (
                  log: {
                    field_name: any;
                    pipelines_phases_from_name: any;
                    pipelines_phases_to_name: any;
                    created_at: moment.MomentInput;
                    recorded_by_name:
                      | boolean
                      | React.ReactChild
                      | React.ReactFragment
                      | React.ReactPortal
                      | null
                      | undefined;
                  },
                  index: React.Key | null | undefined
                ) => {
                  return (
                    <>
                      {/* @ts-ignore */}
                      {log?.field_name ? (
                        <>
                          <div className="timeline-item" key={index}>
                            <div className="timeline-line w-40px"></div>

                            <div className="timeline-icon symbol symbol-circle symbol-40px">
                              <div className="symbol-label bg-light">
                                <KTSVG
                                  //@ts-ignore
                                  path={getIconPath(log?.field_type)}
                                  className="svg-icon-2 svg-icon-gray-500"
                                />
                              </div>
                            </div>

                            <div className="timeline-content mb-10 mt-n2">
                              <div className="overflow-auto pe-3">
                                <div className="mb-2">
                                  {generateLogMessage(log)}
                                </div>

                                <div className="d-flex align-items-center mt-1 fs-6 pb-6">
                                  <div className="text-muted me-2 fs-7">
                                    {intl.formatMessage({
                                      id: "COMMON_LOGS_ON",
                                    })}{" "}
                                    {moment(log.created_at).format(
                                      lang === "en"
                                        ? "DD-MM-YYYY"
                                        : "DD.MM.YYYY"
                                    )}{" "}
                                    {intl.formatMessage({
                                      id: "COMMON_LOGS_BY",
                                    })}
                                  </div>

                                  <div
                                    className="symbol symbol-circle symbol-25px"
                                    style={{ color: "#3766C7" }}
                                    data-bs-toggle="tooltip"
                                    data-bs-boundary="window"
                                    data-bs-placement="top"
                                  >
                                    {/* <img
                                src={log.}
                                alt="img"
                              /> */}
                                    <span>{log.recorded_by_name}</span>
                                  </div>
                                </div>
                                <>
                                  {/* @ts-ignore */}
                                  {log?.field_type === "uploader" && (
                                    <>
                                    {/* @ts-ignore */}
                                  { log?.fileData?.length > 0 && <div className="d-flex align-items-center border border-dashed border-gray-300 rounded  min-w-700px p-5 overflow-auto">
                                      {/* @ts-ignore */}
                                      {log?.fileData?.map((file) => {
                                        const isImage = isImageFile(file);
                                        return (
                                          <div
                                            onClick={() =>
                                              handleShowModal(file)
                                            }
                                            className="d-flex flex-row align-items-center mt-2 cursor-pointer"
                                          >
                                            <div
                                              className="d-flex align-items-center cursor-pointer"
                                              onClick={() => {
                                                // if (isImage) {
                                                //   // Getting the corresponding index in imageFiles
                                                //   const imageIndex =
                                                //     imageFiles.findIndex(
                                                //       (image) =>
                                                //         image?.file_path ===
                                                //         file?.file_path
                                                //     );
                                                //   if (imageIndex !== -1) {
                                                //     handleImageClick(
                                                //       imageIndex,
                                                //     ); // Opening lightbox for images
                                                //   }
                                                // } else {
                                                //   handlePreviewURL(file); // Opening preview modal for non-image files
                                                // }
                                                handlePreviewURL(file);
                                              }}
                                            >
                                              <div className="symbol-50px overflow-hidden me-3 mx-3">
                                                <div className="symbol-label">
                                                  <div className=" fs-3 text-danger text-uppercase">
                                                    <SVGICON
                                                      src={getIconForFileType(
                                                        file
                                                      )}
                                                      className="svg-icon-primary svg-icon-2x "
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                              <div
                                                className="d-flex flex-column justify-content-start"
                                                // onClick={() => {
                                                //   handlePreviewURL(file);
                                                // }}
                                              >
                                                <span
                                                  className="fw-bold text-primary text-truncate mb-1"
                                                  role="button"
                                                  title={file.photo_url}
                                                  style={{
                                                    minWidth: "100px",
                                                    maxWidth: "140px",
                                                    textOverflow: "ellipsis",
                                                    overflow: "hidden",
                                                    whiteSpace: "nowrap",
                                                  }}
                                                >
                                                  {file.name}
                                                </span>
                                                {file?.size && (
                                                  <div className="">
                                                    <span className=" text-muted mb-2">
                                                      {/* {bytesToSize(file?.size, 1)} */}
                                                      {local === "de"
                                                        ? /* @ts-ignore */
                                                          moment(
                                                            file?.created_at
                                                          ).format("DD.MM.yyyy")
                                                        : /* @ts-ignore */
                                                          moment(
                                                            file?.created_at
                                                          ).format(
                                                            "YYYY-MM-DD"
                                                          )}
                                                    </span>
                                                  </div>
                                                )}
                                              </div>
                                            </div>
                                          </div>
                                        );
                                      })}
                                    </div>
                                    }
                                    </>
                                  )}
                                </>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        <div className="timeline-item" key={index}>
                          <div className="timeline-line w-40px"></div>

                          <div className="timeline-icon symbol symbol-circle symbol-40px">
                            <div className="symbol-label bg-light">
                              <KTSVG
                                path="/media/icons/duotune/communication/com009.svg"
                                className="svg-icon-2 svg-icon-gray-500"
                              />
                            </div>
                          </div>

                          <div className="timeline-content mb-10 mt-n2">
                            <div className="overflow-auto pe-3">
                              <div className="fs-5 mb-2">
                                <span>
                                  {intl.formatMessage({
                                    id: "DEAL_LOGS_DEAL_MOVED_FROM",
                                  })}{" "}
                                  <strong>
                                    {log.pipelines_phases_from_name}
                                  </strong>{" "}
                                  {intl.formatMessage({
                                    id: "COMMON_LOGS_TO",
                                  })}{" "}
                                  <strong>
                                    {log.pipelines_phases_to_name}
                                  </strong>
                                </span>
                              </div>

                              <div className="d-flex align-items-center mt-1 fs-6">
                                <div className="text-muted me-2 fs-7">
                                  {intl.formatMessage({
                                    id: "COMMON_LOGS_MOVED_AT",
                                  })}{" "}
                                  {moment(log.created_at).format(
                                    lang === "en" ? "DD-MM-YYYY" : "DD.MM.YYYY"
                                  )}{" "}
                                  {intl.formatMessage({
                                    id: "COMMON_LOGS_BY",
                                  })}{" "}
                                </div>

                                <div
                                  className="symbol symbol-circle symbol-25px"
                                  style={{ color: "#3766C7" }}
                                  data-bs-toggle="tooltip"
                                  data-bs-boundary="window"
                                  data-bs-placement="top"
                                >
                                  {/* <img
                                src={log.}
                                alt="img"
                              /> */}
                                  <span>{log.recorded_by_name}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </>
                  );
                }
              )}
            </>
          ) : (
            <div className="text-muted fs-8 d-flex align-items-center justify-content-center">
              {intl.formatMessage({
                id: "COMMON_NO_LOGS",
              })}
            </div>
          )}
        </div>
      </div>

      {previewFileURL && (
        <div>
          <ProductFilePreviewModal
            show={showPreviewModal}
            previewFileURL={previewFileURL}
            previewFileExtension={previewFileExtension}
            closeModal={closePreviewModal}
            showCloseButton
          />
        </div>
      )}

      {/* {isOpen && (
        <Lightbox
          mainSrc={imageFiles[currentImageIndex]?.file_path}
          nextSrc={
            imageFiles[(currentImageIndex + 1) % imageFiles.length]?.file_path
          }
          prevSrc={
            imageFiles[
              (currentImageIndex + imageFiles.length - 1) % imageFiles.length
            ]?.file_path
          }
          onCloseRequest={() => {
            setCurrentImageIndex(0);
            setIsOpen(false);
          }}
          onMovePrevRequest={() =>
            setCurrentImageIndex(
              (currentImageIndex + imageFiles.length - 1) % imageFiles.length
            )
          }
          onMoveNextRequest={() =>
            setCurrentImageIndex((currentImageIndex + 1) % imageFiles.length)
          }
        />
      )} */}

      {/* end::Table */}
      {sortedLogs.length > 0 && (
        <div className="card card-mb mt-0 list-item-pagination">
          <Pagination
            totalPages={Math.ceil(sortedLogs.length / itemsPerPage)}
            activePage={
              Math.ceil(sortedLogs.length / itemsPerPage) === 1 ? 1 : activePage
            }
            onPageClick={onPageClick}
            noCard
            itemsPerPage={itemsPerPage}
            showItemsPerPage
            setItemsPerPage={(count) => {
              setItemsPerPage(count);
            }}
          />
        </div>
      )}
    </div>
  );
};

export default DealLogs;
