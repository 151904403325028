import React, { useCallback, useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { useHistory, useLocation } from "react-router-dom";
import { PipelinePhaseType, PipelineType } from "../types/PipelinesResponseType";
import { SVGICON } from "../../../../_metronic/helpers";
import DeleteIcon from "../../../../_metronic/assets/icons/delete.svg";
import InfoIcon from "../../../../_metronic/assets/icons/info.svg";
import SVG from "react-inlinesvg";
import DragIcon from "../../../../_metronic/assets/icons/drag.svg";
import PlusIcon from "../../../../_metronic/assets/icons/plus.svg";
import OpenLockIcon from "../../../../_metronic/assets/icons/lockOpen.svg";
import closeLockIcon from "../../../../_metronic/assets/icons/lockClose.svg";
import clsx from "clsx";
import {
	SortPhasesTabs,
	SortPipelinePhases,
	addPhaseTabs,
	deletePhaseTabs,
	deletePipelinePhase,
	getPipelineByID,
	updatePhase,
	updatePipelines,
	importTabsFromPipeline,
} from "../redux/PipelinesAPI";
import AddPipelinePhaseModal from "./AddPipelinePhaseModal";
import { useGlobalModalContext } from "../../../sharedComponents/modals/ModalContext";
import { errorToast, successToast } from "../../../sharedComponents/toasts/Toasts";
import { useSelector } from "react-redux";
import { RootState } from "../../../../setup";
import { RoleType } from "../../RolesAndPermissions/types/getRolesResponseType";
import { TabType } from "../../projectMasterData/types/TabsResponseType";
import { TabSideBar } from "../../projectMasterData/components/EditTabs";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import TabDropzone from "../../projectMasterData/components/TabDropzone";
import SimpleLoader from "../../../sharedComponents/Loader/SimpleLoader";
import { sortGroupsParams } from "../types/request";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import { UserType } from "../../userManagement/types/getUsersListResponseType";
import "../PipelinePage.scss";
import { TabsModal, TabsModalProps } from "../../projectMasterData/components/ProjectTabs";
import { getTabsList } from "../../projectMasterData/redux/TabsAPI";
import { useDispatch } from "react-redux";
import { actions } from "../../projectMasterData/redux/ContentMasterRedux";
import { TouchBackend } from "react-dnd-touch-backend";
import { isMobile } from "react-device-detect";
import withScrolling from "react-dnd-scrolling";
import PipelineTabsAndFields from "./PipelineTabsAndFields";
import SelectTabsModal from "../../projectMasterData/components/SelectTabsModal";
import { enablePermissionForCrud } from "../../../utils/PermisisionEnabledForResource";
import ScrollToTop from "../../../sharedComponents/ScrollToTop/ScrollToTop";
import PipelineTransitionType from "./PipelineGeneralDetails";
import PipelinePhaseRoles from "./PipelinePhaseRoles";
import PipelinePhaseApprovers from "./PipelinePhaseApprovers";
import { Button, Modal } from "react-bootstrap";
import CloseButton from "../../../sharedComponents/Buttons/CloseButton";
import DropDown from "../../../sharedComponents/dropdown/Dropdown";

const EditPipeline = () => {
	const { state = {} } = useLocation();
	const history = useHistory();
	const intl = useIntl();
	const cardWidth = useRef(null);

	const tabsList = useSelector<RootState>(
		// @ts-ignore
		({ contentMaster }) => contentMaster.tabsList
	) as TabType[];
	const singlePipeline = useSelector<RootState>(
		// @ts-ignore
		({ pipelines }) => pipelines.pipelineDetails
	) as PipelineType;

	const userRoleDetails = useSelector<RootState>(
		// @ts-ignore
		(state) => state.auth.roleDetails
	) as RoleType;
	//@ts-ignore
	const crudPermission: any = enablePermissionForCrud(userRoleDetails, "pipelines");
	const crudPermissionForTabs: any = enablePermissionForCrud(userRoleDetails, "tabs");

	// @ts-ignore
	const pipeline: PipelineType = state.pipelineDetails || singlePipeline || {};
	const userData = useSelector<RootState>(({ auth }) => auth.user) as UserType;
	const dispatch = useDispatch();

	//   state
	const [activePhase, setActivePhase] = useState<PipelinePhaseType>(pipeline.phases?.[0]);
	const [currPipeline, setCurrPipeline] = useState<PipelineType>(pipeline);
	const [showPhaseModal, setShowPhaseModal] = useState(false);
	const { showDeleteConfirmModal, hideDeleteConfirmModal, setDeleteModalLoading } = useGlobalModalContext();

	const [phaseHeading, setPhaseHeading] = useState(activePhase?.name || "");
	const [pipelineHeading, setPipelineHeading] = useState(pipeline.name || "");
	const [phaseIsRequired, setPhaseIsRequired] = useState(activePhase?.is_required);
	const [showAddModal, setShowAddModal] = useState(false);
	const [selectedPhases, setSelectedPhases] = useState([]);
	const [loading, setLoading] = useState(false);
	const [phaseTabActiveIndex, setPhaseTabActiveIndex] = useState(0);
	const [searchText, setSearchText] = useState<string>("");
	const [fieldData, setFieldData] = useState<TabType[]>(tabsList);
	const [addTabModal, setAddTabModal] = useState<TabsModalProps>({
		show: false,
		type: "new",
	});
	const [selectedTab, setSelectedTab] = useState<TabType>(activePhase?.tabs?.[0]);

	const ScrollingComponent = withScrolling("div");

	useEffect(() => {
		if (pipeline && pipeline.phases && pipeline.phases.length > 0) {
			setPhaseHeading(activePhase?.name);
			setPhaseIsRequired(activePhase?.is_required);
		}
		if (pipeline?.id) {
			setCurrPipeline(pipeline);
		}
	}, [activePhase]);

	useEffect(() => {
		if (activePhase && activePhase?.id) {
			const cloneActivePhase = pipeline.phases.filter((d) => d?.id === activePhase?.id)[0];
			if (cloneActivePhase && cloneActivePhase?.id) {
				setActivePhase(cloneActivePhase);
				setSelectedTab(cloneActivePhase?.tabs?.[0]);
			} else {
				setActivePhase(pipeline.phases?.[0]);
				setSelectedTab(pipeline.phases?.[0]?.tabs?.[0]);
			}
		} else {
			setActivePhase(pipeline.phases?.[0]);
			setSelectedTab(pipeline.phases?.[0]?.tabs?.[0]);
		}
	}, [pipeline]);

	useEffect(() => {
		ScrollToTop();
	}, []);

	const getPipelineDetailsById = () => {
		setLoading(true);
		getPipelineByID(currPipeline?.id)
			.then(({ data: { pipeline } }) => {
				history.replace({ state: { pipelineDetails: pipeline } });
				setCurrPipeline(pipeline);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const handleDeletePipelinePhase = (id: number) => {
		setDeleteModalLoading(true);
		deletePipelinePhase(id)
			.then((data) => {
				// setActivePhase(pipeline.phases?.[0]);
				successToast(
					intl.formatMessage({
						id: "PROJECTS_PIPELINE_PHASE_DELETE_SUCCESS_MESSAGE",
					})
				);
			})
			.catch(() => {
				errorToast(
					intl.formatMessage({
						id: "PROJECTS_PIPELINE_PHASE_DELETE_FAILURE_MESSAGE",
					})
				);
			})
			.finally(() => {
				getPipelineDetailsById();
				setDeleteModalLoading(false);
				hideDeleteConfirmModal();
			});
	};

	const handlePhaseHeading = (phaseIsRequired: any) => {
		updatePhase(currPipeline?.id, activePhase?.id, phaseHeading, phaseIsRequired, activePhase.is_endPhase)
			.then(() => {
				successToast(
					intl.formatMessage({
						id: "PROJECTS_PIPELINE_PHASE_SUCCES_MESSAGE",
					})
				);
			})
			.catch(() => {
				errorToast(
					intl.formatMessage({
						id: "PROJECTS_PIPELINE_PHASE_FAILURE_MESSAGE",
					})
				);
			})
			.finally(() => {
				getPipelineDetailsById?.();
			});
	};

	const handlePipelineHeading = (value: string) => {
		updatePipelines(currPipeline?.id, value, currPipeline?.transition_type, currPipeline?.status)
			.then(() => {
				successToast(
					intl.formatMessage({
						id: "PROJECTS_PIPELINE_NAME_SUCCES_MESSAGE",
					})
				);
			})
			.catch(() => {
				errorToast(
					intl.formatMessage({
						id: "PROJECTS_PIPELINE_NAME_FAILURE_MESSAGE",
					})
				);
			})
			.finally(() => {
				getPipelineDetailsById?.();
			});
	};

	const handleDrop = useCallback(
		(
			dropzone: {
				childrenCount: any;
				path: any;
				phase: number;
				pipeline: number;
			},
			item: { id: number; isNew: any; type: any }
		) => {
			// console.log(item);
			if (item && item.isNew) {
				const existingPhase = currPipeline.phases.filter((data) => data.id === dropzone.phase)[0];
				const existingTabIds = existingPhase.tabs.map((data) => data.id) || [];
				// console.log(existingTabIds);
				addPhaseTabs(dropzone.phase, [...existingTabIds, item.id])
					.then(() => {
						successToast(
							intl.formatMessage({
								id: "PROJECTS_PIPELINE_TAB_ADD_SUCCES_MESSAGE",
							})
						);
					})
					.catch(() => {
						errorToast(
							intl.formatMessage({
								id: "PROJECTS_PIPELINE_TAB_ADD_FAILURE_MESSAGE",
							})
						);
					})
					.finally(() => {
						getPipelineDetailsById?.();
					});
			}
		},
		[pipeline]
	);

	const handleDeletePipelinePhaseTab = (id: number) => {
		// const filteredTabs = activePhase?.tabs.filter((data) => data.id !== id);
		// const ids =
		//   filteredTabs && filteredTabs.length > 0
		//     ? filteredTabs.map((data) => data.id)
		//     : [];
		setDeleteModalLoading(true);
		deletePhaseTabs(activePhase?.id, [id])
			.then((data) => {
				successToast(
					intl.formatMessage({
						id: "PROJECTS_PIPELINE_PHASE_TAB_DELETE_SUCCESS_MESSAGE",
					})
				);
			})
			.catch(() => {
				errorToast(
					intl.formatMessage({
						id: "PROJECTS_PIPELINE_PHASE_TAB_DELETE_FAILURE_MESSAGE",
					})
				);
			})
			.finally(() => {
				setPhaseTabActiveIndex(0);
				getPipelineDetailsById();
				setDeleteModalLoading(false);
				hideDeleteConfirmModal();
			});
	};

	const handlePipelinePhaseSort = (sortedList: PipelinePhaseType[]) => {
		const newSortedTabsArr: sortGroupsParams = [];
		sortedList.forEach((sortedListItem, i) => {
			newSortedTabsArr.push({
				id: sortedListItem.id,
				newposition: i + 1,
			});
		});
		// sort existing tags API Call
		SortPipelinePhases(currPipeline.id, newSortedTabsArr)
			.then(() => {
				successToast(intl.formatMessage({ id: "PIPELINE_PHASE_SORTING_SUCCESS_MESSAGE" }));
			})
			.catch(() => {})
			.finally(() => {
				getPipelineDetailsById();
			});
	};

	const handlePhaseTabSort = (sortedList: TabType[]) => {
		const newSortedTabsArr: sortGroupsParams = [];
		sortedList.forEach((sortedListItem, i) => {
			newSortedTabsArr.push({
				id: sortedListItem.id,
				newposition: i + 1,
			});
		});
		// sort existing tags API Call
		SortPhasesTabs(activePhase?.id, newSortedTabsArr)
			.then(() => {})
			.catch(() => {})
			.finally(() => {
				getPipelineDetailsById();
			});
	};

	const onSearchTextChange = (text: string) => {
		setSearchText((text || "").trim());
	};

	const updateDisplayTabsList = () => {
		const updatedTabsList = fieldData.filter((tab) => {
			if ((tab.name || "").toLocaleLowerCase().includes((searchText || "").toLocaleLowerCase())) {
				return true;
			}
			return false;
		});
		// save updatedlist to display
		setFieldData(updatedTabsList);
	};

	useEffect(() => {
		updateDisplayTabsList();

		if (searchText?.length === 0) {
			setFieldData(tabsList);
		}

		return () => {
			setFieldData(tabsList);
		};
	}, [tabsList, searchText]);

	const dragPhase = useRef<number>(0);
	const dragOverPhase = useRef<number>(0);

	const dragTab = useRef<number>(0);
	const dragOverTab = useRef<number>(0);

	const getTabsListAPI = () => {
		getTabsList().then(({ data: { data } }) => {
			dispatch(actions.setTabsList(data));
		});
	};

	const hasNative = document && document.elementsFromPoint;

	function getDropTargetElementsAtPoint(x: any, y: any, dropTargets: any) {
		return dropTargets.filter((t: any) => {
			const rect = t.getBoundingClientRect();
			return x >= rect.left && x <= rect.right && y <= rect.bottom && y >= rect.top;
		});
	}

	// use custom function only if elementsFromPoint is not supported
	const backendOptions = {
		getDropTargetElementsAtPoint: !hasNative && getDropTargetElementsAtPoint,
	};

	const handleAddTabs = (addTabs: TabType[]) => {
		const existingTabIds = activePhase.tabs.map((data) => data.id) || [];
		const newTabIds = addTabs.map((data) => data.id) || [];
		addPhaseTabs(activePhase.id, [...existingTabIds, ...newTabIds])
			.then(() => {
				successToast(
					intl.formatMessage({
						id: "PROJECTS_PIPELINE_TAB_ADD_SUCCES_MESSAGE",
					})
				);
			})
			.catch(() => {
				errorToast(
					intl.formatMessage({
						id: "PROJECTS_PIPELINE_TAB_ADD_FAILURE_MESSAGE",
					})
				);
			})
			.finally(() => {
				getPipelineDetailsById?.();
			});
	};

	const getOtherPhases = (pipeline: any, activePhase: any) => {
		return pipeline?.phases?.filter((phase: any) => phase?.id !== activePhase?.id && phase?.tabs?.length > 0);
	};
	const otherPhases = getOtherPhases(pipeline, activePhase);

	const handleImportTabs = () => {
		importTabsFromPipeline(
			activePhase?.id,
			selectedPhases?.map((phase: any) => phase?.id)
		)
			.then(() => {
				successToast(intl.formatMessage({ id: "TABS_IMPORTED_SUCCESS_MESSAGE" }));
			})
			.catch(() => {
				errorToast(intl.formatMessage({ id: "TABS_IMPORTED_FAILURE_MESSAGE" }));
			})
			.finally(() => {
				setShowAddModal(false);
				setDeleteModalLoading(false);
				hideDeleteConfirmModal();
				setSelectedPhases([]);
				getPipelineDetailsById();
			});
	};

	const handleOnSelectPhase = (item: any) => {
		const index = selectedPhases.findIndex(
			// @ts-ignore
			(selectedItem) => selectedItem?.id === item?.id
		);

		if (index === -1) {
			// @ts-ignore
			setSelectedPhases((prev: any) => [...prev, item]);
		} else {
			// @ts-ignore
			setSelectedPhases((prevState) => {
				prevState.splice(index, 1);
				return [...prevState];
			});
		}
	};

	return (
		<>
			<DndProvider backend={isMobile ? TouchBackend : HTML5Backend} options={backendOptions}>
				<div>
					{showPhaseModal && (
						<AddPipelinePhaseModal
							show={showPhaseModal}
							closeModal={() => {
								setShowPhaseModal(false);
							}}
							pipeline={currPipeline}
							getPipelineDetailsById={getPipelineDetailsById}
						/>
					)}
					{addTabModal.show && (
						// <TabsModal
						//   ModalProps={addTabModal}
						//   closeModal={() => {
						//     setAddTabModal({ show: false, type: "new" });
						//   }}
						//   getTabsListAPI={getTabsListAPI}
						// />
						<SelectTabsModal
							show={addTabModal.show}
							closeModal={() => {
								setAddTabModal({
									show: false,
								});
							}}
							onSelectedTabs={(tabs) => {
								if (tabs && tabs.length > 0) {
									setAddTabModal({
										show: false,
									});
									handleAddTabs(tabs);
								}
							}}
							selectedTabs={activePhase.tabs}
							isSelectionModal={true}
						/>
					)}

					{/* Transition Type */}
					<PipelineTransitionType pipeline={currPipeline} getPipelineDetailsById={getPipelineDetailsById} />

					{/* pipline name header */}
					{/* new UI to edit pipeline name, adding phases and sorting */}
					<div className={`card card-my card-p ${!crudPermission?.edit && "pe-none"}`} ref={cardWidth}>
						{/* Pipeline Name */}
						<div className="">
							{/* <label className="d-flex align-items-center fs-5 fw-bold mb-2">
                <span className="">
                  {intl.formatMessage({
                    id: "PIPELINE_DETAILS_PIPELINE_NAME",
                  })}
                </span>
              </label> */}
							<div className="d-flex flex-row align-items-center mb-4">
								<input
									type="text"
									value={pipelineHeading}
									onChange={(e) => {
										setPipelineHeading(e.target.value);
									}}
									style={{
										flex: 1,
										border: "none",
										outline: "none",
									}}
									className="panelHeading fw-bold py-4"
									onBlur={() => {
										if (pipelineHeading.length === 0) {
											setPipelineHeading(activePhase?.name || "");
										} else if (pipeline?.name !== pipelineHeading) {
											handlePipelineHeading(pipelineHeading);
										}
									}}
								/>
							</div>
						</div>

						{/* Phases progress bar */}
						<div className="d-flex col-12 align-items-center gap-5 ">
							<div
								className="d-flex rounded"
								style={{
									minWidth: "250px",
									maxWidth: "100%",
									overflow: "auto",
								}}
							>
								{currPipeline?.phases?.map((phase, index) => {
									if (phase?.condition === "start") {
										return (
											<div className="w-100 min-w-100px" key={index}>
												<div
													className="mb-2 d-flex align-items-center justify-content-center h-10px fw-bold position-relative borderStyle me-1"
													role="button"
													onClick={() => {
														if (activePhase?.id !== phase.id) {
															setActivePhase(phase);
															setSelectedTab(phase?.tabs?.[0]);
														}
													}}
													style={{
														flex: 1,
														background: "#3766C7",
													}}
												></div>
												<div className="d-flex  text-muted textEllipsis">
													{crudPermission?.edit && (
														<SVGICON src={DragIcon} className="svg-icon-4 svg-icon-hover-primary me-2" />
													)}
													{phase.name}
												</div>
											</div>
										);
									} else {
										if (phase?.display_order) {
											return (
												<div
													className="w-100 min-w-100px"
													key={index}
													draggable
													role="button"
													onClick={() => {
														if (activePhase?.id !== phase.id) {
															setActivePhase(phase);
															setSelectedTab(phase?.tabs?.[0]);
														}
													}}
													onDragStart={(e) => {
														e.stopPropagation();
														dragPhase.current = index;
													}}
													onDragEnter={(e) => {
														e.stopPropagation();
														dragOverPhase.current = index;
														// console.log(index);
													}}
													onDragEnd={(e) => {
														e.stopPropagation();
														if (dragPhase.current !== dragOverPhase.current) {
															const clonePhases = [...currPipeline.phases];

															const deletedSlice = clonePhases.splice(dragPhase.current, 1)[0];
															clonePhases.splice(dragOverPhase.current, 0, deletedSlice);
															const phaseWithDisplayOrder = clonePhases.filter((data) => data?.display_order);

															handlePipelinePhaseSort(phaseWithDisplayOrder);
														}
													}}
													onDragOver={(e) => {
														e.preventDefault();
													}}
												>
													<div
														className="mb-2 d-flex align-items-center justify-content-center h-10px fw-bold position-relative borderStyle me-1"
														key={index}
														style={{
															flex: 1,
															background: "#3766C7",
														}}
													></div>
													<div className="d-flex  text-muted textEllipsis">
														{crudPermission?.edit && (
															<SVGICON src={DragIcon} className="svg-icon-4 svg-icon-hover-primary me-2" />
														)}
														{phase.name}
													</div>
												</div>
											);
										}
									}
								})}

								{currPipeline?.phases?.map((phase, index) => {
									if (phase?.condition === "end" && phase?.display_order == undefined) {
										return (
											<div className="w-100 min-w-100px" key={index}>
												<div
													className="mb-2 d-flex align-items-center justify-content-center h-10px fw-bold position-relative borderStyle me-1"
													role="button"
													style={{
														flex: 1,
														background: "#3766C7",
													}}
													onClick={() => {
														if (activePhase?.id !== phase.id) {
															setActivePhase(phase);
															setSelectedTab(phase?.tabs?.[0]);
														}
													}}
												></div>
												<div className="d-flex align-items-center justify-content-center text-muted textEllipsis">
													{crudPermission?.edit && (
														<SVGICON src={DragIcon} className="svg-icon-4 svg-icon-hover-primary me-2" />
													)}
													{phase.name}
												</div>
											</div>
										);
									}
								})}
							</div>
							<div className="d-flex justify-content-end px-0 align-items-center">
								{crudPermission?.edit && (
									<button
										className={clsx("btn btn-primary  btn-sm me-1")}
										onClick={() => {
											setShowPhaseModal(true);
										}}
										style={{ minWidth: "120px" }}
									>
										<SVGICON src={PlusIcon} className="svg-icon-2" />
										{intl.formatMessage({ id: "PROJECTS_PHASES_TITLE" })}
									</button>
								)}
							</div>
						</div>
					</div>

					<div className="row g-25px card-mb">
						{/* Phase edit Card */}
						<div className="col-lg-8">
							<div className="card card-p card-mb">
								{/* phase name edit and delete buttons */}
								<div className="mb-4 d-flex flex-row align-items-center justify-content-between borderNone">
									<div
										className={`d-flex flex-row align-items-center ${!crudPermission?.edit && "pe-none"}`}
										style={{
											flex: 1,
										}}
									>
										<input
											type="text"
											value={phaseHeading}
											onChange={(e) => {
                        setPhaseHeading(e.target.value)
											}}
											style={{
												flex: 1,
												border: "none",
												outline: "none",
											}}
											className="panelHeading me-4 fw-bold py-4"
											onBlur={() => {
												if (phaseHeading.length === 0) {
													setPhaseHeading(activePhase?.name || "");
												} else if (activePhase?.name !== phaseHeading) {
													handlePhaseHeading(phaseIsRequired);
												}
											}}
										/>
									</div>

									<div>
										{crudPermission?.edit && (
											<button
												className="btn btn-icon btn-light  btn-active-light-primary btn-sm"
												onClick={() => {
													if (userData.role.id === 1) {
														setPhaseIsRequired(phaseIsRequired === 0 ? 1 : 0);
														handlePhaseHeading(phaseIsRequired === 0 ? 1 : 0);
													}
												}}
											>
												<SVGICON src={phaseIsRequired === 1 ? closeLockIcon : OpenLockIcon} className="svg-icon-2" />
											</button>
										)}

										{(!activePhase?.can_delete && activePhase?.is_required === 0) || userData?.role.id === 1
											? activePhase?.display_order && (
													<button
														className="btn btn-icon btn-light btn-active-light-danger btn-sm ms-2"
														onClick={() => {
															showDeleteConfirmModal(
																intl.formatMessage({
																	id: "PROJECTS_PIPELINE_DELETE_CONFIRM_MESSAGE",
																}),
																() => {
																	handleDeletePipelinePhase(activePhase?.id);
																}
															);
														}}
													>
														<SVGICON src={DeleteIcon} className="svg-icon-3" />
													</button>
												)
											: activePhase?.display_order && (
													<div
														className="d-flex align-items-center justify-content-center"
														style={{
															zIndex: "11",
														}}
													>
														<a
															data-tooltip-id="my-tooltip"
															data-tooltip-content={
																activePhase?.is_required === 1
																	? intl.formatMessage({
																			id: "PIPELINE_PHASE_NOT_DELTET_REQUIRED_REASON_INFO_MESSAGE",
																		})
																	: intl.formatMessage({
																			id: "PIPELINE_PHASE_NOT_DELTET_DEALS_ATTACH_REASON_INFO_MESSAGE",
																		})
															}
															data-tooltip-place="top"
														>
															<SVG src={InfoIcon} className="svg-icon mx-2" />
														</a>

														<Tooltip
															id="my-tooltip"
															place="top"
															style={{
																backgroundColor: "#f5f8fa",
																color: "black",
                                height: "fit-content",
															}}
															border="1px solid green"
														/>
													</div>
												)}
									</div>
								</div>
								{/* tabs | add tabs button | import tabs button  */}
								<div className="d-flex justify-content-between w-100">
                  {/* tabs */}
									<div
										style={{
											minWidth: "10px",
											// maxWidth: "80%",
											overflowX: "auto",
											overflowY: "hidden",
										}}
									>
										{activePhase && activePhase?.tabs && activePhase?.tabs.length > 0 && (
											<div className="nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-6 fw-bold flex-nowrap">
												{activePhase?.tabs.map((tab, i) => {
													if (tab) {
														return (
															<div
																className={`d-flex align-items-center ${!crudPermission?.edit && "pe-none"}`}
																draggable
																onDragStart={(e) => {
																	e.stopPropagation();
																	dragTab.current = i;
																}}
																onDragEnter={(e) => {
																	e.stopPropagation();
																	dragOverTab.current = i;
																}}
																onDragEnd={(e) => {
																	e.stopPropagation();
																	if (dragTab.current !== dragOverTab.current) {
																		const cloneTabs = [...activePhase?.tabs];
																		const deletedSlice = cloneTabs.splice(dragTab.current, 1)[0];
																		cloneTabs.splice(dragOverTab.current, 0, deletedSlice);
																		handlePhaseTabSort(cloneTabs);
																	}
																}}
																onDragOver={(e) => {
																	//   e.stopPropagation();
																	e.preventDefault();
																}}
															>
																<span>
																	{crudPermission?.edit && (
																		<SVGICON src={DragIcon} className="svg-icon-2 svg-icon-hover-primary me-3" />
																	)}
																</span>
																<span
																	className="nav-item d-flex me-4"
																	key={i}
																	onClick={() => {
																		if (phaseTabActiveIndex !== i) {
																			setPhaseTabActiveIndex(i);
																			setSelectedTab(activePhase?.tabs?.[i]);
																			dispatch(actions.setTabDetails(activePhase.tabs[i]));
																		}
																	}}
																>
																	<span
																		className={clsx(
																			"nav-link text-active-primary pb-2 m-0 text-truncate",
																			phaseTabActiveIndex === i ? "active" : ""
																		)}
																		role={"button"}
																		style={{
																			width: "100px",
																		}}
																	>
																		{tab.name}
																	</span>
																	{crudPermission?.edit && (
																		<button
																			className={clsx(
																				"btn btn-icon btn-sm",
																				phaseTabActiveIndex === i ? "btn-icon-danger" : ""
																			)}
																			role={"button"}
																			onClick={() => {
																				showDeleteConfirmModal(
																					intl.formatMessage({
																						id: "PROJECTS_PIPELINE_PHASE_TAB_DELETE_CONFIRM_MESSAGE",
																					}),
																					() => {
																						handleDeletePipelinePhaseTab(tab.id);
																					}
																				);
																			}}
																		>
																			<SVGICON src={DeleteIcon} className="svg-icon-3 mb-2" />
																		</button>
																	)}
																</span>
															</div>
														);
													} else {
														return null;
													}
												})}
											</div>
										)}
									</div>
									<div className="d-flex align-items-start justify-content-start flex-nowrap gap-2 ms-4">
                    {/* Add Tabs Button */}
										{crudPermissionForTabs?.read && (
											<button
												type="button"
												className="btn btn-sm btn-primary"
												onClick={() => {
													setAddTabModal({
														show: true,
														type: "new",
													});
												}}
											>
												<SVGICON src={PlusIcon} className="svg-icon-2" />
												{intl.formatMessage({
													id: "PROJECTS_PIPELINE_TABS_TITLE",
												})}
											</button>
										)}
                    {/* Import Tabs Button */}
                    <div className="d-flex align-items-center">
                      {crudPermission?.edit && (                        
                        <button
                          type="button"
                          className="btn btn-sm btn-primary"
                          onClick={() => {
                            setShowAddModal(true);
                          }}
                          title="Import"
                        >
                          {intl.formatMessage({
                            id: "PIPELINE_IMPORT_BUTTON",
                          })}
                        </button>
                      )}
                      {/* Import Tabs Button Tooltip */}
                      {crudPermission?.edit && (
                        <div
                          style={{
                            position: "relative",
                            cursor: "pointer",
                          }}
                        >
                          <span
                            data-tooltip-id="import-tooltip"
                            data-tooltip-content={intl.formatMessage({
                              id: "PIPELINE_TOOLTIP_INFO",
                            })}
                            data-tooltip-place="top"
                          >
                            <SVG src={InfoIcon} className="svg-icon ms-2 me-0" />
                          </span>

                          <Tooltip
                            id="import-tooltip"
                            place="top"
                            style={{
                              backgroundColor: "#f5f8fa",
                              color: "#a1a5b7",
                              zIndex: 99,
                              whiteSpace: "normal",
                              maxWidth: "250px",
                              wordWrap: "break-word",
                              height: "fit-content",
                            }}
                            border="1px solid #a1a5b7"
                            className="btn text-dark my-2"
                          />
                        </div>
                      )}
                    </div>
									</div>
								</div>
								{/* end tabs */}
							</div>
							{activePhase && activePhase?.tabs?.length > 0 && (
								<PipelineTabsAndFields selectedTab={selectedTab} onChangeTabName={getPipelineDetailsById} />
							)}
						</div>
						{/* right side phase questions Card */}
						<div className="col-lg-4">
							<PipelinePhaseRoles activePhase={activePhase} getPipelineDetailsById={getPipelineDetailsById} />
							<PipelinePhaseApprovers
								activePhase={activePhase}
								pipeline={currPipeline}
								getPipelineDetailsById={getPipelineDetailsById}
							/>
						</div>
					</div>
				</div>
			</DndProvider>

      {/* Import Tabs Modal */}
			<Modal
				show={showAddModal}
				centered
				contentClassName={loading ? "pe-none" : "AddNew"}
				dialogClassName={"medium-size-modal"}
				className="kt-modal"
				backdrop="static"
			>
				<Modal.Header>
					<Modal.Title>{intl.formatMessage({ id: "PIPELINE_IMPORT_TABS" })}</Modal.Title>
					<CloseButton onClose={() => setShowAddModal(false)} />
				</Modal.Header>

				<Modal.Body>
					<div className="tab-field mb-0">
						<label className="d-flex align-items-center tab-field-label">
							<span className="">
								{intl.formatMessage({
									id: "PIPELINE_PHASES",
								})}
							</span>
						</label>
						<DropDown
							id="post-groups-dropdown"
							multiSelect={true}
							items={otherPhases?.map((phase: any) => {
								return { id: phase?.id, name: phase?.name };
							})}
							displayFunc={(item) => item.name}
							selectedItems={selectedPhases}
							displayValue={
								otherPhases.filter(
									//@ts-ignore
									(
										item: any //@ts-ignore
									) => item?.name === selectedPhases?.name
								)[0]?.name
							}
							onSelectItem={(item) => {
								//   @ts-ignore
								handleOnSelectPhase(item);
							}}
							//selectedItems={selectedLabels}
							enableHoverBackground
							hideSelectedItems
							// onMultiSelectBlur={() => {
							//   handleOnBlur("label");
							// }}
						/>
					</div>
				</Modal.Body>

				<Modal.Footer className="justify-content-center">
					<Button
						variant="secondary"
						onClick={() => {
							setShowAddModal(false);
							setSelectedPhases?.([]);
						}}
					>
						{intl.formatMessage({ id: "CLOSE_BUTTON" })}
					</Button>
					<Button variant="primary" type="submit" onClick={handleImportTabs}>
						{!loading && intl.formatMessage({ id: "SUBMIT_BUTTON" })}
						{loading && (
							<span className="indicator-progress" style={{ display: "block" }}>
								{intl.formatMessage({
									id: "MASTERDATA_TAGS_LOADING_MESSAGE",
								})}{" "}
								<span className="spinner-border spinner-border-sm align-middle ms-2"></span>
							</span>
						)}
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
};

export default EditPipeline;
