import React, { FC, useEffect, useState } from "react";
// @ts-ignore
import OutsideClickHandler from "react-outside-click-handler";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import clsx from "clsx";
import _ from "lodash";
import { formatPriceLocale } from "../../utils/PriceFormatLocale";

import {
  SaleContactType,
  SalePartnerType,
  SaleProductType,
} from "../../modules/sales/Offers/types/SaleTypes";
import { UserType } from "../../modules/userManagement/types/getUsersListResponseType";

import { getSalesUsers } from "../../modules/sales/Offers/redux/SalesApi";

interface Props {
  id: string;
  handleItemClick?: (
    type: string,
    contact?: SaleContactType,
    partner?: SalePartnerType
  ) => void;
  selectedItem?: string | null;
  isUsers?: boolean;
  isDeals?: boolean;
  dealsList?: any;
  handleProcessorClick?: (user: UserType) => void;
  disabled?: boolean;
  isProducts?: boolean;
  handleProductSelect?: (product: any) => void;
  handleDealSelect?: (deal: any) => void;
  saleInteralItems?: any;
  productsAndItems?: SaleProductType[] | undefined;
}

const DynamicDropdown: FC<Props> = ({
  id,
  handleItemClick,
  selectedItem,
  isUsers,
  isDeals,
  dealsList,
  handleDealSelect,
  handleProcessorClick,
  disabled,
  isProducts,
  handleProductSelect,
  saleInteralItems,
  productsAndItems,
}) => {
  const intl = useIntl();

  const salesPartnersAndContacts = useSelector(
    ({ salesOffers }) => salesOffers?.offersPartnersAndContacts
  );

  const [show, setShow] = useState<boolean>(false);
  const [value, setValue] = useState("");
  const [items, setItems] = useState<any>([
    ...(salesPartnersAndContacts?.partnerDetails?.filter(
      (item: any) => item?.contacts?.length > 0
    ) || []),
    // ...(salesPartnersAndContacts?.userDetails || []),
  ]);

  const [systemUsers, setSystemUsers] = useState<UserType[]>([]);
  const [displayUsers, setDisplayUsers] = useState<UserType[]>([]);
  const [displayDeals, setDisplayDeals] = useState<any>(dealsList || []);
  const [displayProducts, setDisplayProducts] = useState<SaleProductType[]>([]);

  const getUsers = () => {
    getSalesUsers().then(({ data: { users } }) => {
      setDisplayUsers(users);
      setSystemUsers(users);
    });
  };

  const returnSearch = (item: any) => {
    return (item?.firstname || "")
      .concat(item?.lastname || "")
      .concat(item?.name || "")
      .concat(item?.city || "")
      .concat(item?.address || "")
      .concat(item?.zip_code || "")
      .concat(item?.country || "")
      .concat(item?.address_1 || "")
      .concat(item?.address_2 || "")
      .concat(item?.email || "")
      .concat(item?.username || "");
  };

  const updateDisplayData = () => {
    if (isUsers) {
      const updatedUsers = displayUsers?.filter((user: UserType) => {
        const search = (user.firstname || "")
          .concat(user.lastname || "")
          .concat(user?.email || "")
          .concat(user?.role?.name || "")
          .concat(user?.username || "");

        if (
          (search || "")
            .toLocaleLowerCase()
            .includes((value || "").toLocaleLowerCase())
        ) {
          return true;
        } else {
          return false;
        }
      });
      setSystemUsers(updatedUsers);
    } else if (isProducts) {
      const items = productsAndItems ? _.cloneDeep(productsAndItems) : [];
      const updatedList = items?.filter((product) => {
        if (
          (product?.name || "")
            .toLocaleLowerCase()
            .includes((value || "").toLocaleLowerCase())
        ) {
          return true;
        } else if (!value) {
          return true;
        }
        return false;
      });
      setDisplayProducts(updatedList);
    } else if (isDeals) {
      const updatedDeals = dealsList
        ?.map((pipeline: { deals: any[] }) => ({
          ...pipeline,
          deals: pipeline.deals.filter((deal) => {
            const searchString = returnSearch(deal).toLowerCase();
            return searchString.includes(value.toLowerCase());
          }),
        }))
        .filter(
          (pipeline: { deals: string | any[] }) => pipeline.deals.length > 0
        );

      setDisplayDeals(updatedDeals);
    } else {
      const updatedUsers = [
        ...(salesPartnersAndContacts?.partnerDetails?.filter(
          (item: any) => item?.contacts?.length > 0
        ) || []),
        // ...(salesPartnersAndContacts?.userDetails || []),
      ].filter((user: any) => {
        let contactMatches = false;

        if ("contacts" in user) {
          const matchedContacts = user?.contacts?.filter((item: any) => {
            const search = returnSearch(item);
            if (
              (search || "")
                .toLocaleLowerCase()
                .includes((value || "").toLocaleLowerCase())
            ) {
              contactMatches = true;
              return true;
            } else {
              return false;
            }
          });

          if (contactMatches || !value) {
            user.contacts = matchedContacts;
            return true;
          }
        }

        const search = returnSearch(user);
        if (
          (search || "")
            .toLocaleLowerCase()
            .includes((value || "").toLocaleLowerCase())
        ) {
          return true;
        } else if (!value) {
          return true;
        }

        return false;
      });

      setItems(updatedUsers);
    }
  };

  useEffect(() => {
    /**
     *? Only display Organisations(partners) only if more than one contact is available
     */
    const contactsList = salesPartnersAndContacts?.partnerDetails?.filter(
      (item: any) => item?.contacts?.length > 0
    );
    setItems([...(contactsList || [])]);
    // setItems([
    //   ...(salesPartnersAndContacts?.partnerDetails || []),
    //   ...(salesPartnersAndContacts?.userDetails || []),
    // ]);
  }, [salesPartnersAndContacts]);

  useEffect(() => {
    getUsers();
  }, [isUsers]);

  useEffect(() => {
    setDisplayProducts(productsAndItems || []);
  }, [productsAndItems]);

  useEffect(() => {
    updateDisplayData();
  }, [value]);

  const getFirstContactIndex = () => {
    return items?.findIndex((user: any) => !("contacts" in user));
  };

  const isProductAlreadySelected = (id: number) => {
    if (saleInteralItems) {
      return saleInteralItems?.some((item: any) => item?.data?.[0]?.id === id);
    }
  };

  return (
    <div id={id} className="position-relative " tabIndex={0}>
      <OutsideClickHandler
        onOutsideClick={() => {
          setShow(false);
          setValue("");
        }}
      >
        <div
          tabIndex={0}
          className={`dropdown form-control form-control-solid text-break ${show ? "show" :""} ${
            !disabled && "cursor-pointer"
          }`}
          onClick={() => {
            !disabled && setShow(true);
          }}
        >
          {selectedItem ? (
            selectedItem
          ) : (
            <span className="text-gray-500">
              {intl.formatMessage({
                id: isUsers
                  ? "PARTNERS_EDIT_TAB_CONTACT"
                  : isDeals
                    ? "TASKS_ADD_TASK_DEAL_FIELD_PLACEHOLDER"
                    : isProducts
                      ? "SALES_OFFER_ADD_ITEM_DROPDOWN_PLACEHOLDER"
                      : "SALES_OFFER_DROPDOWN_CONTACT_OR_PARTNER_PLACEHOLDER",
              })}
            </span>
          )}
        </div>

        {show && (
          <div
            className="card d-flex flex-column position-absolute show pt-0 top-100px w-100"
            style={{ zIndex: 9, boxShadow: "0px 0px 4px #e7e1e1" }}
          >
            <div className="dropdownOptions">
              {/* Search */}
              <div className="px-5 pb-1 pt-2 mb-3 position-relative">
                <input
                  type="text"
                  className="form-control py-2"
                  value={value}
                  onChange={(e) => {
                    setValue(e.target.value);
                  }}
                  autoFocus
                  style={{ paddingRight: "30px" }}
                />
                <i
                  className="bi bi-search position-absolute"
                  style={{ top: "18px", right: "30px" }}
                ></i>
              </div>

              {/* Options */}
              <div className="d-flex flex-column mh-200px w-100 overflow-auto">
                {isUsers ? (
                  systemUsers?.length > 0 ? (
                    systemUsers?.map((item: any, i: number) => {
                      return (
                        <div
                          className={clsx(
                            "d-flex flex-column px-5 py-3 cursor-pointer bg-hover-light-primary"
                            //   items?.length - 1 !== i
                            //     ? "border border-top-0 border-left-0 border-right-0 border-1 border-bottom-2"
                            //     : ""
                          )}
                          onClick={() => {
                            handleProcessorClick?.(item);
                            setShow(false);
                            setValue("");
                          }}
                          key={item?.email}
                        >
                          <div className="d-flex flex-row gap-2 align-items-center justify-content-start text-break flex-wrap">
                            <span className="fs-7 text-gray-800 fw-bold">
                              {item?.firstname} {item?.lastname}
                            </span>

                            <span className="fs-8 text-primary">
                              {item.email}
                            </span>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <div className="d-flex px-5 py-2 justify-content-center">
                      <span className="fs-7 text-gray-800">
                        {intl.formatMessage({
                          id: "SALES_OFFER_EDIT_PROCESSOR_NO_USERS_FOUND_MESSAGE",
                        })}
                      </span>
                    </div>
                  )
                ) : isDeals ? (
                  displayDeals?.map((pipeline: any, i: number) => {
                    return (
                      <>
                        {pipeline?.deals?.length > 0 ? (
                          <div
                            className="d-flex flex-column"
                            key={`pipeline-${i}`}
                          >
                            <div className="fs-8 text-primary pt-2 px-3">
                              {pipeline?.pipelineName}
                            </div>
                            {pipeline?.deals?.map(
                              (deal: any, index: number) => {
                                return (
                                  <div
                                    key={`pipeline-deal-${i}${index}`}
                                    className={clsx(
                                      "d-flex flex-column px-5 py-3 cursor-pointer bg-hover-light-primary gap-1"
                                      //   index === pipeline?.deals?.length - 1
                                      //     ? "border border-top-0 border-left-0 border-right-0 border-1 border-bottom-2"
                                      //     : ""
                                    )}
                                    onClick={() => {
                                      handleDealSelect?.(deal?.id);
                                      setShow(false);
                                      setValue("");
                                    }}
                                  >
                                    <div className="d-flex flex-row gap-2 align-items-center justify-content-start text-break">
                                      <span className="fs-7 text-gray-800 fw-bold">
                                        {deal?.name}
                                      </span>
                                    </div>
                                  </div>
                                );
                              }
                            )}
                          </div>
                        ) : (
                          <div>
                            <span>
                              {intl.formatMessage({
                                id: "DEALS_LIST_NOITEMS_FOUND",
                              })}
                            </span>
                          </div>
                        )}
                      </>
                    );
                  })
                ) : isProducts ? (
                  displayProducts?.length > 0 ? (
                    displayProducts?.map((item: any, index: number) => {
                      return (
                        <div
                          key={index}
                          className={clsx(
                            "d-flex flex-column px-5 py-3 gap-1",
                            // item?.length - 1 !== index
                            //   ? "border border-top-0 border-left-0 border-right-0 border-1 border-bottom-2"
                            //   : "",
                            isProductAlreadySelected(item.id)
                              ? "bg-secondary"
                              : "bg-hover-light-primary cursor-pointer"
                          )}
                          onClick={() => {
                            if (!isProductAlreadySelected(item.id)) {
                              handleProductSelect?.({
                                type: "internal",
                                id: item?.id,
                                data_id: item.id,
                              });
                              setShow(false);
                              setValue("");
                            }
                          }}
                        >
                          <div className="d-flex flex-row gap-2 align-items-center justify-content-start">
                            <span
                              className={`fs-7 fw-bold ${
                                isProductAlreadySelected(item.id)
                                  ? "text-gray-600 "
                                  : "text-gray-800 "
                              }`}
                            >
                              {item.name}
                            </span>
                            {item.price && (
                              <span className="fs-8 text-primary">
                                €{" "}
                                {formatPriceLocale(
                                  intl.locale,
                                  (item.price / 100).toFixed(2)
                                )}
                              </span>
                            )}
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <div className="d-flex px-5 py-4 cursor-pointer bg-hover-light-primary justify-content-center">
                      <span className="fs-7 text-gray-800 text-center">
                        <span>
                          {intl.formatMessage({
                            id: "SALES_OFFER_NOT_FOUND_MSG",
                          })}{" "}
                        </span>
                      </span>
                    </div>
                  )
                ) : items?.length > 0 ? (
                  items?.map((item: any, i: number) => {
                    return (
                      <>
                        {"contacts" in item ? (
                          item?.contacts?.length > 0 ? (
                            <div className="d-flex flex-column">
                              <div className="fs-8 text-primary pt-2 px-3">
                                {item.name}
                              </div>
                              {item.contacts?.map(
                                (contact: SaleContactType, index: number) => {
                                  return (
                                    <div
                                      key={index}
                                      className={clsx(
                                        "d-flex flex-column px-5 py-3 cursor-pointer bg-hover-light-primary gap-1"
                                        //   index === item?.contacts?.length - 1
                                        //     ? "border border-top-0 border-left-0 border-right-0 border-1 border-bottom-2"
                                        //     : ""
                                      )}
                                      onClick={() => {
                                        handleItemClick?.(
                                          "partner",
                                          contact,
                                          item
                                        );
                                        setShow(false);
                                        setValue("");
                                      }}
                                    >
                                      <div className="d-flex flex-row flex-wrap gap-2 align-items-center justify-content-start text-break">
                                        <span className="fs-7 text-gray-800 fw-bold">
                                          {contact?.firstname}{" "}
                                          {contact?.lastname}
                                        </span>

                                        <span className="fs-8 text-primary">
                                          {contact.email}
                                        </span>
                                      </div>
                                    </div>
                                  );
                                }
                              )}
                            </div>
                          ) : (
                            <div className="d-flex px-5 py-2 justify-content-center">
                              <span className="fs-7 text-gray-800">
                                {intl.formatMessage({
                                  id: "SALES_OFFER_EDIT_TO_CONTACT_NO_CONTACT_FOUND_MESSAGE",
                                })}
                              </span>
                            </div>
                          )
                        ) : (
                          <div className="d-flex flex-column">
                            {getFirstContactIndex() === i && (
                              <span className="text-primary fs-8 px-3 py-1">
                                {intl.formatMessage({
                                  id: "SALES_OFFER_DROPDOWN_DROPDOWN_CONTACTS_LABEL",
                                })}
                              </span>
                            )}
                            <div
                              className="d-flex flex-column px-5 py-3 cursor-pointer bg-hover-light-primary"
                              onClick={() => {
                                handleItemClick?.("contact", item);
                                setShow(false);
                                setValue("");
                              }}
                            >
                              <div className="d-flex flex-row gap-2 align-items-center justify-content-start">
                                <span className="fs-7 text-gray-800 fw-bold text-break">
                                  {item?.firstname} {item?.lastname}
                                </span>

                                <span className="fs-8 text-primary">
                                  {item.email}
                                </span>
                              </div>
                            </div>
                          </div>
                        )}
                      </>
                    );
                  })
                ) : (
                  <div className="d-flex px-5 py-2 justify-content-center">
                    <span className="fs-7 text-gray-800">
                      {intl.formatMessage({
                        id: "SALES_OFFER_EDIT_TO_CONTACT_NO_CONTACT_FOUND_MESSAGE",
                      })}
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </OutsideClickHandler>
    </div>
  );
};

export default DynamicDropdown;
