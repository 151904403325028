import React, { FC, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import OutsideClickHandler from "react-outside-click-handler";
import { useIntl } from "react-intl";

import {
  errorToast,
  successToast,
} from "../../../sharedComponents/toasts/Toasts";

import { SVGICON } from "../../../../_metronic/helpers";
import SearchIcon from "../../../../_metronic/assets/icons/search.svg";
import CloseButton from "../../../sharedComponents/Buttons/CloseButton";

import { RootState } from "../../../../setup";

import { TagModalProps, TagsModal } from "../../masterdata/components/Tags";
import { getTagsList } from "../../masterdata/redux/TagsAPI";
import { GroupType } from "../../masterdata/types/GetGroupsListResponseType";
import { actions as TagActions } from "../../masterdata/redux/MasterdataRedux";
import { updateTag } from "../redux/DealsAPI";


interface Props {
  dealInformation: any;
  getDealDetailsById: (id: number) => void;
}

const SelectTagModal: FC<Props> = ({ dealInformation, getDealDetailsById }) => {
  const tagsList = useSelector<RootState>(
    ({ masterdata }) => masterdata.tagsList
  ) as GroupType[];

  const intl = useIntl();
  const dispatch = useDispatch();

  // refs
  const outsideHandlerRef = useRef(null);

  const [searchText, setSearchText] = useState("");
  const [openTagModal, setOpenTagModal] = useState(false);
  const [openAddTagsModal, setOpenAddTagsModal] = useState<TagModalProps>({
    show: false,
    type: "new",
  });

  const getTagsListAPI = () => {
    getTagsList().then(({ data: { data } }) => {
      dispatch(TagActions.setTagsList(data));
    });
  };

  const handleUpdateTag = (id: number) => {
    updateTag(dealInformation?.id, id)
      .then(() => {
        successToast(
          intl.formatMessage({ id: "DEAL_TAG_ADD_SUCCESS_MESSAGE" })
        );
        setSearchText("");
      })
      .catch(() => {
        errorToast(intl.formatMessage({ id: "DEAL_TAG_ADD_FAILURE_MESSAGE" }));
      })
      .finally(() => {
        setOpenTagModal(false);
        getDealDetailsById(dealInformation?.id);
      });
  };

  const onSearchText = (value: string) => {
    setSearchText((value.toLocaleLowerCase() || "").trim());
  };

  return (
    <OutsideClickHandler
      ref={outsideHandlerRef}
      onOutsideClick={() => {
        if(!openAddTagsModal.show){
          setOpenTagModal(false);
        } 
        setSearchText("");
      }}
    >
      {openAddTagsModal.show && (
        <TagsModal
          ModalProps={openAddTagsModal}
          closeModal={() => {
            setOpenAddTagsModal({
              show: false,
            });
          }}
          getTagsListAPI={getTagsListAPI}
        />
      )}
      <div className="position-relative">
        <div>
          {dealInformation?.tag_details !== null ? (
            <div className="d-flex fs-8" role="button">
              <div
                style={{
                  backgroundColor: dealInformation?.tag_details?.colour,
                  width: "fit-content",
                  borderRadius: "8px",
                  maxWidth: "200px",
                }}
                className="p-1 px-4 border text-white ms-4 text-break textEllipsis"
                onClick={() => {
                  setOpenTagModal(!openTagModal);
                  setSearchText("");
                }}
              >
                {dealInformation?.tag_details?.name}
              </div>
            </div>
          ) : (
            <div
              role="button"
              className="badge badge-primary"
              onClick={() => {
                setOpenTagModal(!openTagModal);
              }}
              title="Add Tag"
            >
              {intl.formatMessage({
                id: "DEALS_ADD_NEW_LABEL_TITLE",
              })}
            </div>
          )}

          {openTagModal && (
            <div
              className="card select-tags-modal position-absolute bg-white d-flex flex-column fs-8 border "
              style={{
                left: "14px",
                boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                borderRadius: "10px",
                zIndex: "999",
                maxHeight: "500px",
                width: "300px",
              }}
            >
              <div className="card-header d-flex align-items-center">
                <h2 className="mb-0">
                  {intl.formatMessage({
                    id: "DEALS_ADD_NEW_LABEL_MODAL_TITLE",
                  })}
                </h2>
                <CloseButton
                  onClose={() => {
                    setOpenTagModal(false);
                    setSearchText("");
                  }}
                />
              </div>
              <div
                className="card-body"
                style={{ overflowY: "auto", position: "relative" }}
              >
                <div className="d-flex align-items-center position-relative tab-field">
                  <span className="position-absolute" style={{ left: "10px" }}>
                    <SVGICON className="svg-icon svg-icon-1" src={SearchIcon} />
                  </span>
                  <input
                    type="text"
                    className="form-control form-control-md form-control-solid min-w-150px min-w-md-200px ps-11"
                    placeholder={intl.formatMessage({
                      id: "TAGS_SEARCH_PLACEHOLDER",
                    })}
                    onChange={(e) => {
                      onSearchText(e.target.value);
                    }}
                  />
                </div>
                <div className="d-flex flex-row flex-wrap gap-2">
                  {tagsList &&
                    tagsList
                      .filter((tag) =>
                        tag.name.toLowerCase().includes(searchText)
                      )
                      .map((tag, index) => {
                        return (
                          <div
                            key={`deal-tags-${index}`}
                            className="d-flex border px-3 py-1 fs-8 text-white text-break"
                            role="button"
                            style={{
                              background: tag.colour,
                              borderRadius: "8px",
                              width: "fit-content",
                            }}
                            onClick={() => {
                              handleUpdateTag(tag?.id);
                            }}
                          >
                            {tag.name}
                          </div>
                        );
                      })}
                </div>
              </div>
              <div className="card-footer d-flex justify-content-center">
                <div
                  className="btn btn-primary btn-sm"
                  role="button"
                  style={{
                    width: "fit-content",
                  }}
                  onClick={() => {
                    setOpenAddTagsModal({
                      show: true,
                      type: "new",
                    });
                    setSearchText("");
                  }}
                >
                  {intl.formatMessage({
                    id: "DEALS_EDIT_ADD_TAG_TITLE",
                  })}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </OutsideClickHandler>
  );
};

export default SelectTagModal;
