import React, { FC, useEffect, useState } from "react";
import { DealType } from "../types/DealResponseType";
import Tabs from "../../../sharedComponents/tabs/Tabs";
import { useIntl } from "react-intl";
import DealLogs from "./DealLogs";
import Notes from "../../notes/Notes";
import Tasks from "../../tasks/components/TasksList";

interface Props {
  deal: DealType;
  defaultTab?: number;
}

const ActivityComponent: FC<Props> = ({ deal, defaultTab = 0 }) => {
  // selector
  const intl = useIntl();

  // state
  const [tabActiveIndex, setTabActiveIndex] = useState(defaultTab);

  const onTabChange = (index: number) => {
    if (index !== tabActiveIndex) {
      setTabActiveIndex(index);
    }
  };

  return (
    <div className="card card-px w-100">
      <div
        className="card card-pt deal-activity-column"
        style={{
          borderTop: "none",
          borderLeft: "none",
          borderRight: "none",
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
        }}
      >
        <Tabs
          tabs={[
            // intl.formatMessage({ id: "DEAL_ACTIVITY_TAB1" }),
            intl.formatMessage({ id: "DEAL_ACTIVITY_TAB3" }),
            intl.formatMessage({ id: "DEAL_ACTIVITY_TAB2" }),
            intl.formatMessage({ id: "DEAL_DETAILS_TAB2" }),
          ]}
          activeIndex={tabActiveIndex}
          onActiveIndexChange={(index) => {
            onTabChange(index);
          }}
        />
      </div>
      <div className="card-mt">
        {/* {tabActiveIndex === 0 && (
          <div className="d-flex justify-content-center align-items-center mb-8">
            <div className="text-muted">No data available.</div>
          </div>
        )} */}
        {tabActiveIndex === 0 && <Tasks deals_id={deal?.id} />}
        {tabActiveIndex === 1 && (
          <Notes
            handleSeeMore={() => {
              setTabActiveIndex(1);
            }}
            deals_id={deal?.id}
          />
        )}
        {tabActiveIndex === 2 && <DealLogs deal={deal} />}
      </div>
    </div>
  );
};

export default ActivityComponent;
