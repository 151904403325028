import React, { FC, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import _ from "lodash";

// styles
import "../Filemanager.scss";
import { uploadfiles } from "../redux";

// custom
import { FolderType } from "../types/getFilemanagerDataResponseType";
import { useIntl } from "react-intl";
import { getFileDuration } from "../../../utils/getFileDuration";
// import Constants from '../../../config/Constants'
import { useSelector } from "react-redux";
import { RootState } from "../../../../setup";
import { UserType } from "../../auth/types/LoginResponseTypes";
import {
  errorToast,
  successToast,
} from "../../../sharedComponents/toasts/Toasts";
import CloseButton from "../../../sharedComponents/Buttons/CloseButton";

interface UploadFilesModalProps {
  show: boolean;
  folder?: FolderType;
}

interface Props {
  modalProps: UploadFilesModalProps;
  closeModal: () => void;
  getFilemanagerDataAPI: (folder_id?: number | null) => void;
}

const UploadFilesModal: FC<Props> = ({
  modalProps,
  closeModal,
  getFilemanagerDataAPI,
}) => {
  const user = useSelector<RootState>(({ auth }) => auth.user) as UserType;
  const { show, folder } = modalProps;
  const intl = useIntl();
  // state
  const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [uploadFilesprogress, setUplaodFilesProgess] = useState(0);

  const isAudioFile = (file: File) => {
    return !!file.type.match("audio.*");
  };
  const isVideoFile = (file: File) => {
    return !!file.type.match("video.*");
  };

  const onUploadProgressCb = (progressEvent: ProgressEvent) => {
    try {
      const completedPercent =
        (progressEvent.loaded / progressEvent.total) * 100;
      setUplaodFilesProgess(completedPercent);
    } catch (e) {}
  };

  const onUploadFilesClick = async () => {
    setLoading(true);
    if (uploadedFiles.length > 0) {
      const formData = new FormData();
      let durations: any = [];
      try {
        durations = await Promise.all(
          uploadedFiles.map(async (uploadedFile) => {
            if (isAudioFile(uploadedFile) || isVideoFile(uploadedFile)) {
              const duration = await getFileDuration(uploadedFile);
              return duration;
            } else {
              // console.log('uploadedFile is not audio or video', true)
              return 0;
            }
          })
        );
      } catch (e) {
        setLoading(false);
        // console.log('error determining duration', e)
      }

      uploadedFiles.forEach((uploadedFile, i) => {
        formData.append("medias[]", uploadedFile);
        formData.append("medias_duration[]", durations[i] || 0);
      });
      formData.append("notified_by", user.id?.toString() || "");

      const id = folder ? folder.id : null;

      uploadfiles(id, formData, onUploadProgressCb)
        .then(() => {
          setLoading(false);
          successToast(
            intl.formatMessage({ id: "FILEMANAGER_UPLOAD_FILE_MESSAGE" })
          );
        })
        .catch((e) => {
          setLoading(false);
          if(e.response.status === 422){
            errorToast(
              intl.formatMessage({
                id: "FILEMANAGER_UPLOAD_CONFLICT_FILE_FAILURE_MESSAGE",
              })
            );
          }else{
            errorToast(
              intl.formatMessage({
                id: "FILEMANAGER_UPLOAD_FILE_FAILURE_MESSAGE",
              })
            );
          }
        })
        .finally(() => {
          closeModal();
          getFilemanagerDataAPI(folder?.id);
        });
    }
  };

  const onFileUploadChange = (files: FileList | null) => {
    const filesArr = _.clone(uploadedFiles);
    files &&
      Object.keys(files).forEach((key) => {
        // @ts-ignore
        filesArr.push(files[key]);
      });
    setUploadedFiles(filesArr);
  };

  const removeFile = (index: number) => {
    const filesArr = _.clone(uploadedFiles);
    filesArr.splice(index, 1);
    setUploadedFiles(filesArr);
  };

  return (
    <Modal
      show={show}
      centered
      contentClassName={loading ? "pe-none" : ""}
      className="kt-modal"
      backdrop="static"
      onHide={closeModal}
    >
      <Modal.Header>
        <Modal.Title>
          {intl.formatMessage({ id: "FILEMANAGER_UPLOAD_FILE_TITLE" })}
        </Modal.Title>
        <CloseButton onClose={closeModal} />
      </Modal.Header>

      <div className="overflow-auto">
        <Modal.Body className="overflow-inherit">
          <div>
            <div className="d-flex flex-row align-items-center">
              <input
                type="file"
                id="actual-btn"
                hidden
                multiple
                onChange={(e) => {
                  onFileUploadChange(e.target.files);
                }}
                accept="image/jpeg, image/jpg, image/png, application/pdf, audio/* ,video/mp4,video/x-m4v,video/*"
              />
              <label
                htmlFor="actual-btn"
                className="btn btn-sm btn-primary me-2"
              >
                {intl.formatMessage({
                  id: "FILEMANAGER_UPLOAD_FILE_ATTACH_FILES",
                })}
              </label>

              {uploadedFiles.length > 0 && (
                <span
                  className="btn btn-sm btn-light-primary"
                  onClick={() => {
                    setUploadedFiles([]);
                  }}
                >
                  {intl.formatMessage({
                    id: "FILEMANAGER_UPLOAD_FILE_REMOVE_ALL",
                  })}
                </span>
              )}
            </div>

            {/* file uplaod progress */}
            {uploadedFiles.length > 0 && (
              <div className="h-5px mt-2">
                {/* {loading && ( */}
                <div
                  className="w-100 bg-secondary h-5px"
                  style={{ borderRadius: "5px", overflow: "hidden" }}
                >
                  <div
                    className="bg-primary h-5px"
                    style={{ width: `${uploadFilesprogress}%` }}
                  ></div>
                </div>
                {/* )} */}
              </div>
            )}

            <div>
              {uploadedFiles.map((file, i) => {
                return (
                  <div
                    className="p-5 d-flex flex-row align-items-center justify-content-between text-dark file-item-container"
                    key={i}
                  >
                    <span className="fs-7 text-dark fw-bold me-8">
                      {file.name}
                    </span>
                    <i
                      role="button"
                      className="bi bi-x fs-1"
                      onClick={() => {
                        removeFile(i);
                      }}
                    ></i>
                  </div>
                );
              })}
            </div>
          </div>
        </Modal.Body>
      </div>

      <Modal.Footer className="justify-content-center">
        <Button variant="secondary" onClick={closeModal}>
          {intl.formatMessage({
            id: "FILEMANAGER_UPLOAD_FILE_MODAL_CLOSE_BUTTON",
          })}
        </Button>
        <Button
          variant="primary"
          onClick={() => {
            // handleSubmit()
            onUploadFilesClick();
          }}
          disabled={uploadedFiles?.length === 0}
        >
          {!loading &&
            intl.formatMessage({
              id: "FILEMANAGER_UPLOAD_FILE_MODAL_UPLOAD_BUTTON",
            })}
          {loading && (
            <span className="indicator-progress" style={{ display: "block" }}>
              {intl.formatMessage({
                id: "FILEMANAGER_UPLOAD_FILE_MODAL_LOADING_MESSAGE",
              })}{" "}
              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default UploadFilesModal;
