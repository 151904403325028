import React, { FC } from "react";
import { useHistory } from "react-router-dom";
import { useIntl } from "react-intl";

import {
  errorToast,
  successToast,
} from "../../../../sharedComponents/toasts/Toasts";
import { useGlobalModalContext } from "../../../../sharedComponents/modals/ModalContext";
import ActionsDropdown from "../../../../sharedComponents/ActionsDropdown/ActionsDropdown";

import {
  deleteOffer,
  forceDeleteOffer,
  offerAcceptOffer,
  offerFinaliseOffer,
  offerRejectOffer,
} from "../redux/OffersApi";
import { OfferType } from "../types/OfferTypes";
import { SaleDetails } from "../types/SaleTypes";

interface Props {
  offer: OfferType;
  getOffersListAPI: (
    status: string,
    offerSearchText: string,
    itemsPerPage: number,
    activePage: number
  ) => void;
  tabActiveIndex: number;
  status: string;
  itemsPerPage: number;
  activePage: number;
  offerSearchText: string;
  getOffersTrashListAPI: () => void;
  handleRestoreOffer: (offer: OfferType) => void;
  paginatedItems: OfferType[];
}
const SalesOfferListDropdown: FC<Props> = ({
  offer,
  getOffersListAPI,
  tabActiveIndex,
  status,
  itemsPerPage,
  activePage,
  offerSearchText,
  getOffersTrashListAPI,
  handleRestoreOffer,
  paginatedItems,
}) => {
  const history = useHistory();
  const {
    showDeleteConfirmModal,
    hideDeleteConfirmModal,
    setDeleteModalLoading,
  } = useGlobalModalContext();

  const intl = useIntl();

  const onEditClick = () => {
    history.push(`/sales/offers/edit`, { offer });
  };

  function isNotValidToConfirm(sale_details: SaleDetails) {
    return (
      !sale_details?.to_firstname ||
      !sale_details?.to_lastname ||
      !sale_details?.to_email ||
      (sale_details?.sale_items?.length === 0 &&
        sale_details?.sale_items?.length === 0) ||
      !sale_details?.sale_items?.every(
        (item: any) =>
          Number(item.unit_price) &&
          Number(item.unit_price) >= 0 &&
          Number(item.tax_percentage) &&
          Number(item.tax_percentage) >= 0
      )
    );
  }

  const deleteExistingOffer = (id: number) => {
    setDeleteModalLoading(true);
    deleteOffer(id)
      .then(() => {
        successToast(
          intl.formatMessage({
            id: "OFFER_SINGLE_OFFER_DELETE_SUCCESS_MESSAGE",
          })
        );
      })
      .catch(() => {
        errorToast(
          intl.formatMessage({
            id: "OFFER_SINGLE_OFFER_DELETE_FAILURE_MESSAGE",
          })
        );
      })
      .finally(() => {
        setDeleteModalLoading(false);
        hideDeleteConfirmModal();
        getOffersListAPI?.(
          status,
          offerSearchText,
          itemsPerPage,
          paginatedItems?.length === 1 && activePage > 1
            ? activePage - 1
            : activePage
        );
      });
  };

  const forceDeleteExistingOffer = (id: number) => {
    setDeleteModalLoading(true);
    forceDeleteOffer(id)
      .then(() => {
        successToast(
          intl.formatMessage({
            id: "OFFER_SINGLE_OFFER_DELETE_SUCCESS_MESSAGE",
          })
        );
      })
      .catch(() => {
        errorToast(
          intl.formatMessage({
            id: "OFFER_SINGLE_OFFER_DELETE_FAILURE_MESSAGE",
          })
        );
      })
      .finally(() => {
        setDeleteModalLoading(false);
        hideDeleteConfirmModal();
        getOffersTrashListAPI?.();
      });
  };

  const handleFinaliseOffer = async () => {
    if (isNotValidToConfirm(offer?.sales_details)) {
      errorToast(
        intl.formatMessage({ id: "OFFER_FINALISE_OFFER_INFO_MESSAGE" })
      );
    } else {
      offerFinaliseOffer(offer?.id)
        .then(() => {
          successToast(
            intl.formatMessage({
              id: "OFFER_FINALISE_OFFER_SUCCESS_MESSAGE",
            })
          );
        })
        .catch(() => {
          errorToast(
            intl.formatMessage({
              id: "OFFER_FINALISE_OFFER_FAILURE_MESSAGE",
            })
          );
        })
        .finally(() => {
          getOffersListAPI?.(
            status,
            offerSearchText,
            itemsPerPage,
            paginatedItems?.length === 1 && activePage - 1 > 1
              ? activePage - 1
              : activePage
          );
        });
    }
  };

  const handleAcceptOffer = async () => {
    offerAcceptOffer(offer?.id)
      .then(() => {
        successToast(
          intl.formatMessage({
            id: "OFFER_ACCEPT_OFFER_SUCCESS_MESSAGE",
          })
        );
      })
      .catch(() => {
        errorToast(
          intl.formatMessage({
            id: "OFFER_ACCEPT_OFFER_FAILURE_MESSAGE",
          })
        );
      })
      .finally(() => {
        getOffersListAPI?.(
          status,
          offerSearchText,
          itemsPerPage,
          paginatedItems?.length === 1 && activePage - 1 > 1
            ? activePage - 1
            : activePage
        );
      });
  };
  const handleRejectOffer = async () => {
    offerRejectOffer(offer?.id)
      .then(() => {
        successToast(
          intl.formatMessage({
            id: "OFFER_REJECT_OFFER_SUCCESS_MESSAGE",
          })
        );
      })
      .catch(() => {
        errorToast(
          intl.formatMessage({
            id: "OFFER_REJECT_OFFER_FAILURE_MESSAGE",
          })
        );
      })
      .finally(() => {
        getOffersListAPI?.(
          status,
          offerSearchText,
          itemsPerPage,
          paginatedItems?.length === 1 && activePage - 1 > 1
            ? activePage - 1
            : activePage
        );
      });
  };

  const dropdownOptions = [
    ...(tabActiveIndex === 0
      ? offer?.status === "accept"
        ? offer?.final_invoice_number
          ? [
              {
                name: intl.formatMessage({
                  id: "OFFER_GO_TO_INVOICE_BUTTON_TITLE",
                }),
                onClick: () => {
                  history.push(`/sales/invoices/edit`, {
                    offer,
                  });
                },
              },
            ]
          : []
        : [
            {
              name: intl.formatMessage({ id: "ORDER_ACCEPT_BUTTON_TITLE" }),
              onClick: handleAcceptOffer,
            },
            {
              name: intl.formatMessage({ id: "ORDER_REJECT_BUTTON_TITLE" }),
              onClick: handleRejectOffer,
            },
          ]
      : []),
    ...(tabActiveIndex === 1
      ? [
          {
            name: intl.formatMessage({ id: "EDIT_BUTTON" }),
            onClick: onEditClick,
          },
          {
            name: intl.formatMessage({ id: "ORDER_FINALISE_BUTTON_TITLE" }),
            onClick: handleFinaliseOffer,
          },
          {
            name: intl.formatMessage({ id: "DELETE_BUTTON" }),
            onClick: () => {
              showDeleteConfirmModal(
                intl.formatMessage({
                  id: "OFFER_DELETE_CONFIRMATION_MESSAGE",
                }),
                () => {
                  deleteExistingOffer(offer?.id);
                }
              );
            },
            className: "text-danger",
          },
        ]
      : []),
    ...(tabActiveIndex === 2
      ? [
          {
            name: intl.formatMessage({ id: "ORDER_ACCEPT_BUTTON_TITLE" }),
            onClick: handleAcceptOffer,
          },
          {
            name: intl.formatMessage({ id: "ORDER_REJECT_BUTTON_TITLE" }),
            onClick: handleRejectOffer,
          },
        ]
      : []),
    ...(tabActiveIndex === 3
      ? offer.final_invoice_number
        ? [
            {
              name: intl.formatMessage({
                id: "OFFER_GO_TO_INVOICE_BUTTON_TITLE",
              }),
              onClick: () => {
                history.push(`/sales/invoices/edit`, {
                  offer,
                });
              },
            },
          ]
        : []
      : []),
    ...(tabActiveIndex !== 0 &&
    tabActiveIndex !== 1 &&
    tabActiveIndex !== 2 &&
    tabActiveIndex !== 3
      ? [
          {
            name: intl.formatMessage({ id: "COMMON_RESTORE" }),
            onClick: () => {
              handleRestoreOffer(offer);
            },
          },
          {
            name: intl.formatMessage({
              id: "COMMON_DELETE_PERMANENT",
            }),
            onClick: () => {
              showDeleteConfirmModal(
                intl.formatMessage({
                  id: "OFFER_DELETE_CONFIRMATION_MESSAGE",
                }),
                () => {
                  forceDeleteExistingOffer(offer?.id);
                }
              );
            },
            className: "text-danger",
          },
        ]
      : []),
  ];

  if (
    dropdownOptions?.length === 0 ||
    offer?.status === "reject" ||
    (offer?.status === "accept" && !offer?.final_invoice_number)
  ) {
    return null;
  }

  return (
    <div className="dropdown d-flex flex-row justify-content-end">
      <ActionsDropdown options={dropdownOptions} />
    </div>
  );
};

export default SalesOfferListDropdown;
