import { useEffect, useState } from "react";
import { useIntl } from "react-intl";

// custom
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { KTSVG, SVGICON } from "../../../../_metronic/helpers";
import Tabs from "../../../sharedComponents/tabs/Tabs";
import { CustomerType, UserType } from "../../auth/types/LoginResponseTypes";
import { actions, getCustomerDetails } from "../redux";
import CustomerNotice from "./CustomerNotice";
import Notes from "../../notes/Notes";
import CustomerActivity from "./CustomerActivity";
import CustomerProfile from "./CustomerProfileTab";
import { CustomerLabelsList } from "./CustomerLabelsSelect";
// import ProgressCard from "./ProgressCard";
// import CustomerEmailChange from "./CustomerEmailChange";
// import BlockCustomer from "./BlockCustomer";
// import CustomerComments from "./CustomerComments";
// import CustomerDevices from "./CustomerDevices";
// import CustomerEmailChange from "./CustomerEmailChange";
// import CustomerHas2FA from "./CustomerHas2FA";
// import CustomerLogs from "./CustomerLogs";
// import CustomerPasswordChange from "./CustomerPasswordChange";

const EditUserDetails = () => {
	const dispatch = useDispatch();
	const { state = {} } = useLocation();
	const history = useHistory();
	const intl = useIntl();
	// @ts-ignore
	const personalInformation: UserType = state.userDetails || {};

	const [tabActiveIndex, setTabActiveIndex] = useState<number>(0);

	const onTabChange = (index: number) => {
		if (index !== tabActiveIndex) {
			setTabActiveIndex(index);
		}
	};

	useEffect(() => {
		getCustomerDetailsAPI();
	}, [personalInformation.id]);

	// state
	const [userDetails, setUserDetails] = useState<UserType>(personalInformation);

	const [customerDetails, setCustomerDetails] = useState<CustomerType>(
		// @ts-ignore
		state.customerDetails || {}
	);
	const [displayComments, setDisplayComments] = useState<string | null>("");

	const getCustomerDetailsAPI = (callback?: (customer: CustomerType) => void) => {
		if (personalInformation?.id) {
			getCustomerDetails(personalInformation?.id).then(({ data }) => {
				const { personalInformation, comments } = data || {};
				// @ts-ignore
				setUserDetails(personalInformation);
				history.replace({ state: { userDetails: personalInformation } });
				// @ts-ignore
				setCustomerDetails(data);
				setDisplayComments(comments);
				// @ts-ignore
				callback?.(data);
				dispatch(actions.setCustomerDetails(data));
			});
		}
	};

	useEffect(() => {
		getCustomerDetailsAPI();
	}, []);

	return (
		<>
			<div className="card card-mt card-p">
				{/* contact/partner information */}
        <div className="d-flex flex-wrap gap-4">
					<h1 className="mb-0" style={{width: "fit-content"}}>
						{customerDetails?.personalInformation?.firstname} {customerDetails?.personalInformation?.lastname}
					</h1>
            <CustomerLabelsList
              customer={customerDetails?.personalInformation}
              customerLabels={personalInformation.labels}
              getCustomerDetailsAPI={getCustomerDetailsAPI}
            />
				</div>

				{/* contact and partner information */}
				<div className="d-flex mt-4">
					<div className="d-flex flex-row d-grid gap-2">
						{customerDetails?.personalInformation?.telephone && (
							<div className="d-flex align-items-start me-5 mb-2">
								<KTSVG path="/media/icons/duotune/communication/com014.svg" className="svg-icon-1 me-1 mt-1" />
								<div className="text-gray-500 fw-semibold fs-7 text-start ps-0 fs-8">
									{customerDetails?.personalInformation?.telephone}
								</div>
							</div>
						)}

						{customerDetails?.personalAddress?.address_1 && (
							<>
								<div className="me-5 mb-2 d-flex align-items-start fs-8">
									<div className="symbol symbol-30px">
										<KTSVG path="media/icons/duotune/general/gen018.svg" className="svg-icon-1 me-2 " />
									</div>
									<div className="fw-semibold me-5">
										{customerDetails?.personalAddress && (
											<>
												<div className="text-gray-500 fw-semibold fs-7 text-start mw-250px ps-0 fs-8">
													{[customerDetails.personalAddress.address_1, customerDetails.personalAddress.address_2]
														.filter(Boolean) // Filters out any falsy values (like empty strings)
														.join(", ")}{" "}
													{/* Joins the non-empty parts with a comma */}
												</div>
												<div className="text-gray-500 fw-semibold fs-7 text-start ps-0 fs-8">
													{[customerDetails.personalAddress.city, customerDetails.personalAddress.zip_code]
														.filter(Boolean)
														.join(", ")}
												</div>
											</>
										)}
									</div>
								</div>
							</>
						)}
						<div className="d-flex align-items-start me-5 mb-2 ">
							<KTSVG path="/media/icons/duotune/communication/mail.svg" className="svg-icon-1 me-2 " />
							<a
								className="d-inline-block text-truncate text-gray-500 fw-semibold fs-7 text-start ps-0 fs-8"
								style={{ maxWidth: "300px" }}
							>
								{personalInformation.email}
							</a>
						</div>
					</div>
				</div>
			</div>

			<div className="row mx-0 card-mb">
				<div className="col-md-4 kt-card">
					{/* <BlockCustomer customer={userDetails} getCustomerDetailsAPI={getCustomerDetailsAPI} /> */}

					<CustomerNotice
						// @ts-ignore
						comment={displayComments}
						userId={userDetails.id}
						getCustomerDetailsAPI={getCustomerDetailsAPI}
					/>
					{/* commenting for now */}
					{/* <ProgressCard className="" chartColor="primary" chartHeight="270" /> */}

					{/* <CustomerEmailChange
            // @ts-ignore
            userDetails={userDetails}
            // @ts-ignore
            getCustomerDetailsAPI={getCustomerDetailsAPI}
          /> */}
				</div>
				<div className="col-md-8 kt-card customer-column">
					<div className="card card-mt">
						<div className="card-header card-px card-pt pb-0 d-flex" style={{ minHeight: "auto" }}>
							<Tabs
								tabs={[
									intl.formatMessage({ id: "CONTACT_DETAILS_EDIT_TAB" }),
									intl.formatMessage({ id: "CONTACT_DETAILS_NOTES_TAB" }),
									// intl.formatMessage({ id: "CONTACT_DETAILS_ACTIVITY_TAB" }),
								]}
								activeIndex={tabActiveIndex}
								onActiveIndexChange={(index) => {
									onTabChange(index);
								}}
							/>
						</div>
						<div className="card-body card-p">
							{tabActiveIndex === 0 && (
								<CustomerProfile
									userDetails={userDetails}
									customerDetails={customerDetails}
									getCustomerDetailsAPI={getCustomerDetailsAPI}
								/>
							)}
							{tabActiveIndex === 1 && <Notes users_id={personalInformation?.id} />}
							{/* {tabActiveIndex === 2 && <CustomerActivity />} */}
						</div>
					</div>

					{/* <SignInMethod userDetails={userDetails} getCustomerDetailsAPI={getCustomerDetailsAPI} /> */}
					{/* <CustomerComments
              // @ts-ignore
              customerDetails={customerDetails}
              userId={personalInformation?.id}
              getCustomerDetailsAPI={getCustomerDetailsAPI}
              displayComments={displayComments}
            /> */}
					{/* <CustomerDevices
              customerDetails={customerDetails}
              getCustomerDetailsAPI={getCustomerDetailsAPI}
            /> */}
				</div>
			</div>
		</>
	);
};

export default EditUserDetails;
