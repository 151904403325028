import React, { FC, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";
import clsx from "clsx";

// Components
import { useGlobalModalContext } from "../../../sharedComponents/modals/ModalContext";
import Checkbox from "../../../sharedComponents/Checkbox/Checkbox";
import { Button, Modal } from "react-bootstrap";
import { SVGICON } from "../../../../_metronic/helpers";
import { errorToast, successToast } from "../../../sharedComponents/toasts/Toasts";

// Assets
import DeleteIcon from "../../../../_metronic/assets/icons/delete.svg";
import BlankAvatar from "../../../../_metronic/assets/icons/blankAvatar.svg";

// Types
import { RootState } from "../../../../setup";

import SelectedUsersModal from "../../teams/components/SelectUsersModal";
import { AddUserSymbol, MoreSymbol, UserSymbol } from "../../tasks/components/UserSymbol";
import SelectResponsibles from "./SelectResponsibles";
import SelectTeamsModal from "./SelectTeamsModal";
import { addresponsible_Persons, deleteresponsible_Persons } from "../redux/DealsAPI";
import { UserType } from "../../userManagement/types/getUsersListResponseType";
import { Assignee } from "../../tasks/types/TasksResponseType";
import CloseButton from "../../../sharedComponents/Buttons/CloseButton";

interface DealResponsiblePersonsProps {
	dealInformation: any;
	getDealDetailsById: (id: number) => void;
}

interface Props {
	show: boolean;
	closeModal: () => void;
	selectedUsers: Assignee[] | undefined;
	isSelectionModal?: boolean;
	handleRemove?: (ids: number[]) => void;
}

const DealResponsibleUsersModal: FC<Props> = ({ show, selectedUsers, closeModal, isSelectionModal, handleRemove }) => {
	const intl = useIntl();
	const history = useHistory();

	const { showDeleteConfirmModal, isOpen } = useGlobalModalContext();

	// selectors
	const usersList = useSelector<RootState>(
		// @ts-ignore
		({ userManagement }) => userManagement.users
	) as UserType[];

	// state
	const [checkedUser, setCheckedUser] = useState<Assignee[]>([]);
	const [responsiblePersons, setResponsiblePersons] = useState<Assignee[]>(selectedUsers || []);
	const [checkAllItems, setCheckAllItems] = useState<boolean>(false);

	// effects
	useEffect(() => {
		if (selectedUsers) {
			setResponsiblePersons(selectedUsers);
		}
	}, [selectedUsers]);

	// handlers
	const toggleCheckedUser = (user: Assignee) => {
		setCheckedUser((prevCheckedUser) => {
			// Check if the user is already in the checkedUser list
			if (prevCheckedUser.some((checkedCustomer) => checkedCustomer.user_id === user.user_id)) {
				// Remove the user if already checked
				return prevCheckedUser.filter((checkedCustomer) => checkedCustomer.user_id !== user.user_id);
			} else {
				// Add the user if not checked
				return [...prevCheckedUser, user];
			}
		});
	};

	const onAllItemsCheckChange = () => {
		const updatedCheckAllItems = !checkAllItems;
		setCheckAllItems(updatedCheckAllItems);
		if (updatedCheckAllItems) {
			setCheckedUser(responsiblePersons);
		} else {
			setCheckedUser([]);
		}
	};

	const handleRemoveAll = () => {
		handleRemove?.([...checkedUser?.map((item) => item.user_id)]);
		setCheckedUser([]);
	};

	const isChecked = (user: Assignee) => checkedUser.some((checkedCustomer) => checkedCustomer.user_id === user.user_id);

	const goToUsersPage = (user_id: number) => {
		const contact = usersList.find((user) => user.id === user_id);
		if (contact) {
			history.push(`/system/user/edituser`, { userDetails: contact });
		}
	};

	return (
		<Modal
			show={show && !isOpen}
			centered
			dialogClassName="large-size-modal"
			backdrop="static"
			id="MailContactsModel"
			className={`kt-modal ${isOpen ? "d-none" : ""}`}
			onHide={closeModal}
		>
			<Modal.Header>
				<div>
					<h3>
						{intl.formatMessage({
							id: "TASKS_LIST_RESPONSIBLE_PERSONS_MODAL_TITLE",
						})}
					</h3>
				</div>
				<div className="d-flex align-items-center">
					{checkedUser.length > 0 && (
						<>
							<span className="text-dark text-hover-primary fs-6 fw-bolder mx-4">
								{checkedUser.length} {intl.formatMessage({ id: "SELECTED" })}
							</span>
							<button
								className="btn btn-danger"
								onClick={() => {
									showDeleteConfirmModal(
										intl.formatMessage({
											id: "TEAM_USERS_DELETE_CONFIRM_MESSAGE",
										}),
										() => {
											handleRemoveAll();
										}
									);
								}}
							>
								<span className="indicator-label">{intl.formatMessage({ id: "DELETE_BUTTON" })}</span>
							</button>
						</>
					)}
					<CloseButton onClose={closeModal} />
				</div>
			</Modal.Header>

			<div style={{ overflow: "auto" }}>
				<Modal.Body style={{ overflow: "inherit !important" }}>
					<div className="table-responsive overflow-auto">
						<table className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer mb-0">
							<thead>
								<tr className="text-start text-muted fw-bolder fs-7 text-uppercase gs-0">
									{isSelectionModal && (
										<th className="w-25px">
											<Checkbox onChange={onAllItemsCheckChange} checked={checkAllItems} disabled={false} />
										</th>
									)}
									<th className="min-w-125px">
										<div className={clsx(!isSelectionModal && "ms-15")}>
											{intl.formatMessage({
												id: "TASKS_LIST_RESPONSIBLE_PERSONS_MODAL_NAME_COLUMN",
											})}
										</div>
									</th>
									<th className="min-w-125px">
										<div>
											{intl.formatMessage({
												id: "TASKS_LIST_RESPONSIBLE_PERSONS_MODAL_EMAIL_COLUMN",
											})}
										</div>
									</th>
									<th className="min-w-125px">
										<div>
											{intl.formatMessage({
												id: "TASKS_LIST_RESPONSIBLE_PERSONS_MODAL_PHONE_COLUMN",
											})}
										</div>
									</th>
									{isSelectionModal && (
										<th className="min-w-40px text-end">
											{intl.formatMessage({
												id: "TASKS_LIST_RESPONSIBLE_PERSONS_MODAL_ACTION_COLUMN",
											})}
										</th>
									)}
								</tr>
							</thead>
							<tbody>
								{responsiblePersons?.length === 0 ? (
									<td valign="top" colSpan={7} className="dataTables_empty">
										<div className="d-flex flex-column flex-center">
											<div className="fs-1 fw-bolder text-dark mb-4">
												{intl.formatMessage({ id: "NO_ITEMS_FOUND" })}
											</div>
										</div>
									</td>
								) : (
									responsiblePersons?.map((contact: Assignee, i) => (
										<tr key={i}>
											{isSelectionModal && (
												<td className="py-2">
													{!isSelectionModal ? null : (
														<div className="form-check form-check-sm form-check-custom form-check-solid me-2">
															<Checkbox onChange={() => toggleCheckedUser(contact)} checked={isChecked(contact)} />
														</div>
													)}
												</td>
											)}
											<td className="py-2">
												<div className="d-flex align-items-center">
													<div className="symbol symbol-circle symbol-40px overflow-hidden me-3">
														{contact.profile_photo_url ? (
															<img
																placeholder={BlankAvatar}
																src={contact.profile_photo_url}
																alt={contact.firstname}
																style={{ height: "40px", objectFit: "cover" }}
																className="img-fluid rounded-circle"
															/>
														) : (
															<div className="symbol-label fs-3 bg-light-danger text-danger text-uppercase cursor-pointer">
																{contact?.firstname?.[0]}
																{contact?.lastname?.[0]}
															</div>
														)}
													</div>
													<div
														className="d-flex justify-content-start flex-column"
														onClick={() => goToUsersPage(contact.user_id)}
													>
														<span
															className="text-gray-800 fw-bold text-hover-primary text-capitalize mb-1"
															role="button"
														>
															{contact?.firstname} {contact?.lastname}
														</span>
													</div>
												</div>
											</td>
											<td className="py-2">
												<span className="text-gray-600 fw-bold">{contact?.email}</span>
											</td>
											<td className="py-2">
												<span className="text-gray-600 fw-bold">{contact?.telephone ? contact?.telephone : "-"}</span>
											</td>
											{isSelectionModal && (
												<td>
													<div className="d-flex justify-content-end">
														<button
															className="btn btn-icon btn-light btn-active-light-danger btn-sm"
															onClick={(e) => {
																showDeleteConfirmModal(
																	intl.formatMessage({
																		id: "TEAM_USERS_DELETE_CONFIRM_MESSAGE",
																	}),
																	() => {
																		handleRemove?.([contact?.user_id]);
																	}
																);
															}}
														>
															<SVGICON src={DeleteIcon} className="svg-icon-3" />
														</button>
													</div>
												</td>
											)}
										</tr>
									))
								)}
							</tbody>
						</table>
					</div>
				</Modal.Body>
			</div>

			<Modal.Footer className="justify-content-center">
				<Button variant="secondary" onClick={closeModal}>
					{intl.formatMessage({ id: "CLOSE_BUTTON" })}
				</Button>
			</Modal.Footer>
		</Modal>
	);
};

const DealResponsiblePersons: FC<DealResponsiblePersonsProps> = ({ dealInformation, getDealDetailsById }) => {
	const intl = useIntl();

	const { hideDeleteConfirmModal } = useGlobalModalContext();

	const [showAddUserModal, setShowAddUserModal] = useState(false);
	const [modalProps, setModalProps] = useState<{
		show: boolean;
		method: string;
	}>({
		show: false,
		method: "",
	});

	const [showAllUsersModal, setShowAllUsersModal] = useState<{
		show: boolean;
		users: undefined | Assignee[];
	}>({
		show: false,
		users: undefined,
	});

	const handleResponsibles = (data: UserType[]) => {
		const ids = data?.map((user) => user?.id);
		const existingUsersIds = dealInformation?.responsible_users.map((data: { user_id: any }) => data.user_id);
		addresponsible_Persons(dealInformation?.id, [...ids, ...existingUsersIds])
			.then((data) => {
				successToast(
					intl.formatMessage({
						id: "DEAL_RESPONSIBLE_USER_ADD_SUCCESS_MESSAGE",
					})
				);
			})
			.catch((e) => {
				errorToast(
					intl.formatMessage({
						id: "DEAL_RESPONSIBLE_USER_ADD_FAILURE_MESSAGE",
					})
				);
			})
			.finally(() => {
				getDealDetailsById(dealInformation?.id);
			});
	};

	const handleTeamResponsibles = (team_user_ids: number[]) => {
		const existingUsersIds = dealInformation?.responsible_users.map((data: { user_id: number }) => data.user_id);
		addresponsible_Persons(dealInformation?.id, [...team_user_ids, ...existingUsersIds])
			.then((data) => {
				successToast(
					intl.formatMessage({
						id: "DEAL_RESPONSIBLE_USER_ADD_SUCCESS_MESSAGE",
					})
				);
			})
			.catch((e) => {
				errorToast(
					intl.formatMessage({
						id: "DEAL_RESPONSIBLE_USER_ADD_FAILURE_MESSAGE",
					})
				);
			})
			.finally(() => {
				getDealDetailsById?.(dealInformation?.id);
			});
	};

	const deleteHandleResponsibles = (ids: number[]) => {
		deleteresponsible_Persons(dealInformation?.id, ids)
			.then(() => {
				successToast(
					intl.formatMessage({
						id: "DEAL_RESPONSIBLE_USER_DELETE_SUCCESS_MESSAGE",
					})
				);
			})
			.catch((e) => {
				errorToast(
					intl.formatMessage({
						id: "DEAL_RESPONSIBLE_USER_DELETE_FAILURE_MESSAGE",
					})
				);
			})
			.finally(() => {
				hideDeleteConfirmModal();
				getDealDetailsById(dealInformation?.id);
			});
	};

	const displayResponsibles = (dealInformation?.responsible_users || []).slice(0, 4);
	const remainingAssignees = (dealInformation?.responsible_users || []).length - displayResponsibles.length;

	return (
		<>
			{showAddUserModal && (
				<SelectResponsibles
					show={showAddUserModal}
					closeModal={() => {
						setShowAddUserModal(false);
					}}
					onSelectMethod={(method) => {
						setShowAddUserModal(false);
						setModalProps({
							show: true,
							method,
						});
					}}
				/>
			)}
			{modalProps.show && modalProps.method === "single" && (
				<SelectedUsersModal
					show={modalProps.show}
					closeModal={() => {
						setModalProps({
							...modalProps,
							show: false,
						});
					}}
					onSelectUsers={(customers) => {
						handleResponsibles(customers);
						setModalProps({
							...modalProps,
							show: false,
						});
					}}
					isSelectionModal={true}
					selectedContacts={dealInformation?.responsible_users}
				/>
			)}
			{modalProps.show && modalProps.method === "team" && (
				<SelectTeamsModal
					show={modalProps.show}
					closeModal={() => {
						setModalProps({
							...modalProps,
							show: false,
						});
					}}
					onSelectTeams={(teams) => {
						const ids = teams.map((team) => team.team_users.map((user) => user?.id))[0];
						setModalProps({
							...modalProps,
							show: false,
						});
						handleTeamResponsibles(ids);
					}}
					isSelectionModal={false}
				/>
			)}
			{showAllUsersModal.show && (
				<DealResponsibleUsersModal
					show={showAllUsersModal.show}
					closeModal={() => {
						setShowAllUsersModal({
							...showAllUsersModal,
							show: false,
						});
					}}
					selectedUsers={dealInformation?.responsible_users}
					handleRemove={deleteHandleResponsibles}
					isSelectionModal={true}
				/>
			)}
			<div className="symbol-group symbol-hover flex-nowrap">
				{displayResponsibles
					?.slice(0, 4)
					.map((data: any, i: number) => (
						<UserSymbol key={i} data={data} handleRemove={() => deleteHandleResponsibles([data?.user_id])} />
					))}
				{remainingAssignees > 0 && (
					<MoreSymbol
						remainingUsers={remainingAssignees}
						onClick={() => {
							setShowAllUsersModal({
								show: true,
								users: dealInformation?.responsible_users,
							});
						}}
					/>
				)}
				<AddUserSymbol tooltipText="Add Responsible Person" onClick={() => setShowAddUserModal(true)} />
			</div>
		</>
	);
};

export default DealResponsiblePersons;
