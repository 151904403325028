import React, { FC } from "react";
import { Tooltip } from "react-tooltip";
import { useIntl } from "react-intl";
import { SVGICON } from "../../../../_metronic/helpers";

// Assets
import PlusIcon from "../../../../_metronic/assets/icons/plus.svg";
import BlankAvatar from "../../../../_metronic/assets/icons/blankAvatar.svg";

// Types
import {
  UserSymbolProps,
  AddUserSymbolProps,
  MoreSymbolProps,
} from "../types/TasksPropsType";

// AddUserSymbol Component
export const AddUserSymbol: FC<AddUserSymbolProps> = ({
  tooltipText,
  onClick,
}) => {
  const tooltipId = "role-user-tooltip-plus";

  return (
    <div
      className="symbol symbol-35px symbol-circle bg-primary add-symbol toolip_container_plus"
      role="button"
      onClick={onClick}
    >
      <span className="symbol-label text-inverse-warning bg-primary ">
        <SVGICON src={PlusIcon} className="svg-icon-2 svg-icon-white" />
      </span>
      <Tooltip id={tooltipId} anchorSelect=".toolip_container_plus">
        {tooltipText}
      </Tooltip>
    </div>
  );
};

// MoreSymbol Component
export const MoreSymbol: FC<MoreSymbolProps> = ({
  remainingUsers,
  onClick,
}) => {
  const intl = useIntl();
  const tooltipId = "role-user-tooltip-more";

  return (
    <div
      className="symbol symbol-35px symbol-circle toolip_container_more"
      role="button"
      onClick={onClick}
      style={{ backgroundColor: "#1B84EF" }}
    >
      <span className="symbol-label fs-7 fw-bolder btn-primary text-light">
        {`+${remainingUsers}`}
      </span>
      <Tooltip id={tooltipId} anchorSelect=".toolip_container_more">
        {intl.formatMessage({ id: "TASKS_LIST_RESPONSIBLE_PERSONS_MORE_BTN" })}
      </Tooltip>
    </div>
  );
};

// UserSymbol Component
export const UserSymbol: FC<UserSymbolProps> = ({
  data,
  handleRemove,
  place,
}) => {
  // @ts-ignore
  const { firstname, lastname, email, id, user_id, profile_photo_url,users_id } = data;
  const uniqueId = user_id ? user_id : users_id
  const tooltipId = `role-user-tooltip-${email}-${id ? id : uniqueId}`;

  return (
    <div
      className={`symbol symbol-35px symbol-circle toolip_container_${id ? id : uniqueId}`}
    >
      {profile_photo_url ? (
        <img
          placeholder={BlankAvatar}
          src={profile_photo_url}
          alt={firstname}
          style={{ height: "35px", objectFit: "cover" }}
          className="img-fluid rounded-circle"
        />
      ) : (
        <div
          className="symbol-label fs-3 text-inverse-warning text-uppercase cursor-pointer"
          style={{ backgroundColor: "#BAD9FF" }}
        >
          {firstname?.[0]}
        </div>
      )}
      {handleRemove && (
        <div
          onClick={() => handleRemove(id)}
          className="bg-danger symbol-delete-icon"
        >
          x
        </div>
      )}
      <Tooltip
        id={tooltipId}
        place={place ? place : "top"}
        anchorSelect={`.toolip_container_${id ? id : uniqueId}`}
      >
        {firstname ? `${firstname} ${lastname || ""}` : email}
      </Tooltip>
    </div>
  );
};
