const Config = {
  API_URL: process.env.REACT_APP_API_URL || "REACT_APP_API_URL_PLACEHOLDER",
  APP_NAME: process.env.REACT_APP_NAME || "REACT_APP_NAME_PLACEHOLDER",
  SUCCESS_URL: process.env.REACT_APP_WEBAPP_URL,
  PARENT_URL: process.env.REACT_APP_PARENT_URL,
  PARENT_ID: process.env.REACT_APP_PARENT_ID,
  STATUS: process.env.REACT_APP_STATUS,
  WEB_APP_URL: process.env.REACT_APP_WEBAPP_URL,
  REACT_APP_SECRET_KEY: process.env.REACT_APP_SECRET_KEY,
  PRODUCTION_API_URL: process.env.REACT_APP_PRODUCTION_API_URL,
  GOOGLE_MAPS_API_KEY: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
};

export default Config;
