import React, { FC, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, Modal } from "react-bootstrap";
import { useIntl } from "react-intl";
import {
  salesNumbersDetails,
  settingsDetailsType,
} from "../../../salesMasterData/SalesSettings/types/SalesSettingstype";
import { getSalesSettings } from "../../../salesMasterData/SalesSettings/redux/SalesSettingsApi";
import LayoutInvoices from "../../../salesMasterData/SalesSettings/components/LayoutInvoices";
import LayoutOffers from "../../../salesMasterData/SalesSettings/components/LayoutOffers";
import CloseButton from "../../../../sharedComponents/Buttons/CloseButton";

interface Props {
  show: boolean;
  layoutID?: any;
  layoutInvoiceID?: any;
  fromInvoiceEdit?: boolean;
  fromSalesOfferEdit?: boolean;
  comingFromInvoice?: any;
  comingFromOffer?: any;
  closeModal: () => void;
  setLayoutInvoiceModal?: () => void;
  setShowSettingModal?: () => void;
}

const SalesSettingModal: FC<Props> = ({
  show,
  layoutID,
  layoutInvoiceID,
  fromInvoiceEdit,
  fromSalesOfferEdit,
  comingFromInvoice,
  comingFromOffer,
  closeModal,
  setLayoutInvoiceModal,
  setShowSettingModal,
}) => {
  const intl = useIntl();
  const history = useHistory();

  const [invoices, setInvoices] = useState<settingsDetailsType[]>([]);
  const [offers, setOffers] = useState<settingsDetailsType[]>([]);
  const [numberCancelledInvoices, setNumberCancelledInvoices] = useState<
    settingsDetailsType[]
  >([]);
  const [numberInvoices, setnumberInvoices] = useState<salesNumbersDetails>();
  const [numberOrders, setNumberOrders] = useState<salesNumbersDetails>();
  const [numberoffers, setNumberOffers] = useState<salesNumbersDetails>();
  const [numbergeneral, setNumberGeneral] = useState<salesNumbersDetails>();
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [error, setError] = useState<string>("");

  //@ts-ignore
  const offerID = offers[2]?.answer;
  //@ts-ignore
  const invoiceID = invoices[0]?.answer;

  const getSalesSettingsApi = () => {
    getSalesSettings()
      .then(({ data: { settingsDetails = [] as settingsDetailsType[] } }) => {
        // @ts-ignore
        const organisedData = settingsDetails?.salesSettingsDetails?.reduce(
          // @ts-ignore
          (acc, item) => {
            const module = item.module;
            if (!acc[module]) {
              acc[module] = [];
            }
            acc[module].push(item);
            return acc;
          },
          {} as Record<string, settingsDetailsType[]>
        );

        setInvoices(organisedData["invoice"] || []);
        setOffers(organisedData["offers"] || []);

        const organisedaNumberData = // @ts-ignore
          settingsDetails?.salesNumbersDetails?.reduce(
            // @ts-ignore
            (acc, item) => {
              const module = item.module;
              if (!acc[module]) {
                acc[module] = [];
              }
              acc[module].push(item);
              return acc;
            },
            {} as Record<string, settingsDetailsType[]>
          );
        setNumberGeneral(organisedaNumberData["general"] || []);
        setnumberInvoices(organisedaNumberData["invoices"] || []);
        setNumberOffers(organisedaNumberData["offers"] || []);
        setNumberCancelledInvoices(
          organisedaNumberData["cancelled_invoices"] || []
        );
      })
      .catch((error) => console.error(error?.response?.data?.message));
  };

  const handleSubmit = () => {
    if (comingFromOffer) {
      if (!offerID || !invoiceID) {
        setError(intl.formatMessage({ id: "SETTINGS_REQUIRED" }));
      } else {
        setError("");
        closeModal?.();
      }
    } else if (comingFromInvoice) {
      if (!invoiceID) {
        setError(intl.formatMessage({ id: "SETTINGS_REQUIRED" }));
      } else {
        setError("");
        closeModal?.();
      }
    } else {
      setError("");
      closeModal?.();
    }
  };

  useEffect(() => {
    getSalesSettingsApi();
  }, []);

  return (
    <div>
      <Modal
        show={show}
        centered
        dialogClassName={`extra-large-size-modal ${modalOpen ? "d-none" : ""} `}
        className="kt-modal"
        backdrop="static"
        onHide={closeModal}
      >
        <Modal.Header>
          <Modal.Title>
            {intl.formatMessage({
              id: "SALES_OFFER_SETTINGS_TITLE",
            })}
          </Modal.Title>
          <CloseButton onClose={closeModal} />
        </Modal.Header>

        <div className="overflow-auto">
          <Modal.Body className="overflow-inherit">
            {comingFromOffer && (
              <>
                <div>
                  <LayoutOffers
                    // @ts-ignore
                    offers={offers}
                    numberoffers={numberoffers}
                    getSalesSettingsApi={getSalesSettingsApi}
                    fromSalesOfferEdit={fromSalesOfferEdit}
                    setShowSettingModal={setShowSettingModal}
                    setModalOpen={(modalinv: boolean) => setModalOpen(modalinv)}
                    mainCloseModal={closeModal}
                    error={error}
                    setError={() => {
                      setError("");
                    }}
                  />
                </div>
                <div>
                  <LayoutInvoices
                    // @ts-ignore
                    invoices={invoices}
                    numberInvoices={numberInvoices}
                    getSalesSettingsApi={getSalesSettingsApi}
                    // @ts-ignore
                    numberCancelledInvoices={numberCancelledInvoices}
                    fromInvoiceEdit={fromInvoiceEdit}
                    setLayoutInvoiceModal={setLayoutInvoiceModal}
                    setModalOpen={(modalinv: boolean) => setModalOpen(modalinv)}
                    mainCloseModal={closeModal}
                    error={error}
                    setError={() => {
                      setError("");
                    }}
                  />
                </div>
              </>
            )}

            {comingFromInvoice && (
              <div>
                <LayoutInvoices
                  // @ts-ignore
                  invoices={invoices}
                  numberInvoices={numberInvoices}
                  getSalesSettingsApi={getSalesSettingsApi}
                  // @ts-ignore
                  numberCancelledInvoices={numberCancelledInvoices}
                  fromInvoiceEdit={fromInvoiceEdit}
                  setLayoutInvoiceModal={setLayoutInvoiceModal}
                  setModalOpen={(modalinv: boolean) => setModalOpen(modalinv)}
                  mainCloseModal={closeModal}
                  error={error}
                  setError={() => {
                    setError("");
                  }}
                />
              </div>
            )}
          </Modal.Body>
        </div>

        <Modal.Footer className="justify-content-center">
          <Button
            className="btn btn-secondary"
            variant="secondary"
            onClick={() => {
              if (comingFromOffer) {
                if (offerID && invoiceID) {
                  closeModal?.();
                } else {
                  history.push(`/sales/offers/overview`);
                }
              } else if (comingFromInvoice) {
                if (invoiceID) {
                  closeModal?.();
                } else {
                  history.push(`/sales/invoices/overview`);
                }
              } else {
                closeModal?.();
              }
            }}
            type="button"
          >
            {intl.formatMessage({ id: "CLOSE_BUTTON" })}
          </Button>
          <Button className="btn btn-primary" onClick={handleSubmit}>
            {intl.formatMessage({ id: "SUBMIT_BUTTON" })}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default SalesSettingModal;
