import React, { FC, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { TabType, panels } from "../types/TabsResponseType";
import { useIntl } from "react-intl";
import { KTSVG, SVGICON } from "../../../../_metronic/helpers";
import InfoIcon from "../../../../_metronic/assets/icons/info.svg";
import SVG from "react-inlinesvg";
import { FieldType } from "../types/FieldsReponseTypes";
import { FileUpload } from "../../products/components/ProductDocuments";
import clsx from "clsx";
import DateTimePicker from "../../../sharedComponents/dateTimePicker/DateTimePicker";
import moment from "moment";
import SunEditorComponent from "../../../sharedComponents/textEditor/SunEditor";
import DropDown from "../../../sharedComponents/dropdown/Dropdown";
import * as Yup from "yup";
import { useFormik } from "formik";
import _ from "lodash";
import { useGlobalModalContext } from "../../../sharedComponents/modals/ModalContext";
import CloseIcon from "../../../../_metronic/assets/icons/close.svg";
import { useSelector } from "react-redux";
import { RootState } from "../../../../setup";
import { UserType } from "../../userManagement/types/getUsersListResponseType";
import { DealPipelineDetailsType } from "../../deals/types/DealResponseType";
// import SelectProductCategories from "../../deals/components/SelectProductCategories";
import { GroupType } from "../../masterdata/types/GetGroupsListResponseType";
import SelectProduct from "../../deals/components/SelectProduct";
import { useDispatch } from "react-redux";
import { actions } from "../redux";
import { imageURLType, ProductType } from "../../products/types/ProductsResponseType";
import { getByProductID } from "../../products/redux/ProductsAPI";
import { Tooltip } from "react-tooltip";
import GoToIcon from "../../../../_metronic/assets/icons/goto.svg";
import CopyIcon from "../../../../_metronic/assets/icons/copy.svg";
import VideoIcon from "../../../../_metronic/assets/icons/video.svg";
import AudioIcon from "../../../../_metronic/assets/icons/audio.svg";
import PDFIcon from "../../../../_metronic/assets/icons/pdf.svg";
import PPTIcon from "../../../../_metronic/assets/icons/ppt.svg";
import PPTXIcon from "../../../../_metronic/assets/icons/pptx.svg";
import CSVIcon from "../../../../_metronic/assets/icons/Csv.svg";
import WORDIcon from "../../../../_metronic/assets/icons/word-icon.png";
import FileIcon from "../../../../_metronic/assets/icons/defaultFile.svg";
import TXTIcon from "../../../../_metronic/assets/icons/txt-icon.png";
import XLSX from "../../../../_metronic/assets/icons/xlsx.svg";
import { getFile } from "../../filemanager/redux";
import ProductFilePreviewModal from "../../products/components/ProductFilePreviewModal";
import { successToast, warningToast } from "../../../sharedComponents/toasts/Toasts";
import { useLang } from "../../../../_metronic/i18n/Metronici18n";
// import Lightbox from 'lightbox-react';
// import 'lightbox-react/style.css';

interface KTAccordionItemProps {
  index: number;
  panel: any;
  currPhase: DealPipelineDetailsType | undefined;
	type: string;
	onSubmitHandle?: (id: any, fId: any, value: any, value_json?: any) => void;
	deleteFile?: (pipelines_phases_tabs_id: number, tabs_panels_fields_id: number, file_id: number) => void;
}

export const KTAccordionItem: FC<KTAccordionItemProps> = ({index, type, panel, currPhase, onSubmitHandle ,deleteFile}) => {
  const intl = useIntl();
  const [isOpen, setIsOpen] = useState<boolean>(true);

  const toggleAccordion = () => {
    setIsOpen((prev) => !prev);
  };

  useEffect(() => () => setIsOpen(true), []);

  return(
    <div
      className={clsx("card d-flex accordion borderNone tab-panel-accordion")}
      key={index}
      id={`kt_accordion_${index}`}
    >
      <div className="accordion-item">
        <h2
          className="accordion-header d-flex align-items-center"
          id={`kt_accordion_${index}_header_${index}`}
        >
          <div
            className="d-flex align-items-center w-100 fs-4 fw-semibold"
            role="button"
            data-bs-toggle="collapse"
            data-bs-target={`#kt_accordion_${index}_body_${index}`}
            aria-expanded="true"
            aria-controls={`kt_accordion_${index}_body_${index}`}
            title={panel.heading}
            onClick={toggleAccordion}
          >
            <span className="accordion-icon" style={{marginTop: "-3px"}}>
              {isOpen ? (
                <KTSVG className="svg-icon svg-icon-2" path="media/icons/duotune/general/gen036.svg" />
              ):(
                <KTSVG className="svg-icon svg-icon-2 svg-icon-primary" path="media/icons/duotune/general/gen035.svg" />
              )}
            </span>
            <h4
              className="py-2 ps-4"
              style={{
                maxWidth: "500px",
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap",
                margin: 0,
                color: "#1B84EF",
              }}
            >
              {panel.heading}
            </h4>
          </div>
        </h2>
        <div
          id={`kt_accordion_${index}_body_${index}`}
          className={clsx("accordion-collapse collapse", isOpen ? "show" : "")}
          aria-labelledby={`kt_accordion_${index}_header_${index}`}
          data-bs-parent={`#kt_accordion_${index}`}
        >
          <div className="accordion-body d-flex gap-12 ps-9 pb-0 card-pt pe-0">
            {panel.sections && panel.sections.length > 0 ? (
              panel.sections.map((section: any, pindex: number) => (
                <div style={{ flex: 1 }} key={pindex}>
                  {
                    section.fields_data && section.fields_data.length > 0
                      ? section.fields_data.map((field: any, sindex: number) => (
                          <div
                            style={{
                              flex: 1,
                            }}
                            key={sindex}
                            className={clsx(section.fields_data.length - 1 === sindex ? "" : "tab-field")}
                          >
                            <DynamicFormInput
                              currPhase={currPhase}
                              field={field}
                              type={type}
                              id={field.id}
                              onSubmitHandle={(fId, value, value_json) => {
                                onSubmitHandle?.(field.pipeline_phase_tab_id, fId, value, value_json);
                              }}
                              index={sindex}
                              deleteFile={(tId, fId, file_id) => {
                                deleteFile?.(tId, fId, file_id);
                              }}
                            />
                          </div>
                        ))
                      : null
                    // <div className="text-muted fs-6 d-flex justify-content-center align-items-center">
                    //   {intl.formatMessage({
                    //     id: "NO_FIELDS_MESSAGE",
                    //   })}
                    // </div>
                  }
                </div>
              ))
            ) : (
              <div className="text-muted fs-6 d-flex justify-content-center align-items-center">
                {intl.formatMessage({ id: "NO_SECTION_MESSAGE" })}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}


interface Props {
	currPhase: DealPipelineDetailsType | undefined;
	dealTab: TabType;
	type: string;
	onSubmitHandle?: (id: any, fId: any, value: any, value_json?: any) => void;
	deleteFile?: (pipelines_phases_tabs_id: number, tabs_panels_fields_id: number, file_id: number) => void;
}

const TabPreview: FC<Props> = ({ currPhase, dealTab, type, onSubmitHandle, deleteFile }) => {
	// @ts-ignore
	const tab: TabType = dealTab || {};

	// state
	const [currPanel, setCurrPanel] = useState<panels[]>(tab.panels);

	useEffect(() => {
		if (tab && tab.panels && tab.panels.length > 0) {
			const data = tab.panels.filter((panel) => {
				return panel.sections.some((section) => section.fields_data.length > 0);
			});
			if (data && data.length > 0) {
				setCurrPanel(data);
			}
		}
	}, [tab]);

	return (
		<div className="card">
			<div className="card-body card-p">
				{currPanel &&
					currPanel.length > 0 &&
					currPanel.map((panel, index) => {
            return(
              <KTAccordionItem 
                currPhase={currPhase}
                index={index}
                panel={panel}
                onSubmitHandle={onSubmitHandle}
                deleteFile={deleteFile}
                type={type}
              />
            )
					})}
			</div>
		</div>
	);
};

export default TabPreview;

interface FormProps {
	field: FieldType;
	type: string;
	id: number;
	onSubmitHandle: (fId: any, value: any, value_json?: any) => void;
	index: number;
	deleteFile: (tab_id: number, field_id: number, file_id: number) => void;
	currPhase: DealPipelineDetailsType | undefined;
}

export const DynamicFormInput: FC<FormProps> = ({ field, type, id, onSubmitHandle, index, deleteFile, currPhase }) => {
	const intl = useIntl();
	const dispatch = useDispatch();
	const userData = useSelector<RootState>(({ auth }) => auth.user) as UserType;
	const { showDeleteConfirmModal, hideDeleteConfirmModal, setDeleteModalLoading } = useGlobalModalContext();

	const fieldProductList = useSelector<RootState>(
		// @ts-ignore
		({ contentMaster }) => contentMaster.fieldProductDetails
	) as {
		product: ProductType;
		field_id: number | undefined;
	}[];
	const local = useLang();

	// state
	const [selectDropdown, setSelectDropdown] = useState<{ id: number; name: string }[]>(
		(type === "edit" && field.type === "dropdown" && JSON.parse(field.value)) || []
	);

	const [checkedBoxes, setCheckedBoxes] = useState<{ name: string }[]>(
		(type === "edit" && field.type === "checkbox" && JSON.parse(field.value)) || []
	);

	const [previewLoading, setPreviewLoading] = useState<string | undefined>();
	const [previewFileURL, setPreviewFileURL] = useState<any>();
	const [imageFiles, setImageFiles] = useState<any[]>([]);
	const [previewFileExtension, setPreviewFileExtension] = useState<any>();
	const [showPreviewModal, setShowPreviewModal] = useState<boolean>(false);
	const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false)

	const [dropdownOptions, setDropdownOptions] = useState<
		{
			id: number;
			name: string;
		}[]
	>([]);

	const [selectedFiles, setSelectedFiles] = useState<File[] | { id: number; url: string }[]>(
		(type === "edit" && field.type === "uploader" && field.value_json) || []
	);

	const [openProductModal, setOpenProductModal] = useState<{
		show: boolean;
		category: GroupType[] | undefined;
		field_id: undefined | FieldType;
	}>({
		show: false,
		category: undefined,
		field_id: undefined,
	});
	const [openProductCategoryModal, setOpenProductCategoryModal] = useState<{
		show: boolean;
		field: undefined | FieldType;
	}>({
		show: false,
		field: undefined,
	});

	const [fileLoading, setFileLoading] = useState(false);

	const isDisabled =
		userData?.role?.id === 1 ||
		(currPhase && currPhase?.roles?.length === 0) ||
		(currPhase?.roles &&
			currPhase?.roles?.length > 0 &&
			currPhase?.roles.filter((role) => role.id === userData?.role?.id).length > 0)
			? false
			: true;

	const initialValues = {
		textBox: type === "edit" && field.type === "text" && id === field.id ? field.value || "" : "",
		link: type === "edit" && field.type === "link" && id === field.id ? field.value || "" : "",
		numberBox: type === "edit" && field.type === "number" && id === field.id ? field.value || "" : "",
		longText: type === "edit" && field.type === "longtext" && id === field.id ? field.value || "" : "",
		dateDetails:
			type === "edit" && field.type === "date" && id === field.id
				? field.value && !isNaN(Date.parse(field.value))
					? field.value
					: ""
				: "",
	};

	const AddNewFieldSchema = Yup.object().shape({
		textBox: Yup.string()
			.max(60, intl.formatMessage({ id: "MASTERDATA_60SYMBOLS_REQUIRED" }))
			.required(field.name + " " + intl.formatMessage({ id: "COMMOM_IS_REQUIRED" })),
		numberBox: Yup.string()
			.matches(/^-?\d*\.?\d*$/, intl.formatMessage({ id: "FIELD_TYPE_NUMBER_VALIDATION_MESSAGE" })) // Allow negative and decimal numbers
			.required(field.name + " " + intl.formatMessage({ id: "COMMOM_IS_REQUIRED" })),
		longText: Yup.string().required(field.name + " " + intl.formatMessage({ id: "COMMOM_IS_REQUIRED" })),
		dateDetails: Yup.string()
			.required(field.name + " " + intl.formatMessage({ id: "COMMOM_IS_REQUIRED" }))
			.nullable(),
		// Link field with URL validation and nullable option
		link: Yup.string()
			.nullable() // Allows null values
			.url(intl.formatMessage({ id: "FIELD_TYPE_LINK_VALIDATION_MESSAGE" })),
		// .required(
		//   field.name + " " + intl.formatMessage({ id: "COMMOM_IS_REQUIRED" })
		// ),
	});

	const formik = useFormik({
		initialValues,
		validationSchema: (field.is_required === 1 || field.type === "link") && AddNewFieldSchema,
		enableReinitialize: true,
		onSubmit: async (values, { setStatus, setSubmitting }) => {},
	});

	useEffect(() => {
		if (type && type === "edit") {
			formik.handleSubmit();
		}
	}, [field]);

	const handleFileUpload = (files: File[]) => {
		onSubmitHandle?.(field.tab_panels_fields_id, null, files);
	};

	const handleOnSelectItem = (item: { id: number; name: string }) => {
		const selectedItemsArr = selectDropdown || [];
		const index = selectedItemsArr.findIndex((selectedItem) => selectedItem.id === item.id);
		// if doesnt exist push item
		if (index === -1) {
			formik.handleSubmit();
			onSubmitHandle?.(field.tab_panels_fields_id, JSON.stringify([...selectDropdown, item]));
			setSelectDropdown(() => [...selectDropdown, item]);
		}
		// if already exists remove item
		else {
			selectDropdown.splice(index, 1);
			formik.handleSubmit();
			onSubmitHandle?.(field.tab_panels_fields_id, JSON.stringify(selectDropdown));
			setSelectDropdown((prevState) => {
				prevState.splice(index, 1);
				return [...prevState];
			});
		}
	};

	const handleOnCheckItem = (item: { name: string }) => {
		const selectedItemsArr = checkedBoxes || [];
		const index = selectedItemsArr.findIndex((selectedItem) => selectedItem.name === item.name);
		// if doesnt exist push item
		if (index === -1) {
			setCheckedBoxes(() => [...(checkedBoxes || []), item]);
		}
		// if already exists remove item
		else {
			setCheckedBoxes((prevState) => {
				prevState.splice(index, 1);
				return [...prevState];
			});
		}
	};

	useEffect(() => {
		if (type === "edit" && field.type === "dropdown") {
			const resData: {
				id: number;
				name: string;
			}[] = [];
			field.options?.map((f: { name: string }, index) => {
				let obj: {
					id: number;
					name: string;
				} = {
					id: 0,
					name: "",
				};
				obj.id += index;
				obj.name = f.name;
				resData.push(obj);
			});
			setDropdownOptions(resData);
		}
	}, [field]);

	useEffect(() => {
		if (type === "edit" && field.type === "checkbox") {
			setCheckedBoxes(JSON.parse(field.value));
		}
	}, [field?.id]);

	const isChecked = (item: { name: string }) => {
		const index = checkedBoxes?.findIndex((checkedPipeline) => checkedPipeline.name === item.name);
		if (index > -1) {
			return true;
		}
		return false;
	};

	const handleFieldValue = (field: FieldType) => {
		if (fieldProductList && fieldProductList.length > 0 && field.value && field.value !== undefined) {
			const removedData = fieldProductList.filter((data) => data.field_id === field.id)[0];
			return removedData?.product?.name;
		} else {
			return "";
		}
	};

	const getProductDetails = (product_id: number, field_id: number) => {
		getByProductID(product_id).then(({ data }) => {
			let result: {
				product: ProductType;
				field_id: number | undefined;
			}[] = [];
			const { productDetails } = data || {};
			const obj = {
				product: productDetails,
				field_id: field_id,
			};
			result = [...fieldProductList];
			result.push(obj);
			dispatch(actions.setFieldProductDetails(result));
		});
	};

	function getFileExtensionFromUrl(url: string) {
		const match = url.match(/\.([^.]+)$/);
		return match ? match[1] : null;
	}

	const isImageFile = (file: any) => {
		const imageExtensions = [
		  "jpeg", "jpg", "png", "gif", "bmp", "tiff", "tif", "heif", "heic", "webp",
		  "psd", "svg", "eps", "ai", "ico", "cr2", "cr3", "nef", "arw", "raf",
		  "orf", "rw2", "pef", "exr", "indd", "jp2", "j2k",
		];
	
		const fileExtension = file?.type?.toLowerCase();
		return imageExtensions.includes(fileExtension);
	  };
	

	const handlePreviewURL = (fileUrl: imageURLType) => {
		// @ts-ignore
		setPreviewLoading(fileUrl.url);
		// @ts-ignore
		const fileExtension = getFileExtensionFromUrl(fileUrl.url);
		setPreviewFileExtension(fileExtension);
		getFile(fileUrl.id)
			.then((res) => {
				// console.log(response.data);
				let fileObjURL = (res && res.data.fileDetails.file_url) || undefined;
				setPreviewFileURL(fileObjURL);
				setShowPreviewModal(true)
				// if(isImageFile(fileUrl)){
				// 	setIsOpen(true)
				//   }else{
				// 	setShowPreviewModal(true)
				//   }
			})
			.catch((err) => {})
			.finally(() => {
				setPreviewLoading(undefined);
			});
	};

	const closePreviewModal = () => {
		setShowPreviewModal(false);
	};

	const getIconForFileType = (file: { id?: number; url: any; name?: string; type: any }) => {
		switch (file?.type) {
			case "pdf":
				return PDFIcon;
			case "mp4":
			case "m4v":
				return VideoIcon;
			case "mp3":
				return AudioIcon;
			case "txt":
				return TXTIcon;
			case "xlsx":
				return XLSX;
			case "doc":
			case "docx":
				return WORDIcon;
			case "csv":
				return CSVIcon;
			case "ppt":
				return PPTIcon;
			case "pptx":
				return PPTXIcon;
			case "jpeg":
			case "jpg":
			case "png":
			case "gif":
			case "bmp":
			case "heif":
			case "heic":
			case "webp":
			case "psd":
			case "svg":
			case "eps":
			case "ai":
			case "ico":
			case "cr2":
			case "cr3":
			case "nef":
			case "arw":
			case "raf":
			case "orf":
			case "rw2":
			case "pef":
			case "exr":
			case "indd":
			case "jp2":
			case "j2k":
				return file?.url;
			default:
				return FileIcon;
		}
	};

	const handleImageClick = (index: number) => {
		setCurrentImageIndex(index);
		setIsOpen(true);
  };

	useEffect(()=>{
		const filteredImages = field?.value_json.filter(isImageFile);
       setImageFiles(filteredImages);
	},[field?.value_json])

	useEffect(() => {
		if (field.type === "product" && field?.value && field?.value !== undefined && id === field.id) {
			getProductDetails(Number(field.value), field.id);
		}

		if (field.type === "dropdown" && type === "edit") {
			if (field?.value && field?.value !== null && id === field.id) {
				setSelectDropdown(JSON.parse(field.value));
			}
		}
	}, [field]);

	return (
		<div key={index + field?.tab_panels_fields_id}>
			{/* {openProductCategoryModal.show && openProductCategoryModal.field ? (
        <SelectProductCategories
          show={openProductCategoryModal.show}
          closeModal={() => {
            setOpenProductCategoryModal({
              show: false,
              field: undefined,
            });
          }}
          onSelectCategory={(category) => {
            setOpenProductModal({
              show: true,
              category,
              field_id: openProductCategoryModal.field,
            });
            setOpenProductCategoryModal({
              show: false,
              field: undefined,
            });
          }}
        />
      ) : null} */}

			{openProductModal.show ? (
				<SelectProduct
					show={openProductModal.show}
					closeModal={() => {
						setOpenProductModal({
							show: false,
							category: undefined,
							field_id: undefined,
						});
					}}
					isSelectionModal={true}
					onSelectProduct={(product) => {
						const obj = {
							product: product[0],
							field_id: openProductModal.field_id?.id,
						};

						let result: {
							product: ProductType;
							field_id: number | undefined;
						}[] = [];
						result.push(obj);
						// const index = fieldProductList.findIndex(
						//   (data) => data.field_id === openProductModal.field_id?.id
						// );

						// if (index === -1) {
						//   result = [...fieldProductList, obj];
						// } else {
						//   result = [...fieldProductList];
						//   result.splice(index, 1);
						//   result.push(obj);
						// }
						// if (fieldProductList && fieldProductList.length > 0) {
						// } else {
						//   result.push(obj);
						// }

						dispatch(actions.setFieldProductDetails(result));
						onSubmitHandle(openProductModal.field_id?.tab_panels_fields_id, product[0].id, "");
						formik.handleSubmit();
						setOpenProductModal({
							...openProductModal,
							show: false,
						});
					}}
				/>
			) : null}
			{field.type !== "linkButton" && (
				<div className="d-flex align-items-center mb-2">
					<label className={field.is_required ? "fw-bold fs-6 required " : "fw-bold fs-6 "}>{field.name}</label>
					{field?.tooltip && (
						<div
							style={{
								position: "relative",
								cursor: "pointer",
							}}
						>
							<span data-tooltip-id="my-preview-tooltip" data-tooltip-content={field.tooltip} data-tooltip-place="top">
								<SVG src={InfoIcon} className="svg-icon mx-2" />
							</span>

							<Tooltip
								id="my-preview-tooltip"
								place="top"
								style={{
									padding: "30px !important",
									color: "black",
									backgroundColor: "#e9ecef",
									zIndex: 999,
									whiteSpace: "normal",
									maxWidth: "250px",
									wordWrap: "break-word",
									height: "fit-content",
								}}
								className="btn text-dark"
								border="1px solid #6c757d"
							/>
						</div>
					)}
				</div>
			)}
			{field.type === "product" ? (
				<>
					<div className="d-flex align-items-center">
						<input
							type="text"
							className="form-control form-control-lg form-control-solid me-4"
							disabled={true}
							placeholder={field?.placeholder && field?.placeholder}
							value={field.id === id ? handleFieldValue(field) : ""}
						/>
						<button
							className={clsx("btn btn-primary", type === "edit" && !isDisabled ? "" : "pe-none")}
							onClick={() => {
								setOpenProductModal({
									show: true,
									category: undefined,
									field_id: field,
								});
							}}
						>
							{intl.formatMessage({ id: "CUSTOMERS_SELECT_PARTNER_TITLE" })}
						</button>
					</div>
					<div>
						{field.is_required === 1 && !field.value && (
							<div className="text-danger mt-2">
								<span role="alert">{field.name + " " + intl.formatMessage({ id: "COMMOM_IS_REQUIRED" })}</span>
							</div>
						)}
					</div>
				</>
			) : field.type === "text" ? (
				<div>
					<input
						type="text"
						className="form-control form-control-lg form-control-solid"
						disabled={type === "edit" && !isDisabled ? false : true}
						placeholder={field?.placeholder && field?.placeholder}
						{...formik.getFieldProps("textBox")}
						onBlur={(e) => {
							const updatedValue = e.target.value;

							if (field.value !== updatedValue) {
								if (field.value !== null || updatedValue !== "") {
									formik.handleSubmit();
									onSubmitHandle?.(field.tab_panels_fields_id, updatedValue);
								}
							}
						}}
						onChange={(e) => {
							// Set field value dynamically
							formik.setFieldValue("textBox", e.target.value);

							// Auto-detect changes due to autofill and trigger API call
							if (document.activeElement?.matches("input:-webkit-autofill")) {
								formik.handleSubmit();
								onSubmitHandle?.(field.tab_panels_fields_id, e.target.value);
							}
						}}
					/>
					{field.is_required === 1 && !field.value && (
						<div className="text-danger mt-2">
							<span role="alert">{field.name + " " + intl.formatMessage({ id: "COMMOM_IS_REQUIRED" })}</span>
						</div>
					)}
				</div>
			) : field.type === "uploader" ? (
				<div className={clsx("tab-field", type === "edit" && !isDisabled ? "" : "pe-none")}>
					<div className="d-flex flex-column flex-sm-row align-items-start">
						{/* File preview cards */}
						<div className="d-flex flex-column align-items-start w-100">
							<div className="gap-25px d-flex flex-wrap justify-content-start">
								{/* File Upload Section */}
								<div style={{ height: "160px", width: "140px" }}>
									<FileUpload
										onFileUpload={async (files: File[]) => {
											setFileLoading(true);
											await handleFileUpload(files);
											setTimeout(() => {
												setFileLoading(false);
											}, 800);
										}}
										showPlusIcon
										loading={fileLoading}
										isDisabled={type === "edit" && !isDisabled ? false : true}
									/>
								</div>
								{/* Files Section (File previews) */}

								{field.value_json.map((file, index) => {
									 const isImage = isImageFile(file);
									return (
										<div
											className="card border p-5 bg-hover-light-primary"
											key={index}
											style={{ height: "160px", width: "140px" }}
										>
											<div
												onClick={() => {
                          // @ts-ignore
                          handlePreviewURL(file); // Open preview modal for non-image files
												}}
                        // reverting the lightbox
												// onClick={() => {
												// 	if (isImage) {
												// 		// Get the corresponding index in imageFiles
												// 		const imageIndex = imageFiles.findIndex(
												// 		  (image) => image.url === file.url
												// 		);
											  
												// 		if (imageIndex !== -1) {
												// 		  handleImageClick(imageIndex); // Open lightbox for images
												// 		}
												// 	  } else {
												// 		// @ts-ignore
												// 		handlePreviewURL(file); // Open preview modal for non-image files
												// 	  }
												// }}
												className="d-flex flex-column align-items-center mt-2 cursor-pointer"
											>
												<img
													src={getIconForFileType(file)}
													height="60px"
													width="60px"
													style={{ objectFit: "cover" }}
													className="mb-4"
													alt=""
												/>
												<span className="text-truncate text-primary fw-bold" style={{ maxWidth: "120px" }}>
													{file?.name}
												</span>
												<span className="badge text-muted mb-2 me-2">
													{/* {bytesToSize(file?.size, 1)} */}
													{local === "de"
														? /* @ts-ignore */
															moment(file?.created_at).format("DD.MM.yyyy")
														: /* @ts-ignore */
															moment(file?.created_at).format("YYYY-MM-DD")}
												</span>
											</div>
											<div
												className="position-absolute btn btn-icon btn-circle btn-active-color-danger w-25px h-25px bg-body shadow-sm"
												style={{
													right: -12,
													top: -12,
													zIndex: 99,
													borderRadius: "50px",
													height: "25px",
													width: "25px",
												}}
												role={"button"}
												onClick={() => {
													showDeleteConfirmModal("Are you sure you want to delete the file?", () => {
														deleteFile(Number(field.pipeline_phase_tab_id), field.tab_panels_fields_id, file.id);
													});
												}}
											>
												<SVGICON src={CloseIcon} className="svg-icon svg-icon-1 svg-icon-grey-800" />
											</div>
										</div>
									);
								})}
							</div>
						</div>
					</div>
					{field.is_required === 1 && field.value_json.length === 0 && (
						<div className="text-danger mt-2">
							<span role="alert">{field.name + " " + intl.formatMessage({ id: "COMMOM_IS_REQUIRED" })}</span>
						</div>
					)}
				</div>
			) : field.type === "longtext" ? (
				<div>
					<SunEditorComponent
						key={field.value} // Ensure the editor re-renders with new content
						initHtml={formik.values.longText || ""}
						handleBlur={(html) => {
							const updatedValue = html.replace(/^\s+|\s+$/g, ""); // Remove leading/trailing whitespace
							if (updatedValue || updatedValue !== "<p><br></p>") {
								if (field.value !== updatedValue) {
									if (field.value !== null || (updatedValue !== "<p><br></p>" && updatedValue !== "")) {
										formik.setFieldValue("longText", updatedValue);
										onSubmitHandle?.(field.tab_panels_fields_id, updatedValue);
										formik.handleSubmit();
									}
								}
							}
						}}
						isDiasbled={type === "edit" && !isDisabled ? false : true}
						wrapperPosition={field.id}
						loading={false}
						hideSubmit={false}
					/>
					{field.is_required === 1 && !field.value && (
						<div className="text-danger mt-2">
							<span role="alert">{field.name + " " + intl.formatMessage({ id: "COMMOM_IS_REQUIRED" })}</span>
						</div>
					)}
				</div>
			) : field.type === "date" ? (
				<div>
					<DateTimePicker
						onDateChange={(date) => {}}
						onCalendarClose={(date) => {
							const newDate = date
								? moment(date).format("YYYY-MM-DD")
								: field.value
									? moment(field.value).format("YYYY-MM-DD")
									: ""; // Handle empty date
							if (field.value !== newDate) {
								if (field.value !== null || newDate !== "") {
									formik.setFieldValue("dateDetails", newDate);
									onSubmitHandle?.(field.tab_panels_fields_id, newDate);
									formik.handleSubmit();
								}
							}
						}}
						// @ts-ignore
						selectedDate={
							formik.values.dateDetails && !isNaN(Date.parse(formik.values.dateDetails))
								? new Date(formik.values.dateDetails) // Set valid date
								: null // Handle empty or invalid date
						}
						placeholder={field?.placeholder && field?.placeholder}
						hideTimeSelect
						type={"date"}
						noMinDate
						//minDate={moment().subtract(2, "months").toDate()}
						disabledRes={type === "edit" && !isDisabled ? false : true}
					/>

					{field.is_required === 1 && !field.value && (
						<div className="text-danger mt-2">
							<span role="alert">{field.name + " " + intl.formatMessage({ id: "COMMOM_IS_REQUIRED" })}</span>
						</div>
					)}
				</div>
			) : field.type === "dropdown" ? (
				<div key={field?.tab_panels_fields_id}>
					<DropDown
						id="tab-parent-dropdown"
						multiSelect
						// @ts-ignore
						items={dropdownOptions || []}
						// @ts-ignore
						selectedItems={selectDropdown}
						onSelectItem={(item) => {
							//   @ts-ignore
							handleOnSelectItem(item);
						}}
						// @ts-ignore
						displayFunc={(item) => {
							return item.name;
						}}
						onMultiSelectBlur={() => {
							// formik.handleSubmit();
							// onSubmitHandle?.(
							//   field.tab_panels_fields_id,
							//   JSON.stringify(selectDropdown)
							// );
						}}
						disabled={type === "edit" && !isDisabled ? false : true}
						searchPlaceholder={field?.placeholder && field?.placeholder}
					/>
					{field.is_required === 1 && selectDropdown.length === 0 && (
						<div className="text-danger mt-2">
							<span role="alert">{field.name + " " + intl.formatMessage({ id: "COMMOM_IS_REQUIRED" })}</span>
						</div>
					)}
				</div>
			) : field.type === "radio" ? (
				<>
					<div className="d-flex flex-wrap">
						{field.options?.map((option: { name: string }, index) => (
							<div
								className="form-check-custom mt-2"
								style={{
									marginRight: "20px",
								}}
								key={index}
							>
								<input
									id={`${option.name + "_" + field.tab_panels_fields_id}`}
									className="form-check-input cursor-pointer me-2"
									type="radio"
									name={`${field.name + "_" + field.tab_panels_fields_id}`}
									checked={field.value === option.name ? true : false}
									onChange={() => {
										// If the current field.value is the same as the option.name, set it to empty string to uncheck
										const newValue = field.value === option.name ? "" : option.name;
										formik.handleSubmit();
										onSubmitHandle?.(field.tab_panels_fields_id, newValue);
									}}
									disabled={type === "edit" && !isDisabled ? false : true}
								/>
								<label
									//htmlFor={`${option.name + "_" + field.tab_panels_fields_id}`}
									className={"fw-bold fs-6 me-2"}
									style={{
										width: "60px",
										whiteSpace: "nowrap",
										textOverflow: "ellipsis",
										overflow: "hidden",
									}}
								>
									{option.name}
								</label>
							</div>
						))}
					</div>
					<div>
						{field.is_required === 1 && !field.value && (
							<div className="text-danger mt-2">
								<span role="alert">{field.name + " " + intl.formatMessage({ id: "COMMOM_IS_REQUIRED" })}</span>
							</div>
						)}
					</div>
				</>
			) : field.type === "checkbox" ? (
				<>
					<div className="d-flex flex-wrap">
						{field.options?.map((option: { name: string }, index) => {
							return (
								<div
									className="form-check form-check-sm form-check-custom form-check-solid mt-2"
									style={{
										marginRight: "20px",
									}}
									key={index}
									//  onBlur={() => {
									//     formik.handleSubmit();
									//     onSubmitHandle?.(
									//       field.tab_panels_fields_id,
									//       checkedBoxes && checkedBoxes.length > 0
									//         ? JSON.stringify(checkedBoxes)
									//         : null
									//     );
									//   }}
								>
									<input
										onChange={(e) => {
											handleOnCheckItem(option);
										}}
										className="form-check-input widget-9-check me-2"
										type="checkbox"
										id={field.name + "_" + field.tab_panels_fields_id + "_" + option.name}
										checked={isChecked(option)}
										onBlur={() => {
											formik.handleSubmit();
											onSubmitHandle?.(
												field.tab_panels_fields_id,
												checkedBoxes && checkedBoxes.length > 0 ? JSON.stringify(checkedBoxes) : null
											);
										}}
										disabled={type === "edit" && !isDisabled ? false : true}
									/>
									<label
										className={"fw-bold fs-6 me-2"}
										style={{
											width: "80px",
											whiteSpace: "nowrap",
											textOverflow: "ellipsis",
											overflow: "hidden",
										}}
										// htmlFor={
										//   field.name +
										//   "_" +
										//   field.tab_panels_fields_id +
										//   "_" +
										//   option.name
										// }
									>
										{option.name}
									</label>
								</div>
							);
						})}
					</div>
					<div>
						{field.is_required === 1 && !field.value && (
							<div className="text-danger mt-2">
								<span role="alert">{field.name + " " + intl.formatMessage({ id: "COMMOM_IS_REQUIRED" })}</span>
							</div>
						)}
					</div>
				</>
			) : field.type === "number" ? (
				<div>
					<input
						type="text"
						className="form-control form-control-lg form-control-solid"
						disabled={type === "edit" && !isDisabled ? false : true}
						placeholder={field?.placeholder && field?.placeholder}
						{...formik.getFieldProps("numberBox")}
						onBlur={(e) => {
							if (/^-?\d*\.?\d*$/.test(e.target.value)) {
								if (field.value !== e.target.value) {
									if (field.value !== null || e.target.value !== "") {
										formik.handleSubmit();
										onSubmitHandle?.(field.tab_panels_fields_id, e.target.value);
									}
								}
							}
						}}
						onChange={(e) => {
							const value = e.target.value;
							// Allow only digits, optional decimal, and negative sign
							if (/^-?\d*\.?\d*$/.test(value)) {
								formik.setFieldValue("numberBox", value);
								// Auto-detect changes due to autofill and trigger API call
								if (document.activeElement?.matches("input:-webkit-autofill")) {
									formik.handleSubmit();
									onSubmitHandle?.(field.tab_panels_fields_id, value);
								}
							}
						}}
					/>
					{/* {console.log(field)} */}
					{field.is_required === 1 && !field.value && (
						<div className="text-danger mt-2">
							<span role="alert">{field.name + " " + intl.formatMessage({ id: "COMMOM_IS_REQUIRED" })}</span>
						</div>
					)}
				</div>
			) : field.type === "link" ? (
				<div>
					<div>
						<div className="input-group">
							<input
								type="text"
								className="form-control form-control-lg form-control-solid"
								disabled={type === "edit" && !isDisabled ? false : true}
								placeholder={field?.placeholder && field?.placeholder}
								{...formik.getFieldProps("link")}
								onBlur={(e) => {
									const updatedValue = e.target.value;

									if (field.value !== updatedValue) {
										if (field.value !== null || updatedValue !== "") {
											if (updatedValue.length > 0) {
												if (!formik.errors.link) {
													formik.handleSubmit();
													onSubmitHandle?.(field.tab_panels_fields_id, updatedValue);
												}
											} else {
												formik.handleSubmit();
												onSubmitHandle?.(field.tab_panels_fields_id, updatedValue);
											}
										}
									}
								}}
								onChange={(e) => {
									formik.setFieldValue("link", e.target.value);
								}}
							/>
							<div
								className={clsx("input-group-text border", "toolip_copy_container", !field.value && "disabled")}
								role="button"
								onClick={() => {
									try {
										if (field.value && !formik.errors.link) {
											navigator.clipboard.writeText(field.value);
											successToast(
												intl.formatMessage({
													id: "FIELD_TYPE_LINK_COPY_SUCCESS",
												})
											);
										} else {
											warningToast(
												intl.formatMessage({
													id: "FIELD_TYPE_LINK_COPY_FAILURE",
												})
											);
										}
									} catch (e) {}
								}}
							>
								<span>
									<SVGICON src={CopyIcon} className="svg-icon svg-icon-5" />
								</span>
								<Tooltip id="link-field-tooltip-copy" anchorSelect=".toolip_copy_container">
									{intl.formatMessage({
										id: "FIELD_TYPE_LINK_COPY",
									})}
								</Tooltip>
							</div>
							<div
								className={clsx("input-group-text border", "toolip_goto_container", !field.value && "disabled")}
								role="button"
								onClick={() => field.value && !formik.errors.link && window.open(field.value, "_blank")}
							>
								<span>
									<SVGICON src={GoToIcon} className="svg-icon svg-icon-8" />
								</span>
								<Tooltip id="link-field-tooltip-goto" anchorSelect=".toolip_goto_container">
									{intl.formatMessage({
										id: "FIELD_TYPE_LINK_GOTO",
									})}
								</Tooltip>
							</div>
						</div>
					</div>
					{formik?.errors?.link && (
						<div className="text-danger mt-2">
							<span role="alert">{formik.errors.link}</span>
						</div>
					)}
					{field.is_required === 1 && !field.value && (
						<div className="text-danger mt-2">
							<span role="alert">{field.name + " " + intl.formatMessage({ id: "COMMOM_IS_REQUIRED" })}</span>
						</div>
					)}
				</div>
			) : field.type === "linkButton" ? (
				<div className="d-flex align-items-center mt-4">
					<button
						type="button"
						className={clsx("btn btn-primary min-w-100px", type === "edit" && !isDisabled ? "" : "pe-none")}
						onClick={() => type==="edit" && !isDisabled && field.data && window.open(field.data, "_blank")}
					>
						{field.name}
					</button>
					{field?.tooltip && (
						<div
							style={{
								position: "relative",
								cursor: "pointer",
							}}
						>
							<span data-tooltip-id="my-preview-tooltip" data-tooltip-content={field.tooltip} data-tooltip-place="top">
								<SVG src={InfoIcon} className="svg-icon ms-2" />
							</span>

							<Tooltip
								id="my-preview-tooltip"
								place="top"
								style={{
									padding: "30px !important",
									color: "black",
									backgroundColor: "#e9ecef",
									zIndex: 999,
									whiteSpace: "normal",
									maxWidth: "250px",
									wordWrap: "break-word",
									height: "fit-content",
								}}
								className="btn text-dark"
								border="1px solid #6c757d"
							/>
						</div>
					)}
				</div>
			) : null}
			 {/* {isOpen && (
					<Lightbox
					mainSrc={imageFiles[currentImageIndex]?.url}
					nextSrc={imageFiles[(currentImageIndex + 1) % imageFiles.length]?.url}
					prevSrc={
						imageFiles[(currentImageIndex + imageFiles.length - 1) % imageFiles.length]
						?.url
					}
					onCloseRequest={() => {
						setCurrentImageIndex(0)
						setIsOpen(false)}}
					onMovePrevRequest={() =>
						setCurrentImageIndex((currentImageIndex + imageFiles.length - 1) %imageFiles.length)
					}
					onMoveNextRequest={() =>
						setCurrentImageIndex((currentImageIndex + 1) % imageFiles.length)
					}
					/>
				)} */}
			{previewFileURL && (
				<div>
					<ProductFilePreviewModal
						show={showPreviewModal}
						previewFileURL={previewFileURL}
						previewFileExtension={previewFileExtension}
						closeModal={closePreviewModal}
					/>
				</div>
			)}
		</div>
	);
};
