import React, { FC, useEffect, useState } from "react";

// images
import BlankAvatar from "../../../../_metronic/assets/icons/blankAvatar.svg";

// custom
// import Constants from "../../../config/Constants";
// import SelectFileModal from "../../../sharedComponents/selectFileModal/selectFileModal";
// import { FileType } from "../../filemanager/types/getFilemanagerDataResponseType";
// import {
//   errorToast,
//   successToast,
// } from "../../../sharedComponents/toasts/Toasts";
import { useIntl } from "react-intl";

import { RootState } from "../../../../setup";
import { useSelector } from "react-redux";
import { RoleType } from "../../RolesAndPermissions/types/getRolesResponseType";
import { enablePermissionForCrud } from "../../../utils/PermisisionEnabledForResource";
import { SVGICON } from "../../../../_metronic/helpers";
import CloseIcon from "../../../../_metronic/assets/icons/close.svg";


import { deleteConfirmAlert } from "../../../sharedComponents/Alert";
// import {getResolutionFileUrl} from '../../../utils/getResolutionFile'
import { UserType } from "../../userManagement/types/getUsersListResponseType";
import {
  // deleteProfilePicture,
  uploadCustomerProfilePicture,
  uploadProfilePicture,
} from "../redux";
import {
  errorToast,
  successToast,
} from "../../../sharedComponents/toasts/Toasts";

interface Props {
  customer: UserType;
  getCustomerDetailsAPI: () => void;
}

// interface FileUploadType {
//   file: FileType;
//   status?: "success" | "failure" | "loading";
// }

const UploadEventTypeFile: FC<Props> = ({
  customer,
  getCustomerDetailsAPI,
}) => {
  const userRoleDetails = useSelector<RootState>(
    // @ts-ignore
    (state) => state.auth.roleDetails
  ) as RoleType;
  const crudPermission: any = enablePermissionForCrud(
    userRoleDetails,
    "contacts"
  );

  // const [mainFile, setMainFile] = useState<FileUploadType | undefined>();
  // const [showFileSelectModal, setShowFileSelectModal] =
  //   useState<boolean>(false);
  const intl = useIntl();
  const [profilePhotoUrl, setProfilePhotoUrl] = useState<any>();

  useEffect(() => {
    setProfilePhotoUrl(customer?.profile_photo_url);
  }, [customer]);

  // const closeMainFileModal = () => {
  //   setShowFileSelectModal(false);
  // };

  // const onFileSelect = (file: any) => {
  //   closeMainFileModal();
  //   setMainFile({ file, status: "loading" });
  //   uploadCustomerProfilePicture(customer?.id, file.id)
  //     .then(() => {
  //       setMainFile({ file, status: "success" });
  //       successToast(
  //         intl.formatMessage({ id: "CUSTOMER_UPLOAD_PROFILEPIC_SUCCESS" })
  //       );
  //     })
  //     .catch(() => {
  //       setMainFile({ file, status: "failure" });
  //       errorToast(
  //         intl.formatMessage({ id: "CUSTOMER_UPLOAD_PROFILEPIC_FAILURE" })
  //       );
  //     })
  //     .finally(() => {
  //       getCustomerDetailsAPI?.();
  //     });
  // };

  const removeMainFile = (type?: string) => {
    uploadCustomerProfilePicture(customer?.id)
      .then(() => {
        setProfilePhotoUrl(undefined);
        successToast(intl.formatMessage({ id: "CUSTOMER_LOGO_REMOVED_SUCCESS_MESSAGE" }));
      })
      .catch((error) => console.error(error?.response?.data?.message))
      .finally(() => getCustomerDetailsAPI?.());
  };

  const handleProfileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;
    const selectedFiles = files as FileList;
    setProfilePhotoUrl(URL.createObjectURL(selectedFiles?.[0]));
    const formData = new FormData();
    formData.append("profile_photo", selectedFiles?.[0]);

    uploadProfilePicture(customer?.id, formData)
      .then(() => {
        successToast(intl.formatMessage({ id: "CUSTOMERS_LOGO_ADDED_SUCCESS_MESSAGE" }));
      })
      .catch(() => {
        errorToast(intl.formatMessage({ id: "USERS_ADDED_FAILURE_MESSAGE" }));
      })
      .finally(() => {
        getCustomerDetailsAPI();
        event.target.value = "";
      });
  };
  // const handleProfileDelete = () => {
  //   deleteProfilePicture(customer?.id)
  //     .then(() => {
  //       setFile(null);
  //     })
  //     .catch(() => {})
  //     .finally(() => {
  //       getCustomerDetailsAPI();
  //     });
  // };

  return (
    <div className="card card-flush border-0">
      {/* {showFileSelectModal && (
        <SelectFileModal
          show={showFileSelectModal}
          closeModal={closeMainFileModal}
          onSelectFile={onFileSelect}
          allowedMimetypes={Constants.mimeTypes.image}
          isMultiple={false}
          isSelectionModal={true}
        />
      )} */}

      <div className="card-header px-0">
        <div className="card-title mt-0">
          <h3 className="fw-bolder m-0">
            {intl.formatMessage({
              id: "CUSTOMER_UPLOAD_PROFILEPIC_SECTION_TITLE",
            })}
          </h3>
        </div>
      </div>

      <div className="card-body pt-2 pb-0 px-0">
        <div className="d-flex justify-content-start mb-3">
          <div className="image-input image-input-outline">
            <img
              // src={mainFile?.file.file_path || event.event_image_url || BlankAvatar}
              src={
                profilePhotoUrl ? profilePhotoUrl : BlankAvatar || BlankAvatar
              }
              alt="img"
              style={{ objectFit: "cover" }}
              className="image-input-wrapper w-125px h-125px"
              draggable={false}
            />

            {crudPermission?.edit && (
              <label
                className="position-absolute btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow-sm"
                style={{
                  right: -10,
                  bottom: -10,
                  zIndex: 99,
                  borderRadius: "50px",
                  height: "25px",
                  width: "25px",
                }}
                data-kt-image-input-action="change"
                data-bs-toggle="tooltip"
                title=""
                data-bs-original-title="Change avatar"
                htmlFor="file"
              >
                <input
                  type="file"
                  id="file"
                  accept="image/jpeg,image/jpg,image/png"
                  multiple={false}
                  data-original-title="upload photos"
                  style={{ display: "none" }}
                  onChange={(e) => {
                    handleProfileUpload(e);
                  }}
                />
                <i className="bi bi-pencil-fill fs-9"></i>
              </label>
            )}
            {crudPermission?.edit && profilePhotoUrl ? (
              <div
                className="position-absolute btn btn-icon btn-circle btn-active-color-danger w-25px h-25px bg-body shadow-sm"
                style={{
                  right: -10,
                  bottom: -10,
                  zIndex: 99,
                  borderRadius: "50px",
                  height: "25px",
                  width: "25px",
                }}
                role={"button"}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  deleteConfirmAlert(
                    intl.formatMessage({
                      id: "CUSTOMER_PROFILE_CONFIRM_DELETE_MESSAGE",
                    }),
                    (data) => {
                      if (data === "Yes") removeMainFile();
                    }
                  );
                }}
              >
                <SVGICON src={CloseIcon} className="svg-icon svg-icon-1 svg-icon-grey-800" />
              </div>
            ) : null}
          </div>
        </div>
        <div className="text-muted fs-7 text-start mt-3">
          {intl.formatMessage({
            id: "MASTERDATA_SALES_LAYOUT_LOGO_ALLOWED_MESSAGE",
          })}
        </div>
      </div>
    </div>
  );
};

export default UploadEventTypeFile;
