import React, { FC, useState } from "react";
import { PipelineType } from "../types/PipelinesResponseType";
import { Button, Modal } from "react-bootstrap";
import * as Yup from "yup";
import { useIntl } from "react-intl";
import { useFormik } from "formik";
import { createPipeline, updatePipelines } from "../redux/PipelinesAPI";
import clsx from "clsx";
import DropDown from "../../../sharedComponents/dropdown/Dropdown";
import { successToast } from "../../../sharedComponents/toasts/Toasts";
import CloseButton from "../../../sharedComponents/Buttons/CloseButton";

interface Props {
  show: boolean;
  type: string;
  closeModal: () => void;
  getPipelines: () => void;
  pipeline: PipelineType | undefined;
}

const AddPipelineModal: FC<Props> = ({
  show,
  type,
  closeModal,
  getPipelines,
  pipeline,
}) => {
  const intl = useIntl();
  const PIPELINE_TRANSITION_TYPE_OPTIONS = JSON.parse(
    intl.formatMessage({ id: "PIPELINE_TRANSITION_TYPE_OPTIONS" })
  ) as {
    id: number;
    name: string;
    title: string;
  }[];
  const PIPELINE_STATUS_TYPE_OPTIONS = JSON.parse(
    intl.formatMessage({ id: "PIPELINE_STATUS_TYPE_OPTIONS" })
  ) as {
    id: number;
    name: string;
    title: string;
  }[];

  //   state
  const [loadng, setLoading] = useState(false);

  const initialValues = {
    name: type === "edit" ? pipeline?.name || "" : "",
    transition_type: "",
    status:
      type === "edit"
        ? pipeline?.status || ""
        : PIPELINE_STATUS_TYPE_OPTIONS[1].name,
  };

  const AddNewPipelineSchema = Yup.object().shape({
    name: Yup.string()
      .max(60, intl.formatMessage({ id: "MASTERDATA_60SYMBOLS_REQUIRED" }))
      .required(intl.formatMessage({ id: "PROJECTS_PIPELINE_NAME_REQUIRED" })),
    transition_type: Yup.string().required(
      intl.formatMessage({ id: "PROJECTS_PIPELINE_TRANSITION_TYPE_REQUIRED" })
    ),
    status: Yup.string().required(
      intl.formatMessage({ id: "PROJECTS_PIPELINE_STATUS_REQUIRED" })
    ),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: AddNewPipelineSchema,
    enableReinitialize: true,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      if (type === "new") {
        if (values.name) {
          setLoading(true);
          createPipeline(values.name, values.transition_type, values.status)
            .then(() => {
              closeModal();
              successToast(
                intl.formatMessage({
                  id: "PROJECTS_PIPELINE_ADD_SUCCESS_MESSAGE",
                })
              );
            })
            .catch((err) => {
              err.response?.data?.errors?.name
                ? setStatus(err.response.data?.errors?.name)
                : setStatus(
                    intl.formatMessage({
                      id: "PROJECTS_PIPELINE_ADD_FAILURE_MESSAGE",
                    })
                  );
            })
            .finally(() => {
              // recall  get tags list API
              getPipelines();
              setLoading(false);
            });
        }
      } else {
        // if (values.name && pipeline?.id) {
        //   setLoading(true);
        //   updatePipelines(pipeline.id, values.name)
        //     .then(() => {
        //       closeModal();
        //       SuccessAlert(
        //         intl.formatMessage({
        //           id: "PROJECTS_PIPELINE_UPDATE_SUCCESS_MESSAGE",
        //         })
        //       );
        //     })
        //     .catch((err) => {
        //       err.response?.data?.errors?.name
        //         ? setStatus(err.response.data?.errors?.name)
        //         : setStatus(
        //             intl.formatMessage({
        //               id: "PROJECTS_PIPELINE_UPDATE_FAILURE_MESSAGE",
        //             })
        //           );
        //     })
        //     .finally(() => {
        //       // recall  get tags list API
        //       getPipelines();
        //       setLoading(false);
        //     });
        // }
      }
    },
  });

  return (
    <Modal
      show={show}
      centered
      contentClassName={loadng ? "pe-none" : ""}
      dialogClassName={"medium-size-modal"}
      className="kt-modal"
      backdrop="static"
      onHide={closeModal}
    >
      <Modal.Header>
        <Modal.Title>
          {type === "edit"
            ? intl.formatMessage({ id: "PROJECTS_PIPELINE_EDIT_TITLE" })
            : intl.formatMessage({ id: "PROJECTS_PIPELINE_ADD_NEW_BUTTON" })}
        </Modal.Title>
        <CloseButton onClose={closeModal} />
      </Modal.Header>
      <form
        className="form w-100 overflow-auto"
        onSubmit={formik.handleSubmit}
        noValidate
        id="kt_add_category_form"
      >
        <Modal.Body className="overflow-inherit">
          {formik.status && (
            <div className="mb-10 alert alert-danger">
              <div className="alert-text font-weight-bold">{formik.status}</div>
            </div>
          )}

          {/* Pipeline Name */}
          <div className="tab-field">
            <label className="d-flex align-items-center tab-field-label">
              <span className="required">
                {intl.formatMessage({
                  id: "PROJECTS_PIPELINE_NAME_FIELD",
                })}
              </span>
            </label>

            <input
              placeholder={intl.formatMessage({
                id: "PROJECTS_PIPELINE_NAME_FIELD_PLACEHOLDER",
              })}
              {...formik.getFieldProps("name")}
              className={clsx(
                "form-control form-control-lg form-control-solid"
              )}
              name="name"
              autoComplete="off"
            />
            {formik.touched.name && formik.errors.name && (
              <div className="text-danger">
                <span role="alert">{formik.errors.name}</span>
              </div>
            )}
          </div>

          {/* Transition Type */}
          <div className="tab-field mb-0">
            <label className="d-flex align-items-center tab-field-label">
              <span className="required">
                {intl.formatMessage({ id: "PIPELINE_ADD_TRANSITION_TITLE" })}
              </span>
            </label>

            <div className="fv-row">
              <DropDown
                hideSearch
                id="users-titles-dropdown"
                items={PIPELINE_TRANSITION_TYPE_OPTIONS}
                displayFunc={(item) => {
                  // @ts-ignore
                  return item.title;
                }}
                displayValue={
                  PIPELINE_TRANSITION_TYPE_OPTIONS.find(
                    (TITLES_OPTION) =>
                      TITLES_OPTION.name === formik.values.transition_type
                  )?.title || ""
                }
                onSelectItem={(item) => {
                  //@ts-ignore
                  formik.setFieldValue("transition_type", item.name);
                }}
                selectedItem={PIPELINE_TRANSITION_TYPE_OPTIONS.find(
                  (TITLES_OPTION) =>
                    TITLES_OPTION.name === formik.values.transition_type
                )}
              />
              {formik.touched.transition_type &&
                formik.errors.transition_type && (
                  <div className="text-danger">
                    <span role="alert">{formik.errors.transition_type}</span>
                  </div>
                )}
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer className="justify-content-center">
          <Button
            variant="secondary"
            onClick={() => {
              closeModal();
            }}
          >
            {intl.formatMessage({ id: "CLOSE_BUTTON" })}
          </Button>
          <Button
            variant="primary"
            type="submit"
            disabled={formik.isSubmitting}
          >
            {!loadng && intl.formatMessage({ id: "SUBMIT_BUTTON" })}
            {loadng && (
              <span className="indicator-progress" style={{ display: "block" }}>
                {intl.formatMessage({ id: "MASTERDATA_TAGS_LOADING_MESSAGE" })}{" "}
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            )}
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default AddPipelineModal;
