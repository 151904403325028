import { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// images
// import EditIcon from '../../../../_metronic/assets/icons/edit.svg'
import DeleteIcon from "../../../../_metronic/assets/icons/delete.svg";
import SearchIcon from "../../../../_metronic/assets/icons/search.svg";
import PlusIcon from "../../../../_metronic/assets/icons/plus.svg";

// custom
import { SVGICON } from "../../../../_metronic/helpers";
import notFound from "../../../../_metronic/assets/icons/notFound.png";
import { useGlobalModalContext } from "../../../sharedComponents/modals/ModalContext";
import { actions, deleteRole, getRolesList } from "../redux";
import { RoleType } from "../types/getRolesResponseType";
import EditRoleModal from "./EditRoleModal";
import AddNewRoleModal from "./AddNewRoleModal";
import { RootState } from "../../../../setup";
import { ResourceType } from "../types/getResourcesListResponseType";
import { useIntl } from "react-intl";
import NoItemsFound from "../../../sharedComponents/NoItemsFound/NoItemsFound";
import {
  errorToast,
  successToast,
} from "../../../sharedComponents/toasts/Toasts";
import { MoreSymbol } from "../../tasks/components/UserSymbol";
import TaskResponsibleUsersModal from "../../tasks/components/TaskResponsibleUsersModal";

interface Props {}

interface EditModalProps {
  show: boolean;
  role: RoleType | undefined;
}

const UserRolesList: FC<Props> = () => {
  // selectors
  const allRoles = useSelector<RootState>(
    // @ts-ignore
    ({ rolesAndPermissionsManagement }) => rolesAndPermissionsManagement?.roles
  ) as RoleType[];

  const dispatch = useDispatch();
  const intl = useIntl();
  let RESOURCES_TYPES = JSON.parse(
    intl.formatMessage({ id: "RESOURCES_TYPES" })
  ) as ResourceType[];

  // state
  const [displayRolesList, setDisplayRolesList] = useState<RoleType[]>([]);
  const [searchText, setSearchText] = useState<string>("");

  const [showEditUserRoleModal, setShowEditUserRoleModal] =
    useState<EditModalProps>({
      show: false,
      role: undefined,
    });
  const [showAllUsersModal, setShowAllUsersModal] = useState<{
    show: boolean;
    users: any;
  }>({
    show: false,
    users: undefined,
  });

  const [showAddRoleModal, setShowAddRoleModal] = useState<boolean>(false);
  const brandLightBlueTitle = "#BAD9FF";

  // context
  const {
    showDeleteConfirmModal,
    hideDeleteConfirmModal,
    setDeleteModalLoading,
  } = useGlobalModalContext();

  const updateDisplayRolesList = () => {
    const updatedRolesList = allRoles?.filter((role) => {
      if (
        (role.name || "")
          .toLocaleLowerCase()
          .includes((searchText || "").toLocaleLowerCase())
      ) {
        return true;
      }
      return false;
    });
    // save updatedlist to display
    setDisplayRolesList(updatedRolesList);
  };

  const getRolesListAPI = () => {
    getRolesList()
      .then(({ data: { rolesDetails = [] } }) => {
        dispatch(actions.setRolesList(rolesDetails));
      })
      .catch((error) => console.error(error?.response?.data?.message));
  };

  useEffect(() => {
    getRolesListAPI();
  }, []);

  useEffect(() => {
    updateDisplayRolesList();
  }, [allRoles, searchText]);

  const onSearchTextChange = (text: string) => {
    setSearchText((text || "").trim());
  };

  const deleteExistingRole = (role: RoleType) => {
    setDeleteModalLoading(true);
    deleteRole(role.id)
      .then(() => {
        successToast(
          intl.formatMessage({
            id: "ROLES_PERMISSIONS_ROLE_DELETE_SUCCESS_MESSAGE",
          })
        );
      })
      .catch(() => {
        errorToast(
          intl.formatMessage({
            id: "ROLES_PERMISSIONS_ROLE_DELETE_ERROR_MESSAGE",
          })
        );
      })
      .finally(() => {
        setDeleteModalLoading(false);
        hideDeleteConfirmModal();
        getRolesListAPI();
      });
  };

  const getFormattedPermissionsForRole = (role: RoleType) => {
    const resourcePermissions: string[] = [];
    if (role.id === 1) {
      RESOURCES_TYPES.forEach((resource) => {
        resourcePermissions.push(
          `${intl.formatMessage({
            id: "ROLES_AND_PERMISSIONS_ALL_CONTROLS_MESSAGE",
          })} ${intl.formatMessage({
            id: "ROLES_AND_PERMISSIONS_TO_MESSAGE",
          })} ${resource.name}`
        );
      });
    }

    const obj: any = {}; // initialize object
    role.resources?.forEach((resource) => {
      const resource_name = RESOURCES_TYPES.find(
        (obj) => obj?.slug === resource?.resource_slug
      )?.name;
      if (resource_name) {
        if (!obj[resource_name]) {
          obj[resource_name] = [];
        }
        obj[resource_name].push(resource.permission_id);
      }
    });

    // generate permission string for each resource
    Object.keys(obj).forEach((key) => {
      let permission_str = "";
      let counter = 0;
      const permissions_ids: number[] = obj[key] || [];
      // read permission
      if (permissions_ids.length === 4) {
        permission_str =
          permission_str +
          intl.formatMessage({
            id: "ROLES_AND_PERMISSIONS_ALL_CONTROLS_MESSAGE",
          });
      } else {
        if (permissions_ids.includes(1)) {
          counter++;
          permission_str =
            permission_str +
            intl.formatMessage({ id: "ROLES_AND_PERMISSIONS_VIEW_MESSAGE" });
        }
        if (permissions_ids.includes(2)) {
          counter++;
          permission_str =
            permission_str +
            `${
              counter === permissions_ids.length
                ? ` ${intl.formatMessage({
                    id: "ROLES_AND_PERMISSIONS_AND_MESSAGE",
                  })} ${intl.formatMessage({
                    id: "ROLES_AND_PERMISSIONS_CREATE_MESSAGE",
                  })}`
                : `, ${intl.formatMessage({
                    id: "ROLES_AND_PERMISSIONS_CREATE_MESSAGE",
                  })}`
            }`;
        }
        if (permissions_ids.includes(3)) {
          counter++;
          permission_str =
            permission_str +
            `${
              counter === permissions_ids.length
                ? ` ${intl.formatMessage({
                    id: "ROLES_AND_PERMISSIONS_AND_MESSAGE",
                  })} ${intl.formatMessage({
                    id: "ROLES_AND_PERMISSIONS_EDIT_MESSAGE",
                  })}`
                : `, ${intl.formatMessage({
                    id: "ROLES_AND_PERMISSIONS_EDIT_MESSAGE",
                  })}`
            }`;
        }
        if (permissions_ids.includes(4)) {
          permission_str =
            permission_str +
            ` ${intl.formatMessage({
              id: "ROLES_AND_PERMISSIONS_AND_MESSAGE",
            })} ${intl.formatMessage({
              id: "ROLES_AND_PERMISSIONS_DELETE_MESSAGE",
            })}`;
        }
      }

      permission_str = permission_str + ` to ${key}`;
      resourcePermissions.push(permission_str);
    });
    return resourcePermissions;
  };

  return (
    <div>
      {showEditUserRoleModal.show && (
        <EditRoleModal
          // @ts-ignore
          modalProps={showEditUserRoleModal}
          closeModal={() => {
            setShowEditUserRoleModal({
              show: false,
              role: undefined,
            });
          }}
          getRolesListAPI={getRolesListAPI}
        />
      )}

      {showAllUsersModal.show && (
        <TaskResponsibleUsersModal
          show={showAllUsersModal.show}
          closeModal={() => {
            setShowAllUsersModal({
              ...showAllUsersModal,
              show: false,
            });
          }}
          selectedUsers={showAllUsersModal?.users}
          isSelectionModal={false}
          fromUserRoles
        />
      )}

      {showAddRoleModal && (
        <AddNewRoleModal
          show={showAddRoleModal}
          closeModal={() => {
            setShowAddRoleModal(false);
          }}
          getRolesListAPI={getRolesListAPI}
        />
      )}

      {/* search roles */}
      <div className="card card-search d-flex flex-row justify-content-between align-items-center">
        <div className="d-flex align-items-center position-relative">
          <span className="position-absolute" style={{ left: "10px" }}>
            <SVGICON className="svg-icon svg-icon-1" src={SearchIcon} />
          </span>
          <input
            type="text"
            className="form-control form-control-md form-control-solid min-w-150px min-w-md-200px ps-11"
            placeholder={intl.formatMessage({
              id: "ROLES_AND_PERMISSIONS_SEARCH_PLACEHOLDER",
            })}
            onChange={(e) => {
              onSearchTextChange(e.target.value);
            }}
          />
        </div>

        {/* add new role */}
        <button
          className="btn  btn-primary"
          onClick={() => {
            setShowAddRoleModal(true);
          }}
        >
          <SVGICON src={PlusIcon} className="svg-icon-2" />
          {intl.formatMessage({
            id: "ROLES_PERMISSIONS_ADD_NEW_ROLE_TITLE",
          })}
        </button>
      </div>

      <div className="mt-18px card-mb">
        <div className="row mx-0 g-18px">
          {displayRolesList?.map((role, i) => {
            // display only top 4 resources
            const displayResources = (
              getFormattedPermissionsForRole(role) || []
            ).slice(0, 4);
            const remainingResources =
              (getFormattedPermissionsForRole(role) || []).length -
              displayResources.length;
            // display only top 8 resources
            const displayUsers = (role.users || []).slice(0, 8);
            const remainingUsers =
              (role.users || []).length - displayUsers.length;
            return (
              <div className="col-md-6 roles-card" key={i}>
                <div className="card h-100 card-p card-flush">
                  <div className="card-header card-header-search p-0 d-flex mb-2">
                    <div className="card-title">
                      <h2>{role.name}</h2>
                    </div>
                    <div className="card-toolbar my-0">
                      {/* super admin(role id :1) cannot be deleted */}
                      {(role?.count_of_users === 0) && (
                        <button
                          className="btn btn-sm btn-icon btn-light btn-active-light-danger"
                          onClick={() => {
                            showDeleteConfirmModal(
                              intl.formatMessage({
                                id: "ROLES_PERMISSIONS_ROLE_DELETE_CONFIRMATION_MESSAGE",
                              }),
                              () => {
                                deleteExistingRole(role);
                              }
                            );
                          }}
                        >
                          <SVGICON src={DeleteIcon} className="svg-icon-3" />
                        </button>
                      )}
                    </div>
                  </div>
                  <div className="card-body p-0">
                    <span className="fs-6 fw-bolder text-gray-800 d-block mb-2">
                      {intl.formatMessage({
                        id: "ROLE_RESOURCE_PERMISSIONS_TITLE",
                      })}
                    </span>
                    {role.id !== 1 &&
                      (!role.resources || role.resources.length === 0) && (
                        <div className="fw-bold text-gray-600 mb-5">
                          {intl.formatMessage({
                            id: "ROLE_RESOURCE_PERMISSIONS_EMPTY_TITLE",
                          })}
                        </div>
                      )}
                    {displayResources.length > 0 && (
                      <div className="d-flex flex-column text-gray-600">
                        {/* @ts-ignore */}
                        {displayResources?.map((permission, j) => {
                          return (
                            <div
                              className="d-flex align-items-center py-3"
                              key={j}
                            >
                              <span className="bullet bg-primary me-3"></span>
                              <span>{permission}</span>
                            </div>
                          );
                        })}

                        {remainingResources > 0 && (
                          <div className="d-flex align-items-center pt-3">
                            <span className="bullet bg-primary me-3"></span>
                            <em>{`and ${remainingResources} more...`}</em>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                  <div className="card-footer p-0 flex-wrap">
                    <div className="d-flex flex-wrap align-items-center justify-content-between mt-2">
                      <div>
                        {displayUsers && displayUsers.length > 0 && (
                          <div>
                            <div className="symbol-group symbol-hover flex-nowrap">
                              {displayUsers?.map((user, i) => {
                                return (
                                  <div
                                    key={i}
                                    className="symbol symbol-35px symbol-circle"
                                    // data-bs-toggle='tooltip'
                                    // data-kt-initialized='1'
                                    // title={user.name}
                                    style={{ position: "relative" }}
                                    onMouseOver={() => {
                                      const tooltipEle =
                                        document.getElementById(
                                          `role-user-tooltip-${user.email}`
                                        );
                                      if (tooltipEle) {
                                        tooltipEle.style.display = "flex";
                                      }
                                    }}
                                    onMouseOut={() => {
                                      const tooltipEle =
                                        document.getElementById(
                                          `role-user-tooltip-${user.email}`
                                        );
                                      if (tooltipEle) {
                                        tooltipEle.style.display = "none";
                                      }
                                    }}
                                  >
                                    <div
                                      id={`role-user-tooltip-${user.email}`}
                                      className="position-absolute bg-light px-3 py-2 fs-8"
                                      style={{
                                        display: "none",
                                        backgroundColor: "white",
                                        top: -60,
                                        borderRadius: 5,
                                      }}
                                    >
                                      {user.name}
                                    </div>
                                    {user.profile_picture_url ? (
                                      <img
                                        alt="Pic"
                                        src={user.profile_picture_url}
                                        style={{ objectFit: "cover" }}
                                      />
                                    ) : (
                                      <span
                                        className="symbol-label text-inverse-warning fw-bold"
                                        style={{
                                          backgroundColor: `${brandLightBlueTitle}`,
                                        }}
                                      >
                                        {(user.name || " ")[0].toUpperCase()}
                                      </span>
                                    )}
                                  </div>
                                );
                              })}
                              {remainingUsers > 0 && (
                                // <div className="symbol symbol-35px symbol-circle">
                                //   <span className="symbol-label btn-primary text-gray-300 fs-8 fw-bold">
                                //     +{remainingUsers}
                                //   </span>
                                // </div>
                                <MoreSymbol
                                  remainingUsers={remainingUsers}
                                  onClick={() => {
                                    setShowAllUsersModal({
                                      show: true,
                                      users: displayUsers,
                                    });
                                  }}
                                />
                              )}
                            </div>
                          </div>
                        )}
                      </div>

                      <button
                        className="btn btn-light btn-active-light-primary mt-2"
                        onClick={() => {
                          setShowEditUserRoleModal({
                            show: true,
                            role,
                          });
                        }}
                      >
                        {intl.formatMessage({
                          id: "ROLE_RESOURCE_PERMISSIONS_EDIT_ROLE",
                        })}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}

          {/* no data */}
          {displayRolesList?.length === 0 && (
            <table className="col-12 px-0 d-flex justify-content-center align-items-center mt-2">
              <tbody>
                <NoItemsFound languageKey="NO_ITEMS_FOUND" />
              </tbody>
            </table>
          )}
        </div>
      </div>
    </div>
  );
};

export default UserRolesList;
