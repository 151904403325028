import React, { FC, useState } from "react";
import { ProductActivityType, ProductType } from "../types/ProductsResponseType";
import { useFormik } from "formik";
import { createProductAcitivity } from "../redux/ProductsAPI";
import { useIntl } from "react-intl";
import { Button, Modal } from "react-bootstrap";
import DateTimePicker from "../../../sharedComponents/dateTimePicker/DateTimePicker";
import clsx from "clsx";
import SunEditorComponent from "../../../sharedComponents/textEditor/SunEditor";
import moment from "moment";
import { successToast } from "../../../sharedComponents/toasts/Toasts";
import DropDown from "../../../sharedComponents/dropdown/Dropdown";
import CloseButton from "../../../sharedComponents/Buttons/CloseButton";

interface Props {
	show: boolean;
	closeModal?: () => void;
	product: ProductType;
	getProductDetailsAPI: () => void;
	activity?: ProductActivityType | undefined;
}

const AddActivityModal: FC<Props> = ({ show, closeModal, product, getProductDetailsAPI, activity }) => {
	const intl = useIntl();
	// state
	const [loading, setLoading] = useState<boolean>(false);

	const INPUT_OPTIONS = JSON.parse(intl.formatMessage({ id: "PRODUCT_ACTIVITY_MODAL_INPUT_OPTIONS" })) as {
		id: number;
		name: string;
		title: string;
	}[];

	const formik = useFormik({
		initialValues: {
			date: (activity && new Date(activity.date)) || new Date(),
			price: (activity && activity.price) || "",
			units: (activity && activity.units) || "",
			notes: (activity && activity.notes) || "",
			input: (activity && activity?.input == 0 ? false : true) || false,
		},
		validate: (values) => {
			let errors = {};
			if (!values.date) {
				//@ts-ignore
				errors.date = intl.formatMessage({
					id: "PRODUCT_ACTIVITY_DATE_REQUIRED_MESSAGE",
				});
			}
			if (!values.price && values.input) {
				//@ts-ignore
				errors.price = intl.formatMessage({
					id: "PRODUCT_ACTIVITY_PRICE_REQUIRED_MESSAGE",
				});
			}
			if (!values.units) {
				//@ts-ignore
				errors.units = intl.formatMessage({
					id: "PRODUCT_ACTIVITY_UNITS_REQUIRED_MESSAGE",
				});
			}

			if (
				product &&
				values.units &&
				!values.input &&
				// @ts-ignore
				values.units > product.stock
			) {
				//@ts-ignore
				errors.units = intl.formatMessage({
					id: "PRODUCT_ACTIVITY_UNITS_MAX_REQUIRED_MESSAGE",
				});
			}
			//   if (!values.notes) {
			//     //@ts-ignore
			//     errors.notes = intl.formatMessage({
			//       id: "",
			//     });
			//   }
			//   if (!values.input) {
			//     //@ts-ignore
			//     errors.input = intl.formatMessage({
			//       id: "",
			//     });
			//   }
			return errors;
		},
		onSubmit: (values, { setStatus, setSubmitting }) => {
			setLoading(true);
			createProductAcitivity(
				product.id,
				moment(values.date).format("YYYY-MM-DD h:mm:ss"),
				values.units,
				values.notes,
				values.input ? "1" : "0",
				values.input ? values.price : null
			)
				.then(({ data: {} }) => {
					closeModal?.();

					successToast(intl.formatMessage({ id: "PRODUCT_ACTIVITY_ADD_SUCCESS_MESSAGE" }));
					// dispatch(actions.setProductsList(stripeProductDetails))
				})
				.catch((err) => {
					err.response?.data?.errors?.email
						? setStatus(err.response.data?.errors?.email)
						: setStatus(
								intl.formatMessage({
									id: "PRODUCT_ACTIVITY_ADD_FAILURE_MESSAGE",
								})
							);
				})
				.finally(() => {
					getProductDetailsAPI?.();
					setLoading(false);
				});
		},
	});
	return (
		<Modal
			show={show}
			centered
			dialogClassName="medium-size-modal"
			className="kt-modal"
			contentClassName={loading ? "pe-none" : ""}
			backdrop="static"
			onHide={closeModal}
		>
			<Modal.Header>
				<Modal.Title>{intl.formatMessage({ id: "PRODUCT_ACTIVITY_MODAL_TITLE" })}</Modal.Title>
				<CloseButton onClose={closeModal} />
			</Modal.Header>
			<form onSubmit={formik.handleSubmit} noValidate id="kt_login_signin_form" className="overflow-auto">
				<Modal.Body className="overflow-inherit">
					{formik.status && (
						<div className="mb-10 alert alert-danger">
							<div className="alert-text font-weight-bold">{formik.status}</div>
						</div>
					)}

					{/* input */}
					<div className="fv-row align-items-center tab-field">
						<label className="required tab-field-label">
							{intl.formatMessage({
								id: "PRODUCT_ACTIVITY_MODAL_INPUT_TITLE",
							})}
						</label>

						<DropDown
							hideSearch
							id="product-input"
							items={INPUT_OPTIONS}
							disabled={activity && activity.id ? true : false}
							displayFunc={(item) => {
								return item.name;
							}}
							size="medium"
							displayValue={
								INPUT_OPTIONS.find(
									//@ts-ignore
									(item) => item.value === formik.values.input
								)?.name || ""
							}
							onSelectItem={(item) => {
								//@ts-ignore
								formik.setFieldValue("input", item.value);
							}}
							selectedItem={INPUT_OPTIONS.find(
								//@ts-ignore
								(item) => item.value === formik.values.input
							)}
						/>

						{formik.touched.input && formik.errors.input && (
							<div className="text-danger mt-2">
								<span role="alert">{formik.errors.input}</span>
							</div>
						)}
						{activity && activity.id
							? null
							: !product.stock && (
									<div>
										<span className="text-muted">
											{intl.formatMessage({
												id: "PRODUCT_ACTIVITY_MODAL_INPUT_OUTPUT_STOCK_ERROR",
											})}
										</span>
									</div>
								)}
					</div>

					{/* date */}
					<div className="fv-row align-items-center tab-field">
						<label className="required tab-field-label">
							{intl.formatMessage({
								id: "PRODUCT_ACTIVITY_MODAL_DATE_TITLE",
							})}
						</label>

						<DateTimePicker
							onDateChange={(date) => {
								formik.setFieldValue("date", date);
							}}
							onCalendarClose={(date) => {}}
							// @ts-ignore
							selectedDate={formik.values.date}
							hideTimeSelect
							type={"date"}
							//minDate={moment().subtract(2, "months").toDate()}
							noMinDate
							disabledRes={activity && activity.id ? true : false}
						/>
						{formik.touched.date && formik.errors.date && (
							<div className="text-danger mt-2">
								<span role="alert">{formik.errors.date}</span>
							</div>
						)}
					</div>

					{/* price */}
					{formik.values.input && (
						<div className="align-items-center tab-field">
							<div className="">
								<label className="required tab-field-label">
									{intl.formatMessage({
										id: "PRODUCT_ACTIVITY_MODAL_PRICE_TITLE",
									})}
								</label>
								<input
									type="number"
									autoComplete="off"
									{...formik.getFieldProps("price")}
									className={clsx("form-control form-control-lg form-control-solid")}
									min={1}
									disabled={activity && activity.id ? true : false}
								/>

								{formik.touched.price && formik.errors.price && (
									<div className="text-danger">
										<span role="alert">{formik.errors.price}</span>
									</div>
								)}
							</div>
						</div>
					)}

					{/* units */}
					<div className="align-items-center tab-field">
						<div className="">
							<label className="required tab-field-label">
								{intl.formatMessage({
									id: "PRODUCT_ACTIVITY_MODAL_UNITS_TITLE",
								})}
							</label>
							<input
								type="number"
								autoComplete="off"
								{...formik.getFieldProps("units")}
								className={clsx("form-control form-control-lg form-control-solid")}
								min={1}
								disabled={activity && activity.id ? true : false}
							/>

							{formik.touched.units && formik.errors.units && (
								<div className="text-danger">
									<span role="alert">{formik.errors.units}</span>
								</div>
							)}
						</div>
					</div>

					{/* notes */}
					<div className="align-items-center tab-field mb-0">
						<div className="">
							<label className="tab-field-label">
								{intl.formatMessage({
									id: "PRODUCT_ACTIVITY_MODAL_NOTES_TITLE",
								})}
							</label>
							<SunEditorComponent
								initHtml={formik.values.notes || ""}
								handleBlur={(html) => {
									formik.setFieldValue("notes", html);
								}}
								wrapperPosition={0}
								loading={loading}
								hideSubmit={false}
								isDiasbled={activity && activity.id ? true : false}
							/>

							{formik.touched.notes && formik.errors.notes && (
								<div className="text-danger">
									<span role="alert">{formik.errors.notes}</span>
								</div>
							)}
						</div>
					</div>
				</Modal.Body>

				<Modal.Footer className="justify-content-center">
					<Button variant="secondary" onClick={closeModal}>
						{intl.formatMessage({ id: "CLOSE_BUTTON" })}
					</Button>
					{activity && activity.id ? null : (
						<Button type="submit" id="kt_sign_in_submit" className="btn  btn-primary">
							{!loading && <span className="indicator-label">{intl.formatMessage({ id: "SUBMIT_BUTTON" })}</span>}
							{loading && (
								<span className="indicator-progress" style={{ display: "block" }}>
									{intl.formatMessage({
										id: "CUSTOMER_MANAGEMENT_ADD_CUSTOMER_LOADING_MESSAGE",
									})}
									<span className="spinner-border spinner-border-sm align-middle ms-2"></span>
								</span>
							)}
						</Button>
					)}
				</Modal.Footer>
			</form>
		</Modal>
	);
};

export default AddActivityModal;
