// import {createEvent} from '@testing-library/dom'
import React, { FC } from "react";
import CloseIcon from "../../../_metronic/assets/icons/closeIcon.svg";
import SVG from "react-inlinesvg";

interface Props {
  text: string;
  onRemove: (e: React.MouseEvent) => void;
}

const Tag: FC<Props> = ({ text, onRemove }) => {
  return (
    <div
      className="d-flex align-items-center bg-secondary px-2 py-1"
      style={{ borderRadius: "10px" }}
    >
      {/* <i
        className='la la-close text-gray-600 me-1 text-hover-primary'
        onClick={(e) => {
          onRemove?.(e)
        }}
      ></i> */}
      <SVG
        style={{height: "14px", width: "14px"}}
        src={CloseIcon}
        onClick={(e) => {
          onRemove?.(e);
        }}
        className="me-2 text-hover-danger"
      />
      <span className="fs-7">{text}</span>
    </div>
  );
};

export default Tag;
