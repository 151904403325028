import React, { FC, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { Button, Modal } from "react-bootstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import DropDown from "../../../sharedComponents/dropdown/Dropdown";
import closeIcon from "../../../../_metronic/assets/icons/close.svg";
import { UserType } from "../../userManagement/types/getUsersListResponseType";
import { getUsers } from "../../userManagement/redux";
import { useSelector } from "react-redux";
import { DealFilterType } from "../types/DealResponseType";
import { RootState } from "../../../../setup";
import { useDispatch } from "react-redux";
import { actions } from "../redux/DealsRedux";
import { PartnerType } from "../../partnerManagement/types/getPartnersListResponseType";
import SelectCustomerModal from "../../customerManagement/components/SelectCustomerModal";
import SelectPartnersModal from "../../../sharedComponents/SelectPartnersModal/SelectPartnersModal";
import clsx from "clsx";
import { SVGICON } from "../../../../_metronic/helpers";
import { GroupType } from "../../masterdata/types/GetGroupsListResponseType";
import CloseButton from "../../../sharedComponents/Buttons/CloseButton";

interface Props {
	show: boolean;
	closeModal: () => void;
	type: string;
	onFilterSubmit: (
		Responsible_Persons: number[],
		status: string,
		pId: PartnerType,
		cId: UserType,
		tags: number | undefined
	) => void;
}

const DealsListFilterModal: FC<Props> = ({ show, closeModal, type, onFilterSubmit }) => {
	const intl = useIntl();
	const dispatch = useDispatch();
	const DEAL_STATUS_TYPES = JSON.parse(intl.formatMessage({ id: "DEAL_STATUS_TYPES" })) as any[];

	// selector

	const filterData = useSelector<RootState>(
		// @ts-ignore
		({ deals }) => deals.filterdetails
	) as DealFilterType;

	const tagsList = useSelector<RootState>(({ masterdata }) => masterdata.tagsList) as GroupType[];

	// State
	const [loading, setLoading] = useState(false);
	const [selectedPersons, setSelectedPersons] = useState<UserType[]>([]);
	const [allUsers, setAllUsers] = useState<UserType[]>([]);
	const [selectedPartner, setSelectedPartner] = useState<PartnerType>(
		// @ts-ignore
		filterData.partner || {}
	);
	const [selectedContact, setSelectedContact] = useState<UserType>(
		// @ts-ignore
		filterData.contact || {}
	);
	const [openPartnerModal, setOpenPartnerModal] = useState(false);
	const [openContactModal, setOpenContactModal] = useState(false);

	useEffect(() => {
		getUsers().then(({ data: { users } }) => {
			setAllUsers(users);
			const data = users.filter((data) => filterData.Responsible_Persons.includes(data.id));
			setSelectedPersons(data);
		});
	}, []);

	const initialValues = {
		status: filterData && filterData.status.length > 0 ? filterData.status : "",
		tags: filterData && filterData?.tags && filterData?.tags > 0 ? filterData.tags : undefined,
	};

	const AddUserSchema = Yup.object().shape({});

	const formik = useFormik({
		initialValues,
		validationSchema: AddUserSchema,
		onSubmit: (values, { setStatus, setSubmitting }) => {
			const ids = selectedPersons.map((data) => data.id);

			// if (
			//   ids.length > 0 ||
			//   values.status.length > 0 ||
			//   selectedPartner?.id ||
			//   selectedContact?.id
			// ) {
			dispatch(
				actions.setDealFilterDetails({
					Responsible_Persons: ids,
					status: values.status,
					partner: selectedPartner || {},
					contact: selectedContact || {},
					tags: values.tags || undefined,
				})
			);
			onFilterSubmit(
				ids,
				values.status,
				// @ts-ignore
				selectedPartner?.id,
				selectedContact?.id,
				values.tags
			);
			// }
		},
	});

	const handleOnSelectItem = (item: UserType) => {
		const index = selectedPersons.findIndex((selectedItem) => selectedItem.id === item.id);

		// if doesnt exist push item
		if (index === -1) {
			setSelectedPersons(() => [...selectedPersons, item]);
		}
		// if already exists remove item
		else {
			setSelectedPersons((prevState) => {
				prevState.splice(index, 1);
				return [...prevState];
			});
		}
	};

	const handleResetFilters = () => {
		// @ts-ignore
		setSelectedContact({});
		// @ts-ignore
		setSelectedPartner({});
		setSelectedPersons([]);
		formik.setFieldValue("tags", undefined);
		formik.setFieldValue("status", "");
		// dispatch(
		//   actions.setDealFilterDetails({
		//     Responsible_Persons: [],
		//     status: "",
		//     partner: {},
		//     contact: {},
		//     tags: undefined,
		//   })
		// );
		// onFilterSubmit(
		//   [],
		//   "",
		//   // @ts-ignore
		//   {},
		//   {},
		//   ""
		// );
		//closeModal();
	};

	return (
		<>
			{openContactModal && (
				<SelectCustomerModal
					show={openContactModal}
					closeModal={() => setOpenContactModal(false)}
					onSelectCustomers={(customers) => {
						setSelectedContact(customers[0]);
						setOpenContactModal(false);
					}}
					isSelectionModal={true}
					selectedContacts={[selectedContact]}
				/>
			)}
			{openPartnerModal && (
				<SelectPartnersModal
					show={openPartnerModal}
					closeModal={() => setOpenPartnerModal(false)}
					onSelectPartner={(partners) => {
						setOpenPartnerModal(false);
						setSelectedPartner(partners[0]);
					}}
					isSelectionModal={true}
				/>
				// <></>
			)}
			<Modal
				show={show}
				centered
				dialogClassName="medium-size-modal"
				contentClassName={loading ? "pe-none" : "AddNew"}
				backdrop="static"
				className={`kt-modal ${openContactModal || openPartnerModal ? "d-none" : "d-block"}`}
			>
				<Modal.Header>
					<Modal.Title>{intl.formatMessage({ id: "DEAL_FILTER_MODAL_TITLE" })}</Modal.Title>
					<div className="d-flex justify-content-end align-items-center">
						<button
							className="btn btn-sm btn-primary me-2"
							onClick={() => {
								handleResetFilters();
							}}
						>
							{intl.formatMessage({ id: "RESET_BUTTON" })}
						</button>
						<CloseButton onClose={closeModal} />
					</div>
				</Modal.Header>
				<form className="overflow-auto" onSubmit={formik.handleSubmit} noValidate id="kt_login_signin_form">
					<Modal.Body className="overflow-inherit">
						<div>
							{formik.status && (
								<div className="mb-10 alert alert-danger">
									<div className="alert-text font-weight-bold">{formik.status}</div>
								</div>
							)}
							<div className="tab-field">
								<label className="tab-field-label">
									{intl.formatMessage({
										id: "DEAL_FILTER_MODAL_RESPONSIBLE_PERSONS_TITLE",
									})}
								</label>
								<DropDown
									id="post-groups-dropdown"
									multiSelect
									//   @ts-ignore
									items={allUsers || []}
									//   @ts-ignore
									displayFunc={(item) => item.firstname + " " + item.lastname}
									onSelectItem={(item) => {
										//   @ts-ignore
										handleOnSelectItem(item);
									}}
									//   @ts-ignore
									selectedItems={selectedPersons}
									searchkey={["firstname", "lastname"]}
								/>
							</div>

							<div className="d-flex flex-wrap flex-lg-nowrap gap-4">
								{/* Titles */}
								<div className="tab-field w-100 w-lg-50">
									<label className="tab-field-label">
										{intl.formatMessage({
											id: "DEAL_FILTER_MODAL_STATUS_TITLE",
										})}
									</label>
									<DropDown
										hideSearch
										id="users-titles-dropdown"
										items={DEAL_STATUS_TYPES || []}
										displayFunc={(item) => {
											//@ts-ignore
											return item.value;
										}}
										displayValue={
											DEAL_STATUS_TYPES.find((TITLES_OPTION) => TITLES_OPTION.name === formik.values.status)?.value ||
											""
										}
										onSelectItem={(item) => {
											//@ts-ignore
											formik.setFieldValue("status", item.name);
										}}
										selectedItem={DEAL_STATUS_TYPES.find(
											(TITLES_OPTION) => TITLES_OPTION.name === formik.values.status
										)}
									/>
								</div>

								<div className="tab-field w-100 w-lg-50">
									<label className="tab-field-label">
										{intl.formatMessage({
											id: "CONTACT_FORMS_LIST_FILTER_TAGS_TITLE",
										})}
									</label>
									<DropDown
										hideSearch
										id="users-titles-dropdown"
										items={tagsList || []}
										displayFunc={(item) => {
											//@ts-ignore
											return item.name;
										}}
										displayValue={tagsList.find((TITLES_OPTION) => TITLES_OPTION.id === formik.values.tags)?.name || ""}
										onSelectItem={(item) => {
											//@ts-ignore
											formik.setFieldValue("tags", item.id);
										}}
										selectedItem={tagsList.find((TITLES_OPTION) => TITLES_OPTION.id === formik.values.tags)}
									/>
								</div>
							</div>

							<div className="tab-field">
								<label className="tab-field-label">
									{intl.formatMessage({ id: "ADD_NEW_DEAL_PARTNER_TITLE" })}
								</label>
								<div className="d-flex">
									<input
										placeholder={intl.formatMessage({
											id: "ADD_DEAL_PARTNER_PLACEHOLDER",
										})}
										className={clsx("form-control form-control-solid me-4 position-relative")}
										value={
											`${(selectedPartner?.name && selectedPartner?.name) || ""}${
												(selectedPartner?.city && ", " + selectedPartner?.city + ", ") || ""
											}${(selectedPartner?.zip_code && selectedPartner?.zip_code) || ""}` || ""
										}
										name="Partner"
										autoComplete="off"
										disabled
									/>
									{selectedPartner.id && (
										<span
											className=" position-absolute"
											style={{
												right: "23%",
												bottom: "40.5%",
											}}
											role="button"
											onClick={() => {
												// @ts-ignore
												setSelectedPartner({});
											}}
										>
											<SVGICON src={closeIcon} className="svg svg-icon-1 position-absolute" />
										</span>
									)}
									<button
										className="btn btn-sm btn-primary"
										onClick={(e) => {
											e.preventDefault();
											setOpenPartnerModal(true);
										}}
									>
										{intl.formatMessage({
											id: "CUSTOMERS_SELECT_PARTNER_TITLE",
										})}
									</button>
								</div>
							</div>
              
							<div className="tab-field m-0">
								<label className="tab-field-label">
									{intl.formatMessage({ id: "ADD_NEW_DEAL_CONTACT_TITLE" })}
								</label>
								<div className="d-flex">
									<input
										placeholder={intl.formatMessage({
											id: "ADD_DEAL_CONTACT_PLACEHOLDER",
										})}
										className={clsx("form-control form-control-solid me-4 position-relative")}
										value={
											selectedContact?.id //@ts-ignore
												? `${selectedContact.firstname} ${selectedContact.lastname}, ${selectedContact.email}${selectedContact.personalAddress?.address_1 ? `, ${selectedContact.personalAddress.address_1}` : ""}`
												: ""
										}
										name="Contact"
										autoComplete="off"
										disabled
									/>
									{selectedContact.id && (
										<span
											className=" position-absolute"
											style={{
												right: "23%",
												bottom: "19.5%",
											}}
											role="button"
											onClick={() => {
												// @ts-ignore
												setSelectedContact({});
											}}
										>
											<SVGICON src={closeIcon} className="svg svg-icon-1 position-absolute" />
										</span>
									)}
									<button
										className="btn btn-sm btn-primary"
										onClick={(e) => {
											e.preventDefault();
											setOpenContactModal(true);
										}}
									>
										{intl.formatMessage({
											id: "CUSTOMERS_SELECT_PARTNER_TITLE",
										})}
									</button>
								</div>
							</div>
						</div>
					</Modal.Body>

					<Modal.Footer className="justify-content-center">
						<Button variant="secondary" onClick={closeModal}>
							{intl.formatMessage({ id: "CLOSE_BUTTON" })}
						</Button>
						{/* <Button type='submit' id='kt_sign_in_submit' className='btn btn-light-primary'> */}
						<button type="submit" id="kt_sign_in_submit" className="btn btn-primary">
							{!loading && <span className="indicator-label">{intl.formatMessage({ id: "SUBMIT_BUTTON" })}</span>}
							{loading && (
								<span className="indicator-progress" style={{ display: "block" }}>
									{intl.formatMessage({ id: "USERS_LOADING_MESSAGE" })}
									<span className="spinner-border spinner-border-sm align-middle ms-2"></span>
								</span>
							)}
						</button>

						{/* </Button> */}
					</Modal.Footer>
				</form>
			</Modal>
		</>
	);
};

export default DealsListFilterModal;
