import { Action } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import { UserModel } from "../models/UserModel";
import {
  ClientDetailsType,
  ClientTokensResponseType,
} from "../types/GetClientTokenResponseType";
import { UserType } from "../types/LoginResponseTypes";
import { NotificationType } from "../../Notifications/types/getNotificationsResponseType";
import { RoleType } from "../../RolesAndPermissions/types/getRolesResponseType";

export interface ActionWithPayload<T> extends Action {
  payload?: T;
}

export const actionTypes = {
  Login: "[Login] Action",
  Logout: "[Logout] Action",
  Register: "[Register] Action",
  UserRequested: "[Request User] Action",
  UserLoaded: "[Load User] Auth API",
  SetUser: "[Set User] Action",
  SET_CLIENT_DETAILS: "SET_CLIENT_DETAILS",
  SET_ROLE_DETAILS: "SET_ROLE_DETAILS",
  SET_NOTIFICATIONS: "SET_NOTIFICATIONS",
  UPDATE_NOTIFICATIONS: "UPDATE_NOTIFICATIONS",
  SET_NOTIFICATION_DOT: "SET_NOTIFICATION_DOT",
  SET_ACTIVE_DEAL_PIPELINE: "SET_ACTIVE_DEAL_PIPELINE",
  SET_ACTIVE_PIPELINE_NAME: "SET_ACTIVE_PIPELINE_NAME",
};

const initialAuthState: IAuthState = {
  user: undefined,
  accessToken: undefined,
  isAuthorized: false,
  clientDetails: null,
  roleDetails: undefined,
  notifications: [],
  showNotificationDot: false,
  pipelineId: undefined,
  pipelineName: "",
};

export interface IAuthState {
  user?: UserModel;
  accessToken?: string;
  isAuthorized?: boolean;
  clientDetails?: ClientTokensResponseType | null;
  roleDetails?: RoleType;
  notifications?: NotificationType[];
  showNotificationDot?: boolean;
  pipelineId?: number;
  pipelineName: string;
}

export const reducer = persistReducer(
  { storage, key: "auth", whitelist: ["user", "accessToken"] },
  // @ts-ignore
  (
    state: IAuthState = initialAuthState,
    action: ActionWithPayload<IAuthState>
  ) => {
    switch (action.type) {
      case actionTypes.Login: {
        const accessToken = action.payload?.accessToken;
        return {
          ...state,
          accessToken,
          isAuthorized: true,
          showNotificationDot: true,
        };
      }

      case actionTypes.Register: {
        const accessToken = action.payload?.accessToken;
        return { accessToken, user: undefined };
      }

      case actionTypes.Logout: {
        return {
          user: undefined,
          accessToken: undefined,
          isAuthorized: false,
          notifications: [],
          showNotificationDot: false,
        };
      }

      case actionTypes.UserRequested: {
        return { ...state, user: undefined };
      }

      case actionTypes.UserLoaded: {
        const user = action.payload?.user;
        return { ...state, user };
      }

      case actionTypes.SetUser: {
        const user = action.payload?.user;
        return { ...state, user };
      }
      case actionTypes.SET_NOTIFICATIONS: {
        const notifications = action.payload?.notifications;
        return { ...state, notifications };
      }

      case actionTypes.UPDATE_NOTIFICATIONS: {
        const notifications = action.payload?.notifications;
        return { ...state, notifications, showNotificationDot: true };
      }

      case actionTypes.SET_NOTIFICATION_DOT: {
        const showNotificationDot = action.payload?.showNotificationDot;
        return { ...state, showNotificationDot };
      }

      // save client  details
      case actionTypes.SET_CLIENT_DETAILS: {
        const clientDetails = action.payload?.clientDetails;
        return { ...state, clientDetails };
      }

      // save role  details
      case actionTypes.SET_ROLE_DETAILS: {
        const roleDetails = action.payload;
        return { ...state, roleDetails };
      }

      // save active deal pipeline  details
      case actionTypes.SET_ACTIVE_DEAL_PIPELINE: {
        const pipelineId = action.payload;
        return { ...state, pipelineId };
      }

      case actionTypes.SET_ACTIVE_PIPELINE_NAME: {
        const pipelineName = action.payload;
        return { ...state, pipelineName };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  login: (accessToken: string) => ({
    type: actionTypes.Login,
    payload: { accessToken },
  }),
  register: (accessToken: string) => ({
    type: actionTypes.Register,
    payload: { accessToken },
  }),
  logout: () => ({ type: actionTypes.Logout }),
  requestUser: () => ({
    type: actionTypes.UserRequested,
  }),
  fulfillUser: (user: UserModel) => ({
    type: actionTypes.UserLoaded,
    payload: { user },
  }),
  setUser: (user: UserType) => ({
    type: actionTypes.SetUser,
    payload: { user },
  }),
  setClientDetails: (clientDetails: ClientDetailsType) => ({
    type: actionTypes.SET_CLIENT_DETAILS,
    payload: { clientDetails },
  }),
  setNotifications: (notifications: NotificationType[]) => ({
    type: actionTypes.SET_NOTIFICATIONS,
    payload: { notifications },
  }),
  updateNotifications: (notifications: NotificationType[]) => ({
    type: actionTypes.UPDATE_NOTIFICATIONS,
    payload: { notifications },
  }),
  setShowNotificationDot: (showNotificationDot: boolean) => ({
    type: actionTypes.SET_NOTIFICATION_DOT,
    payload: { showNotificationDot },
  }),

  setRoleDetails: (roleDetails: RoleType) => ({
    type: actionTypes.SET_ROLE_DETAILS,
    payload: roleDetails,
  }),
  setActivePipelineId: (pipelineId: number) => ({
    type: actionTypes.SET_ACTIVE_DEAL_PIPELINE,
    payload: pipelineId,
  }),
  setActivePipelineName: (pipelineName: string) => ({
    type: actionTypes.SET_ACTIVE_PIPELINE_NAME,
    payload: pipelineName,
  }),
};

export function* saga() {}
