/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";

import * as Yup from "yup";
import { useFormik } from "formik";
import {
  IUpdatePassword,
  IUpdateEmail,
} from "../../accounts/components/settings/SettingsModel";
import { KTSVG } from "../../../../_metronic/helpers";
import { UserType } from "../types/getUsersListResponseType";
import { updateUserEmail, updateUserPassword } from "../redux";
import { useDispatch } from "react-redux";
import ChooseAuthMethodModal from "../../twoFactorAuth/components/ChooseAuthMethodModal";
import {
  disableTwoFactorAuthentication,
  getRecoveryCodes,
} from "../../twoFactorAuth/redux/TwoFactorAuthAPI";
import ConfirmPasswordModal from "../../../sharedComponents/confirmPasswordModal/ConfirmPasswordModal";
import clsx from "clsx";
import { useIntl } from "react-intl";
import PasswordInput from "../../../sharedComponents/PasswordInput/PasswordInput";
import ShowRecoveryCodesModal from "./ShowRecoveryCodesModal";
import * as auth from "../../../../app/modules/auth/redux/AuthRedux";
import {
  errorToast,
  successToast,
} from "../../../sharedComponents/toasts/Toasts";

interface Props {
  userDetails: UserType;
  getUserDetailsAPI: () => void;
}

const SignInMethod: React.FC<Props> = ({ userDetails, getUserDetailsAPI }) => {
  const dispatch = useDispatch();
  const intl = useIntl();

  // state
  const [showEmailForm, setShowEmailForm] = useState<boolean>(false);
  const [showPasswordForm, setPasswordForm] = useState<boolean>(false);
  const [user, setUser] = useState<UserType>(userDetails);
  const [showChooseAuthModal, setShowChooseAuthModal] =
    useState<boolean>(false);
  const [loading1, setLoading1] = useState<boolean>(false);
  const [loading2, setLoading2] = useState<boolean>(false);
  const [isDisableLoading, setIsDisableLoading] = useState<boolean>(false);
  const [showConfirmPasswordModal, setShowConfirmPasswordModal] =
    useState<boolean>(false);
  const [recoveryCodesLoading, setRecoveryCodesLoading] =
    useState<boolean>(false);
  const [showRecoveryCodesModal, setShowRecoveryCodesModal] =
    useState<boolean>(false);
  const [recoveryCodes, setRecoveryCodes] = useState<string[] | []>([]);

  const emailFormValidationSchema = Yup.object().shape({
    newEmail: Yup.string()
      .email(intl.formatMessage({ id: "USERS_SIGN_IN_WRONG_EMAIL_FORMAT" }))
      .max(60, intl.formatMessage({ id: "USERS_60SYMBOLS_REQUIRED" }))
      .required(intl.formatMessage({ id: "USERS_EMAIL_REQUIRED" })),
    confirmPassword: Yup.string().required(
      intl.formatMessage({ id: "USERS_PASSWORD_REQUIRED" })
    ),
  });

  const passwordFormValidationSchema = Yup.object().shape({
    currentPassword: Yup.string().required(
      intl.formatMessage({ id: "USERS_PASSWORD_REQUIRED" })
    ),
    newPassword: Yup.string()
      .required(intl.formatMessage({ id: "USERS_PASSWORD_REQUIRED" }))
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
        intl.formatMessage({ id: "USERS_PASSWORD_MATCHES_INFO" })
      )
      .notOneOf(
        [Yup.ref("currentPassword"), null],
        intl.formatMessage({ id: "USERS_PASSWORD_SHOULD_NOT_MATCH" })
      ),
    passwordConfirmation: Yup.string()
      .required(intl.formatMessage({ id: "USERS_SIGN_IN_CONFIRM_PASSWORD" }))
      .oneOf(
        [Yup.ref("newPassword"), null],
        intl.formatMessage({ id: "USERS_SIGN_IN_PASSWORD_MATCH" })
      ),
  });

  useEffect(() => {
    setUser(userDetails);
  }, [userDetails]);

  const emailFormInitialValues = {
    newEmail: user?.email || "",
    confirmPassword: "",
  };

  const logout = () => {
    window.location.replace("/login");
    dispatch(auth.actions.logout());
    sessionStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
  };

  const formik1 = useFormik<IUpdateEmail>({
    initialValues: emailFormInitialValues,
    validationSchema: emailFormValidationSchema,
    onSubmit: (values) => {
      setLoading1(true);
      updateUserEmail(user?.id, values.newEmail, values.confirmPassword)
        .then(() => {
          successToast(
            intl.formatMessage({ id: "USERS_EMAIL_CHNAGE_SUCCESS_MESSAGE" })
          );
          setUser({
            ...user,
            email: values.newEmail,
          });
        })
        .catch((e) => {
          if(e.response.status === 406){
            errorToast(
              intl.formatMessage({ id: "USERS_EMAIL_CHANGE_CONFLICT_FAILURE_MESSAGE" })
            );
          }else if(e.response.status === 422){
            errorToast(
              intl.formatMessage({ id: "USERS_ADD_FAILURE_MESSAGE" })
            );
          }
          else{
            errorToast(
              intl.formatMessage({ id: "USERS_EMAIL_CHANGE_FAILURE_MESSAGE" })
            );
          }
        })
        .finally(() => {
          setLoading1(false);
          getUserDetailsAPI();
        });
    },
  });

  const passwordFormInitialValues = {
    currentPassword: "",
    newPassword: "",
    passwordConfirmation: "",
  };

  const formik2 = useFormik<IUpdatePassword>({
    initialValues: passwordFormInitialValues,
    validationSchema: passwordFormValidationSchema,
    onSubmit: (values) => {
      setLoading2(true);
      updateUserPassword(
        user?.id,
        values.currentPassword,
        values.newPassword,
        values.passwordConfirmation
      )
        .then((response) => {
          if (response?.status === 203) {
            errorToast(
              intl.formatMessage({ id: "CURRENT_PASSWORD_DOES_NOT_MATCH" })
            );
          } else {
            successToast(
              intl.formatMessage({
                id: "USERS_PASSWORD_CHANGE_SUCCESS_MESSAGE",
              })
            );
            // Optional logout after success
            setTimeout(() => {
              logout();
            }, 1000);
          }
        })
        .catch(() => {
          errorToast(
            intl.formatMessage({ id: "USERS_PASSWORD_CHANGE_FAILURE_MESSAGE" })
          );
        })
        .finally(() => {
          setLoading2(false);
          getUserDetailsAPI();
        });
    },
  });

  const disableUserTwoFactorAuthentication = () => {
    setIsDisableLoading(true);
    disableTwoFactorAuthentication(userDetails.id)
      .then(() => {
        setUser({
          ...user,
          has2FA: false,
        });
        successToast(
          intl.formatMessage({
            id: "USERS_DISABLE_VERIFICATION_SUCCESS_MESSAGE",
          })
        );
        getUserDetailsAPI();
      })
      .catch(() => {
        errorToast(
          intl.formatMessage({
            id: "USERS_DISABLE_VERIFICATION_FAILURE_MESSAGE",
          })
        );
      })
      .finally(() => {
        setIsDisableLoading(false);
      });
  };

  const closeModal = () => {
    setShowChooseAuthModal(false);
  };

  const closeConfirmPasswordModal = () => {
    setShowConfirmPasswordModal(false);
  };

  const handleRecoveryCodes = (id: number) => {
    setRecoveryCodesLoading(true);
    getRecoveryCodes(id)
      .then(({ data: { recoveryCodes } }) => {
        setRecoveryCodes(recoveryCodes);
        setShowRecoveryCodesModal(true);
      })
      .catch(() => {
        errorToast(
          intl.formatMessage({
            id: "USERS_2FA_GET_RECOVERY_CODES_ERROR_MESSAGE",
          })
        );
      })
      .finally(() => {
        setRecoveryCodesLoading(false);
      });
  };

  return (
    <div className="card card-my">
      {showRecoveryCodesModal && (
        <ShowRecoveryCodesModal
          show={showRecoveryCodesModal}
          closeModal={() => {
            setShowRecoveryCodesModal(false);
          }}
          recoveryCodes={recoveryCodes}
        />
      )}
      {showChooseAuthModal && (
        <ChooseAuthMethodModal
          show={showChooseAuthModal}
          closeModal={closeModal}
          userDetails={userDetails}
          getUserDetailsAPI={getUserDetailsAPI}
        />
      )}
      {showConfirmPasswordModal && (
        <ConfirmPasswordModal
          show={showConfirmPasswordModal}
          user_id={userDetails.id}
          onPasswordConfirm={disableUserTwoFactorAuthentication}
          closeModal={closeConfirmPasswordModal}
        />
      )}

      <div
        className="card-header card-px border-0 cursor-pointer"
        role="button"
        data-bs-toggle="collapse"
        data-bs-target="#kt_account_signin_method"
      >
        <div className="card-title m-0">
          <h3 className="fw-bolder m-0">
            {intl.formatMessage({ id: "USERS_SIGN_IN_TITLE" })}
          </h3>
        </div>
      </div>

      <div id="kt_account_signin_method" className="collapse show">
        <div className="card-body border-top card-p">
          <div className="d-flex flex-wrap align-items-center">
            <div
              id="kt_signin_email"
              className={" " + (showEmailForm && "d-none")}
            >
              <div className="fs-6 fw-bolder mb-1">
                {intl.formatMessage({ id: "USERS_SIGN_IN_EMAIL_ADDRESS" })}
              </div>
              <div className="fw-bold text-gray-600">{user?.email}</div>
            </div>

            <div
              id="kt_signin_email_edit"
              className={"flex-row-fluid " + (!showEmailForm && "d-none")}
            >
              <form
                onSubmit={formik1.handleSubmit}
                id="kt_signin_change_email"
                className={clsx("form", loading1 ? "pe-none" : "")}
                noValidate
              >
                <div className="row">
                  <div className="col-lg-6 tab-field">
                    <div className="fv-row mb-0">
                      <label
                        htmlFor="emailaddress"
                        className="form-label fs-6 fw-bolder mb-3"
                      >
                        {intl.formatMessage({
                          id: "USERS_SIGN_IN_NEW_EMAIL_ADDRESS",
                        })}
                      </label>
                      <input
                        type="email"
                        className="form-control form-control-lg form-control-solid"
                        id="emailaddress"
                        {...formik1.getFieldProps("newEmail")}
                      />
                      {formik1.touched.newEmail && formik1.errors.newEmail && (
                        <div className="text-danger">
                          <span role="alert">{formik1.errors.newEmail}</span>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-6 tab-field">
                    <div className="fv-row mb-0">
                      <label
                        htmlFor="confirmemailpassword"
                        className="form-label fs-6 fw-bolder mb-3"
                      >
                        {intl.formatMessage({
                          id: "USERS_SIGN_IN_CONFIRM_PASSWORD_FIELD",
                        })}
                      </label>
                      <PasswordInput
                        id="confirmemailpassword"
                        {...formik1.getFieldProps("confirmPassword")}
                      />
                      {formik1.touched.confirmPassword &&
                        formik1.errors.confirmPassword && (
                          <div className="text-danger">
                            <span role="alert">
                              {formik1.errors.confirmPassword}
                            </span>
                          </div>
                        )}
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="d-flex">
                      <button
                        id="kt_signin_submit"
                        type="submit"
                        className="btn btn-primary me-2 px-6"
                      >
                        {!loading1 &&
                          intl.formatMessage({
                            id: "USERS_UPDATE_EMAIL_BUTTON",
                          })}
                        {loading1 && (
                          <span
                            className="indicator-progress"
                            style={{ display: "block" }}
                          >
                            {intl.formatMessage({
                              id: "USERS_LOADING_MESSAGE",
                            })}{" "}
                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                          </span>
                        )}
                      </button>
                      <button
                        id="kt_signin_cancel"
                        type="button"
                        onClick={() => {
                          setShowEmailForm(false);
                          formik1.resetForm();
                        }}
                        className="btn btn-color-gray-400 btn-active-light-primary px-6"
                      >
                        {intl.formatMessage({ id: "CANCEL_BUTTON" })}
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>

            <div
              id="kt_signin_email_button"
              className={"ms-auto " + (showEmailForm && "d-none")}
            >
              <button
                onClick={() => {
                  setShowEmailForm(true);
                }}
                className="btn btn-light btn-active-light-primary"
              >
                {intl.formatMessage({ id: "USERS_CHANGE_EMAIL_BUTTON" })}
              </button>
            </div>
          </div>

          <div className="separator separator-dashed my-6"></div>

          {/* password form */}
          <div className="d-flex flex-wrap align-items-center">
            <div
              id="kt_signin_password"
              className={" " + (showPasswordForm && "d-none")}
            >
              <div className="fs-6 fw-bolder mb-1">
                {intl.formatMessage({ id: "USERS_PASSWORD_FIELD" })}
              </div>
              <div className="fw-bold text-gray-600">
                {intl.formatMessage({ id: "USERS_REQUIRED" })}
              </div>
            </div>

            <div
              id="kt_signin_password_edit"
              className={"flex-row-fluid " + (!showPasswordForm && "d-none")}
            >
              <form
                onSubmit={formik2.handleSubmit}
                id="kt_signin_change_password"
                className={clsx("form", loading2 ? "pe-none" : "")}
                noValidate
              >
                <div className="row">
                  <div className="col-lg-4 tab-field">
                    <div className="fv-row mb-0">
                      <label
                        htmlFor="currentpassword"
                        className="form-label fs-6 fw-bolder mb-3"
                      >
                        {intl.formatMessage({
                          id: "USERS_SIGN_IN_CURRENT_PASSWORD",
                        })}
                      </label>
                      <PasswordInput
                        id="currentpassword"
                        {...formik2.getFieldProps("currentPassword")}
                      />
                      {formik2.touched.currentPassword &&
                        formik2.errors.currentPassword && (
                          <div className="text-danger">
                            <span role="alert">
                              {formik2.errors.currentPassword}
                            </span>
                          </div>
                        )}
                    </div>
                  </div>

                  <div className="col-lg-4 tab-field">
                    <div className="fv-row mb-0">
                      <label
                        htmlFor="newpassword"
                        className="form-label fs-6 fw-bolder mb-3"
                      >
                        {intl.formatMessage({
                          id: "USERS_SIGN_IN_NEW_PASSWORD",
                        })}
                      </label>
                      <PasswordInput
                        id="newpassword"
                        {...formik2.getFieldProps("newPassword")}
                      />
                      {formik2.touched.newPassword &&
                        formik2.errors.newPassword && (
                          <div className="text-danger">
                            <span role="alert">
                              {formik2.errors.newPassword}
                            </span>
                          </div>
                        )}
                    </div>
                  </div>

                  <div className="col-lg-4 tab-field">
                    <div className="fv-row mb-0">
                      <label
                        htmlFor="confirmpassword"
                        className="form-label fs-6 fw-bolder mb-3"
                      >
                        {intl.formatMessage({
                          id: "USERS_SIGN_IN_CONFIRM_NEW_PASSWORD",
                        })}
                      </label>
                      <PasswordInput
                        id="confirmpassword"
                        {...formik2.getFieldProps("passwordConfirmation")}
                      />
                      {formik2.touched.passwordConfirmation &&
                        formik2.errors.passwordConfirmation && (
                          <div className="text-danger">
                            <span role="alert">
                              {formik2.errors.passwordConfirmation}
                            </span>
                          </div>
                        )}
                    </div>
                  </div>
                  <div className="col-12 d-flex">
                    <button
                      id="kt_password_submit"
                      type="submit"
                      className="btn btn-primary me-2 px-6"
                    >
                      {!loading2 &&
                        intl.formatMessage({
                          id: "USERS_UPDATE_PASSWORD_BUTTON",
                        })}
                      {loading2 && (
                        <span
                          className="indicator-progress"
                          style={{ display: "block" }}
                        >
                          {intl.formatMessage({
                            id: "USERS_LOADING_MESSAGE",
                          })}{" "}
                          <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                      )}
                    </button>
                    <button
                      onClick={() => {
                        setPasswordForm(false);
                        formik2.resetForm();
                      }}
                      id="kt_password_cancel"
                      type="button"
                      className="btn btn-color-gray-400 btn-active-light-primary px-6"
                    >
                      {intl.formatMessage({ id: "CANCEL_BUTTON" })}
                    </button>
                  </div>
                </div>
              </form>
            </div>

            <div
              id="kt_signin_password_button"
              className={"ms-auto " + (showPasswordForm && "d-none")}
            >
              <button
                onClick={() => {
                  setPasswordForm(true);
                }}
                className="btn btn-light btn-active-light-primary"
              >
                {intl.formatMessage({ id: "USERS_RESET_PASSWORD_BUTTON" })}
              </button>
            </div>
          </div>

          {user?.has2FA ? (
            <>
              <div className="separator separator-dashed my-6"></div>
              <div className="d-flex flex-wrap align-items-center">
                <div className="d-flex">
                  <div className="fs-6 fw-bolder mb-1 me-2">
                    {intl.formatMessage({ id: "USERS_AUTHNETICATION" })}
                  </div>
                  <div className="badge badge-light-success fw-bolder">
                    {intl.formatMessage({
                      id: "USERS_VERIFICATION_ENABLED_TEXT",
                    })}
                  </div>
                </div>
                <div id="kt_signin_email_button" className={"ms-auto d-flex"}>
                  <button
                    onClick={() => {
                      handleRecoveryCodes(user.id);
                    }}
                    className="btn btn-primary me-4"
                  >
                    {!recoveryCodesLoading &&
                      intl.formatMessage({
                        id: "USERS_2FA_GET_RECOVERY_CODES_BUTTON",
                      })}
                    {recoveryCodesLoading && (
                      <span
                        className="indicator-progress"
                        style={{ display: "block" }}
                      >
                        {intl.formatMessage({
                          id: "CUSTOMER_MANAGEMENT_SIGNIN_LOADING_MESSAGE",
                        })}{" "}
                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                      </span>
                    )}
                  </button>
                  <button
                    onClick={() => {
                      setShowConfirmPasswordModal(true);
                    }}
                    className="btn btn-danger"
                  >
                    {!isDisableLoading &&
                      intl.formatMessage({ id: "USERS_DISABLE_BUTTON" })}
                    {isDisableLoading && (
                      <span
                        className="indicator-progress"
                        style={{ display: "block" }}
                      >
                        {intl.formatMessage({
                          id: "USERS_DISABLING_LOADING_MESSAGE",
                        })}{" "}
                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                      </span>
                    )}
                  </button>
                  {/* <button onClick={disableUserTwoFactorAuthentication} className='btn btn-danger'>
                    {!isDisableLoading && 'Disable'}
                    {isDisableLoading && (
                      <span className='indicator-progress' style={{display: 'block'}}>
                        Disabling...{' '}
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                  </button> */}
                </div>
              </div>
            </>
          ) : (
            <div className="notice d-flex bg-light-primary rounded border-primary border border-dashed card-p card-mt">
              <KTSVG
                path="/media/icons/duotune/general/gen048.svg"
                className="svg-icon-2tx svg-icon-primary me-4"
              />
              <div className="d-flex flex-stack flex-grow-1 flex-wrap flex-md-nowrap">
                <div className="mb-3 mb-md-0 fw-bold">
                  <h4 className="text-gray-800 fw-bolder">
                    {intl.formatMessage({ id: "USERS_SECURE_ACCOUNT" })}
                  </h4>
                  <div className="fs-6 text-gray-600 pe-7">
                    {intl.formatMessage({ id: "USERS_AUTHENTICATION_INFO" })}
                  </div>
                </div>
                <span
                  role={"button"}
                  className="btn btn-primary px-6 align-self-center text-nowrap"
                  onClick={() => {
                    setShowChooseAuthModal(true);
                  }}
                >
                  {intl.formatMessage({ id: "USERS_ENABLE_BUTTON" })}
                </span>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SignInMethod;
