import React, { FC, useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import clsx from "clsx";
import { useHistory, useLocation } from "react-router-dom";

import {
  actions,
  createPartner,
  getPartnerDetails,
  updatePartnerGenericDetails,
} from "../redux";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { RootState } from "../../../../setup";
import { RoleType } from "../../RolesAndPermissions/types/getRolesResponseType";
import { enablePermissionForCrud } from "../../../utils/PermisisionEnabledForResource";
import { successToast } from "../../../sharedComponents/toasts/Toasts";
import DropDown from "../../../sharedComponents/dropdown/Dropdown";
import { CountryType } from "../types/getPartnersListResponseType";
import CloseButton from "../../../sharedComponents/Buttons/CloseButton";

interface Props {
  show: boolean;
  closeModal?: () => void;
  getPartnersListAPI?: () => void;
  onChange?: (arg0: any) => void;
  isSelectPartnerType?: boolean;
}

const initialValues = {
  name: "",
  address_1: "",
  address_2: "",
  zip_code: undefined,
  city: "",
  country_id: undefined,
  email: "",
  website_url: "",
  telephone: "",
};

const AddPartnerModal: FC<Props> = ({
  show,
  closeModal,
  getPartnersListAPI,
  onChange,
  isSelectPartnerType,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const intl = useIntl();

  const [loading, setLoading] = useState<boolean>(false);

  const userRoleDetails = useSelector<RootState>(
    // @ts-ignore
    (state) => state.auth.roleDetails
  ) as RoleType;

  const countries = useSelector<RootState>(
    ({ masterdata }) => masterdata.countriesList
  ) as CountryType[];
  const [selectedCountry, setSelectedCountry] = useState<CountryType>();
  const [parnterId, setPartnerId] = useState<number>();

  const crudPermission: any = enablePermissionForCrud(
    userRoleDetails,
    "partners"
  );

  const AddPartnerSchema = Yup.object().shape({
    name: Yup.string()
      .max(60, intl.formatMessage({ id: "FORM_VALIDATION_60SYMBOLS_REQUIRED" }))
      .required(
        intl.formatMessage({ id: "FORM_VALIDATION_FIELD_NAME_REQUIRED" })
      ),
  });

  const getPartnerDetailsAPI = (parnterId: number) => {
    parnterId &&
      getPartnerDetails(parnterId).then(({ data: { partnerDetails } }) => {
        history.replace({ state: { partnerDetails: partnerDetails } });
        if (isSelectPartnerType) {
          if (onChange) {
            onChange(partnerDetails);
          }
        } else {
          history.push(`/crm/organisation/edit`, { partnerDetails });
        }
        dispatch(actions.setPartnerDetails(partnerDetails));
      });
  };

  const formik = useFormik({
    initialValues,
    validationSchema: AddPartnerSchema,
    onSubmit(values, { setStatus, setSubmitting }) {
      setLoading(true);
      createPartner(values.name)
        .then(({ data: { partnerDetails } }) => {
          closeModal?.();
          //history.replace({ state: { partnerDetails: partnerDetails } });
          if (isSelectPartnerType) {
            if (onChange) {
              onChange(partnerDetails);
            }
          }
          successToast(
            intl.formatMessage({
              id: "PARTNER_MANAGEMENT_ADD_SUCCESS_MESSAGE",
            })
          );
          dispatch(actions.setPartnerDetails(partnerDetails));
          return partnerDetails.id;
        })
        .then((id) => {
          updatePartnerGenericDetails(
            id,
            values.name,
            values.address_1,
            values.address_2,
            values.city,
            values.zip_code || null,
            values.country_id || null,
            values.email,
            values.website_url,
            values.telephone
            // @ts-ignore
          ).then(({ data: { partnerDetails } }) => {
            getPartnerDetailsAPI(partnerDetails?.id);
          });
        })
        .catch((err) => {
          err.response?.data?.message
            ? setStatus(err.response?.data?.message)
            : err.response?.data?.errors?.name[0]
              ? setStatus(err.response?.data?.errors?.name[0])
              : err.response?.data?.errors?.email
                ? setStatus(err.response.data?.errors?.email)
                : setStatus(
                    intl.formatMessage({
                      id: "PARTNER_MANAGEMENT_ADD_FAILURE_MESSAGE",
                    })
                  );
        })
        .finally(() => {
          getPartnersListAPI?.();
          setLoading(false);
        });
    },
  });

  // const onCountrySelect = (item: CountryType, updateState?: boolean) => {
  //   setSelectedCountry(item);
  // };

  useEffect(() => {
    if (countries && countries.length > 0) {
      const germany = countries.filter((data) => data.id === 84)[0];
      setSelectedCountry(germany);
    }
  }, []);

  return (
    <Modal
      show={show}
      centered
      dialogClassName="medium-size-modal"
      className="kt-modal"
      contentClassName={loading ? "pe-none" : ""}
      backdrop="static"
      onHide={closeModal}
    >
      <Modal.Header>
        <Modal.Title>
          {intl.formatMessage({ id: "PARTNERS_ADD_NEW_PARTNER" })}
        </Modal.Title>
        <CloseButton onClose={closeModal} />
      </Modal.Header>
      <form
        onSubmit={formik.handleSubmit}
        noValidate
        id="kt_login_signin_form"
        className="overflow-auto"
      >
        <Modal.Body className="overflow-inherit">
          {formik.status && (
            <div className="mb-10 alert alert-danger">
              <div className="alert-text font-weight-bold">{formik.status}</div>
            </div>
          )}
          <div className="fv-row tab-field">
            <label className="required tab-field-label">
              {intl.formatMessage({
                id: "PARTNERS_ADD_PARTNER_MODAL_PARTNER_NAME",
              })}
            </label>
            <input
              type="text"
              autoComplete="off"
              {...formik.getFieldProps("name")}
              className={clsx(
                "form-control form-control-lg form-control-solid"
              )}
            />
            {formik.touched.name && formik.errors.name && (
              <div className="text-danger">
                <span role="alert">{formik.errors.name}</span>
              </div>
            )}
          </div>
          <div className="fv-row tab-field">
            <label className="tab-field-label">
              {intl.formatMessage({
                id: "PARTNERS_GENERAL_DETAILS_FIELD_ADDRESS1",
              })}
            </label>
            <input
              placeholder={""}
              className={clsx(
                "form-control form-control-lg form-control-solid"
              )}
              {...formik.getFieldProps("address_1")}
              autoComplete="off"
            />
          </div>

          {/* Address 2 */}
          <div className="fv-row tab-field">
            <label className="tab-field-label">
              {intl.formatMessage({
                id: "PARTNERS_GENERAL_DETAILS_FIELD_ADDRESS2",
              })}
            </label>
            <input
              placeholder={""}
              className={clsx(
                "form-control form-control-lg form-control-solid"
              )}
              {...formik.getFieldProps("address_2")}
              autoComplete="off"
            />
          </div>

          <div className="row">
            {/* city */}
            <div className="fv-row tab-field col-lg-6">
              <label className="tab-field-label">
                {intl.formatMessage({
                  id: "PARTNERS_GENERAL_DETAILS_FIELD_CITY",
                })}
              </label>
              <input
                placeholder={""}
                className={clsx(
                  "form-control form-control-lg form-control-solid"
                )}
                {...formik.getFieldProps("city")}
                autoComplete="off"
              />
            </div>

            {/* zip code */}
            <div className="fv-row tab-field col-lg-6">
              <label className="tab-field-label">
                {intl.formatMessage({
                  id: "PARTNERS_GENERAL_DETAILS_FIELD_ZIPCODE",
                })}
              </label>
              <input
                placeholder={""}
                className={clsx(
                  "form-control form-control-lg form-control-solid"
                )}
                {...formik.getFieldProps("zip_code")}
                autoComplete="off"
              />
            </div>
          </div>

          {/* Country */}
          <div className="fv-row tab-field">
            <label className="tab-field-label">
              {intl.formatMessage({
                id: "PARTNERS_GENERAL_DETAILS_FIELD_COUNTRY",
              })}
            </label>
            <DropDown
              id="countriesDropDown"
              items={countries}
              selectedItem={selectedCountry}
              // @ts-ignore
              onSelectItem={(item: CountryType) => {
                formik.setFieldValue("country_id", item.id);
                setSelectedCountry(item);
              }}
              displayValue={selectedCountry?.name || ""}
              // @ts-ignore
              displayFunc={(item: CountryType) => {
                return item.emoji + " " + item.name;
              }}
            />
          </div>

          <div className="fv-row tab-field">
            <label className="tab-field-label">
              {intl.formatMessage({
                id: "PARTNERS_GENERAL_DETAILS_FIELD_EMAIL",
              })}
            </label>
            <input
              type="text"
              autoComplete="off"
              {...formik.getFieldProps("email")}
              className={clsx(
                "form-control form-control-lg form-control-solid"
              )}
            />
          </div>
          <div className="fv-row tab-field">
            <label className="tab-field-label">
              {intl.formatMessage({
                id: "PARTNERS_GENERAL_DETAILS_FIELD_WEBSITE_URL",
              })}
            </label>
            <input
              type="text"
              autoComplete="off"
              {...formik.getFieldProps("website_url")}
              className={clsx(
                "form-control form-control-lg form-control-solid"
              )}
            />
          </div>
          <div className="fv-row tab-field mb-0">
            <label className="tab-field-label">
              {intl.formatMessage({
                id: "PARTNERS_GENERAL_DETAILS_FIELD_TELEPHONE",
              })}
            </label>
            <input
              type="text"
              autoComplete="off"
              {...formik.getFieldProps("telephone")}
              className={clsx(
                "form-control form-control-lg form-control-solid"
              )}
            />
          </div>
          {/* <div className="fv-row tab-field">
              <label className="required tab-field-label">
                {intl.formatMessage({
                  id: "FORM_VALIDATION_FIELD_LABEL_EMAIL",
                })}
              </label>
              <input
                type="email"
                autoComplete="off"
                {...formik.getFieldProps("email")}
                className={clsx(
                  "form-control form-control-lg form-control-solid"
                )}
              />
              {formik.touched.email && formik.errors.email && (
                <div className="text-danger">
                  <span role="alert">{formik.errors.email}</span>
                </div>
              )}
            </div> */}
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <Button variant="secondary" onClick={closeModal}>
            {intl.formatMessage({ id: "CLOSE_BUTTON" })}
          </Button>
          {/* <Button type='submit' id='kt_sign_in_submit' className='btn btn-light-primary'> */}
          <button
            type="submit"
            id="kt_sign_in_submit"
            className="btn btn-primary"
          >
            {!loading && (
              <span className="indicator-label">
                {intl.formatMessage({ id: "SUBMIT_BUTTON" })}
              </span>
            )}
            {loading && (
              <span className="indicator-progress" style={{ display: "block" }}>
                {intl.formatMessage({ id: "FORM_VALIDATION_LOADING_MESSAGE" })}
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            )}
          </button>

          {/* </Button> */}
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default AddPartnerModal;
