import React, { FC, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import * as Yup from "yup";
import { Formik, FormikHelpers } from "formik";
import clsx from "clsx";
import { confirmPassword } from "../../modules/auth/redux/AuthCRUD";
import { useIntl } from "react-intl";
import PasswordInput from "../PasswordInput/PasswordInput";

interface Props {
  show: boolean;
  onPasswordConfirm: () => void;
  user_id: number;
  closeModal: () => void;
}

interface FormikValues {
  password: string;
}

const initialValues = {
  password: "",
};

const ConfirmPasswordModal: FC<Props> = ({
  show,
  onPasswordConfirm,
  user_id,
  closeModal,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const intl = useIntl();
  const ConfirmPasswordSchema = Yup.object().shape({
    password: Yup.string().required(
      intl.formatMessage({ id: "USERS_PASSWORD_REQUIRED" })
    ),
  });
  const onFormSubmit = (
    values: FormikValues,
    formikHelpers: FormikHelpers<FormikValues>
  ) => {
    setLoading(true);
    confirmPassword(user_id, values.password)
      .then(() => {
        onPasswordConfirm?.();
        closeModal();
      })
      .catch(() => {
        formikHelpers.setStatus(
          intl.formatMessage({ id: "USERS_INCORRECT_PASSOWRD" })
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Modal
      show={show}
      centered
      dialogClassName={"medium-size-modal"}
      backdrop="static"
    >
      <Modal.Header>
        <Modal.Title>
          {intl.formatMessage({ id: "USERS_CONFIRM_PASSWORD_MODAL_TITLE" })}
        </Modal.Title>
      </Modal.Header>
      <Formik
        initialValues={initialValues}
        validationSchema={ConfirmPasswordSchema}
        onSubmit={(values, formikHelpers) => {
          onFormSubmit(values, formikHelpers);
        }}
      >
        {({ errors, values, touched, handleChange, status, handleSubmit }) => (
          <>
            <Modal.Body>
                {status && (
                  <div className="mb-10 alert alert-danger">
                    <div className="alert-text font-weight-bold">
                      {intl.formatMessage({ id: "USERS_STATUS_FIELD" })}
                    </div>
                  </div>
                )}

                <p className="text-muted fs-5 fw-bold mb-10">
                  {intl.formatMessage({ id: "USERS_CONFIRM_PASSWORD_AUTH" })}
                </p>

                <div className="tab-field mb-0">
                  <label className="d-flex align-items-center tab-field-label">
                    <span className="required">
                      {intl.formatMessage({ id: "USERS_PASSWORD_FIELD" })}
                    </span>
                  </label>
                  <PasswordInput
                    placeholder={""}
                    name="password"
                    onChange={handleChange("password")}
                    value={values.password}
                  />
                  {touched.password && errors.password && (
                    <div className="text-danger mt-2">
                      <span role="alert">{errors.password}</span>
                    </div>
                  )}
                </div>
            </Modal.Body>

            <Modal.Footer className="justify-content-center">
              <Button variant="secondary" onClick={closeModal}>
                {intl.formatMessage({ id: "CLOSE_BUTTON" })}
              </Button>
              <Button
                variant="primary"
                onClick={() => {
                  handleSubmit();
                }}
              >
                {!loading && intl.formatMessage({ id: "SUBMIT_BUTTON" })}
                {loading && (
                  <span
                    className="indicator-progress"
                    style={{ display: "block" }}
                  >
                    {intl.formatMessage({ id: "USERS_LOADING_MESSAGE" })}{" "}
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                )}
              </Button>
            </Modal.Footer>
          </>
        )}
      </Formik>
    </Modal>
  );
};

export default ConfirmPasswordModal;
