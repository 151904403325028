import React from "react";
import clsx from "clsx";

type Props = {
  title?: string;
  index?: number;
  className?: string;
};

const AsideMenuHeading: React.FC<Props> = ({
  title,
  children,
  index,
  className,
}) => {
  return (
    <div className={clsx("menu-item", className)} key={index}>
      <div className={clsx("menu-link menu-heading")}>
        <span
          className="menu-title d-inline-block font-bold text-truncate text-uppercase"
          style={{ maxWidth: "160px" }}
        >
          {title}
        </span>
      </div>
      {children}
    </div>
  );
};

export { AsideMenuHeading };
