import { FC, useEffect, useState } from "react";
import _ from "lodash";
import { pdf } from "@react-pdf/renderer";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";

// images
import { SVGICON } from "../../../../../_metronic/helpers";
import DeleteIcon from "../../../../../_metronic/assets/icons/delete.svg";
import EditIcon from "../../../../../_metronic/assets/icons/edit.svg";
import PlusIcon from "../../../../../_metronic/assets/icons/plus.svg";
import SearchIcon from "../../../../../_metronic/assets/icons/search.svg";

// custom
import { useGlobalModalContext } from "../../../../sharedComponents/modals/ModalContext";
import SimpleLoader from "../../../../sharedComponents/Loader/SimpleLoader";
import Checkbox from "../../../../sharedComponents/Checkbox/Checkbox";
import Pagination from "../../../../sharedComponents/pagination/Pagination";
import { useLang } from "../../../../../_metronic/i18n/Metronici18n";
import Constants from "../../../../config/Constants";

// types
import { LayoutListType } from "../types/LayoutResponsetypes";

import {
  deleteLayout,
  downloadImageFooterLayout,
  getLayoutList,
  multiDeleteLayouts,
} from "../redux/LayoutApi";

import AddLayoutModal from "./AddLayoutModal";
import LayoutPdf from "./LayoutPdf";
import LayoutPreviewModal from "./LayoutPreviewModal";
import NoItemsFound from "../../../../sharedComponents/NoItemsFound/NoItemsFound";
import {
  errorToast,
  successToast,
} from "../../../../sharedComponents/toasts/Toasts";
import clsx from "clsx";

interface LayoutTypeProps {
  isSelectionModal?: boolean;
  onHandleApply?: (id: number) => void;
  selectedfooter?: any;
}

interface ModalProps {
  show: boolean;
  type?: string;
  emailTemplate?: LayoutTypeProps;
}

const LayoutList: FC<LayoutTypeProps> = ({
  isSelectionModal,
  onHandleApply,
  selectedfooter,
}) => {
  // context
  const {
    showDeleteConfirmModal,
    hideDeleteConfirmModal,
    setDeleteModalLoading,
  } = useGlobalModalContext();
  const intl = useIntl();
  const history = useHistory();
  const lang = useLang();
  // state
  const [layoutList, setLayoutList] = useState<LayoutListType[]>([]);
  const [templatesDefaultList, setTemplatesDefaultList] = useState<
    LayoutListType[]
  >([]);

  const [displayLayoutList, setDisplayLayoutList] = useState<LayoutListType[]>(
    []
  );
  const [templateLoading, setTemplateLoading] = useState(false);
  const [showLayoutModal, setShowLayoutModal] = useState<ModalProps>({
    show: false,
  });
  const [showPdfModal, setShowPdfModal] = useState<ModalProps>({
    show: false,
  });
  const [searchText, setSearchText] = useState("");
  const [checkedLayouts, setCheckedLayouts] = useState<LayoutListType[]>([]);
  const [checkAllItems, setCheckAllItems] = useState<boolean>(false);
  const [activeRecord, setActiveRecord] = useState<number>(-1);
  const [activePage, setActivePage] = useState<number>(1);
  const [itemsPerPage, setItemsPerPage] = useState(
    Constants.defaultItemsPerPageCount
  );

  const [pdfUrl, setPdfUrl] = useState("");

  const updateDisplaylayoutList = () => {
    const updatedlayoutList = layoutList.filter((layout) => {
      if (
        (layout.name || "")
          .toLocaleLowerCase()
          .includes((searchText || "").toLocaleLowerCase())
      ) {
        return true;
      }
      return false;
    });
    setDisplayLayoutList(updatedlayoutList);
  };

  const getLayoutListAPI = () => {
    setTemplateLoading(true);
    setCheckedLayouts([]);
    getLayoutList()
      // @ts-ignore
      .then(({ data: { data } }) => {
        setLayoutList(data);
      })
      .catch((error) => console.error(error?.response?.data?.message))
      .finally(() => {
        setTemplateLoading(false);
      });
  };

  useEffect(() => {
    getLayoutListAPI();
    if (selectedfooter) {
      setCheckedLayouts([selectedfooter]);
    }
    return () => {
      setLayoutList([]);
      setTemplatesDefaultList([]);
    };
  }, []);

  useEffect(() => {
    updateDisplaylayoutList();

    return () => {
      setDisplayLayoutList([]);
    };
  }, [layoutList, searchText]);

  const closeModal = () => {
    setShowLayoutModal({
      show: false,
    });
  };
  const closeHeaderModal = () => {
    setShowPdfModal({
      show: false,
    });
  };

  const onSearchTextChange = (text: string) => {
    setSearchText((text || "").trim());
  };

  const handleDeleteLayout = (id: number) => {
    setDeleteModalLoading(true);
    deleteLayout(id)
      .then(() => {
        successToast(
          intl.formatMessage({
            id: "MASTERDATA_SALES_LAYOUT_DELETE_SUCCESS_MESSAGE",
          })
        );
      })
      .catch(() => {
        errorToast(
          intl.formatMessage({
            id: "MASTERDATA_SALES_LAYOUT_DELETE_FAILURE_MESSAGE",
          })
        );
      })
      .finally(() => {
        getLayoutListAPI();
        hideDeleteConfirmModal();
        setDeleteModalLoading(false);
      });
  };

  const onCheckedChange = (layout: LayoutListType) => {
    if (isSelectionModal) {
      setCheckedLayouts([layout]);
    } else {
      let checkedLayoutsCopy = _.clone(checkedLayouts);
      const index = checkedLayoutsCopy.findIndex(
        (checkedLayout) => checkedLayout.id === layout.id
      );
      if (index > -1) {
        checkedLayoutsCopy.splice(index, 1);
        setCheckAllItems(false);
      } else {
        checkedLayoutsCopy.push(layout);
      }
      setCheckedLayouts(checkedLayoutsCopy);
    }
  };

  const isChecked = (layout: LayoutListType) => {
    const index = checkedLayouts?.findIndex(
      (checkedLayout: any) => checkedLayout?.id === layout?.id
    );
    if (index > -1) {
      return true;
    }
    return false;
  };

  const handleDeleteLayouts = (id?: number) => {
    const partnerIds = checkedLayouts?.map((item) => item.id);
    setDeleteModalLoading(true);
    multiDeleteLayouts(id ? [id] : partnerIds)
      .then(() => {
        successToast(
          intl.formatMessage({
            id: "MASTERDATA_SALES_LAYOUT_DELETE_SUCCESS_MESSAGE",
          })
        );
        setCheckedLayouts([]);
      })
      .catch(() => {
        errorToast(
          intl.formatMessage({
            id: "MASTERDATA_SALES_LAYOUT_DELETE_FAILURE_MESSAGE",
          })
        );
      })
      .finally(() => {
        setDeleteModalLoading(false);
        hideDeleteConfirmModal();
        getLayoutListAPI();
      });
  };

  const onPageClick = (page: number) => {
    setActivePage(page);
  };

  const openPdf = async (layout: any, blobURL: string | undefined) => {
    // getContractDetailsAPI()

    const blob = await pdf(
      <LayoutPdf layout={layout} logo={blobURL} lang={lang} />
    ).toBlob();
    const pdfURL = URL.createObjectURL(blob);

    setPdfUrl(pdfURL);
  };

  const startIndex = itemsPerPage * (activePage - 1);
  const paginatedList =
    displayLayoutList && displayLayoutList.length > 10
      ? displayLayoutList.slice(startIndex, startIndex + itemsPerPage)
      : displayLayoutList;

  useEffect(() => {
    if (paginatedList.length === 0 && displayLayoutList.length > 0) {
      setActivePage((prev) => prev - 1);
    }
  }, [paginatedList]);

  return (
    <div className="mt-7">
      {showLayoutModal.show && (
        <AddLayoutModal
          modalProps={showLayoutModal}
          closeModal={closeModal}
          getLayoutListAPI={getLayoutListAPI}
        />
      )}

      {showPdfModal.show && (
        <LayoutPreviewModal
          modalProps={showPdfModal}
          pdfUrl={pdfUrl}
          closeLayoutModal={() => {
            closeHeaderModal();
            setActiveRecord(-1);
          }}
        />
      )}

      {/* search categories */}
      <div
        className={clsx(
          "card card-search d-flex flex-row justify-content-between",
          isSelectionModal ? "modal-item" : "card-mt"
        )}
      >
        <div className="d-flex flex-row search-container">
          {/* <Checkbox
            // onChange={onAllItemsCheckChange}
            // checked={checkAllItems}
            // disabled={isSelectPartnerType()}
            disabled={true}
          /> */}
          {/* <div style={{ width: "20px" }}></div> */}
          <div className="d-flex align-items-center position-relative">
            <span className="position-absolute" style={{ left: "10px" }}>
              <SVGICON className="svg-icon svg-icon-1" src={SearchIcon} />
            </span>
            <input
              type="text"
              className="form-control form-control-md form-control-solid min-w-150px min-w-md-200px ps-11"
              placeholder={intl.formatMessage({
                id: "MASTERDATA_SALES_LAYOUT_SEARCH_PLACEHOLDER",
              })}
              onChange={(e) => {
                onSearchTextChange(e.target.value);
              }}
            />
          </div>
        </div>
        {/* add new site button */}
        {checkedLayouts?.length > 0 ? (
          <div>
            {/* <button
              className='btn  btn-primary mx-5'
              onClick={() => {
                setShowTemplatesDefaultModal({
                  show: true,
                  type: 'new',
                })
              }}
            >
              <SVGICON src={PlusIcon} className='svg-icon-2' />
              {intl.formatMessage({id: 'SYSTEM_EMAIL_ADD_NEW_DEFAULT'})}
            </button> */}
            {/* @ts-ignore */}
            {!isSelectionModal && (
              <span className="text-dark text-hover-primary fs-6 fw-bolder mx-4">
                {checkedLayouts.length}{" "}
                {intl.formatMessage({ id: "PARTNERS_LIST_SELECTED" })}
              </span>
            )}
            {isSelectionModal ? (
              <button
                className="btn  btn-primary"
                onClick={() => {
                  onHandleApply?.(checkedLayouts?.[0]?.id);
                }}
              >
                {intl.formatMessage({
                  id: "APPLY_BUTTON",
                })}
              </button>
            ) : (
              <button
                className={"btn btn-danger"}
                onClick={() => {
                  showDeleteConfirmModal(
                    intl.formatMessage({
                      id: "MASTERDATA_SALES_LIST_DELETE_CONFIRM_MESSAGE",
                    }),
                    handleDeleteLayouts
                  );
                }}
              >
                {intl.formatMessage({
                  id: "PARTNERS_LIST_DELETE_SELECTED",
                })}
              </button>
            )}
          </div>
        ) : (
          !isSelectionModal && (
            <div>
              <button
                className="btn  btn-primary"
                onClick={() => {
                  setShowLayoutModal({
                    show: true,
                    type: "new",
                  });
                }}
              >
                <SVGICON src={PlusIcon} className="svg-icon-2" />
                {intl.formatMessage({
                  id: "MASTERDATA_SALES_LAYOUT_ADD_NEW_LAYOUT",
                })}
              </button>
            </div>
          )
        )}
      </div>

      {templateLoading && paginatedList.length === 0 && (
        <table style={{ display: "flex", justifyContent: "center" }}>
          <tbody>
            <tr style={{ display: "flex", justifyContent: "center" }}>
              <td valign="top" colSpan={7} className="dataTables_empty">
                <SimpleLoader />
              </td>
            </tr>
          </tbody>
        </table>
      )}

      <div
        style={
          isSelectionModal
            ? { overflowY: "auto", maxHeight: "calc(100vh - 400px)" }
            : {}
        }
      >
        {paginatedList &&
          paginatedList?.length > 0 &&
          paginatedList.map((layout, index) => {
            return (
              <div
                className={clsx(
                  "card list-item",
                  isSelectionModal && "modal-item"
                )}
                key={index}
              >
                <div className="d-flex flex-row align-items-center">
                  <div className="me-5 form-check form-check-sm form-check-custom form-check-solid">
                    <Checkbox
                      onChange={() => {
                        onCheckedChange(layout);
                      }}
                      checked={isChecked(layout)}
                      // disabled={isSelectPartnerType() && checkedPartners.length > 0}
                    />
                  </div>

                  <div
                    className={`me-5 fw-bold min-w-150px text-hover-primary ${
                      isSelectionModal ? "pe-none" : "cursor-pointer"
                    } `}
                    onClick={() => {
                      setShowLayoutModal({
                        show: true,
                        type: "edit",
                        // @ts-ignore
                        layout,
                      });
                    }}
                  >
                    {layout.name}
                  </div>
                </div>

                <div className="d-flex">
                  {/* <button
                className='btn  btn-secondary btn-sm'
                style={{marginRight: '5px'}}
                onClick={() => {
                  history.push(`/sales/master-data/layout/edit`, {
                    layout,
                  })
                }}
                disabled={isSelectionModal}
              >
                {intl.formatMessage({id: 'MASTERDATA_SALES_LAYOUT_EDIT_LAYOUT_TITLE'})}
              </button> */}
                  <button
                    className="btn btn-sm btn-icon btn-light-primary mx-1 "
                    type="button"
                    onClick={() => {
                      // @ts-ignore
                      setActiveRecord(layout?.id);
                      if (layout?.headers_logo_id) {
                        downloadImageFooterLayout(layout?.headers_logo_id)
                          .then(({ data }) => {
                            const fileUrl = URL.createObjectURL(data);
                            setActiveRecord(layout?.id);
                            setShowPdfModal({
                              show: true,
                            });
                            openPdf(layout, fileUrl);
                          })
                          .catch(() => {})
                          .finally(() => {
                            setActiveRecord(-1);
                          });
                      } else {
                        setActiveRecord(layout?.id);
                        setShowPdfModal({
                          show: true,
                        });
                        openPdf(layout, undefined)
                          .catch(() => {})
                          .finally(() => {
                            setActiveRecord(-1);
                          });
                      }
                    }}
                  >
                    {activeRecord !== layout?.id && <i className="bi bi-eye" />}
                    {activeRecord === layout?.id && (
                      <div
                        className="spinner-border spinner-border-sm"
                        role="status"
                      ></div>
                    )}
                  </button>
                  {!isSelectionModal && (
                    <button
                      className="btn btn-icon btn-light btn-active-light-primary btn-sm me-1"
                      onClick={() => {
                        history.push(`/sales/master-data/layout/edit`, {
                          headerState: layout,
                        });
                      }}
                      disabled={isSelectionModal}
                    >
                      <SVGICON src={EditIcon} className="svg-icon-3" />
                    </button>
                  )}
                  {!isSelectionModal && (
                    <button
                      className="btn btn-icon btn-light btn-active-light-primary btn-sm"
                      onClick={() => {
                        showDeleteConfirmModal(
                          intl.formatMessage({
                            id: "MASTERDATA_SALES_LIST_DELETE_CONFIRM_MESSAGE",
                          }),
                          () => {
                            handleDeleteLayout(layout.id);
                          }
                        );
                      }}
                      disabled={isSelectionModal}
                    >
                      <SVGICON src={DeleteIcon} className="svg-icon-3" />
                    </button>
                  )}
                </div>
              </div>
            );
          })}

        {displayLayoutList.length > 0 && !templateLoading && (
          <div
            className={clsx(
              "card list-item-pagination",
              isSelectionModal && "modal-item"
            )}
          >
            <Pagination
              totalPages={Math.ceil(displayLayoutList.length / itemsPerPage)}
              activePage={
                Math.ceil(displayLayoutList.length / itemsPerPage) === 1
                  ? 1
                  : activePage
              }
              onPageClick={onPageClick}
              itemsPerPage={itemsPerPage}
              showItemsPerPage
              setItemsPerPage={(count) => {
                setItemsPerPage(count);
                setActivePage(1);
              }}
              noCard
              // fromHeaderList
            />
          </div>
        )}

        {paginatedList?.length === 0 && !templateLoading && (
          <div className="d-flex justify-content-center align-items-center">
            <NoItemsFound languageKey="NO_ITEMS_FOUND" />
          </div>
        )}
      </div>
    </div>
  );
};

export default LayoutList;
