// import clsx from 'clsx'
import React, { FC, useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { RootState } from "../../../../setup";
// import Constants from '../../../config/Constants'
// import Checkbox from '../../../sharedComponents/Checkbox/Checkbox'
import DropDown from "../../../sharedComponents/dropdown/Dropdown";
import { getFilemanagerFoldersData, moveFile } from "../redux";
import {
  FilemanagerDataType,
  FileType,
  FolderType,
  FolderTypes,
} from "../types/getFilemanagerDataResponseType";
import {
  errorToast,
  successToast,
} from "../../../sharedComponents/toasts/Toasts";
import CloseButton from "../../../sharedComponents/Buttons/CloseButton";

interface Props {
  show: boolean;
  closeModal: () => void;
  getFilemanagerDataAPI: () => void;
  checkedFiles: FileType[];
  checkedFolders: FolderType[];
}

const MoveFolderModal: FC<Props> = ({
  show,
  closeModal,
  getFilemanagerDataAPI,
  checkedFiles,
  checkedFolders,
}) => {
  const filemanagerData = useSelector<RootState>(
    ({ filemanager }) => filemanager
  ) as FilemanagerDataType;
  const intl = useIntl();
  const [displayItems, setDisplayItems] = useState<FolderTypes[]>([]);
  const [displayName, setDisplayName] = useState<any>();
  const [destinationItem, setDestinationItem] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    getFilemanagerFoldersData()
      .then(({ data }) => {
        if (
          filemanagerData.breadcrumbs &&
          filemanagerData.breadcrumbs.length > 0
        ) {
          const result = data.folderDetails.filter((temp) => {
            //@ts-ignore
            if (
              temp.name !==
                //@ts-ignore
                filemanagerData.breadcrumbs[
                  //@ts-ignore
                  filemanagerData.breadcrumbs?.length - 1
                ].name &&
              checkedFolders.findIndex((data) => data.name === temp.name) === -1
            ) {
              return (temp.name =
                temp.name +
                " " +
                (temp.parent_name ? "(" + temp.parent_name + ")" : ""));
            } else {
              return false;
            }
          });
          setDisplayItems(result);
        } else {
          if (checkedFolders.length > 0) {
            const result = data.folderDetails.filter((value) => {
              return checkedFolders.some(
                (folder) => value.name !== folder.name
              );
            });
            // console.log(result);
            setDisplayItems(result);
          } else {
            setDisplayItems(data.folderDetails);
          }
        }
      })
      .catch((e) => {
        // console.log(`ERROR: getFilemanagerData`, e)
      });
  }, []);

  const handleMoveChange = () => {
    let tempFolderIds: any = [];
    let tempFileIds: any = [];
    if (checkedFolders && checkedFolders.length > 0) {
      tempFolderIds = checkedFolders.map((data) => data.id);
    }
    if (checkedFiles && checkedFiles.length > 0) {
      tempFileIds = checkedFiles.map((data) => data.id);
    }
    moveFile(
      tempFolderIds,
      tempFileIds,
      destinationItem && destinationItem.id === 0 ? null : destinationItem.id
    )
      .then(() => {
        closeModal();
        successToast(
          intl.formatMessage({ id: "FILEMANAGER_MOVE_SUCCESS_MESSAGE" })
        );
      })
      .catch((err) => {
        errorToast(
          intl.formatMessage({ id: "FILEMANAGER_MOVE_ERROR_MESSAGE" })
        );
      })
      .finally(() => {
        getFilemanagerDataAPI?.();
        setLoading(false);
      });
  };

  return (
    <Modal
      show={show}
      centered
      dialogClassName={"medium-size-modal"}
      className="kt-modal"
      contentClassName={loading ? "pe-none" : "AddNew"}
      backdrop="static"
      onHide={closeModal}
    >
      <Modal.Header>
        <Modal.Title>
          {intl.formatMessage({ id: "FILEMANAGER_MOVE_FOLDER_TITLE" })}
        </Modal.Title>
        <CloseButton onClose={closeModal} />
      </Modal.Header>
      <Modal.Body>
        {/* destination */}
        <div className="tab-field mb-0">
          <label className="d-flex align-items-center tab-field-label">
            <span className="required">
              {intl.formatMessage({
                id: "FILEMANAGER_MOVE_SELECT_DESTINATION_FOLDER_TITLE",
              })}
            </span>
          </label>
          <DropDown
            id="pages-parent-dropdown"
            // @ts-ignore
            items={displayItems}
            // @ts-ignore
            displayFunc={(item) => {
              return item.name;
            }}
            displayValue={displayName || ""}
            hideSearch
            onSelectItem={(item) => {
              // @ts-ignore
              setDestinationItem(item);
              setDisplayName(item.name);
            }}
            // @ts-ignore
            selectedItem={destinationItem}
          />
        </div>
      </Modal.Body>
      <Modal.Footer className="justify-content-center">
        <Button variant="secondary" onClick={closeModal}>
          {intl.formatMessage({
            id: "FILEMANAGER_UPLOAD_FILE_MODAL_CLOSE_BUTTON",
          })}
        </Button>
        <Button
          variant="primary"
          onClick={() => {
            handleMoveChange();
          }}
        >
          {!loading && intl.formatMessage({ id: "SUBMIT_BUTTON" })}
          {loading && (
            <span className="indicator-progress" style={{ display: "block" }}>
              {intl.formatMessage({
                id: "FILEMANAGER_UPLOAD_FILE_MODAL_LOADING_MESSAGE",
              })}{" "}
              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default MoveFolderModal;
