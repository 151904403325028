import React, { FC, useEffect, useState } from "react";
import { useIntl } from "react-intl";

// images
import BlankAvatar from "../../../../_metronic/assets/icons/blankAvatar.svg";
import {
  errorToast,
  successToast,
} from "../../../sharedComponents/toasts/Toasts";
import { updateLogo } from "../redux/CompanySettingsAPi";

// custom
interface Props {
  companySettings: any;
}

const CompanyLogo: FC<Props> = ({ companySettings }) => {
  const intl = useIntl();

  // state

  const [loading, setLoading] = useState(false);
  const [porfilePhotoUrl, setProfilePhotoUrl] = useState<any>(
    companySettings?.logo
  );

  useEffect(() => {
    setProfilePhotoUrl(companySettings?.logo);
  }, [companySettings]);

  const onImageSubmit = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLoading(true);
    const { files } = event.target;
    const selectedFiles = files as FileList;
    setProfilePhotoUrl(URL.createObjectURL(selectedFiles?.[0]));
    const formData = new FormData();
    formData.append("logo", selectedFiles?.[0]);
    formData.append("type", "logo_square");
    formData.append("store_type", "Partners-logo-square");
    updateLogo(companySettings?.id, formData)
      .then(() => {
        successToast(
          intl.formatMessage({
            id: "MASTERDATA_SALES_LAYOUT_LOGO_UPDATE_SUCCESS",
          })
        );
      })
      .catch(() => {
        errorToast(
          intl.formatMessage({
            id: "MASTERDATA_SALES_LAYOUT_LOGO_UPDATE_FAILURE",
          })
        );
      })
      .finally(() => {
        setLoading(false);
        //getPartnerDetailsAPI();
        event.target.value = "";
      });
  };

  return (
    <div className="card">
      <div className="card-header card-px">
        <div className="card-title">
          <h3 className="fw-bolder m-0">
            {intl.formatMessage({ id: "PARTNERS_LOGO_SQUARE_TITLE" })}
          </h3>
        </div>
      </div>
      <div className="card-body card-p">
        <div className="d-flex justify-content-center mb-3">
          <div className="image-input image-input-outline">
            <img
              src={porfilePhotoUrl ? porfilePhotoUrl : BlankAvatar}
              alt="img"
              style={{
                height: "100%",
                width: "100%",
                maxHeight: "136px",
                maxWidth: "241.5px",
                objectFit: "contain",
              }}
              className="image-input-wrapper w-100 h-100px"
              draggable={false}
            />

            <label
              className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
              data-kt-image-input-action="change"
              data-bs-toggle="tooltip"
              title=""
              data-bs-original-title="Change avatar"
              htmlFor="file"
            >
              <input
                type="file"
                id="file"
                accept="image/jpeg,image/jpg,image/png"
                multiple={false}
                data-original-title="upload photos"
                style={{ display: "none" }}
                onChange={onImageSubmit}
              />
              <i className="bi bi-pencil-fill fs-9"></i>
            </label>
          </div>
        </div>
        <div className="text-muted fs-7 text-center ">
          {intl.formatMessage({
            id: "MASTERDATA_SALES_LAYOUT_LOGO_ALLOWED_MESSAGE",
          })}
        </div>
      </div>
    </div>
  );
};

export default CompanyLogo;
