import clsx from "clsx";
import { useFormik } from "formik";
import { FC, useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useIntl } from "react-intl";
import { useHistory, useLocation } from "react-router-dom";
import * as Yup from "yup";

// custom
import { useDispatch, useSelector } from "react-redux";
import { generateRandomString } from "../../../utils/RandomStringGenerator";
import {
  actions,
  getCustomerDetails,
  registerCustomer,
  updateCustomerPersonalAddress,
} from "../redux";
import DropDown from "../../../sharedComponents/dropdown/Dropdown";
import { RootState } from "../../../../setup";
import { RoleType } from "../../RolesAndPermissions/types/getRolesResponseType";
import { enablePermissionForCrud } from "../../../utils/PermisisionEnabledForResource";
import { successToast } from "../../../sharedComponents/toasts/Toasts";
import { CountryType } from "../../masterdata/types/GetCountriesListResponseTypes";
import { CustomerType } from "../../auth/types/LoginResponseTypes";
import CloseButton from "../../../sharedComponents/Buttons/CloseButton";

interface Props {
  show: boolean;
  closeModal?: () => void;
  getCustomersListAPI?: () => void;
  isSelectType?: boolean;
  onChange?: (arg0: any) => void;
}

const AddUserModal: FC<Props> = ({
  show,
  closeModal,
  getCustomersListAPI,
  onChange,
  isSelectType,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const intl = useIntl();

  // state
  const [loading, setLoading] = useState<boolean>(false);
  const userRoleDetails = useSelector<RootState>(
    // @ts-ignore
    (state) => state.auth.roleDetails
  ) as RoleType;
  //@ts-ignore
  const crudPermission: any = enablePermissionForCrud(
    userRoleDetails,
    "contacts"
  );

  const SALUTATION_TYPES = JSON.parse(
    intl.formatMessage({
      id: "CUSTOMER_MANAGEMENT_ADD_CUSTOMER_SALUTATION_TYPES",
    })
  ) as any[];

  const TITLE_TYPES = JSON.parse(
    intl.formatMessage({ id: "CUSTOMER_MANAGEMENT_ADD_CUSTOMER_TITLE_TYPES" })
  ) as any[];

  const initialValues = {
    salutation: 1,
    title: 0,
    firstname: "",
    lastname: "",
    email: "",
    // username: '',
    password: "",
    changepassword: "",
    enableAddress: false,
    address_1: "",
    address_2: "",
    zip_code: undefined,
    city: "",
    country_id: 84,
  };

  const countries = useSelector<RootState>(
    ({ masterdata }) => masterdata.countriesList
  ) as CountryType[];

  const [selectedCountry, setSelectedCountry] = useState<CountryType>();
  const [contactId, setContactId] = useState<number>();

  const AddUserSchema = Yup.object().shape({
    salutation: Yup.string().required(
      intl.formatMessage({
        id: "CUSTOMER_MANAGEMENT_ADD_CUSTOMER_SALUATION_REQUIRED",
      })
    ),
    firstname: Yup.string()
      .max(
        60,
        intl.formatMessage({ id: "CUSTOMER_MANAGEMENT_MAX_SYMBOL_ERROR" })
      )
      .required(
        intl.formatMessage({
          id: "CUSTOMER_MANAGEMENT_ADD_CUSTOMER_FIRST_NAME_REQUIRED",
        })
      ),
    lastname: Yup.string()
      .max(
        60,
        intl.formatMessage({ id: "CUSTOMER_MANAGEMENT_MAX_SYMBOL_ERROR" })
      )
      .required(
        intl.formatMessage({
          id: "CUSTOMER_MANAGEMENT_ADD_CUSTOMER_LAST_NAME_REQUIRED",
        })
      ),
    email: Yup.string()
      .email(
        intl.formatMessage({
          id: "CUSTOMER_MANAGEMNENT_ADD_COMPANY_WRONG_EMAIL_FORMAT",
        })
      )
      .max(
        60,
        intl.formatMessage({ id: "CUSTOMER_MANAGEMENT_MAX_SYMBOL_ERROR" })
      )
      .required(
        intl.formatMessage({ id: "CUSTOMER_MANAGEMENT_EMAIL_REQUIRED" })
      ),
    enableAddress: Yup.boolean(),
    address_1: Yup.string().when("enableAddress", {
      is: true, // Condition: enableAddress is true
      then: Yup.string().required(
        intl.formatMessage({ id: "CUSTOMER_ADDRESS_VALIDATION_MESSAGE" })
      ),
      otherwise: Yup.string(), // Not required if enableAddress is false
    }),
    zip_code: Yup.string()
      .matches(
        /^\d+$/,
        intl.formatMessage({
          id: "CUSTOMER_ADDRESS_ZIP_CODE_NUMERIC_MESSAGE",
        })
      )
      .max(
        10,
        intl.formatMessage({
          id: "CUSTOMER_ADDRESS_ZIP_CODE_VALIDATION_MESSAGE",
        })
      )
      .when("enableAddress", {
        is: true,
        then: Yup.string().required(
          intl.formatMessage({
            id: "CUSTOMER_ADDRESS_ZIP_CODE_VALIDATION_REQUIRED_MESSAGE",
          })
        ),
      }),
    city: Yup.string().when("enableAddress", {
      is: true,
      then: Yup.string().required(
        intl.formatMessage({
          id: "CUSTOMER_ADDRESS_CITY_VALIDATION_REQUIRED_MESSAGE",
        })
      ),
    }),
    country_id: Yup.number().when("enableAddress", {
      is: true,
      then: Yup.number().required(
        intl.formatMessage({
          id: "CUSTOMER_ADDRESS_COUNTRY_VALIDATION_REQUIRED_MESSAGE",
        })
      ),
    }),
    // password: Yup.string()
    //   .required(intl.formatMessage({ id: "USERS_PASSWORD_REQUIRED" }))
    //   .matches(
    //     /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
    //     intl.formatMessage({ id: "USERS_PASSWORD_MATCHES_INFO" })
    //   ),
    // changepassword: Yup.string()
    //   .required(intl.formatMessage({ id: "USERS_CONFIRM_PASSWORD_REQUIRED" }))
    //   .when("password", {
    //     is: (val: string) => (val && val.length > 0 ? true : false),
    //     then: Yup.string().oneOf(
    //       [Yup.ref("password")],
    //       intl.formatMessage({ id: "USERS_PASSWORD_MISMATCH" })
    //     ),
    //   }),
    // username: Yup.string()
    //   .matches(/^[a-zA-Z0-9]*$/, intl.formatMessage({id: 'USERS_USERNAME_FIELD_ERROR'}))
    //   .required(intl.formatMessage({id: 'CUSTOMER_MANAGEMENT_USERNAME_REQUIRED'})),
  });

  const getCustomerDetailsAPI = (
    callback?: (customer: CustomerType) => void
  ) => {
    if (contactId) {
      getCustomerDetails(contactId).then(({ data }) => {
        const { personalInformation } = data || {};
        // @ts-ignore
        setUserDetails(personalInformation);
        history.replace({ state: { userDetails: personalInformation } });
        // @ts-ignore
        setCustomerDetails(data);
        // @ts-ignore
        callback?.(data);
        dispatch(actions.setCustomerDetails(data));
      });
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema: AddUserSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      const randomPassword = generateRandomString();
      registerCustomer(
        values.salutation,
        values.title,
        values.firstname,
        values.lastname,
        values.email
        // values.password,
        // values.changepassword
        // values.username
      )
        // @ts-ignore
        .then(({ data }) => {
          const { personalInformation } = data;
          setContactId(personalInformation?.id);
          closeModal?.();
          // redirect
          if (isSelectType) {
            if (onChange) {
              onChange(data);
            }
          } else {
            history.push(`/crm/contacts/editcustomer`, {
              userDetails: personalInformation,
            });
          }
          successToast(
            intl.formatMessage({ id: "CUSTOMER_ADD_CUSTOMER_SUCCESS_MESSAGE" })
          );
          dispatch(actions.setCustomerDetails(data));
          if (formik.values.enableAddress) {
            updateCustomerPersonalAddress(
              personalInformation?.id,
              values.address_1,
              values.address_2,
              // @ts-ignore
              values.zip_code,
              values.city,
              values.country_id
              // values.state
            ).then(({ data: { personalAddress } }) => {
              getCustomerDetailsAPI();
            });
          }
        })
        .catch((err) => {
          err.response?.data?.errors?.email
            ? setStatus(err.response.data?.errors?.email)
            : setStatus(
                intl.formatMessage({
                  id: "CUSTOMER_MANAGEMENT_ADD_CUSTOMER_FAILURE_MESSAGE",
                })
              );
        })
        .finally(() => {
          getCustomersListAPI?.();
          setLoading(false);
        });
    },
  });

  const onCountrySelect = (item: CountryType, updateState?: boolean) => {
    setSelectedCountry(item);
  };

  useEffect(() => {
    if (countries && countries.length > 0) {
      const germany = countries.filter((data) => data.id === 84)[0];
      setSelectedCountry(germany);
    }
  }, []);

  return (
    <Modal
      show={show}
      centered
      dialogClassName="medium-size-modal"
      className="kt-modal"
      contentClassName={loading ? "pe-none" : ""}
      backdrop="static"
      onHide={closeModal}
    >
      <Modal.Header>
        <Modal.Title>
          {intl.formatMessage({
            id: "CUSTOMER_MANAGEMENT_LIST_ADD_NEW_CUSTOMER",
          })}
        </Modal.Title>
        <CloseButton onClose={closeModal} />
      </Modal.Header>
      <form
        onSubmit={formik.handleSubmit}
        noValidate
        id="kt_login_signin_form"
        className="overflow-auto"
      >
        <Modal.Body className="overflow-inherit">
          {formik.status && (
            <div className="mb-10 alert alert-danger">
              <div className="alert-text font-weight-bold">{formik.status}</div>
            </div>
          )}
          <div className="tab-field">
            <label className="required tab-field-label">
              {intl.formatMessage({
                id: "CUSTOMER_MANAGEMENT_ADD_CUSTOMER_SALUTATION",
              })}
            </label>
            <DropDown
              id="customer_saluation"
              hideSearch
              items={SALUTATION_TYPES}
              displayFunc={(item) => {
                return item.name;
              }}
              displayValue={
                SALUTATION_TYPES.find(
                  (item) => item.id === formik.values.salutation
                )?.name || ""
              }
              onSelectItem={(item) => {
                //@ts-ignore
                formik.setFieldValue("salutation", item.id);
              }}
              selectedItem={SALUTATION_TYPES.find(
                (item) => item.id === formik.values.salutation
              )}
            />
            {/* <select
                className='form-select form-select-solid'
                data-kt-select2='true'
                data-placeholder='Select option'
                data-allow-clear='true'
                onChange={(e) => {
                  formik.setFieldValue(
                    'salutation',
                    e.target.value ? parseInt(e.target.value) : e.target.value,
                    true
                  )
                }}
                defaultValue={1}
                // value={formik.getFieldProps('salutation').value}
              >
                <option value={1}>
                  {intl.formatMessage({id: 'CUSTOMER_MANAGEMENT_ADD_CUSTOMER_SALUTATION_MR'})}
                </option>
                <option value={2}>
                  {intl.formatMessage({id: 'CUSTOMER_MANAGEMENT_ADD_CUSTOMER_SALUTATION_MRS'})}
                </option>
              </select> */}
          </div>

          {/* Titles */}
          <div className="tab-field">
            <label className="tab-field-label">
              {intl.formatMessage({
                id: "CUSTOMER_MANAGEMENT_ADD_CUSTOMER_TITLES",
              })}
            </label>
            <DropDown
              id="customer_titles"
              hideSearch
              items={TITLE_TYPES}
              displayFunc={(item) => {
                return item.name;
              }}
              displayValue={
                TITLE_TYPES.find((item) => item.id === formik.values.title)
                  ?.name || ""
              }
              onSelectItem={(item) => {
                //@ts-ignore
                formik.setFieldValue("title", item.id);
              }}
              selectedItem={TITLE_TYPES.find(
                (item) => item.id === formik.values.title
              )}
            />
            {/* <select
                className='form-select form-select-solid'
                data-kt-select2='true'
                data-placeholder='Select option'
                data-allow-clear='true'
                onChange={(e) => {
                  formik.setFieldValue(
                    'title',
                    e.target.value ? parseInt(e.target.value) : e.target.value,
                    true
                  )
                }}
                defaultValue={0}
              >
                <option value={0}></option>
                <option value={1}>
                  {intl.formatMessage({id: 'CUSTOMER_MANAGEMENT_ADD_CUSTOMER_TITLES_DR'})}
                </option>
                <option value={2}>
                  {intl.formatMessage({id: 'CUSTOMER_MANAGEMENT_ADD_CUSTOMER_TITLES_PROF'})}
                </option>
                <option value={3}>
                  {intl.formatMessage({id: 'CUSTOMER_MANAGEMENT_ADD_CUSTOMER_TITLES_PROF_DR'})}
                </option>
              </select> */}
          </div>

          {/* begin::Form group Firstname */}
          <div className="row fv-row">
            <div className="col-xl-6 tab-field">
              <label className="required tab-field-label">
                {intl.formatMessage({
                  id: "CUSTOMER_MANAGEMENT_ADD_CUSTOMER_FIRST_NAME",
                })}
              </label>
              <input
                type="text"
                autoComplete="off"
                {...formik.getFieldProps("firstname")}
                className={clsx(
                  "form-control form-control-lg form-control-solid"
                )}
              />
              {formik.touched.firstname && formik.errors.firstname && (
                <div className="text-danger">
                  <span role="alert">{formik.errors.firstname}</span>
                </div>
              )}
            </div>
            <div className="col-xl-6 tab-field">
              <label className="required tab-field-label">
                {intl.formatMessage({
                  id: "CUSTOMER_MANAGEMENT_ADD_CUSTOMER_LAST_NAME",
                })}
              </label>
              <input
                type="text"
                autoComplete="off"
                {...formik.getFieldProps("lastname")}
                className={clsx(
                  "form-control form-control-lg form-control-solid"
                )}
              />
              {formik.touched.lastname && formik.errors.lastname && (
                <div className="text-danger">
                  <span role="alert">{formik.errors.lastname}</span>
                </div>
              )}
            </div>
          </div>
          {/* end::Form group */}

          {/* begin::Form group Email */}
          <div className="fv-row tab-field">
            <label className="required tab-field-label">
              {intl.formatMessage({
                id: "CUSTOMER_MANAGEMENT_ADD_CUSTOMER_EMAIL",
              })}
            </label>
            <input
              type="email"
              autoComplete="off"
              {...formik.getFieldProps("email")}
              className={clsx(
                "form-control form-control-lg form-control-solid"
              )}
            />
            {formik.touched.email && formik.errors.email && (
              <div className="text-danger">
                <span role="alert">{formik.errors.email}</span>
              </div>
            )}
          </div>
          <div className="d-flex align-items-center tab-field">
            <label className="tab-field-label me-4 mb-0">
              {intl.formatMessage({
                id: "ADD_ADDRESS",
              })}
            </label>
            <div className="form-check form-switch form-switch-sm form-check-custom form-check-solid">
              <input
                className="form-check-input"
                type="checkbox"
                name="enableAddress"
                value=""
                checked={!!formik.values.enableAddress}
                style={{ width: "50px", height: "25px" }}
                onChange={(e) => {
                  formik.setFieldValue("enableAddress", e.target.checked);
                }}
              />
            </div>
          </div>

          {formik.values.enableAddress && (
            <div className="fv-row tab-field">
              <label className="required tab-field-label">
                {intl.formatMessage({
                  id: "CUSTOMER_MANAGEMENT_ADDRESS_LINE_1",
                })}
              </label>
              <input
                className={clsx(
                  "form-control form-control-lg form-control-solid"
                )}
                type="text"
                placeholder=""
                autoComplete="off"
                {...formik.getFieldProps("address_1")}
                value={formik.values.address_1}
              />
              {formik.touched.address_1 && formik.errors.address_1 && (
                <div className="text-danger">
                  <span role="alert">{formik.errors.address_1}</span>
                </div>
              )}
            </div>
          )}

          {/* Address Line2 */}
          {formik.values.enableAddress && (
            <div className="fv-row tab-field">
              <label className="tab-field-label">
                {intl.formatMessage({
                  id: "CUSTOMER_MANAGEMENT_ADDRESS_LINE_2",
                })}
              </label>
              <input
                className={clsx(
                  "form-control form-control-lg form-control-solid"
                )}
                type="text"
                placeholder=""
                autoComplete="off"
                {...formik.getFieldProps("address_2")}
                value={formik.values.address_2}
              />
              {formik.touched.address_2 && formik.errors.address_2 && (
                <div className="text-danger">
                  <span role="alert">{formik.errors.address_2}</span>
                </div>
              )}
            </div>
          )}

          {formik.values.enableAddress && (
            <div className="row fv-row align-items-start">
              {/* city */}
              <div className="col-xl-6 tab-field">
                <label className="required tab-field-label">
                  {intl.formatMessage({
                    id: "CUSTOMER_MANAGEMENT_ADDRESS_CITY",
                  })}
                </label>
                <input
                  placeholder=""
                  type="text"
                  autoComplete="off"
                  {...formik.getFieldProps("city")}
                  className={clsx(
                    "form-control form-control-lg form-control-solid"
                  )}
                  value={formik.values.city}
                />
                {formik.touched.city && formik.errors.city && (
                  <div className="text-danger">
                    <span role="alert">{formik.errors.city}</span>
                  </div>
                )}
              </div>

              {/*zip code */}
              <div className="col-xl-6 tab-field">
                <label className="required tab-field-label">
                  {intl.formatMessage({
                    id: "CUSTOMER_MANAGEMENT_ADDRESS_ZIP",
                  })}
                </label>
                <input
                  placeholder=""
                  type="text"
                  autoComplete="off"
                  {...formik.getFieldProps("zip_code")}
                  className={clsx(
                    "form-control form-control-lg form-control-solid"
                  )}
                  value={formik.values.zip_code}
                />
                {formik.touched.zip_code && formik.errors.zip_code && (
                  <div className="text-danger">
                    <span role="alert">{formik.errors.zip_code}</span>
                  </div>
                )}
              </div>
            </div>
          )}

          {/* country */}
          {formik.values.enableAddress && (
            <div className="fv-row tab-field mb-0">
              <label className="required tab-field-label">
                {intl.formatMessage({
                  id: "CUSTOMER_MANAGEMENT_ADDRESS_COUNTRY",
                })}
              </label>
              <DropDown
                id="countriesDropDown"
                items={countries}
                selectedItem={selectedCountry}
                // @ts-ignore
                onSelectItem={(item: CountryType) => {
                  formik.setFieldValue("country_id", item.id);
                  onCountrySelect(item);
                }}
                displayValue={selectedCountry?.name || ""}
                // @ts-ignore
                displayFunc={(item: CountryType) => {
                  return item.emoji + " " + item.name;
                }}
              />
              {formik.touched.country_id && formik.errors.country_id && (
                <div className="text-danger">
                  <span role="alert">{formik.errors.country_id}</span>
                </div>
              )}
            </div>
          )}

          {/* end::Form group */}
          {/* begin::Form group Password */}
          {/* <div className="mb-4 fv-row" data-kt-password-meter="true"> */}
          {/* <di tab-fieldv>
                <label className="required tab-field-label">
                  {intl.formatMessage({ id: "USERS_PASSWORD_FIELD" })}
                </label>
                <div className="position-relative mb-3">
                  <input
                    type="password"
                    autoComplete="new-password"
                    {...formik.getFieldProps("password")}
                    className={clsx(
                      "form-control form-control-lg form-control-solid"
                    )}
                  />
                  {formik.touched.password && formik.errors.password && (
                    <div className="text-danger">
                      <span role="alert">{formik.errors.password}</span>
                    </div>
                  )}
                </div> */}
          {/* begin::Meter */}
          {/* <PasswordMeter /> */}
          {/* end::Meter */}
          {/* </div> */}
          {/* <div className="text-muted">
                {intl.formatMessage({ id: "USERS_PASSWORD_INFO" })}
              </div>
            </div> */}
          {/* end::Form group */}

          {/* begin::Form group Confirm password */}
          {/* <div className="fv-row tab-field">
              <label className="required tab-field-label">
                {intl.formatMessage({ id: "USERS_CONFIRM_PASSWORD_FIELD" })}
              </label>
              <input
                type="password"
                autoComplete="off"
                {...formik.getFieldProps("changepassword")}
                className={clsx(
                  "form-control form-control-lg form-control-solid"
                )}
              />
              {formik.touched.changepassword &&
                formik.errors.changepassword && (
                  <div className="text-danger">
                    <span role="alert">{formik.errors.changepassword}</span>
                  </div>
                )}
            </div> */}
        </Modal.Body>

        <Modal.Footer className="justify-content-center">
          <Button variant="secondary" onClick={closeModal}>
            {intl.formatMessage({
              id: "CUSTOMER_MANAGEMENT_ADD_CUSTOMER_CLOSE",
            })}
          </Button>
          <Button
            type="submit"
            id="kt_sign_in_submit"
            className="btn  btn-primary"
          >
            {!loading && (
              <span className="indicator-label">
                {intl.formatMessage({
                  id: "CUSTOMER_MANAGEMENT_ADD_CUSTOMER_SUBMIT",
                })}
              </span>
            )}
            {loading && (
              <span className="indicator-progress" style={{ display: "block" }}>
                {intl.formatMessage({
                  id: "CUSTOMER_MANAGEMENT_ADD_CUSTOMER_LOADING_MESSAGE",
                })}
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            )}
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default AddUserModal;
