import React, { FC } from "react";
import { Button, Modal } from "react-bootstrap";
import ComingSoon from "../../../../_metronic/assets/icons/comingsoon.webp";
import { useIntl } from "react-intl";

import DealLocation from "./DealLocationCard";
import CloseButton from "../../../sharedComponents/Buttons/CloseButton";

interface Props {
  show: boolean;
  closeModal: () => void;
  dealInformation: any;
}

const EmailCominSoonModal: FC<Props> = ({ show, closeModal, dealInformation }) => {
  const intl = useIntl();

  return (
    <Modal
      show={show}
      centered
      dialogClassName="medium-size-modal"
      className="kt-modal"
      backdrop="static"
      onHide={closeModal}
    >
      <Modal.Header>
        <Modal.Title>
          {intl.formatMessage({
            id: "DEALS_EMAILS",
          })}
        </Modal.Title>
        <CloseButton onClose={closeModal} />
      </Modal.Header>

      <div className="overflow-auto">
        <Modal.Body className="overflow-inherit p-0">
          {/* <img
          alt="email coming soon"
          src={ComingSoon}
          style={{ width: "100%", height: '500px'}}
          /> */}
          <h1 className="text-muted"> {intl.formatMessage({ id: "EMAIL_COMING_SOON_DESCRIPTION" })}</h1>
        </Modal.Body>
      </div>

      <Modal.Footer className="justify-content-center">
        <Button variant="secondary" onClick={closeModal}>
          {intl.formatMessage({ id: "CLOSE_BUTTON" })}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EmailCominSoonModal;
