import React, { useEffect, useRef, useState } from "react";
import { SVGICON } from "../../../../_metronic/helpers";
// images
import DragIcon from "../../../../_metronic/assets/icons/drag.svg";
import MenuIcon from "../../../../_metronic/assets/icons/menu.svg";
import PlusIcon from "../../../../_metronic/assets/icons/plus.svg";
import notFound from "../../../../_metronic/assets/icons/notFound.png";
import SearchIcon from "../../../../_metronic/assets/icons/search.svg";

import { useIntl } from "react-intl";
import {
  Sortpipeline,
  deletePipeline,
  duplicatePipeline,
  getPipelinesList,
  massDeletePipelines,
} from "../redux/PipelinesAPI";
import { useDispatch } from "react-redux";
import { actions } from "../redux/PipelinesRedux";
import { actions as DealActions } from "../../deals/redux/DealsRedux";
import { useSelector } from "react-redux";
import { RootState } from "../../../../setup";
import {
  PipelinePhaseType,
  PipelineType,
} from "../types/PipelinesResponseType";
import { useGlobalModalContext } from "../../../sharedComponents/modals/ModalContext";
import AddPipelineModal from "./AddPipelineModal";
import { sortGroupsParams } from "../types/request";
import { useHistory, useLocation } from "react-router-dom";
import Constants from "../../../config/Constants";
import _ from "lodash";
import Pagination from "../../../sharedComponents/pagination/Pagination";
import { getDealPipelines } from "../../deals/redux/DealsAPI";
import { RoleType } from "../../RolesAndPermissions/types/getRolesResponseType";
import { enablePermissionForCrud } from "../../../utils/PermisisionEnabledForResource";
import { MenuComponent } from "../../../../_metronic/assets/ts/components";
import ScrollToTop from "../../../sharedComponents/ScrollToTop/ScrollToTop";
import NoItemsFound from "../../../sharedComponents/NoItemsFound/NoItemsFound";
import {
  errorToast,
  successToast,
} from "../../../sharedComponents/toasts/Toasts";

const Pipelines = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    showDeleteConfirmModal,
    hideDeleteConfirmModal,
    setDeleteModalLoading,
  } = useGlobalModalContext();

  const pipelineList = useSelector<RootState>(
    // @ts-ignore
    ({ pipelines }) => pipelines.pipelinesList
  ) as PipelineType[];

  const userRoleDetails = useSelector<RootState>(
    // @ts-ignore
    (state) => state.auth.roleDetails
  ) as RoleType;
  //@ts-ignore
  const crudPermission: any = enablePermissionForCrud(
    userRoleDetails,
    "pipelines"
  );

  const PIPELINE_STATUS_TYPE_OPTIONS = JSON.parse(
    intl.formatMessage({ id: "PIPELINE_STATUS_TYPE_OPTIONS" })
  ) as {
    id: number;
    name: string;
    title: string;
  }[];

  //   state
  const [loading, setLoading] = useState(false);
  const [showPipelineModal, setShowPipelineModal] = useState<{
    show: boolean;
    type: string;
    pipeline: PipelineType | undefined;
  }>({
    show: false,
    type: "new",
    pipeline: undefined,
  });
  const [displayPipelineList, setDisplayPipelineList] = useState(pipelineList);
  const [checkedPipeline, setCheckedPipeline] = useState<PipelineType[]>([]);
  const [searchText, setSearchText] = useState<string>("");
  const [checkAllPipeline, setCheckAllPipeline] = useState<boolean>(false);
  const [itemsPerPage, setItemsPerPage] = useState(
    Constants.defaultItemsPerPageCount
  );
  const [activePage, setActivePage] = useState<number>(1);

  const updateContentPipelines = () => {
    getDealPipelines()
      .then(({ data: { pipelines } }) => {
        dispatch(DealActions.setDealPipelines(pipelines));
      })
      .catch((error) => console.error(error?.response?.data?.message));
  };

  const getPipelines = () => {
    setLoading(true);
    getPipelinesList()
      .then(({ data: { pipelines } }) => {
        dispatch(actions.setPipelinesList(pipelines));
      })
      .catch((e) => {})
      .finally(() => {
        setLoading(false);
        updateContentPipelines();
      });
  };

  useEffect(() => {
    getPipelines();
  }, []);

  const handleDuplicatePipeline = (id: number) => {
    setDeleteModalLoading(true);
    duplicatePipeline(id)
      .then((data) => {
        successToast(
          intl.formatMessage({
            id: "PROEJCTS_PIPELINE_DUPLICATE_SUCCESS_MESSAGE",
          })
        );
      })
      .catch(() => {
        errorToast(
          intl.formatMessage({
            id: "PROEJCTS_PIPELINE_DUPLICATE_FAILURE_MESSAGE",
          })
        );
      })
      .finally(() => {
        setDeleteModalLoading(false);
        getPipelines();
        hideDeleteConfirmModal();
      });
  };

  const handleDeletePipeline = (id: number) => {
    setDeleteModalLoading(true);
    deletePipeline(id)
      .then((data) => {
        successToast(
          intl.formatMessage({
            id: "PROEJCTS_PIPELINE_DELETE_SUCCESS_MESSAGE",
          })
        );
      })
      .catch((e) => {
        if (e.response?.status=== 409) {
          errorToast(
            intl.formatMessage({
              id: "PROEJCTS_PIPELINE_DELETE_CONFLICT_FAILURE_MESSAGE",
            })
          );
        } else {
          errorToast(
            intl.formatMessage({
              id: "PROEJCTS_PIPELINE_DELETE_FAILURE_MESSAGE",
            })
          );
        }
      })
      .finally(() => {
        getPipelines();
        setDeleteModalLoading(false);
        setCheckedPipeline([]);
        hideDeleteConfirmModal();
      });
  };

  const handlePipelineSort = (sortedList: PipelineType[]) => {
    const newSortedTabsArr: sortGroupsParams = [];
    sortedList.forEach((sortedListItem, i) => {
      newSortedTabsArr.push({
        id: sortedListItem.id,
        newposition: startIndex + i + 1,
      });
    });
    // sort existing tags API Call
    Sortpipeline(newSortedTabsArr)
      .then(() => {})
      .catch(() => {})
      .finally(() => {
        getPipelines();
      });
  };

  const dragPipeline = useRef<number>(0);
  const draggedOverPipeline = useRef<number>(0);

  const updateDisplayPipelinesList = () => {
    const updatedPipelinesList = pipelineList.filter((Pipeline) => {
      if (
        (Pipeline.name || "")
          .toLocaleLowerCase()
          .includes((searchText || "").toLocaleLowerCase())
      ) {
        return true;
      }
      return false;
    });
    // save updatedlist to display
    setDisplayPipelineList(updatedPipelinesList);
  };

  useEffect(() => {
    updateDisplayPipelinesList();

    return () => {
      setDisplayPipelineList(pipelineList);
    };
  }, [pipelineList, searchText]);

  useEffect(() => {
    if (checkAllPipeline && checkedPipeline.length !== pipelineList.length) {
      setCheckAllPipeline(false);
    }
    if (
      checkedPipeline.length > 0 &&
      checkedPipeline.length === pipelineList.length
    ) {
      setCheckAllPipeline(true);
    }
  }, [checkedPipeline]);

  const onPageClick = (page: number) => {
    setActivePage(page);
  };

  const onSearchTextChange = (text: string) => {
    setSearchText((text || "").trim());
  };

  const onPipelineCheckChange = (pipline: PipelineType) => {
    let checkedPipelinesCopy = _.clone(checkedPipeline);
    // check if already exists in the checked list
    const index = checkedPipelinesCopy.findIndex(
      (checkedPipeline) => checkedPipeline.id === pipline.id
    );
    // if exists remove
    if (index > -1) {
      checkedPipelinesCopy.splice(index, 1);
    }
    // if doesnt exist push to checked tabs
    else {
      checkedPipelinesCopy.push(pipline);
    }
    setCheckedPipeline(checkedPipelinesCopy);
  };

  const isPipelineChecked = (pipline: PipelineType) => {
    const index = checkedPipeline.findIndex(
      (checkedPipeline) => checkedPipeline.id === pipline.id
    );
    if (index > -1) {
      return true;
    }
    return false;
  };

  const onAllPipelinesCheckChange = () => {
    const updatedCheckAllPipelines = !checkAllPipeline;
    setCheckAllPipeline(updatedCheckAllPipelines);
    if (updatedCheckAllPipelines) {
      setCheckedPipeline(pipelineList);
    } else {
      setCheckedPipeline([]);
    }
  };

  const deleteMultiplePipelines = () => {
    setDeleteModalLoading(true);
    const ids = checkedPipeline.map((checkedTab) => {
      return checkedTab.id;
    });
    massDeletePipelines(ids)
      .then(() => {
        successToast(
          intl.formatMessage({
            id: "PROEJCTS_PIPELINE_DELETE_SUCCESS_MESSAGE",
          })
        );
      })
      .catch((e) => {
        if (e.response?.status=== 409) {
          errorToast(
            intl.formatMessage({
              id: "PROEJCTS_PIPELINE_MASS_DELETE_CONFLICT_FAILURE_MESSAGE",
            })
          );
        } else {
          errorToast(
            intl.formatMessage({
              id: "PROEJCTS_PIPELINE_DELETE_FAILURE_MESSAGE",
            })
          );
        }
      })
      .finally(() => {
        setDeleteModalLoading(false);
        hideDeleteConfirmModal();
        getPipelines();
        setCheckedPipeline([]);
      });
  };
  // get paginated records
  const startIndex = itemsPerPage * (activePage - 1);
  const paginatedPipelines =
    displayPipelineList && displayPipelineList.length > 10
      ? displayPipelineList.slice(startIndex, startIndex + itemsPerPage)
      : displayPipelineList;

  useEffect(() => {
    if (paginatedPipelines.length === 0 && displayPipelineList.length > 0) {
      setActivePage((prev) => prev - 1);
    }
  }, [paginatedPipelines]);

  useEffect(() => {
    ScrollToTop();
  }, [activePage]);

  return (
    <div className="card-mb">
      {showPipelineModal.show && (
        <AddPipelineModal
          show={showPipelineModal.show}
          type={showPipelineModal.type}
          closeModal={() => {
            setShowPipelineModal({
              show: false,
              type: "new",
              pipeline: undefined,
            });
          }}
          getPipelines={getPipelines}
          pipeline={showPipelineModal.pipeline}
        />
      )}
      {/* search pipelines */}
      <div className="card card-search d-flex flex-row justify-content-between">
        <div className="d-flex flex-row">
          {crudPermission?.delete && (
            <div className="form-check form-check-sm form-check-custom form-check-solid ">
              <input
                onChange={onAllPipelinesCheckChange}
                className="form-check-input widget-9-check"
                type="checkbox"
                checked={checkAllPipeline}
              />
            </div>
          )}
          <div className="d-flex align-items-center position-relative ms-4">
            <span className="position-absolute" style={{ left: "10px" }}>
              <SVGICON className="svg-icon svg-icon-1" src={SearchIcon} />
            </span>
            <input
              type="text"
              className="form-control form-control-md form-control-solid min-w-150px min-w-md-200px ps-11"
              placeholder={intl.formatMessage({
                id: "PROJECTS_PIPELINE_SEARCH_PLACEHOLDER",
              })}
              onChange={(e) => {
                onSearchTextChange(e.target.value);
              }}
            />
          </div>
        </div>
        {/* add new button */}
        <div className="d-flex align-items-center">
          {checkedPipeline.length > 0 ? (
            <>
              <span className="text-dark text-hover-primary fs-6 fw-bolder mx-4">
                {checkedPipeline.length}{" "}
                {intl.formatMessage({ id: "SELECTED" })}
              </span>
              <button
                className="btn btn-danger d-flex align-items-center"
                onClick={() => {
                  showDeleteConfirmModal(
                    intl.formatMessage({
                      id: "PROJECTS_PIPELINES_MULTI_DELETE_CONFIRM_MESSAGE",
                    }),
                    () => {
                      deleteMultiplePipelines();
                    }
                  );
                }}
              >
                <i className={"bi bi-trash3-fill fs-6"}></i>
                {intl.formatMessage({ id: "DELETE_BUTTON" })}
              </button>
            </>
          ) : (
            <>
              {crudPermission?.create && (
                <button
                  className="btn  btn-primary"
                  onClick={() => {
                    setShowPipelineModal({
                      show: true,
                      type: "new",
                      pipeline: undefined,
                    });
                  }}
                >
                  <SVGICON src={PlusIcon} className="svg-icon-2" />
                  {intl.formatMessage({
                    id: "PROJECTS_PIPELINE_ADD_NEW_BUTTON",
                  })}
                </button>
              )}
            </>
          )}
        </div>
      </div>
      <div className=" d-flex flex-column ">
        {!loading && displayPipelineList.length === 0 && (
          <table className="d-flex justify-content-center align-items-center mt-2">
            <tbody>
              <NoItemsFound languageKey="NO_ITEMS_FOUND" />
            </tbody>
          </table>
        )}

        {paginatedPipelines.map((pipeline, index) => (
          <div
            className="card list-item"
            key={index}
            // style={{
            //   background: "#F5F8FA",
            // }}
            // As per discussion with Ravi we are commenting drag and drop for now
            // draggable
            // onDragStart={() => (dragPipeline.current = index)}
            // onDragEnter={() => (draggedOverPipeline.current = index)}
            // onDragEnd={() => {
            //   if (dragPipeline.current !== draggedOverPipeline.current) {
            //     const clonedPipelineList = [...paginatedPipelines];
            //     const deletedSlice = clonedPipelineList.splice(
            //       dragPipeline.current,
            //       1
            //     )[0];
            //     clonedPipelineList.splice(
            //       draggedOverPipeline.current,
            //       0,
            //       deletedSlice
            //     );
            //     // const temp = clonedPipelineList[dragPipeline.current];
            //     // clonedPipelineList[dragPipeline.current] =
            //     //   clonedPipelineList[draggedOverPipeline.current];
            //     // clonedPipelineList[draggedOverPipeline.current] = temp;
            //     if(crudPermission?.edit){
            //       handlePipelineSort(clonedPipelineList)
            //     }

            //   }
            // }}
            // onDragOver={(e) => {
            //   e.preventDefault();
            // }}
          >
            <div className="d-flex flex-row align-items-center">
              {crudPermission?.delete && (
                <div className="form-check form-check-sm form-check-custom form-check-solid ">
                  <input
                    onChange={(e) => {
                      onPipelineCheckChange(pipeline);
                    }}
                    className="form-check-input widget-9-check"
                    type="checkbox"
                    checked={isPipelineChecked(pipeline)}
                  />
                </div>
              )}
              {/* {crudPermission?.edit && <div className="mx-5">
                  <SVGICON
                    src={DragIcon}
                    className="svg-icon-2 svg-hover-primary"
                  />
                </div>} */}

              <div
                className={`fw-bold mx-5  ${
                  !crudPermission?.edit && "no-drag"
                }`}
                role="button"
                onClick={() => {
                  const tempPipeline = Object.assign({}, pipeline);
                  const result: PipelinePhaseType[] = [];
                  pipeline.phases.map((val, index) => {
                    if (
                      val.condition !== "end" ||
                      (val.condition === "end" && val.display_order)
                    ) {
                      result.push(val);
                    }
                    if (pipeline.phases.length - 1 === index) {
                      result.push(
                        pipeline.phases.filter(
                          (data) => data.condition === "end"
                        )[0]
                      );
                    }
                  });
                  tempPipeline.phases = result;
                  history.push(`/projects/pipelines/editPipeline`, {
                    pipelineDetails: tempPipeline,
                  });
                  dispatch(actions.setPipelineDetails(tempPipeline));
                }}
              >
                {" "}
                {pipeline.name}
              </div>
              <div className="d-flex gap-2 pe-2">
                <span className="badge badge-light-primary">
                  {pipeline.transition_type}
                </span>
                {PIPELINE_STATUS_TYPE_OPTIONS[0].name === pipeline.status ? (
                  <span className="badge badge-light-success">
                    {pipeline.status}
                  </span>
                ): PIPELINE_STATUS_TYPE_OPTIONS[1].name === pipeline.status ? (
                  <span className="badge badge-secondary">
                    {pipeline.status}
                  </span>
                ):PIPELINE_STATUS_TYPE_OPTIONS[2].name === pipeline.status ? (
                  <span className="badge badge-light-danger">
                    {pipeline.status}
                  </span>
                ): null}
              </div>
            </div>
            <div className="dropdown d-flex flex-row justify-content-end">
              {(crudPermission?.create ||
                crudPermission?.edit ||
                crudPermission?.delete) && (
                <button
                  type="button"
                  className="btn btn-sm btn-icon btn-light btn-active-light-primary bgBrandLightBlueHover dropdown-toggl "
                  id="dropdownMenuButton1"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <SVGICON src={MenuIcon} />
                </button>
              )}
              <div
                className="dropdown-menu  menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-150px py-4 userListActionsDropDownCont"
                aria-labelledby="dropdownMenuButton1"
              >
                <div
                  className="menu-item px-3"
                  onClick={() => {
                    const tempPipeline = Object.assign({}, pipeline);
                    const result: PipelinePhaseType[] = [];
                    pipeline.phases.map((val, index) => {
                      if (
                        val.condition !== "end" ||
                        (val.condition === "end" && val.display_order)
                      ) {
                        result.push(val);
                      }
                      if (pipeline.phases.length - 1 === index) {
                        result.push(
                          pipeline.phases.filter(
                            (data) => data.condition === "end"
                          )[0]
                        );
                      }
                    });
                    tempPipeline.phases = result;
                    history.push(`/projects/pipelines/editPipeline`, {
                      pipelineDetails: tempPipeline,
                    });
                    dispatch(actions.setPipelineDetails(tempPipeline));
                  }}
                >
                  {crudPermission?.edit && (
                    <span className="menu-link px-3 bgBrandLightBlueHover">
                      {intl.formatMessage({ id: "EDIT_BUTTON" })}
                    </span>
                  )}
                </div>
                <div
                  className="menu-item px-3"
                  onClick={() => {
                    const dropdownMenu = document.querySelector(
                      ".dropdown-menu.show"
                    );
                    if (dropdownMenu) {
                      dropdownMenu.classList.remove("show");
                    }
                    showDeleteConfirmModal(
                      intl.formatMessage({
                        id: "PROJECTS_PIPELINE_DUPLICATE_CONFIRM_MESSAGE",
                      }),
                      () => {
                        handleDuplicatePipeline(pipeline.id);
                      },
                      intl.formatMessage({
                        id: "YES_DUPLICATE_BUTTON",
                      })
                    );
                  }}
                >
                  {crudPermission?.create && (
                    <span className="menu-link px-3 bgBrandLightBlueHover">
                      {intl.formatMessage({ id: "DUPLICATE_BUTTON" })}
                    </span>
                  )}
                </div>
                <div
                  className="menu-item px-3"
                  onClick={() => {
                    const dropdownMenu = document.querySelector(
                      ".dropdown-menu.show"
                    );
                    if (dropdownMenu) {
                      dropdownMenu.classList.remove("show");
                    }
                    showDeleteConfirmModal(
                      intl.formatMessage({
                        id: "PROJECTS_PIPELINE_DELETE_CONFIRM_MESSAGE",
                      }),
                      () => {
                        handleDeletePipeline(pipeline.id);
                      }
                    );
                  }}
                >
                  {crudPermission?.delete && (
                    <span className="menu-link px-3 text-danger bgBrandLightBlueHover">
                      {intl.formatMessage({ id: "DELETE_BUTTON" })}
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
        ))}

        {displayPipelineList.length > 0 && (
          <div className="card list-item-pagination">
            <Pagination
              totalPages={Math.ceil(displayPipelineList.length / itemsPerPage)}
              activePage={
                Math.ceil(displayPipelineList.length / itemsPerPage) === 1
                  ? 1
                  : activePage
              }
              onPageClick={onPageClick}
              noCard
              itemsPerPage={itemsPerPage}
              showItemsPerPage
              setItemsPerPage={(count) => {
                setItemsPerPage(count);
                setActivePage(1);
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Pipelines;
