import React, { FC, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useIntl } from "react-intl";
import { pdf } from "@react-pdf/renderer";

import { useLang } from "../../../../../_metronic/i18n/Metronici18n";
import { errorToast } from "../../../../sharedComponents/toasts/Toasts";
import DropDown from "../../../../sharedComponents/dropdown/Dropdown";

import InvoicePDF from "./InvoicePDF";
import OfferPreviewModal from "../../Offers/components/OfferPreviewModal";

import { downloadImageFooterLayout } from "../../../salesMasterData/SalesLayout/redux/LayoutApi";
import { LayoutListType } from "../../../salesMasterData/SalesLayout/types/LayoutResponsetypes";
import { getInvoiceDetails } from "../redux/InvoicesAPI";
import { InvoiceType } from "../types/InvoicesTypes";

interface Props {
  invoice?: InvoiceType;
  layoutFooterDetails: LayoutListType | undefined;
  invoiceId: number;
}

const InvoicePreview: FC<Props> = ({
  invoice,
  layoutFooterDetails,
  invoiceId,
}) => {
  const intl = useIntl();
  const lang = useLang();
  const [loading, setLoading] = useState(false);
  const [invoiceDetails, setInvoiceDetails] = useState(invoice);
  const [pdfUrl, setPdfUrl] = useState<string>("");
  const [showPreviewModal, setShowPreviewModal] = useState<boolean>(false);
  const [selectedPreviewType, setSelectedPreviewType] = useState<any>();

  useEffect(() => {
    if (invoiceId) {
      getInvoiceDetails(invoiceId)
        .then(({ data: { invoiceDetails } }) => {
          setInvoiceDetails(invoiceDetails);
        })
        .catch((error) => console.error(error?.response?.data?.message));
    } else {
      setInvoiceDetails(invoice);
    }
  }, [invoice]);

  function handleOpenPreview(minus?: boolean) {
    if (layoutFooterDetails) {
      setLoading(true);
      if (layoutFooterDetails?.headers_logo_id) {
        downloadImageFooterLayout(layoutFooterDetails?.headers_logo_id)
          .then(({ data }) => {
            const fileUrl = URL.createObjectURL(data);
            // @ts-ignore
            openPdf(layoutFooterDetails, invoiceDetails, fileUrl, minus);
            setShowPreviewModal(true);
          })
          .catch((error) => console.error(error?.response?.data?.message))
          .finally(() => {
            setLoading(false);
            setShowPreviewModal(true);
          });
      } else {
        // @ts-ignore
        openPdf(layoutFooterDetails, invoiceDetails, undefined, minus)
          .then(() => {
            setShowPreviewModal(true);
          })
          .catch(() => {
            errorToast(
              intl.formatMessage({ id: "INVOICES_PREVIEW_PDF_ERROR_MESSAGE" })
            );
          })
          .finally(() => {
            setLoading(false);
            setShowPreviewModal(true);
          });
      }
    }
  }

  const openPdf = async (
    layoutFooterDetails: LayoutListType,
    invoiceDetails: InvoiceType,
    blobURL?: string,
    minus?: boolean
  ) => {
    const blob = await pdf(
      <InvoicePDF
        layoutFooterDetails={layoutFooterDetails}
        invoice={invoiceDetails}
        logoURL={blobURL}
        lang={lang}
        minus={minus}
      />
    ).toBlob();
    const pdfURL = URL.createObjectURL(blob);
    setPdfUrl(pdfURL);
  };

  return (
    <>
      {showPreviewModal && (
        <OfferPreviewModal
          show={showPreviewModal}
          closeModal={() => {
            setShowPreviewModal(false);
            setSelectedPreviewType(undefined);
          }}
          pdfURL={pdfUrl}
        />
      )}

      {invoiceDetails?.status === "cancel" ? (
        <DropDown
          items={[
            { id: 1, name: "Original" },
            { id: 2, name: "Cancelled" },
          ]}
          selectedItem={selectedPreviewType}
          id="preview_type_selection"
          onSelectItem={(item) => {
            handleOpenPreview(item?.name === "Cancelled");
            setSelectedPreviewType(item);
          }}
          displayValue={
            selectedPreviewType?.name ||
            intl.formatMessage({
              id: "OPTION_PREVEIW_SELECTION",
            })
          }
          displayFunc={(item) => <div>{item.name}</div>}
          hideSearch
        />
      ) : (
        <div>
          <Button
            variant="primary"
            onClick={() => {
              handleOpenPreview(false);
            }}
          >
            {!loading && intl.formatMessage({ id: "PREVIEW" })}
            {loading && (
              <span className="indicator-progress" style={{ display: "block" }}>
                {intl.formatMessage({
                  id: "WEBSITE_GENERAL_LOADING_MESSAGE",
                })}{" "}
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            )}
          </Button>
        </div>
      )}
    </>
  );
};

export default InvoicePreview;
