import React, { FC, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import moment from "moment";

// custom
import { SVGICON } from "../../../../../_metronic/helpers";
import { useLang } from "../../../../../_metronic/i18n/Metronici18n";
import DeleteIcon from "../../../../../_metronic/assets/icons/delete.svg";
import { useGlobalModalContext } from "../../../../sharedComponents/modals/ModalContext";

import NoItemsFound from "../../../../sharedComponents/NoItemsFound/NoItemsFound";
import SimpleLoader from "../../../../sharedComponents/Loader/SimpleLoader";

import { PaymentPositionType } from "../types/InvoicesTypes";

interface Props {
  positions: PaymentPositionType[];
  // getPositionsList: () => void
  loading: boolean;
  setCheckedPositions: React.Dispatch<
    React.SetStateAction<PaymentPositionType[]>
  >;
  checkedPositions: PaymentPositionType[];
  setCheckedAllPositions: (state: boolean) => void;
  checkedAllPositions: boolean;
  deletePosition: (id: number) => void;
}
const PaymentPositionsList: FC<Props> = ({
  positions,
  // getPositionsList,
  loading,
  setCheckedPositions,
  checkedPositions,
  setCheckedAllPositions,
  checkedAllPositions,
  deletePosition,
}) => {
  const intl = useIntl();
  const lang = useLang();
  const [positionsList, setPositionsList] = useState<PaymentPositionType[]>([]);

  const { showDeleteConfirmModal } = useGlobalModalContext();

  useEffect(() => {
    setPositionsList(positions || []);
  }, [positions]);

  const isChecked = (position: PaymentPositionType) => {
    return checkedPositions?.findIndex(({ id }) => id === position.id) > -1;
  };

  const onCheckedChange = (position: PaymentPositionType) => {
    if (checkedPositions.findIndex(({ id }) => id === position.id) > -1) {
      setCheckedPositions((prev) =>
        prev.filter(({ id }) => id !== position.id)
      );
      setCheckedAllPositions(false);
    } else {
      setCheckedPositions((prev) => {
        const updated = [...prev, position];
        updated.length === positionsList.length && setCheckedAllPositions(true);
        return updated;
      });
    }
  };

  const getAllCheckChange = (e: boolean) => {
    setCheckedAllPositions(e);
    if (e) {
      setCheckedPositions(positionsList);
    } else {
      setCheckedPositions([]);
    }
  };

  return (
    <table className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer mh-100px mb-0">
      <thead>
        <tr className="text-start text-gray-400 fw-bolder fs-7 text-uppercase gs-0">
          {/* <th className='fm-checkbox-table-header'>
           
            <Checkbox onChange={getAllCheckChange} checked={checkedAllPositions} />
          </th> */}
          <th className="">
            {intl.formatMessage({
              id: "INVOICES_DATE_TITLE",
            })}
          </th>
          <th className="">
            {intl.formatMessage({
              id: "PAYMENT_POSITIONS_BID_PRICE",
            })}
          </th>
        </tr>
      </thead>
      <tbody className="fw-bold text-gray-600">
        {!!loading && (
          <tr>
            <td valign="top" colSpan={12} className="dataTables_empty">
              <SimpleLoader />
            </td>
          </tr>
        )}
        {!!positions?.length &&
          !loading &&
          positions?.map((position, i) => {
            return (
              <tr className="fs-6 text-gray-800" key={i}>
                {/* <td className=''>
                  <Checkbox
                    onChange={() => {
                      onCheckedChange(position)
                    }}
                    checked={isChecked(position)}
                  />
                </td> */}
                <td className="">
                  <span
                    className={"text-gray-800 text-hover-primary"}
                    role="button"
                  >
                    {moment(position.amount_paid_at).format("YYYY-MM-DD")}
                  </span>
                </td>

                <td className="text-gray-600 ">
                  {/* @ts-ignore */}
                  {(+position.amount_paid / 100).toLocaleString(lang) || 0}{" "}
                  <span className="ms-2">€</span>{" "}
                </td>

                <td className="d-flex justify-content-end">
                  <button
                    type="button"
                    className="btn btn-icon btn-light btn-active-light-danger btn-sm "
                    onClick={() => {
                      showDeleteConfirmModal(
                        intl.formatMessage({
                          id: "DELETE_PAYMENT_POSITION_CONFIRMATION",
                        }),
                        () => {
                          deletePosition(position?.id);
                        }
                      );
                    }}
                  >
                    <SVGICON src={DeleteIcon} className="svg-icon-3" />
                  </button>
                </td>
              </tr>
            );
          })}
        {!loading && !positions?.length && (
          <NoItemsFound languageKey="NO_ITEMS_FOUND" />
        )}
      </tbody>
    </table>
  );
};

export default PaymentPositionsList;
