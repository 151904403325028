import React, { FC, useEffect, useState } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { PageLink, PageTitle } from "../../../_metronic/layout/core";
import { useIntl } from "react-intl";
import { Helmet } from "react-helmet";
import Config from "../../config";
import DealsList from "./components/DealsList";
import EditDeal from "./components/EditDeal";
import { useSelector } from "react-redux";
import { RootState } from "../../../setup";
import { FieldLogsProvider } from "./components/context";
import { getDealPipelineDetailsById } from "./redux/DealsAPI";
import SimpleLoader from "../../sharedComponents/Loader/SimpleLoader";

interface Props {
  pipelineInformation: number;
}

const DealsPage: FC<Props> = ({ pipelineInformation }) => {
  const id = useSelector<RootState>(({ auth }) => auth.pipelineId) as number;
  const name = useSelector<RootState>(
    ({ auth }) => auth.pipelineName
  ) as string;
  const [loading, setLoading] = useState(false);
  const [pipelineName, setPipelineName] = useState(name || "");
  const intl = useIntl();

  const getDealPhaseAPI = () => {
    // setLoading(true);
    getDealPipelineDetailsById(id)
      .then((data) => {
        //@ts-ignore
        setPipelineName(data?.data?.pipeline_deals[0]?.pipeline_name);
      })
      .catch((e: any) => {})
      .finally(() => {
        // setLoading(false);
      });
  };

  useEffect(() => {
    getDealPhaseAPI();
    setPipelineName(name);
  }, [id, name]);

  const dealsBreadCrumbs: Array<PageLink> = [
    {
      title: intl.formatMessage({ id: "MENU_DEALS" }),
      path: `/content/deals`,
      isSeparator: false,
      isActive: false,
    },
  ];

  return (
    <div>
      <Helmet>
        <title>
          {Config.APP_NAME}-{intl.formatMessage({ id: "MENU_DEALS" })}
        </title>
      </Helmet>
      {/* <AccountHeader /> */}
      <Switch>
        <Route path={`/content/deals/overview`}>
          <PageTitle breadcrumbs={dealsBreadCrumbs}>
            {!loading && pipelineName}
          </PageTitle>
          {/* {loading && (
            <div className="spinner-border mt-7" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          )} */}
          <DealsList pipelineId={id} />
        </Route>

        <Route path={`/content/deals/editDeal`}>
          <PageTitle
            breadcrumbs={[
              ...dealsBreadCrumbs,
              {
                title: intl.formatMessage({
                  id: "DEAL_EDIT_TITLE",
                }),
                path: "/content/deals/editDeal",
                isSeparator: false,
                isActive: true,
              },
            ]}
          >
            {pipelineName}
          </PageTitle>
          <FieldLogsProvider>
            <EditDeal />
          </FieldLogsProvider>
        </Route>

        <Redirect from="/content" exact={true} to={`/content/deals/overview`} />
        <Redirect to={`/content/deals/overview`} />
      </Switch>
    </div>
  );
};

export default DealsPage;
