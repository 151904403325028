import clsx from "clsx";
import { Formik, useFormik } from "formik";
import { FC, useEffect, useRef, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import * as Yup from "yup";

// custom
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { RootState } from "../../../../setup";
import DropDown from "../../../sharedComponents/dropdown/Dropdown";
import { AddressType, CustomerType } from "../../auth/types/LoginResponseTypes";
import { CountryType } from "../../masterdata/types/GetCountriesListResponseTypes";
// import {StateType} from '../../masterdata/types/GetStatesListResponseType'
import { errorToast, successToast } from "../../../sharedComponents/toasts/Toasts";
import PartnerGeneralDetails from "../../partnerManagement/components/PartnerGeneralDetails";
import { getPartnerDetails, updatePartnerGenericDetails } from "../../partnerManagement/redux";
import { useHistory } from "react-router-dom";
import CloseButton from "../../../sharedComponents/Buttons/CloseButton";

interface Props {
	show: boolean;
	closeModal?: () => void;
	partner: any;
	getDealPhaseApi?: () => void;
}

const AddOrganisationAddress: FC<Props> = ({ show, closeModal, partner, getDealPhaseApi }) => {
	const countries = useSelector<RootState>(({ masterdata }) => masterdata.countriesList) as CountryType[];
	const history = useHistory();
	const intl = useIntl();

	// state
	const [loading, setLoading] = useState<boolean>(false);
	const [titleLength, setTitleLength] = useState<number>(1);
	const [selectedCountry, setSelectedCountry] = useState<CountryType>();
	const [width, setWidth] = useState({});
	const textareaRef = useRef(null);

	const getPartnerDetailsAPI = () => {
		partner.id &&
			getPartnerDetails(partner.id).then(({ data: { partnerDetails } }) => {
				//setPartner(partnerDetails);
				history.replace({ state: { partnerDetails: partnerDetails } });
			});
	};

	const PostGeneralDetailsSchema = Yup.object().shape({
		name: Yup.string().required(
			intl.formatMessage({
				id: "PARTNERS_GENERAL_DETAILS_FIELD_COMPANYNAME_VALIDATION_ERROR",
			})
		),
	});

	const initialValues = {
		name: partner.name || "",
		address_1: partner?.address_1 || "",
		address_2: partner?.address_2 || "",
		zip_code: partner?.zip_code || undefined,
		city: partner?.city || "",
		country_id: partner?.country_id || undefined,
		email: partner?.email || "",
		website_url: partner?.website || "",
		telephone: partner?.telefone || "",
	};

	const formik = useFormik({
		initialValues,
		validationSchema: PostGeneralDetailsSchema,
		enableReinitialize: true,
		onSubmit: async (values, { setStatus, setSubmitting }) => {
			setLoading(true);
			if (partner?.id && values.name) {
				setLoading(true);
				setStatus(null);
				updatePartnerGenericDetails(
					partner.id,
					values.name,
					values.address_1,
					values.address_2,
					values.city,
					values.zip_code || null,
					values.country_id || null,
					values.email,
					values.website_url,
					values.telephone
				)
					.then(() => {
						successToast(
							intl.formatMessage({
								id: "PARTNERS_ADDRESS_DETAILS_UPDATE_SUCCESS",
							})
						);
					})
					.catch((e) => {
						if (e.response?.status === 422) {
							setStatus(
								intl.formatMessage({
									id: "NAME_ALREADY_EXISTS",
								})
							);
							errorToast(
								intl.formatMessage({
									id: "PARTNERS_GENERAL_DETAILS_UPDATE_FAILURE",
								})
							);
						} else {
							errorToast(
								intl.formatMessage({
									id: "PARTNERS_GENERAL_DETAILS_UPDATE_FAILURE",
								})
							);
						}
					})
					.finally(() => {
						setLoading(false);
						getPartnerDetailsAPI();
						getDealPhaseApi?.();
						closeModal?.();
					});
			}
		},
	});

	const handleKeyUp = (value: string) => {
		const res = Math.ceil(value.length / 90);
		if (res <= 1) {
			setTitleLength(1);
		} else {
			setTitleLength(res);
		}
	};

	const handleResize = () => {
		setWidth({ width: window.innerWidth });
	};

	useEffect(() => {
		window.addEventListener("resize", handleResize);

		return () => window.removeEventListener("resize", handleResize);
	}, []);

	useEffect(() => {
		handleKeyUp(partner.name || "");
		countries.forEach((country) => {
			if (country.id === partner?.country_id) {
				formik.setFieldValue("country_id", country.id);
				setSelectedCountry(country);
			}
		});
	}, []);

	return (
		<Modal
			show={show}
			centered
			dialogClassName="medium-size-modal"
			className="kt-modal"
			contentClassName={loading ? "pe-none" : ""}
			backdrop="static"
      onHide={closeModal}
		>
			<Modal.Header>
				<Modal.Title>{intl.formatMessage({ id: "ADD_NEW_ADDRESS_MESSAGE" })}</Modal.Title>
        <CloseButton onClose={closeModal}/>
			</Modal.Header>
			<div className="">
				<Modal.Body className="overflow-inherit ">
					{/* Title */}
					<div className="fv-row tab-field">
						<label className="required tab-field-label">
							{intl.formatMessage({
								id: "PARTNERS_GENERAL_DETAILS_FIELD_COMPANYNAME",
							})}
						</label>
						<textarea
							ref={textareaRef}
							cols={90}
							rows={titleLength}
							className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
							autoComplete="off"
							placeholder=""
							{...formik.getFieldProps("name")}
							style={{ resize: "none" }}
							onKeyUp={() => handleKeyUp(formik.values.name)}
						></textarea>
						{formik.touched.name && formik.errors.name && (
							<div className="text-danger">
								<span role="alert">{formik.errors.name}</span>
							</div>
						)}
						{formik.status && (
							<div className="text-danger">
								<span role="alert">{formik.status}</span>
							</div>
						)}
					</div>

					{/* Address 1 */}
					<div className="fv-row tab-field">
						<label className="tab-field-label">
							{intl.formatMessage({
								id: "PARTNERS_GENERAL_DETAILS_FIELD_ADDRESS1",
							})}
						</label>
						<input
							placeholder={""}
							className={clsx("form-control form-control-lg form-control-solid")}
							{...formik.getFieldProps("address_1")}
							autoComplete="off"
						/>
					</div>

					{/* Address 2 */}
					<div className="fv-row tab-field">
						<label className="tab-field-label">
							{intl.formatMessage({
								id: "PARTNERS_GENERAL_DETAILS_FIELD_ADDRESS2",
							})}
						</label>
						<input
							placeholder={""}
							className={clsx("form-control form-control-lg form-control-solid")}
							{...formik.getFieldProps("address_2")}
							autoComplete="off"
						/>
					</div>

					<div className="row">
						{/* city */}
						<div className="fv-row tab-field col-lg-6">
							<label className="tab-field-label">
								{intl.formatMessage({
									id: "PARTNERS_GENERAL_DETAILS_FIELD_CITY",
								})}
							</label>
							<input
								placeholder={""}
								className={clsx("form-control form-control-lg form-control-solid")}
								{...formik.getFieldProps("city")}
								autoComplete="off"
							/>
						</div>

						{/* zip code */}
						<div className="fv-row tab-field col-lg-6">
							<label className="tab-field-label">
								{intl.formatMessage({
									id: "PARTNERS_GENERAL_DETAILS_FIELD_ZIPCODE",
								})}
							</label>
							<input
								placeholder={""}
								className={clsx("form-control form-control-lg form-control-solid")}
								{...formik.getFieldProps("zip_code")}
								autoComplete="off"
							/>
						</div>
					</div>

					{/* Country */}
					<div className="fv-row tab-field">
						<label className="tab-field-label">
							{intl.formatMessage({
								id: "PARTNERS_GENERAL_DETAILS_FIELD_COUNTRY",
							})}
						</label>
						<DropDown
							id="countriesDropDown"
							items={countries}
							selectedItem={selectedCountry}
							// @ts-ignore
							onSelectItem={(item: CountryType) => {
								formik.setFieldValue("country_id", item.id);
								setSelectedCountry(item);
							}}
							displayValue={selectedCountry?.name || ""}
							// @ts-ignore
							displayFunc={(item: CountryType) => {
								return item.emoji + " " + item.name;
							}}
						/>
					</div>

					<div className="fv-row tab-field">
						<label className="tab-field-label">
							{intl.formatMessage({
								id: "PARTNERS_GENERAL_DETAILS_FIELD_EMAIL",
							})}
						</label>
						<input
							type="text"
							autoComplete="off"
							{...formik.getFieldProps("email")}
							className={clsx("form-control form-control-lg form-control-solid")}
						/>
					</div>
					<div className="fv-row tab-field">
						<label className="tab-field-label">
							{intl.formatMessage({
								id: "PARTNERS_GENERAL_DETAILS_FIELD_WEBSITE_URL",
							})}
						</label>
						<input
							type="text"
							autoComplete="off"
							{...formik.getFieldProps("website_url")}
							className={clsx("form-control form-control-lg form-control-solid")}
						/>
					</div>
					<div className="fv-row tab-field mb-0">
						<label className="tab-field-label">
							{intl.formatMessage({
								id: "PARTNERS_GENERAL_DETAILS_FIELD_TELEPHONE",
							})}
						</label>
						<input
							type="text"
							autoComplete="off"
							{...formik.getFieldProps("telephone")}
							className={clsx("form-control form-control-lg form-control-solid")}
						/>
					</div>
				</Modal.Body>
			</div>
			<Modal.Footer className="justify-content-center">
				<Button variant="secondary" onClick={closeModal}>
					{intl.formatMessage({
						id: "CLOSE_BUTTON",
					})}
				</Button>
				<Button
					type="submit"
					id="kt_sign_in_submit"
					className="btn  btn-primary"
					onClick={() => {
						formik.handleSubmit();
					}}
				>
					{!loading && (
						<span className="indicator-label">
							{intl.formatMessage({
								id: "CUSTOMER_MANAGEMENT_ADDRESS_SUBMIT",
							})}
						</span>
					)}
					{loading && (
						<span className="indicator-progress" style={{ display: "block" }}>
							{intl.formatMessage({
								id: "CUSTOMER_MANAGEMENT_ADDRESS_LOADING_MESSAGE",
							})}
							<span className="spinner-border spinner-border-sm align-middle ms-2"></span>
						</span>
					)}
				</Button>
			</Modal.Footer>
		</Modal>
	);
};

export default AddOrganisationAddress;
