import React, { FC } from "react";
// import {Props} from 'react-apexcharts'
import { Modal, Button } from "react-bootstrap-v5";
import { useIntl } from "react-intl";

interface GlobalModelContext {
  isOpen: boolean;
  isLoading: boolean;
  deleteText: string;
  deleteButtonText: string;
  onDeletePress: () => void;
  hide: () => void;
}

const DeleteConfirmModal: FC<GlobalModelContext> = ({
  isOpen,
  isLoading,
  deleteText,
  deleteButtonText,
  onDeletePress,
  hide,
}) => {
  const intl = useIntl();
  return (
    <Modal
      show={isOpen}
      contentClassName={isLoading ? "pe-none" : ""}
      centered
      backdrop="static"
    >
      {/* <Modal.Header>
        <Modal.Title>{'New post'}</Modal.Title>
      </Modal.Header> */}
      <div className="overflow-auto">
        <Modal.Body className="overflow-inherit">
          <div className="d-flex flex-column align-items-center">
            <i
              className="bi bi-exclamation-circle text-warning"
              style={{ fontSize: "100px" }}
            ></i>
            <p className="fs-4 mt-8 text-center">
              {deleteText ? deleteText :  intl.formatMessage({ id: "DELETE_TEXT" })}
            </p>
          </div>
        </Modal.Body>
      </div>
      <Modal.Footer className="justify-content-center">
        <Button variant="secondary" onClick={hide}>
          {intl.formatMessage({ id: "NO_CANCEL_BUTTON" })}
        </Button>
        <button
          // variant={'danger'}
          onClick={() => {
            onDeletePress?.();
          }}
          className={`${
            deleteButtonText ? "btn btn-danger" : "btn btn-danger"
          }`}
        >
          {!isLoading &&
            (deleteButtonText
              ? deleteButtonText
              : intl.formatMessage({ id: "COMMON_YES_DELETE" }))}
          {isLoading && (
            <span
              className="indicator-progress d-flex flex-row align-items-center"
              style={{ display: "block" }}
            >
              Please wait...{" "}
              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          )}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteConfirmModal;
