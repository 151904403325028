import React, { FC, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import {
  PhaseApproverType,
  PipelineType,
} from "../types/PipelinesResponseType";
import { SVGICON } from "../../../../_metronic/helpers";
import PlusIcon from "../../../../_metronic/assets/icons/plus.svg";
import {
  needApproval,
  updateApprovers,
  updatePhase,
} from "../redux/PipelinesAPI";
import { useGlobalModalContext } from "../../../sharedComponents/modals/ModalContext";
import {
  errorToast,
  successToast,
} from "../../../sharedComponents/toasts/Toasts";

import { UserType } from "../../userManagement/types/getUsersListResponseType";

import SelectedUsersModal from "../../teams/components/SelectUsersModal";
import { UserSymbol } from "../../tasks/components/UserSymbol";
import clsx from "clsx";

interface Props {
  pipeline: PipelineType;
  activePhase: any;
  getPipelineDetailsById: () => void;
}

const PipelinePhaseApprovers: FC<Props> = ({
  pipeline,
  activePhase,
  getPipelineDetailsById,
}) => {
  const intl = useIntl();

  //   state
  const [isChecked, setIsChecked] = useState(
    activePhase?.need_approvals === 1 ? true : false
  );
  const {
    showDeleteConfirmModal,
    hideDeleteConfirmModal,
    setDeleteModalLoading,
  } = useGlobalModalContext();

  const [phaseIsEndPhase, setPhaseIsEndPhase] = useState(
    activePhase?.condition === "end" && activePhase?.display_order !== null
      ? 1
      : 0
  );
  const [openUserModal, setOpenUserModal] = useState<{
    show: boolean;
    type: string;
    approvalValue: number;
  }>({
    show: false,
    type: "",
    approvalValue: 0,
  });

  const handleToggleChange = (
    checked: boolean | ((prevState: boolean) => boolean)
  ) => {
    if (activePhase?.phaseApprovers?.length === 0 && !isChecked) {
      // Open the modal if no approvers are present
      setOpenUserModal({
        show: true,
        type: "primary",
        approvalValue: checked ? 1 : 0,
      });
      // Keep the toggle checked but modal will confirm the action
      setIsChecked(checked);
    } else {
      // Directly handle approval if approvers are already present
      handleApproval(checked ? 1 : 0);
      setIsChecked(checked);
    }
  };

  useEffect(() => {
    if (activePhase?.phaseApprovers?.length === 0) {
      setIsChecked(false);
    }
  }, [activePhase?.phaseApprovers]);

  useEffect(() => {
    if (activePhase?.phaseApprovers?.length > 0) {
      setIsChecked(activePhase?.need_approvals === 1);
    }
  }, [activePhase]);

  useEffect(() => {
    if (pipeline && pipeline.phases && pipeline.phases.length > 0) {
      setPhaseIsEndPhase(
        activePhase?.condition === "end" && activePhase?.display_order !== null
          ? 1
          : 0
      );
    }
  }, [activePhase]);

  const handleIsEndpHase = (isEndPhase: number) => {
    updatePhase(
      pipeline?.id,
      activePhase?.id,
      activePhase?.name,
      activePhase?.is_required,
      isEndPhase
    )
      .then(() => {
        successToast(
          intl.formatMessage({
            id: "PROJECTS_PIPELINE_PHASE_SUCCESS_MESSAGE",
          })
        );
      })
      .catch(() => {
        errorToast(
          intl.formatMessage({
            id: "PROJECTS_PIPELINE_PHASE_FAILURE_MESSAGE",
          })
        );
      })
      .finally(() => {
        getPipelineDetailsById?.();
      });
  };

  const handleApproval = (need_approvals: number) => {
    needApproval(activePhase?.id, need_approvals)
      .then(() => {
        successToast(
          intl.formatMessage({
            id: "PIPELINE_APPROVAL_SUCCESS_MESSAGE",
          })
        );
      })
      .catch(() => {
        errorToast(
          intl.formatMessage({
            id: "PIPELINE_APPROVAL_FAILURE_MESSAGE",
          })
        );
      })
      .finally(() => {
        getPipelineDetailsById?.();
      });
  };

  const handleUpdateApprovers = (approverUsers: UserType[], type: string) => {
    const result: { users_id: number; condition: string }[] = [];
    const existingResult: { users_id: number; condition: string }[] = [];
    approverUsers.map((data) => {
      let obj = {
        users_id: data.id,
        condition: type,
      };
      result.push(obj);
    });

    activePhase.phaseApprovers.map(
      (data: { users_id: number; condition: any }) => {
        let obj = {
          users_id: data.users_id,
          condition: data?.condition,
        };
        existingResult.push(obj);
      }
    );

    updateApprovers(activePhase.id, [...result, ...existingResult])
      .then((data) => {
        successToast(
          intl.formatMessage({ id: "APPROVERS_ADDED_SUCCESS_MESSAGE" })
        );
      })
      .catch(() => {
        errorToast(
          intl.formatMessage({ id: "APPROVERS_ADDED_FAILURE_MESSAGE" })
        );
      })
      .finally(() => {
        getPipelineDetailsById();
      });
  };

  const deleteApprover = (user: PhaseApproverType) => {
    setDeleteModalLoading(true);
    const result = activePhase.phaseApprovers.filter(
      (data: { users_id: number }) => data.users_id !== user.users_id
    );
    updateApprovers(activePhase.id, result)
      .then((data) => {
        successToast(
          intl.formatMessage({ id: "APPROVERS_REMOVED_SUCCESS_MESSAGE" })
        );
      })
      .catch(() => {
        errorToast(
          intl.formatMessage({ id: "APPROVERS_REMOVED_FAILURE_MESSAGE" })
        );
      })
      .finally(() => {
        setDeleteModalLoading(true);
        hideDeleteConfirmModal();
        getPipelineDetailsById();
      });
  };

  return (
    <>
      {openUserModal.show && (
        <SelectedUsersModal
          show={openUserModal.show}
          closeModal={() => {
            setOpenUserModal({
              ...openUserModal,
              show: false,
            });
            if (activePhase?.phaseApprovers?.length === 0) {
              setIsChecked(false);
            }
          }}
          onSelectUsers={(customers) => {
            if (activePhase.phaseApprovers.length === 0) {
              handleApproval(openUserModal.approvalValue);
            }
            handleUpdateApprovers(customers, openUserModal.type);
            setOpenUserModal({
              ...openUserModal,
              show: false,
            });
          }}
          isSelectionModal={true}
          selectedContacts={activePhase.phaseApprovers}
        />
      )}
      <div>
        {activePhase?.condition !== "start" &&
          activePhase?.display_order !== null && (
            <div className="card card-mt">
              <div className="card-header card-px">
                <div className="card-title">
                  <h2>{intl.formatMessage({ id: "PIPELINE_PHASE_APPROVALS_CARD_TITLE" })}</h2>
                </div>
              </div>
              <div className="card-body card-p">
                {/* questionirre */}
                <div className="d-flex flex-column">
                  {activePhase?.condition !== "start" &&
                    activePhase?.display_order !== null && (
                      <div className={clsx("d-flex flex-stack")}>
                        <div className="fw-bold fs-6">
                          {intl.formatMessage({
                            id: "PIPELINE_PROJECTS_QUESTION1",
                          })}
                        </div>
                        <div className="form-check form-switch form-switch-sm form-check-custom form-check-solid ms-4">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            name="notifications"
                            value=""
                            checked={phaseIsEndPhase === 1 ? true : false}
                            style={{ width: "45px", height: "22px" }}
                            onChange={(e) => {
                              setPhaseIsEndPhase(e.target.checked ? 1 : 0);
                              handleIsEndpHase(e.target.checked ? 1 : 0);
                            }}
                          />
                        </div>
                      </div>
                    )}
                </div>
                <div>
                  {activePhase?.condition === "start" ||
                  (activePhase?.condition === "end" &&
                    activePhase?.display_order === null)
                    ? null
                    : pipeline?.transition_type === "step-by-step" && (
                        <div className="d-flex flex-stack mt-4">
                          <div className="fw-bold fs-6">
                            {intl.formatMessage({
                              id: "PIPELINE_PROJECTS_QUESTION3",
                            })}
                          </div>
                          <div className="form-check form-switch form-switch-sm form-check-custom form-check-solid ms-4">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              name="notifications"
                              value=""
                              checked={isChecked}
                              style={{ width: "45px", height: "22px" }}
                              onChange={(e) => {
                                handleToggleChange(e.target.checked);
                              }}
                            />
                          </div>
                        </div>
                      )}
                </div>
                {activePhase?.need_approvals === 1 && isChecked && (
                  <div className="mt-4">
                    <div className="flex-wrap borderNone">
                      {/* Primary Users */}
                      <div className="mb-4">
                        <div className="fw-bold fs-6">
                          {intl.formatMessage({
                            id: "PIPELINE_APPROVAL_PRIMARY_USERS",
                          })}
                        </div>
                        {activePhase?.phaseApprovers &&
                        activePhase?.phaseApprovers.length > 0 ? (
                          <div className="symobleDetails d-flex justify-content-between align-items-center">
                            <div className="symbol-group symbol-hover flex-nowrap">
                              {activePhase?.phaseApprovers?.map(
                                (user: any, index: number) => {
                                  if (user?.condition === "primary") {
                                    return (
                                      <>
                                        <React.Fragment key={index}>
                                          <UserSymbol
                                            data={user}
                                            handleRemove={() => {
                                              if (
                                                activePhase?.phaseApprovers &&
                                                activePhase?.phaseApprovers
                                                  .length > 0 &&
                                                activePhase?.phaseApprovers.filter(
                                                  (temp: {
                                                    condition: string;
                                                  }) =>
                                                    temp?.condition ===
                                                    "primary"
                                                ).length > 0
                                              ) {
                                                setDeleteModalLoading(false);
                                                showDeleteConfirmModal(
                                                  intl.formatMessage({
                                                    id: "APPROVER_PRIMARY_DELETE_CONFIRM_MESSAGE",
                                                  }),
                                                  () => {
                                                    deleteApprover(user);
                                                    if (
                                                      activePhase
                                                        ?.phaseApprovers
                                                        ?.length === 1
                                                    ) {
                                                      setIsChecked(false);
                                                    }
                                                  }
                                                );
                                              }
                                            }}
                                          />
                                        </React.Fragment>

                                        {index ===
                                          activePhase?.phaseApprovers.length -
                                            1 && (
                                          <div
                                            className="symbol symbol-35px symbol-circle "
                                            onClick={() => {
                                              setOpenUserModal({
                                                show: true,
                                                type: "primary",
                                                approvalValue:
                                                  activePhase?.need_approvals ===
                                                  1
                                                    ? 1
                                                    : 0,
                                              });
                                            }}
                                          >
                                            <span className="symbol-label text-inverse-warning fw-bold btn-primary">
                                              <SVGICON
                                                src={PlusIcon}
                                                className="svg-icon-2 svg-icon-white"
                                              />
                                            </span>
                                          </div>
                                        )}
                                      </>
                                    );
                                  } else {
                                    return (
                                      <>
                                        {index ===
                                          activePhase?.phaseApprovers.length -
                                            1 && (
                                          <div
                                            className="symbol symbol-35px symbol-circle "
                                            onClick={() => {
                                              setOpenUserModal({
                                                show: true,
                                                type: "primary",
                                                approvalValue:
                                                  activePhase?.need_approvals ===
                                                  1
                                                    ? 1
                                                    : 0,
                                              });
                                            }}
                                          >
                                            <span className="symbol-label text-inverse-warning fw-bold btn-primary">
                                              <SVGICON
                                                src={PlusIcon}
                                                className="svg-icon-2 svg-icon-white"
                                              />
                                            </span>
                                          </div>
                                        )}
                                      </>
                                    );
                                  }
                                }
                              )}
                            </div>
                          </div>
                        ) : (
                          <>
                            <div
                              className="symbol symbol-35px symbol-circle "
                              role="button"
                              onClick={() => {
                                setOpenUserModal({
                                  show: true,
                                  type: "primary",
                                  approvalValue:
                                    activePhase?.need_approvals === 1 ? 1 : 0,
                                });
                              }}
                            >
                              <span className="symbol-label text-inverse-warning fw-bold btn-primary">
                                <SVGICON
                                  src={PlusIcon}
                                  className="svg-icon-2 svg-icon-white"
                                />
                              </span>
                            </div>
                          </>
                        )}
                      </div>
                      {/* Secondary Users */}
                      <div>
                        {activePhase?.phaseApprovers &&
                          activePhase?.phaseApprovers.length > 0 &&
                          activePhase?.phaseApprovers.filter(
                            (temp: { condition: string }) =>
                              temp?.condition === "primary"
                          ).length > 0 && (
                            <div className="mt-4">
                              <div className="fw-bold fs-6">
                                {intl.formatMessage({
                                  id: "PIPELINE_APPROVAL_SECONDARY_USERS",
                                })}
                              </div>
                              <div className="symobleDetails d-flex justify-content-between align-items-center">
                                <div className="symbol-group symbol-hover flex-nowrap">
                                  {activePhase?.phaseApprovers?.map(
                                    (user: any, index: number) => {
                                      if (user?.condition === "secondary") {
                                        return (
                                          <>
                                            <React.Fragment key={index}>
                                              <UserSymbol
                                                data={user}
                                                handleRemove={() => {
                                                  setDeleteModalLoading(false);
                                                  showDeleteConfirmModal(
                                                    intl.formatMessage({
                                                      id: "APPROVER_SECONDARY_DELETE_CONFIRM_MESSAGE",
                                                    }),
                                                    () => {
                                                      deleteApprover(user);
                                                    }
                                                  );
                                                }}
                                              />
                                            </React.Fragment>

                                            {index ===
                                              activePhase?.phaseApprovers
                                                .length -
                                                1 && (
                                              <div
                                                className=""
                                                onClick={() => {
                                                  setOpenUserModal({
                                                    show: true,
                                                    type: "secondary",
                                                    approvalValue:
                                                      activePhase?.need_approvals ===
                                                      1
                                                        ? 1
                                                        : 0,
                                                  });
                                                }}
                                              >
                                                <div className="symbol symbol-35px symbol-circle">
                                                  <span className="symbol-label text-inverse-warning fw-bold bg-color-sybmol">
                                                    <SVGICON
                                                      src={PlusIcon}
                                                      className="svg-icon-2 svg-icon-white"
                                                    />
                                                  </span>
                                                </div>
                                              </div>
                                            )}
                                          </>
                                        );
                                      } else {
                                        return (
                                          <>
                                            {index ===
                                              activePhase?.phaseApprovers
                                                .length -
                                                1 && (
                                              <div
                                                className=""
                                                onClick={() => {
                                                  setOpenUserModal({
                                                    show: true,
                                                    type: "secondary",
                                                    approvalValue:
                                                      activePhase?.need_approvals ===
                                                      1
                                                        ? 1
                                                        : 0,
                                                  });
                                                }}
                                                role="button"
                                              >
                                                <div className="symbol symbol-35px symbol-circle">
                                                  <span className="symbol-label text-inverse-warning fw-bold btn-primary">
                                                    <SVGICON
                                                      src={PlusIcon}
                                                      className="svg-icon-2 svg-icon-white"
                                                    />
                                                  </span>
                                                </div>
                                              </div>
                                            )}
                                          </>
                                        );
                                      }
                                    }
                                  )}
                                </div>
                              </div>
                            </div>
                          )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
      </div>
    </>
  );
};

export default PipelinePhaseApprovers;
