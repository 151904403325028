import React, { FC } from "react";
import { Modal } from "react-bootstrap";
import { useIntl } from "react-intl";
import "./style.scss";
import CloseButton from "../../../../sharedComponents/Buttons/CloseButton";

interface Props {
  modalProps: ModalProps;
  pdfUrl: any;
  closeLayoutModal: () => void;
}

interface ModalProps {
  show: boolean;
  type?: string;
  logo?: string;
}

const LayoutPreviewModal: FC<Props> = ({
  modalProps,
  closeLayoutModal,
  pdfUrl,
}) => {
  const { show } = modalProps;
  const intl = useIntl();
  const loading = false;

  return (
    <Modal
      show={show}
      centered
      dialogClassName="extra-extra-large-size-modal"
      className="kt-modal"
      contentClassName={loading ? "pe-none" : ""}
      backdrop="static"
    >
      <Modal.Header>
        <Modal.Title>
          {intl.formatMessage({
            id: "MASTERDATA_SALES_LAYOUT_PREVIEW_MODAL_TITLE",
          })}
        </Modal.Title>
        <CloseButton onClose={closeLayoutModal} />
      </Modal.Header>
      <>
        <Modal.Body className="full_modal_height">
          <div
            className="d-flex flex-row justify-content-center"
            style={{ height: "100%" }}
          >
            <iframe src={pdfUrl} height="100%" width="100%" title={""}></iframe>
          </div>
        </Modal.Body>
      </>
    </Modal>
  );
};

export default LayoutPreviewModal;
