import React, { FC, useEffect, useRef, useState } from "react";
import { Field, FieldArray, FormikProvider, useFormik } from "formik";
import { useHistory } from "react-router-dom";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import _ from "lodash";

import { SVGICON } from "../../../../../_metronic/helpers";
import { useLang } from "../../../../../_metronic/i18n/Metronici18n";
import DeleteIcon from "../../../../../_metronic/assets/icons/delete.svg";

import { useGlobalModalContext } from "../../../../sharedComponents/modals/ModalContext";
import DynamicDropdown from "../../../../sharedComponents/DynamicDropdown/DynamicDropdown";
import {
  errorToast,
  successToast,
} from "../../../../sharedComponents/toasts/Toasts";

import { RootState } from "../../../../../setup";
import {
  SaleItemType,
  SaleProductType,
  SaleInternalItemType,
} from "../types/SaleTypes";
import { OfferType } from "../types/OfferTypes";

import { updateOfferNotes } from "../redux/OffersApi";
import {
  createSale,
  addSaleItems,
  deleteSaleItem,
  updateSaleItem,
} from "../redux/SalesApi";

interface Props {
  offer: OfferType;
  offerId?: number;
  getOfferDetailsAPI: (_id?: number) => void;
}

const AddOfferItems: FC<Props> = ({ offer, getOfferDetailsAPI, offerId }) => {
  const intl = useIntl();
  const lang = useLang();
  const history = useHistory();
  const {
    showDeleteConfirmModal,
    hideDeleteConfirmModal,
    setDeleteModalLoading,
  } = useGlobalModalContext();

  const productsAndItems = useSelector<RootState>(
    //@ts-ignore
    ({ salesOffers }) => salesOffers.productsAndItems
  ) as SaleProductType[];

  const [headerNotes, setHeaderNotes] = useState(
    offer?.offer_header_notes || ""
  );
  const [footerNotes, setFooterNotes] = useState(
    offer?.offer_footer_notes || ""
  );
  const [title, setTitle] = useState(offer?.subject || "");
  const [taxedProducts, setTaxedProducts] = useState({}) as any;
  const [discountedProducts, setDiscountedProducts] = useState({}) as any;

  useEffect(() => {
    setTitle(offer?.subject || "");
    setFooterNotes(offer?.offer_footer_notes || "");
    setHeaderNotes(offer?.offer_header_notes || "");
  }, [offer]);

  const stripHtml = (html: string) => {
    let tmp = document.createElement("DIV");
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || "";
  };

  const handleAddItems = (item_ids: any[]) => {
    if (!offerId) {
      const default_payload = { to_type: "business" };
      createSale(default_payload)
        .then(({ data: { salesOffer } }) => {
          history.replace({
            state: { id: salesOffer?.id, sales_id: salesOffer?.sales_id },
          });
          return { offer_id: salesOffer?.id, sales_id: salesOffer?.sales_id };
        })
        .then(({ offer_id, sales_id }) => {
          addSaleItems(sales_id, item_ids)
            .then(() => {
              successToast(
                intl.formatMessage({
                  id: "OFFERS_ITEM_INTERNAL_ADD_SUCCESS_MESSAGE",
                })
              );
            })
            .catch(() => {
              errorToast(
                intl.formatMessage({
                  id: "OFFERS_ITEM_INTERNAL_ADD_FAILURE_MESSAGE",
                })
              );
            })
            .finally(() => {
              getOfferDetailsAPI?.(offer_id);
            });
        })
        .catch((error) => console.error(error?.response?.data?.message));
    } else {
      addSaleItems(offer?.sales_id, item_ids)
        .then(() => {
          successToast(
            intl.formatMessage({
              id: "OFFERS_ITEM_INTERNAL_ADD_SUCCESS_MESSAGE",
            })
          );
        })
        .catch(() => {
          errorToast(
            intl.formatMessage({
              id: "OFFERS_ITEM_INTERNAL_ADD_FAILURE_MESSAGE",
            })
          );
        })
        .finally(() => {
          getOfferDetailsAPI?.(offerId);
        });
    }
  };

  const validationSchema = Yup.object({
    items: Yup.array().of(
      Yup.object({
        title: Yup.string()
          .required(
            intl.formatMessage({
              id: "CONTRACTS_ADD_CONTRACT_VALIDATION_TITLE",
            })
          )
          .nullable(),
        unit_price: Yup.number()
          .required(
            intl.formatMessage({
              id: "SALES_OFFER_ADD_ITEM_IS_REQUIRED_VALIDATION",
            })
          )
          .min(0)
          .nullable(),
        tax_percentage: Yup.number()
          .required(
            intl.formatMessage({
              id: "SALES_OFFER_ADD_ITEM_IS_REQUIRED_VALIDATION",
            })
          )
          .min(0)
          .nullable(),
      })
    ),
  });

  const getInitialValues = (internalItems: SaleItemType[]) => {
    let arr: any[] = [];
    internalItems?.forEach((item: SaleItemType) => {
      arr.push({
        id: item?.id,
        title: item?.product_title,
        description: stripHtml(item?.product_description) || "",
        quantity: parseFloat(item?.quantity) || 1,
        unit_price: item?.unit_price
          ? (parseFloat(item?.unit_price) / 100).toFixed(2)
          : null,
        tax_percentage: parseFloat(item?.tax_percentage),
        discount_percentage: parseFloat(item?.discount_percentage),
      });
    });

    return arr;
  };

  const initialValues = {
    items: [...getInitialValues(offer?.sales_details?.sale_items)]?.sort(
      (a, b) => a.id - b.id
    ),
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: async () => {},
  });

  const deleteSelectedItem = (id: number) => {
    setDeleteModalLoading(true);
    deleteSaleItem(id)
      .then(() => {
        successToast(
          intl.formatMessage({ id: "OFFERS_ITEM_DELETE_SUCCESS_MESSAGE" })
        );
      })
      .catch(() => {
        errorToast(
          intl.formatMessage({ id: "OFFERS_ITEM_DELETE_FAILURE_MESSAGE" })
        );
      })
      .finally(() => {
        setDeleteModalLoading(false);
        hideDeleteConfirmModal();
        getOfferDetailsAPI();
      });
  };

  const [updateLoading, setUpdateLoading] = useState(false);
  const handleUpdateItem = async (currItem: SaleInternalItemType) => {
    let { id, ...rest } = currItem;
    setUpdateLoading(true);
    updateSaleItem(id, {
      ...rest,
      quantity: currItem?.quantity ? currItem?.quantity : 1,
      unit_price: currItem?.unit_price
        ? Number(currItem?.unit_price) * 100
        : null,
    })
      .then(() => {
        successToast(
          intl.formatMessage({ id: "OFFERS_ITEM_UPDATE_SUCCESS_MESSAGE" })
        );
      })
      .catch(() => {
        errorToast(
          intl.formatMessage({ id: "OFFERS_ITEM_UPDATE_FAILURE_MESSAGE" })
        );
      })
      .finally(() => {
        getOfferDetailsAPI();
        setUpdateLoading(false);
      });
  };

  const handleUpdateNotes = (
    type: "footer_notes" | "header_notes" | "subject"
  ) => {
    if (
      type === "header_notes"
        ? offer?.offer_header_notes !== headerNotes
        : type === "subject"
          ? offer?.subject !== title
          : offer?.offer_footer_notes !== footerNotes
    ) {
      let notes =
        type === "header_notes"
          ? headerNotes
          : type === "subject"
            ? title
            : footerNotes;
      if (notes) {
        if (!offerId) {
          createSale()
            .then(({ data: { salesOffer } }) => {
              history.replace({
                state: {
                  id: salesOffer?.id,
                  sales_id: salesOffer?.sales_id,
                  offer: salesOffer,
                },
              });
              return { id: salesOffer?.id, sales_id: salesOffer?.sales_id };
            })
            .then((res) => {
              updateOfferNotes(res?.id, type, notes)
                .then(() => {
                  successToast(
                    intl.formatMessage({
                      id:
                        type === "footer_notes"
                          ? "OFFERS_FOOTER_NOTES_UPDATE_SUCCESS_MESSAGE"
                          : type === "subject"
                            ? "OFFERS_SUBJECT_UPDATE_SUCCESS_MESSAGE"
                            : "OFFERS_HEADER_NOTES_UPDATE_SUCCESS_MESSAGE",
                    })
                  );
                })
                .catch(() => {
                  errorToast(
                    intl.formatMessage({
                      id:
                        type === "footer_notes"
                          ? "OFFERS_FOOTER_NOTES_FAILURE_MESSAGE"
                          : type === "subject"
                            ? "OFFERS_SUBJECT_FAILURE_MESSAGE"
                            : "OFFERS_HEADER_NOTES_FAILURE_MESSAGE",
                    })
                  );
                })
                .finally(() => {
                  getOfferDetailsAPI(res?.id);
                });
            })
            .catch((error) => console.error(error?.response?.data?.message));
        } else {
          updateOfferNotes(offerId, type, notes)
            .then(() => {
              successToast(
                intl.formatMessage({
                  id:
                    type === "footer_notes"
                      ? "OFFERS_FOOTER_NOTES_UPDATE_SUCCESS_MESSAGE"
                      : type === "subject"
                        ? "OFFERS_SUBJECT_UPDATE_SUCCESS_MESSAGE"
                        : "OFFERS_HEADER_NOTES_UPDATE_SUCCESS_MESSAGE",
                })
              );
            })
            .catch(() => {
              errorToast(
                intl.formatMessage({
                  id:
                    type === "footer_notes"
                      ? "OFFERS_FOOTER_NOTES_FAILURE_MESSAGE"
                      : type === "subject"
                        ? "OFFERS_SUBJECT_FAILURE_MESSAGE"
                        : "OFFERS_HEADER_NOTES_FAILURE_MESSAGE",
                })
              );
            })
            .finally(() => {
              getOfferDetailsAPI?.(offerId);
            });
        }
      }
    }
  };

  const getProductPrice = (item: SaleItemType) => {
    if (!item.unit_price) {
      return 0;
    }

    const tax_behaviour = "inclusive";

    let initialPrice =
      Number(item.unit_price) * Number(item.quantity ? item.quantity : 1);
    let discountAmount = item.discount_percentage
      ? initialPrice * (Number(item.discount_percentage) / 100)
      : 0;
    let discountedPrice = initialPrice - discountAmount;

    let taxAmount = item.tax_percentage
      ? discountedPrice * (Number(item.tax_percentage) / 100)
      : 0;

    return discountedPrice + (tax_behaviour === "inclusive" ? 0 : taxAmount);
  };

  const getNetProductPrice = (item: SaleItemType) => {
    if (!item.unit_price) {
      return 0;
    }

    let initialPrice =
      Number(item.unit_price) * Number(item.quantity ? item.quantity : 1);

    let taxPercentage = Number(item.tax_percentage) / 100;
    let netPrice = initialPrice / (1 + taxPercentage);

    // let taxAmount = item.tax_percentage
    //   ? initialPrice * (Number(item.tax_percentage) / 100)
    //   : 0;
    // let netPrice = initialPrice - taxAmount;

    return Number(netPrice.toFixed(2));
  };

  const getTotalAmount = () => {
    let result = 0;
    formik?.values?.items?.forEach((item) => {
      result += getProductPrice(item);
    });
    return Number(result.toFixed(2));
  };

  const getNetTotalAmount = () => {
    let result = 0;

    formik?.values?.items?.forEach((item) => {
      result += getNetProductPrice(item);
    });
    return Number(result.toFixed(2));
  };

  const getTotalTaxedAmount = (items: SaleItemType[]) => {
    let result = 0;
    items.forEach((item) => {
      if (!item.unit_price) {
        return 0;
      }

      // let initialPrice =
      //   Number(item.unit_price) * Number(item.quantity ? item.quantity : 1);
      let initialPrice = getNetProductPrice(item);

      let taxAmount = item.tax_percentage
        ? initialPrice * (Number(item.tax_percentage) / 100)
        : 0;
      result = result + taxAmount;
    });

    return Number(result.toFixed(2));
  };

  const getTotalDiscountAmount = (items: SaleItemType[]) => {
    let result = 0;
    items.forEach((item) => {
      if (!item.unit_price) {
        return 0;
      }

      let initialPrice =
        Number(item.unit_price) * Number(item.quantity ? item.quantity : 1);
      let discountAmount = item.discount_percentage
        ? initialPrice * (Number(item.discount_percentage) / 100)
        : 0;
      result = result + discountAmount;
    });

    return Number(result.toFixed(2));
  };

  const calculateDiscounts = () => {
    const discounts: any = {};
    formik.values.items?.forEach((item) => {
      if (Boolean(item.discount_percentage)) {
        if (!discounts[item.discount_percentage]) {
          discounts[item.discount_percentage] = [];
        }
        discounts[item.discount_percentage].push(item);
      }
    });
    setDiscountedProducts(discounts);
  };

  const calculateTaxes = () => {
    const taxes: any = {};
    formik.values.items?.forEach((item) => {
      if (Boolean(item.tax_percentage)) {
        if (!taxes[item.tax_percentage]) {
          taxes[item.tax_percentage] = [];
        }
        taxes[item.tax_percentage].push(item);
      }
    });
    setTaxedProducts(taxes);
  };

  const [triggerUpdate, setTriggerUpdate] = useState();
  const timeoutRef: any = useRef(null);
  useEffect(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = window.setTimeout(() => {
      if (triggerUpdate) {
        handleUpdateItem(triggerUpdate);
      }
    }, 800);

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [triggerUpdate]);

  useEffect(() => {
    let descriptions = document.querySelectorAll(".item_description_container");
    descriptions?.forEach((item: any) => {
      item.style.height = "auto";
      item.style.height = `${item.scrollHeight + 8}px`;
    });
    calculateTaxes();
    calculateDiscounts();
  }, [formik.values]);

  return (
    <div>
      <div className="px-10 w-100 my-2 mb-4">
        <label className="form-label">
          {intl.formatMessage({
            id: "EVENTS_INVITATIONS_SELECT_TEMPLATE_FIELD_SUBJECT",
          })}
        </label>
        <input
          className="form-control form-control-solid"
          value={title}
          onChange={(e) => {
            setTitle(e.target.value);
          }}
          onBlur={() => {
            handleUpdateNotes("subject");
          }}
          disabled={
            offer?.status &&
            offer?.status !== "draft" &&
            offer?.status !== "open"
          }
        />
      </div>
      <div className="px-10 w-100 my-2">
        <label className="form-label">
          {intl.formatMessage({
            id: "SALES_OFFER_EDIT_HEADER_NOTES_LABEL",
          })}
        </label>
        <textarea
          className="form-control form-control-solid resize-none"
          rows={3}
          value={headerNotes}
          onChange={(e) => {
            setHeaderNotes(e.target.value);
          }}
          onBlur={() => {
            handleUpdateNotes("header_notes");
          }}
          disabled={
            offer?.status &&
            offer?.status !== "draft" &&
            offer?.status !== "open"
          }
        ></textarea>
      </div>

      <div className="table-responsive px-10 w-100 my-2 mb-6">
        <table
          className="table g-5 gs-0 mb-0 fw-bold text-gray-700"
          data-kt-element="items"
        >
          <thead>
            <tr className="border-bottom fs-7 fw-bold text-gray-700 text-uppercase">
              <th className="min-w-300px w-475px required">
                {intl.formatMessage({ id: "SALES_OFFER_PRODUCTS_LABEL" })}
              </th>
              <th className="min-w-75px w-100px">
                {intl.formatMessage({
                  id: "SALES_OFFER_PRODUCTS_QUANTITY_PLACEHOLDER",
                })}
              </th>
              <th className="min-w-125px w-150px required">
                {intl.formatMessage({
                  id: "SALES_OFFER_PRODUCTS_UNIT_PRICE_PLACEHOLDER",
                })}
              </th>
              <th className="min-w-75px w-150px required">
                {intl.formatMessage({
                  id: "SALES_OFFER_PRODUCTS_TAX_PERCENT_PLACEHOLDER",
                })}
              </th>
              <th className="min-w-125px w-150px">
                {intl.formatMessage({
                  id: "SALES_OFFER_PRODUCTS_DISCOUNT_PERCENT_PLACEHOLDER",
                })}
              </th>
              <th className="min-w-100px w-150px text-end">
                {intl.formatMessage({
                  id: "SALES_OFFER_PRODUCTS_TOTAL_COLUMN_LABEL",
                })}
              </th>
              {offer?.status === "draft" && (
                <th className="min-w-75px w-75px text-end">
                  {intl.formatMessage({
                    id: "SALES_OFFER_PRODUCTS_ACTION_COLUMN_LABEL",
                  })}
                </th>
              )}
            </tr>
          </thead>

          <FormikProvider value={formik}>
            <FieldArray name="items">
              {({ push, remove }) => (
                <>
                  {formik.values.items.length > 0 &&
                    formik.values.items.map((item, index) => {
                      return (
                        <tbody
                          key={`offer-item-${index}`}
                          style={updateLoading ? { pointerEvents: "none" } : {}}
                        >
                          <tr>
                            {/* Item Name */}
                            <td className="pe-7">
                              <DynamicDropdown
                                id="products_dropdown"
                                isProducts
                                handleProductSelect={(product) => {
                                  formik.handleSubmit();
                                  handleAddItems([{ id: product.id }]);
                                }}
                                selectedItem={item.title}
                                disabled={
                                  item?.title ||
                                  (offer?.status && offer?.status !== "draft")
                                }
                                saleInteralItems={
                                  offer?.sales_details?.sale_items
                                }
                                productsAndItems={productsAndItems}
                              />
                              {formik.errors.items &&
                                //@ts-ignore
                                formik.errors.items[index]?.title && (
                                  <span className="text-danger fs-8">
                                    {/* @ts-ignore */}
                                    {formik.errors.items[index]?.title}
                                  </span>
                                )}
                            </td>

                            {/* Quantity */}
                            <td className="ps-0">
                              <Field
                                className="form-control form-control-lg fs-6 form-control-solid p-2 px-4"
                                name={`items.${index}.quantity`}
                                onKeyUp={() => {
                                  if (
                                    item?.quantity >= 1 &&
                                    initialValues.items?.[index]?.quantity !==
                                      item?.quantity
                                  ) {
                                    setTriggerUpdate(item);
                                  }
                                }}
                                type="number"
                                disabled={
                                  (offer?.status &&
                                    offer?.status !== "draft") ||
                                  !item?.title
                                }
                                min="1"
                                onWheel={(e: any) => e.target.blur()}
                              />
                            </td>

                            {/* Unit Price */}
                            <td className="ps-0">
                              <Field
                                className="form-control form-control-lg fs-6 form-control-solid p-2 px-4"
                                name={`items.${index}.unit_price`}
                                onKeyUp={() => {
                                  if (
                                    item?.unit_price >= 0 &&
                                    initialValues.items?.[index]?.unit_price !==
                                      item?.unit_price
                                  ) {
                                    setTriggerUpdate(item);
                                  }
                                }}
                                type="number"
                                disabled={
                                  (offer?.status &&
                                    offer?.status !== "draft") ||
                                  !item?.title
                                }
                                min="0"
                                onWheel={(e: any) => e.target.blur()}
                              />
                              {formik.errors.items &&
                                //@ts-ignore
                                formik.errors.items[index]?.unit_price && (
                                  <span className="text-danger fs-8">
                                    {/* @ts-ignore */}
                                    {formik.errors.items[index]?.unit_price}
                                  </span>
                                )}
                            </td>

                            {/* Tax % */}
                            <td className="ps-0" style={{ minWidth: "90px" }}>
                              <Field
                                className="form-control form-control-lg fs-6 form-control-solid p-2 px-4"
                                name={`items.${index}.tax_percentage`}
                                onKeyUp={() => {
                                  if (
                                    item?.tax_percentage >= 0 &&
                                    initialValues.items?.[index]
                                      ?.tax_percentage !== item?.tax_percentage
                                  ) {
                                    setTriggerUpdate(item);
                                  }
                                }}
                                type="number"
                                disabled={
                                  (offer?.status &&
                                    offer?.status !== "draft" &&
                                    offer?.status !== "open") ||
                                  !item?.title
                                }
                                min="0"
                                onWheel={(e: any) => e.target.blur()}
                              />
                              {formik.errors.items &&
                                //@ts-ignore
                                formik.errors.items[index]?.tax_percentage && (
                                  <span className="text-danger fs-8">
                                    {/* @ts-ignore */}
                                    {formik.errors.items[index]?.tax_percentage}
                                  </span>
                                )}
                            </td>

                            {/* Discount % */}
                            <td className="ps-0 pe-0">
                              <Field
                                className="form-control form-control-lg fs-6 form-control-solid p-2 px-4"
                                name={`items.${index}.discount_percentage`}
                                onKeyUp={() => {
                                  if (
                                    item?.discount_percentage >= 0 &&
                                    initialValues.items?.[index]
                                      ?.discount_percentage !==
                                      item?.discount_percentage
                                  ) {
                                    setTriggerUpdate(item);
                                  }
                                }}
                                type="number"
                                disabled={
                                  (offer?.status &&
                                    offer?.status !== "draft" &&
                                    offer?.status !== "open") ||
                                  !item?.title
                                }
                                min="0"
                                onWheel={(e: any) => e.target.blur()}
                              />
                            </td>

                            {/* Total */}
                            <td className="ps-0 pb-0 pt-2">
                              <div className="d-flex justify-content-end text-bold fs-5 pt-3">
                                <div className="d-flex flex-column align-items-center gap-2">
                                  <div>
                                    {getProductPrice(item).toLocaleString(
                                      lang,
                                      {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                      }
                                    )}{" "}
                                    €
                                  </div>
                                </div>
                              </div>
                            </td>

                            {/* Item Delete Button */}
                            {offer?.status === "draft" && (
                              <td className="text-end">
                                <button
                                  type="button"
                                  className="btn btn-icon btn-light btn-active-light-danger btn-sm"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    if (item?.id) {
                                      showDeleteConfirmModal(
                                        intl.formatMessage({
                                          id: "OFFER_ITEM_DELETE_CONFIRM_MESSAGE",
                                        }),
                                        () => {
                                          deleteSelectedItem(item?.id);
                                        },
                                        intl.formatMessage({
                                          id: "COMMON_YES_DELETE",
                                        })
                                      );
                                    } else {
                                      remove(index);
                                    }
                                  }}
                                  disabled={
                                    offer?.status && offer?.status !== "draft"
                                  }
                                >
                                  <SVGICON
                                    src={DeleteIcon}
                                    className="svg-icon-3"
                                  />
                                </button>
                              </td>
                            )}
                          </tr>
                          <tr
                            className={` ${
                              formik.values.items.length - 1 !== index &&
                              "border-bottom border-bottom-dashed"
                            }`}
                          >
                            <td colSpan={5} className="pt-0">
                              <Field
                                className="form-control form-control-lg fs-6 resize-none form-control-solid mt-4 item_description_container"
                                name={`items.${index}.description`}
                                placeholder={intl.formatMessage({
                                  id: "SALES_OFFER_PRODUCTS_DESCRIPTION_PLACEHOLDER",
                                })}
                                as="textarea"
                                disabled={
                                  offer?.sales_details?.sale_items?.some(
                                    (intItem) => intItem?.id === item?.id
                                  ) ||
                                  (offer?.status &&
                                    offer?.status !== "draft") ||
                                  !item?.title
                                }
                                onBlur={(e: any) => {
                                  if (
                                    item?.description &&
                                    initialValues.items?.[index]
                                      ?.description !== item?.description
                                  ) {
                                    setTriggerUpdate(item);
                                  }
                                }}
                              />
                            </td>
                          </tr>
                        </tbody>
                      );
                    })}
                  <button
                    className="btn btn-link py-1"
                    onClick={() => {
                      if (offer?.is_editable !== 0) {
                        push({
                          title: "",
                          description: "",
                          quantity: 1,
                          price: null,
                          tax_percentage: null,
                          discount_percentage: null,
                        });
                      }
                    }}
                    disabled={offer?.status && offer?.status !== "draft"}
                  >
                    {intl.formatMessage({ id: "SALES_OFFER_ADD_ITEM_LABEL" })}
                  </button>
                </>
              )}
            </FieldArray>
          </FormikProvider>

          <tfoot>
            {/* Subtotal (Net Amount) */}
            <tr className="border-top border-top-dashed align-top fs-6 fw-bold text-gray-700">
              <th className="text-primary"></th>
              <th
                colSpan={3}
                className="border-bottom border-bottom-dashed ps-0"
              >
                <div className="d-flex flex-column align-items-start">
                  <div className="fs-5">
                    {intl.formatMessage({
                      id: "SALES_OFFER_PRODUCTS_SUBTOTAL_COLUMN_LABEL",
                    })}
                  </div>
                </div>
              </th>
              <th
                colSpan={2}
                className="border-bottom border-bottom-dashed text-end"
              >
                <span>
                  {getNetTotalAmount()?.toLocaleString(lang, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}{" "}
                  €
                </span>
              </th>
            </tr>
            {/* Taxes */}
            {Object.keys(taxedProducts)?.map((taxItem, index) => (
              <tr
                key={`invoices-tax-${index}`}
                className="border-top border-top-dashed align-top fs-6 fw-bold text-gray-700"
              >
                <th className="text-primary"></th>
                <th
                  colSpan={3}
                  className="border-bottom border-bottom-dashed ps-0"
                >
                  <div className="d-flex flex-column align-items-start">
                    <div className="fs-5">Tax ({taxItem}%)</div>
                  </div>
                </th>
                <th
                  colSpan={2}
                  className="border-bottom border-bottom-dashed text-end"
                >
                  <span>
                    +
                    {getTotalTaxedAmount(
                      taxedProducts[taxItem]
                    )?.toLocaleString(lang, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}{" "}
                    €
                  </span>
                </th>
              </tr>
            ))}
            {/* Discounts */}
            {Object.keys(discountedProducts)?.map((discountItem, index) => (
              <tr
                key={`invoices-discount-${index}`}
                className="border-top border-top-dashed align-top fs-6 fw-bold text-gray-700"
              >
                <th className="text-primary"></th>
                <th
                  colSpan={3}
                  className="border-bottom border-bottom-dashed ps-0"
                >
                  <div className="d-flex flex-column align-items-start">
                    <div className="fs-5">Discount ({discountItem}%)</div>
                  </div>
                </th>
                <th
                  colSpan={2}
                  className="border-bottom border-bottom-dashed text-end"
                >
                  <span>
                    -
                    {getTotalDiscountAmount(
                      discountedProducts[discountItem]
                    )?.toLocaleString(lang, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}{" "}
                    €
                  </span>
                </th>
              </tr>
            ))}
            {/* Total (Gross Amount) */}
            <tr className="align-top fw-bold text-gray-700">
              <th></th>
              <th colSpan={2} className="fs-4 ps-0">
                {intl.formatMessage({
                  id: "SALES_OFFER_PRODUCTS_TOTAL_COLUMN_LABEL",
                })}
              </th>
              <th colSpan={3} className="text-end fs-4 text-nowrap">
                <span>
                  {getTotalAmount()?.toLocaleString(lang, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}{" "}
                  €
                </span>
              </th>
            </tr>
          </tfoot>
        </table>
      </div>

      <div className="px-10 w-100 mt-2">
        <label className="form-label">
          {intl.formatMessage({
            id: "SALES_OFFER_EDIT_FOOTER_NOTES_LABEL",
          })}
        </label>
        <textarea
          className="form-control form-control-solid resize-none"
          rows={3}
          value={footerNotes}
          onChange={(e) => {
            setFooterNotes(e.target.value);
          }}
          onBlur={() => {
            handleUpdateNotes("footer_notes");
          }}
          disabled={
            offer?.status &&
            offer?.status !== "draft" &&
            offer?.status !== "open"
          }
        ></textarea>
      </div>
    </div>
  );
};

export default AddOfferItems;
