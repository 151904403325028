import React, { FC, useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import moment from "moment";

// Constants
import Constants from "../../../config/Constants";

import { SVGICON } from "../../../../_metronic/helpers";
import { useLang } from "../../../../_metronic/i18n/Metronici18n";

import HistoryIcon from "../../../../_metronic/assets/icons/history.svg";

// Components
import { Modal } from "react-bootstrap";
import Pagination from "../../../sharedComponents/pagination/Pagination";
import SimpleLoader from "../../../sharedComponents/Loader/SimpleLoader";

// Types
import { NoteDetailsType } from "../types/getNotesResponseTypes";
import { getNoteLogs } from "../redux";
import { LogType } from "../../tasks/types/TasksResponseType";
import CloseButton from "../../../sharedComponents/Buttons/CloseButton";

interface Props {
	note: NoteDetailsType;
	show: boolean;
	closeModal: () => void;
}

const NotesLogsModal: FC<Props> = ({ note, show, closeModal }) => {
	const local = useLang();
	const intl = useIntl();

	// state
	const [loading, setLoading] = useState<boolean>(false);
	const [logs, setLogs] = useState<LogType[]>([]);
	const [activePage, setActivePage] = useState<number>(1);
	const [itemsPerPage, setItemsPerPage] = useState(Constants.defaultItemsPerPageCount);

	// data API
	const getLogsAPI = () => {
		setLoading(true);
		getNoteLogs(note.notes_id)
			.then(({ data }) => {
				setLogs(data?.logs);
			})
			.catch((error) => {
				console.error(error?.response?.data?.message);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	// handlers
	const onPageClick = (page: number) => {
		setActivePage(page);
	};

	useEffect(() => {
		getLogsAPI();
	}, []);

	// Memoized pagination logic
	const paginatedLogs = useMemo(() => {
		const startIndex = itemsPerPage * (activePage - 1);
		return logs.slice(startIndex, startIndex + itemsPerPage);
	}, [logs, activePage, itemsPerPage]);

	const totalPages = Math.ceil(logs.length / itemsPerPage);

	return (
		<>
			<Modal
				show={show}
				centered
				dialogClassName="large-size-modal"
				className="kt-modal"
				backdrop="static"
				onHide={() => closeModal()}
			>
				<Modal.Header>
					<Modal.Title>{intl.formatMessage({ id: "COMMON_VIEW_LOGS" })}</Modal.Title>
					<CloseButton onClose={closeModal} />
				</Modal.Header>
				<div className="overflow-auto">
					<Modal.Body className="overflow-inherit">
						{loading ? (
							<SimpleLoader />
						) : (
							<>
								{paginatedLogs.length > 0 && (
									<div className="timeline">
										{paginatedLogs.map((log, i) => (
											<React.Fragment key={i}>
												{log.action_type === "create" && (
													<div className="timeline-item" key={i}>
														<div className="timeline-line w-40px"></div>
														<div className="timeline-icon symbol symbol-circle symbol-40px">
															<div className="symbol-label bg-light">
																<SVGICON src={HistoryIcon} className="svg-icon" />
															</div>
														</div>

														<div className="timeline-content d-flex align-items-center mb-5">
															<div className="mb-5">
																<p className="text-muted mb-0">
																	{intl.formatMessage({ id: "COMMON_LOGS_AT" })}{" "}
																	{local === "de"
																		? moment(log?.created_at).format("DD.MM.yyyy HH:mm:ss")
																		: moment(log?.created_at).format("YYYY-MM-DD h:mm:ss A")}
																</p>
																<p className="mb-0">
																	<span className="fw-bold">
																		{log?.recorded_by_details?.firstname} {log?.recorded_by_details?.lastname}
																	</span>{" "}
																	<span>
																		{intl.formatMessage({
																			id: "COMMON_LOGS_CREATED_NOTE",
																		})}
																		.
																	</span>
																</p>
															</div>
														</div>
													</div>
												)}
												{log.action_type === "update" && (
													<div className="timeline-item" key={i}>
														<div className="timeline-line w-40px"></div>
														<div className="timeline-icon symbol symbol-circle symbol-40px">
															<div className="symbol-label bg-light">
																<SVGICON src={HistoryIcon} className="svg-icon" />
															</div>
														</div>

														<div className="timeline-content d-flex align-items-center mb-5">
															<div className="mb-5">
																<p className="text-muted mb-0">
																	{intl.formatMessage({ id: "COMMON_LOGS_AT" })}{" "}
																	{local === "de"
																		? moment(log?.created_at).format("DD.MM.yyyy HH:mm:ss")
																		: moment(log?.created_at).format("YYYY-MM-DD h:mm:ss A")}
																</p>
																<p className="mb-0">
																	<span className="fw-bold">
																		{log?.recorded_by_details?.firstname} {log?.recorded_by_details?.lastname}
																	</span>{" "}
																	<span>
																		{intl.formatMessage({
																			id: "COMMON_LOGS_UPDATED_NOTE",
																		})}
																		.
																	</span>
																</p>
															</div>
														</div>
													</div>
												)}
											</React.Fragment>
										))}
									</div>
								)}
								{/* Pagination */}
								{logs.length > 0 && (
									<Pagination
										totalPages={totalPages}
										activePage={activePage}
										onPageClick={onPageClick}
										showItemsPerPage
										itemsPerPage={itemsPerPage}
										setItemsPerPage={(count) => {
											setItemsPerPage(count);
											setActivePage(1);
										}}
										noCard
									/>
								)}
							</>
						)}
					</Modal.Body>
				</div>
			</Modal>
		</>
	);
};

export default NotesLogsModal;
