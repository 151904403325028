import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { useIntl } from "react-intl";

import { PartnerType } from "../types/getPartnersListResponseType";
import { getPartnerDetails } from "../redux";
import { RootState } from "../../../../setup";
import { UserType } from "../../userManagement/types/getUsersListResponseType";
import { boolean } from "yup";
import { isAccessable } from "../../../utils/PermisisionEnabledForResource";
import { SVGICON } from "../../../../_metronic/helpers";
import FilemanagerIcon from "../../../../_metronic/assets/icons/filemanager.svg";
import Tabs from "../../../sharedComponents/tabs/Tabs";
import PartnerCodeAndCountry from "./PartnerCodeAndCountry";
import PartnerLogoSquare from "./PartnerLogoSquare";
import PartnerTagsDetails from "./PartnerTagsDetails";
import PartnerGeneralDetails from "./PartnerGeneralDetails";
import PartnerFinanceDetails from "./PartnerFinanceDetails";
import PartnerContactTypes from "./PartnerContactTypes";
import PartnerNotes from "./PartnerNotes";
import PartnerContacts from "./PartnerContacts";
import ScrollToTop from "../../../sharedComponents/ScrollToTop/ScrollToTop";

const EditPartnerDetails = () => {
  const { state = {} } = useLocation();
  const history = useHistory();
  const intl = useIntl();
  // @ts-ignore
  const partnerDetails: PartnerType = state.partnerDetails || {};

  //state
  const [partner, setPartner] = useState<PartnerType>(partnerDetails);
  const [activeTab, setActiveTab] = useState<number>(0);
  const userData = useSelector<RootState>(({ auth }) => auth.user) as UserType;
  const [hasAccess, setHassAccess] = useState<boolean>(
    isAccessable(userData, ["is_admin"])
  );

  const getPartnerDetailsAPI = () => {
    partnerDetails.id &&
      getPartnerDetails(partnerDetails.id).then(
        ({ data: { partnerDetails } }) => {
          setPartner(partnerDetails);
          history.replace({ state: { partnerDetails: partnerDetails } });
        }
      );
  };

  useEffect(() => {
    ScrollToTop();
  }, []);

  const onTabChange = (index: number) => {
    if (index !== activeTab) {
      setActiveTab(index);
    }
  };

  return (
    <>
      <div className="card card-mt card-px list-item pb-0 card-my">
        <Tabs
          tabs={[
            intl.formatMessage({ id: "PARTNERS_EDIT_TAB_CONTENT" }),
            intl.formatMessage({ id: "PARTNERS_EDIT_TAB_CONTACT" }),
          ]}
          activeIndex={activeTab}
          onActiveIndexChange={(index) => {
            onTabChange(index);
          }}
        />
      </div>

      <div className="card-mb">
        {activeTab === 0 && (
          <div className="row mx-0 g-25px">
            <div className="col-md-4 kt-card">
              {/* <PartnerCodeAndCountry partner={partner} /> */}
              <PartnerLogoSquare
                partner={partner}
                getPartnerDetailsAPI={getPartnerDetailsAPI}
              />
              <PartnerTagsDetails
                partner={partner}
                getPartnerDetailsAPI={getPartnerDetailsAPI}
              />
            </div>
            <div className="col-md-8 kt-card">
              <PartnerGeneralDetails
                partner={partner}
                getPartnerDetailsAPI={getPartnerDetailsAPI}
              />
              {/* <PartnerFinanceDetails
              partner={partner}
              getPartnerDetailsAPI={getPartnerDetailsAPI}
            /> */}
              {/* <PartnerContactTypes
              partner={partner}
              getPartnerDetailsAPI={getPartnerDetailsAPI}
            /> */}
              <PartnerNotes
                partner={partner}
                getPartnerDetailsAPI={getPartnerDetailsAPI}
              />
            </div>
          </div>
        )}

        {activeTab === 1 && (
          <PartnerContacts
            partner={partner}
            getPartnerDetailsAPI={getPartnerDetailsAPI}
          />
        )}
      </div>
    </>
  );
};

export default EditPartnerDetails;
