import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useIntl } from "react-intl";
import { useFormik } from "formik";
import moment from "moment";
import * as Yup from "yup";

import {
  errorToast,
  successToast,
} from "../../../../sharedComponents/toasts/Toasts";
import DropDown from "../../../../sharedComponents/dropdown/Dropdown";
import DateTimePicker from "../../../../sharedComponents/dateTimePicker/DateTimePicker";
import { useGlobalModalContext } from "../../../../sharedComponents/modals/ModalContext";
import DynamicDropdown from "../../../../sharedComponents/DynamicDropdown/DynamicDropdown";

import AddOfferItems from "./AddOfferItems";
import SalesSettingModal from "./SalesSettingModal";
import EditAddressModal from "./EditAddressModal";
import UpdateTaxModal from "./UpdateTaxModal";
import CreateCustomerModal from "./CreateCustomerModal";
import OfferPreview from "./OfferPreview";

import { RootState } from "../../../../../setup";

import { getSalesSettings } from "../../../salesMasterData/SalesSettings/redux/SalesSettingsApi";
import { LayoutListType } from "../../../salesMasterData/SalesLayout/types/LayoutResponsetypes";
import { getLayoutDetails } from "../../../salesMasterData/SalesLayout/redux/LayoutApi";

import { CountryType } from "../../../masterdata/types/GetCountriesListResponseTypes";
import { UserType } from "../../../userManagement/types/getUsersListResponseType";
import { OfferType } from "../types/OfferTypes";
import {
  SaleDetails,
  SalePartnerType,
  SaleContactType,
} from "../types/SaleTypes";
import { actions } from "../redux/OffersRedux";
import {
  deleteOffer,
  getOfferDetails,
  updateOfferDate,
  offerAcceptOffer,
  offerCreateInvoice,
  offerFinaliseOffer,
  offerRejectOffer,
} from "../redux/OffersApi";
import {
  createSale,
  getProductsAndItems,
  updateSaleCurrency,
  updateSaleDetails,
} from "../redux/SalesApi";

const EditSalesOffer = () => {
  const dispatch = useDispatch();
  const { state = {} } = useLocation();
  const intl = useIntl();
  const history = useHistory();
  const {
    // showDeleteConfirmModal,
    hideDeleteConfirmModal,
    setDeleteModalLoading,
  } = useGlobalModalContext();

  // @ts-ignore
  const sharedOffer: OfferType = state.offer || {};

  // @ts-ignore
  const HeaderOfferId = state?.offer?.headerOfferID;
  // @ts-ignore
  const HeaderInvoiceID = state?.offer?.headerInvoiceID;

  const countries = useSelector<RootState>(
    ({ masterdata }) => masterdata.countriesList
  ) as CountryType[];
  const [selectedToCountry, setSelectedToCountry] =
    useState<CountryType | null>();
  const [offer, setOffer] = useState<OfferType>(sharedOffer);
  const [showEditAddressModal, setShowEditAddressModal] = useState(false);
  const [showTaxEditModal, setShowTaxEditModal] = useState(false);
  const [showCreateContactModal, setShowCreateContactModal] = useState<any>({
    show: false,
    value: "",
  });

  const [salesSettingModal, setSalesSettingModal] = useState<boolean>(
    (HeaderOfferId && HeaderInvoiceID) || sharedOffer?.offers_layouts_id
      ? false
      : true
  );

  const SALES_OFFER_CURRENCY_TYPES = JSON.parse(
    intl.formatMessage({ id: "SALES_OFFER_CURRENCY_TYPES" })
  ) as any[];
  const OFFER_ACCEPTED_TYPES = JSON.parse(
    intl.formatMessage({ id: "OFFER_ACCEPTED_TYPES" })
  ) as any[];

  const getInitialCurrency = () => {
    return SALES_OFFER_CURRENCY_TYPES.filter(
      (item) => item.value === (offer?.sales_details?.currency || "eur")
    )?.[0];
  };
  const [selectedCurrency, setSelectedCurrency] =
    useState<any>(getInitialCurrency());

  const [layoutFooterDetails, setLayoutFooterDetails] =
    useState<LayoutListType>();

  const SALES_OFFER_DRAFT_TYPES = JSON.parse(
    intl.formatMessage({ id: "SALES_OFFER_DRAFT_TYPES" })
  ) as any[];

  const OFFER_GENERATE_ACCEPT_REJECT_TYPES = JSON.parse(
    intl.formatMessage({ id: "OFFER_GENERATE_ACCEPT_REJECT_TYPES" })
  ) as any[];

  const getOfferDetailsAPI = (_id?: number) => {
    let tempOffer_id = sharedOffer?.id || _id;
    if (tempOffer_id) {
      getOfferDetails(tempOffer_id)
        .then(({ data: { salesOfferDetails } }) => {
          setOffer(salesOfferDetails);
          history.replace({ state: { offer: salesOfferDetails } });
          dispatch(actions.setOfferDetails(salesOfferDetails));
        })
        .catch((error) => console.error(error?.response?.data?.message));
    }
  };

  useEffect(() => {
    getOfferDetailsAPI();
  }, [sharedOffer?.id]);

  const onSelectCurrency = (item: any) => {
    if (offer?.sales_details?.currency !== item?.value) {
      updateSaleCurrency(offer?.sales_details?.id, item?.value)
        .then(() => {
          successToast(
            intl.formatMessage({
              id: "SALES_OFFER_CURRENCY_UPDATE_SUCCESS_MESSAGE",
            })
          );
        })
        .catch(() => {
          errorToast(
            intl.formatMessage({
              id: "SALES_OFFER_CURRENCY_UPDATE_FAILURE_MESSAGE",
            })
          );
        })
        .finally(() => {
          getOfferDetailsAPI();
        });
    }
  };

  useEffect(() => {
    setSelectedToCountry(
      countries?.filter(
        (item) => item?.id === Number(offer?.sales_details?.to_country_id)
      )?.[0]
    );
  }, [offer]);

  const initialValues = {
    from_name: offer?.sales_details?.from_name,
    from_email: offer?.sales_details?.from_email || "",
    from_address: offer?.sales_details?.from_address,
    from_company_name: offer?.sales_details?.from_company_name,
    from_city: offer?.sales_details?.from_city,
    from_zipcode: offer?.sales_details?.from_zipcode,
    from_countries_id: Number(offer?.sales_details?.from_country_id),
    from_tax_id: offer?.sales_details?.from_tax_id,
    from_tax_number: offer?.sales_details?.from_tax_number,
    from_creditor_number: offer?.sales_details?.from_creditor_number,
    from_contact_person: offer?.sales_details?.from_contact_person,
    from_contact_person_email: offer?.sales_details?.from_contact_person_email,
    from_contact_person_phone: offer?.sales_details?.from_contact_person_phone,
    to_type: offer?.sales_details?.to_type || "business",
    to_firstname: offer?.sales_details?.to_firstname,
    to_lastname: offer?.sales_details?.to_lastname,
    to_email: offer?.sales_details?.to_email,
    to_address: offer?.sales_details?.to_address,
    to_city: offer?.sales_details?.to_city,
    to_zipcode: offer?.sales_details?.to_zipcode,
    to_countries_id: offer?.sales_details?.to_country_id,
    to_company_name: offer?.sales_details?.to_company_name || "",
    module: "offers",
  };

  const offerDetailsSchema = Yup.object().shape({
    to_email: Yup.string()
      .email(
        intl.formatMessage({
          id: "AUTH_FORGOT_PASSWORD_EMAIL_VALIDATION_MESSAGE",
        })
      )
      .nullable()
      .required(
        intl.formatMessage({ id: "PARTNERS_ACTIVITIES_EMAIL_IS_REQUIRED" })
      ),
    to_type: Yup.string(),
    module: Yup.string().required(),
    to_company_name: Yup.string()
      .when("to_type", {
        is: "business",
        then: Yup.string().required(
          intl.formatMessage({
            id: "SALES_OFFER_TO_COMPANY_NAME_REQUIRED_MESSAGE",
          })
        ),
        otherwise: Yup.string().nullable(),
      })
      .nullable(),
  });

  const handleUpdateSaleDetails = (
    offerId: number,
    salesId: number,
    values: any
  ) => {
    updateSaleDetails(salesId, values)
      .then(() => {
        successToast(
          intl.formatMessage({
            id: "OFFER_GENERAL_UPDATE_SUCCESS_MESSAGE",
          })
        );
      })
      .catch((err) => {
        errorToast(
          intl.formatMessage({
            id: "OFFER_GENERAL_UPDATE_FAILURE_MESSAGE",
          })
        );
      })
      .finally(() => {
        getOfferDetailsAPI?.(offerId);
        setShowTaxEditModal(false);
        setShowEditAddressModal(false);
        setShowCreateContactModal({
          show: false,
          value: "",
        });
      });
  };

  const formik = useFormik({
    initialValues,
    validationSchema: offerDetailsSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      if (sharedOffer?.id === null) {
        const {
          to_type,
          to_firstname,
          to_lastname,
          to_email,
          to_address,
          to_city,
          to_zipcode,
          to_countries_id,
          to_company_name,
          module,
        } = values;
        createSale({
          to_type,
          to_firstname,
          to_lastname,
          to_email,
          to_address,
          to_city,
          to_zipcode,
          to_countries_id,
          to_company_name,
          module,
        })
          .then(({ data: { salesOffer } }) => {
            history.replace({
              state: {
                id: salesOffer?.id,
                sales_id: salesOffer?.sales_id,
                offer: salesOffer,
              },
            });
            return { id: salesOffer?.id, sales_id: salesOffer?.sales_id };
          })
          .then(({ id, sales_id }) => {
            handleUpdateSaleDetails(id, sales_id, values);
          })
          .catch((error) => console.error(error?.response?.data?.message));
      } else {
        handleUpdateSaleDetails(
          //@ts-ignore
          offer?.id ? offer?.id : state?.id,
          //@ts-ignore
          offer?.sales_id ? offer?.sales_id : state?.sales_id,
          values
        );
      }
    },
  });

  const handleGetLayoutSettings = () => {
    getSalesSettings()
      .then(
        ({
          data: {
            settingsDetails: { salesSettingsDetails },
          },
        }) => {
          let LayoutFooterId = salesSettingsDetails?.find((item) => {
            return item.id === 3;
          })?.answer;

          if (Number(LayoutFooterId)) {
            getLayoutDetails(Number(LayoutFooterId))
              .then(({ data: { data } }) => {
                setLayoutFooterDetails(data);
              })
              .catch((error) => console.error(error?.response?.data?.message));
          }
        }
      )
      .catch((error) => console.error(error?.response?.data?.message));
  };

  useEffect(() => {
    handleGetLayoutSettings();
  }, []);

  const resetAddress = async () => {
    await formik.setFieldValue("to_address", null);

    await formik.setFieldValue("to_city", null);
    await formik.setFieldValue("to_zipcode", null);
    await formik.setFieldValue("to_countries_id", null);
    setSelectedToCountry(null);
  };

  const updateOfferDates = (type: string, date: Date) => {
    if (type === "offer") {
      if (sharedOffer?.id === null) {
        const default_payload = { module: "offers" };
        createSale(default_payload)
          .then(({ data: { salesOffer } }) => {
            history.replace({
              state: {
                id: salesOffer?.id,
                sales_id: salesOffer?.sales_id,
                offer: salesOffer,
              },
            });
            return { id: salesOffer?.id, sales_id: salesOffer?.sales_id };
          })
          .then((res) => {
            let datee = moment(date).format("Y-MM-DD");
            if (moment(offer?.offer_date).format("Y-MM-DD") !== datee) {
              updateOfferDate(res?.id, datee, "offer_date")
                .then(() => {
                  successToast(
                    intl.formatMessage({
                      id: "OFFER_DATE_UPDATE_SUCCESS_MESSAGE",
                    })
                  );
                })
                .catch(() => {
                  errorToast(
                    intl.formatMessage({
                      id: "OFFER_DATE_UPDATE_FAILURE_MESSAGE",
                    })
                  );
                })
                .finally(() => {
                  getOfferDetailsAPI(res.id);
                });
            }
          })
          .catch((error) => console.error(error?.response?.data?.message));
      } else {
        let datee = moment(date).format("Y-MM-DD");
        if (moment(offer?.offer_date).format("Y-MM-DD") !== datee) {
          updateOfferDate(
            //@ts-ignore
            offer?.id ? offer?.id : state?.id,
            datee,
            "offer_date"
          )
            .then(() => {
              successToast(
                intl.formatMessage({ id: "OFFER_DATE_UPDATE_SUCCESS_MESSAGE" })
              );
            })
            .catch(() => {
              errorToast(
                intl.formatMessage({ id: "OFFER_DATE_UPDATE_FAILURE_MESSAGE" })
              );
            })
            .finally(() => {
              getOfferDetailsAPI();
            });
        }
      }
    } else {
      if (sharedOffer?.id === null) {
        const default_payload = { module: "offers" };
        createSale(default_payload)
          .then(({ data: { salesOffer } }) => {
            history.replace({
              state: {
                id: salesOffer?.id,
                sales_id: salesOffer?.sales_id,
                offer: salesOffer,
              },
            });
            return { id: salesOffer?.id, sales_id: salesOffer?.sales_id };
          })
          .then((res) => {
            let datee = moment(date).format("Y-MM-DD");
            if (moment(offer?.expires_at).format("Y-MM-DD") !== datee) {
              updateOfferDate(res?.id, datee, "expiration_date")
                .then(() => {
                  successToast(
                    intl.formatMessage({
                      id: "SALES_OFFER_EXPIRY_DATE_UPDATE_SUCCESS_MESSAGE",
                    })
                  );
                })
                .catch(() => {
                  errorToast(
                    intl.formatMessage({
                      id: "SALES_OFFER_EXPIRY_DATE_UPDATE_FAILURE_MESSAGE",
                    })
                  );
                })
                .finally(() => {
                  getOfferDetailsAPI(res.id);
                });
            }
          })
          .catch((error) => console.error(error?.response?.data?.message));
      } else {
        let datee = moment(date).format("Y-MM-DD");
        if (moment(offer?.expires_at).format("Y-MM-DD") !== datee) {
          updateOfferDate(
            //@ts-ignore
            offer?.id ? offer?.id : state?.id,
            datee,
            "expiration_date"
          )
            .then(() => {
              successToast(
                intl.formatMessage({
                  id: "SALES_OFFER_EXPIRY_DATE_UPDATE_SUCCESS_MESSAGE",
                })
              );
            })
            .catch(() => {
              errorToast(
                intl.formatMessage({
                  id: "SALES_OFFER_EXPIRY_DATE_UPDATE_FAILURE_MESSAGE",
                })
              );
            })
            .finally(() => {
              getOfferDetailsAPI();
            });
        }
      }
    }
  };

  const handleClickItem = async (
    type: string,
    contact?: SaleContactType,
    partner?: SalePartnerType
  ) => {
    if (type === "partner") {
      resetAddress();
      await formik.setFieldValue("to_company_name", partner?.name);
      await formik.setFieldValue("to_email", contact?.email || "");
      await formik.setFieldValue("to_firstname", contact?.firstname);
      await formik.setFieldValue("to_lastname", contact?.lastname);
      await formik.setFieldValue("to_type", "business");

      await formik.setFieldValue("to_address", partner?.address);

      await formik.setFieldValue("to_city", partner?.city);
      await formik.setFieldValue("to_zipcode", partner?.zip_code);

      if (partner?.countries_id) {
        await formik.setFieldValue("to_countries_id", partner?.countries_id);
        setSelectedToCountry(
          countries?.filter((item) => item.id == partner?.countries_id)?.[0]
        );
      }

      formik.handleSubmit();
    } else if (type === "contact") {
      resetAddress();
      await formik.setFieldValue("to_company_name", null);
      await formik.setFieldValue("to_email", contact?.email || "");
      await formik.setFieldValue(
        "to_name",
        contact?.firstname + " " + contact?.lastname
      );
      await formik.setFieldValue("to_type", "customer");

      await formik.setFieldValue(
        "to_address",
        `${contact?.address_1 ? contact?.address_1 : ""}${
          contact?.address_2
            ? (contact?.address_1 ? ", " : "") + contact?.address_2
            : ""
        }`
      );

      await formik.setFieldValue("to_city", contact?.city);
      await formik.setFieldValue("to_zipcode", contact?.zip_code);

      if (contact?.countries_id) {
        await formik.setFieldValue("to_countries_id", contact?.countries_id);
        setSelectedToCountry(
          countries?.filter((item) => item.id == contact?.countries_id)?.[0]
        );
      }

      formik.handleSubmit();
    } else if (type && partner) {
      await formik.setFieldValue("to_company_name", partner?.name);
      await formik.setFieldValue("to_type", "business");
      await formik.setFieldValue("to_address", partner?.address);

      await formik.setFieldValue("to_city", partner?.city);
      await formik.setFieldValue("to_zipcode", partner?.zip_code);

      if (partner?.countries_id) {
        await formik.setFieldValue("to_countries_id", partner?.countries_id);
        setSelectedToCountry(
          countries?.filter((item) => item.id == partner?.countries_id)?.[0]
        );
      }

      setShowCreateContactModal({
        show: true,
        value: type,
      });
    } else {
      await formik.setFieldValue("to_company_name", null);
      await formik.setFieldValue("to_type", "customer");
      setShowCreateContactModal({
        show: true,
        value: type,
      });
    }
  };

  const handleProcessorClick = (user: UserType) => {
    formik.setFieldValue(
      "from_contact_person",
      user?.firstname + " " + user?.lastname
    );
    formik.setFieldValue("from_contact_person_email", user?.email);
    formik.setFieldValue("from_contact_person_phone", user?.telephone);
    formik.handleSubmit();
  };

  useEffect(() => {
    getProductsAndItems()
      .then(({ data: { productDetails } }) => {
        dispatch(actions.setProductsAndItems(productDetails));
      })
      .catch((error) => console.error(error?.response?.data?.message));
  }, []);

  const neitherAcceptedOrRejected = () => {
    return offer?.status === "accept" || offer?.status === "reject";
  };

  function isNotValidToConfirm(offer: SaleDetails) {
    return (
      !offer?.to_firstname ||
      !offer?.to_lastname ||
      !offer?.to_email ||
      offer?.sale_items?.length === 0 ||
      !offer?.sale_items?.every(
        (item: any) =>
          Number(item.unit_price) &&
          Number(item.unit_price) >= 0 &&
          Number(item.tax_percentage) &&
          Number(item.tax_percentage) >= 0
      )
    );
  }

  const getClassName = (status: string) => {
    if (status === "draft") {
      return "draft";
    } else if (status === "open") {
      return "open";
    } else if (status === "accept") {
      return "text-bg-success";
    } else if (status === "reject") {
      return "text-bg-danger";
    } else {
      return "cancel";
    }
  };

  const SALES_OFFER_DRAFT_FILTERED_TYPES = isNotValidToConfirm(
    offer?.sales_details
  )
    ? [SALES_OFFER_DRAFT_TYPES?.find((item) => item.id === 2)]
    : SALES_OFFER_DRAFT_TYPES;

  const handleFinaliseOffer = async () => {
    if (isNotValidToConfirm(offer?.sales_details)) {
      errorToast(
        intl.formatMessage({ id: "OFFER_FINALISE_OFFER_INFO_MESSAGE" })
      );
    } else {
      offerFinaliseOffer(offer?.id)
        .then(() => {
          successToast(
            intl.formatMessage({
              id: "OFFER_FINALISE_OFFER_SUCCESS_MESSAGE",
            })
          );
        })
        .catch(() => {
          errorToast(
            intl.formatMessage({
              id: "OFFER_FINALISE_OFFER_FAILURE_MESSAGE",
            })
          );
        })
        .finally(() => {
          getOfferDetailsAPI();
        });
    }
  };

  const deleteExistingOffer = (id: number) => {
    setDeleteModalLoading(true);
    deleteOffer(id)
      .then(() => {
        successToast(
          intl.formatMessage({
            id: "OFFER_SINGLE_OFFER_DELETE_SUCCESS_MESSAGE",
          })
        );
        history.push("sales/offers/overview");
      })
      .catch(() => {
        errorToast(
          intl.formatMessage({
            id: "OFFER_SINGLE_OFFER_DELETE_FAILURE_MESSAGE",
          })
        );
      })
      .finally(() => {
        setDeleteModalLoading(false);
        hideDeleteConfirmModal();
        getOfferDetailsAPI();
      });
  };

  const handleAcceptOffer = async () => {
    offerAcceptOffer(offer?.id)
      .then(() => {
        successToast(
          intl.formatMessage({
            id: "OFFER_ACCEPT_OFFER_SUCCESS_MESSAGE",
          })
        );
      })
      .catch(() => {
        errorToast(
          intl.formatMessage({
            id: "OFFER_ACCEPT_OFFER_FAILURE_MESSAGE",
          })
        );
      })
      .finally(() => {
        getOfferDetailsAPI();
      });
  };

  const handleRejectOffer = async () => {
    offerRejectOffer(offer?.id)
      .then(() => {
        successToast(
          intl.formatMessage({
            id: "OFFER_REJECT_OFFER_SUCCESS_MESSAGE",
          })
        );
      })
      .catch(() => {
        errorToast(
          intl.formatMessage({
            id: "OFFER_REJECT_OFFER_FAILURE_MESSAGE",
          })
        );
      })
      .finally(() => {
        getOfferDetailsAPI();
      });
  };

  const handleGoToInvoice = async () => {
    offerCreateInvoice(offer?.id)
      .then(({ data: { invoice } }) => {
        successToast(
          intl.formatMessage({
            id: "OFFER_CREATE_INVOICE_SUCCESS_MESSAGE",
          })
        );
        history.push(`/sales/invoices/edit`, {
          invoice: invoice[0],
        });
      })
      .catch(() => {
        errorToast(
          intl.formatMessage({
            id: "OFFER_CREATE_INVOICE_FAILURE_MESSAGE",
          })
        );
      })
      .finally(() => {
        getOfferDetailsAPI();
      });
  };

  const resetAdditionalInfo = async () => {
    await formik.setFieldValue(
      "from_tax_id",
      offer?.sales_details?.from_tax_id || null
    );

    await formik.setFieldValue(
      "from_tax_number",
      offer?.sales_details?.from_tax_number || null
    );
    await formik.setFieldValue(
      "from_creditor_number",
      offer?.sales_details?.from_creditor_number || null
    );
  };

  const resetAddressInfo = async () => {
    await formik.setFieldValue(
      "to_zipcode",
      offer?.sales_details?.to_zipcode || null
    );
    await formik.setFieldValue(
      "to_city",
      offer?.sales_details?.to_city || null
    );
    await formik.setFieldValue(
      "to_email",
      offer?.sales_details?.to_email || null
    );
    await formik.setFieldValue(
      "to_address",
      offer?.sales_details?.to_address || null
    );
    await formik.setFieldValue(
      "to_countries_id",
      offer?.sales_details?.to_country_id || null
    );

    setSelectedToCountry(
      countries?.filter(
        (item) => item?.id === Number(offer?.sales_details?.to_country_id)
      )?.[0]
    );
  };

  return (
    <>
      {showEditAddressModal && (
        <EditAddressModal
          show={showEditAddressModal}
          closeModal={() => {
            formik.setErrors({});
            resetAddressInfo();
            setShowEditAddressModal(false);
          }}
          formik={formik}
          selectedToCountry={selectedToCountry}
          setSelectedToCountry={setSelectedToCountry}
        />
      )}

      {showTaxEditModal && (
        <UpdateTaxModal
          show={showTaxEditModal}
          closeModal={() => {
            resetAdditionalInfo();
            setShowTaxEditModal(false);
          }}
          formik={formik}
        />
      )}

      {showCreateContactModal.show && (
        <CreateCustomerModal
          show={showCreateContactModal.show}
          closeModal={() => {
            setShowCreateContactModal({
              show: false,
              value: "",
            });
          }}
          initialValue={showCreateContactModal.value}
          formik={formik}
        />
      )}

      {salesSettingModal && (
        <SalesSettingModal
          show={salesSettingModal}
          closeModal={() => {
            setSalesSettingModal(false);
          }}
          layoutID={HeaderOfferId}
          layoutInvoiceID={HeaderInvoiceID}
          fromInvoiceEdit
          fromSalesOfferEdit
          comingFromOffer
        />
      )}

      <div id="kt_app_content" className="app-content flex-column-fluid">
        <div
          id="kt_app_content_container"
          className="px-0 app-container container-xxl"
        >
          <div className="d-flex flex-column flex-lg-row">
            <div className="flex-lg-row-fluid mb-10 mb-lg-0">
              <div className="card mt-7">
                <div className="card-body px-0 py-10">
                  {/* Status Badge | Preview Button | Workflow Dropdown | Warning badge */}
                  <div className="px-10 pb-4 d-flex align-items-center justify-content-between gap-4">
                    {/* Status Badge */}
                    <div>
                      <span
                        className={`${getClassName(
                          offer?.status
                        )} badge text-uppercase p-4`}
                      >
                        {offer?.status}
                      </span>
                    </div>
                    {/* Preview Button | Workflow Dropdown | Warning Badge */}
                    <div className="d-flex align-items-center justify-self-end gap-4">
                      {/* Preview Button */}
                      <OfferPreview
                        offer={offer}
                        layoutFooterDetails={layoutFooterDetails}
                      />
                      {/* Workflow Dropdown */}
                      {offer?.status === "accept" && (
                        <DropDown
                          id="offerDraftTypesDrop"
                          items={OFFER_ACCEPTED_TYPES}
                          displayFunc={(item) => {
                            return item.name;
                          }}
                          onSelectItem={(item: any) => {
                            if (item?.value === "goToInvoice") {
                              handleGoToInvoice();
                            }
                          }}
                          displayValue={intl.formatMessage({
                            id: "OPTION_METHOD_SELECTION",
                          })}
                          hideSearch
                        />
                      )}
                      {offer?.status === "draft" && (
                        <DropDown
                          id="offerDraftTypesDrop"
                          items={SALES_OFFER_DRAFT_FILTERED_TYPES}
                          displayFunc={(item) => {
                            return item.name;
                          }}
                          onSelectItem={(item: any) => {
                            item?.value === "finalise" && handleFinaliseOffer();
                            item?.value === "delete" &&
                              deleteExistingOffer(offer?.id);
                          }}
                          displayValue={intl.formatMessage({
                            id: "OPTION_METHOD_SELECTION",
                          })}
                          hideSearch
                        />
                      )}
                      {!neitherAcceptedOrRejected() &&
                        offer?.status &&
                        offer?.status === "open" && (
                          <DropDown
                            id="offerCurrencyDropown"
                            items={OFFER_GENERATE_ACCEPT_REJECT_TYPES}
                            displayFunc={(item) => {
                              return item.name;
                            }}
                            onSelectItem={(item: any) => {
                              item?.value === "accept" && handleAcceptOffer();
                              item?.value === "reject" && handleRejectOffer();
                            }}
                            displayValue={intl.formatMessage({
                              id: "OPTION_METHOD_SELECTION",
                            })}
                            hideSearch
                            disabled={isNotValidToConfirm(offer?.sales_details)}
                          />
                        )}

                      {/* Warning Badge */}
                      <div className="rounded-circle bg-secondary d-flex justify-content-center align-items-center p-2">
                        {isNotValidToConfirm(offer?.sales_details) ? (
                          <OverlayTrigger
                            placement="top"
                            overlay={
                              <Tooltip
                                id="message"
                                style={{
                                  height: "fit-content",
                                }}
                              >
                                {intl.formatMessage({
                                  id: "SALES_OFFER_INVALID_DRAFT_INFO_MESSAGE",
                                })}
                              </Tooltip>
                            }
                          >
                            <i className="cursor-pointer bi bi-info-circle-fill fs-7 text-danger"></i>
                          </OverlayTrigger>
                        ) : (
                          <i className="bi bi-check-circle-fill fs-7 text-success"></i>
                        )}
                      </div>
                    </div>
                  </div>

                  {/* Offer Details */}
                  <div className="row px-10 gx-8 gy-6 mb-5">
                    <div className="col-lg-6">
                      {/* To Contact */}
                      <div className="mb-5">
                        <label className="form-label">
                          {intl.formatMessage({
                            id: "SUBSCRIBERS_INVITED_CONTACTS_CONTACT_COLUMN",
                          })}
                        </label>
                        <DynamicDropdown
                          id="customer_dropdown"
                          handleItemClick={handleClickItem}
                          selectedItem={
                            formik.values.to_firstname &&
                            formik.values.to_lastname
                              ? `${formik.values.to_firstname} ${formik.values.to_lastname}`
                              : null
                          }
                          disabled={offer?.status && offer?.status !== "draft"}
                        />
                        {formik.errors.to_email && (
                          <span className="text-danger">
                            {formik.errors.to_email}
                          </span>
                        )}
                      </div>

                      {/* To Address */}
                      <div>
                        <div className="d-flex justify-content-between align-items-center">
                          <label className="form-label">
                            {intl.formatMessage({
                              id: "SALES_OFFER_ADDRESS_INFO_LABEL",
                            })}
                          </label>
                          {(!offer || offer?.status === "draft") && (
                            <span
                              className="text-primary text-lowercase mb-2 cursor-pointer"
                              onClick={() => {
                                setShowEditAddressModal(true);
                              }}
                            >
                              {intl.formatMessage({
                                id: "CUSTOMER_MANAGEMENT_USER_ACTION_EDIT",
                              })}
                            </span>
                          )}
                        </div>
                        <div className="form-control form-control-solid min-h-100px">
                          {formik.values.to_company_name && (
                            <div className="text-gray-700 d-flex gap-2">
                              <span className="fw-bold text-break">
                                {offer?.sales_details?.to_company_name}
                              </span>
                            </div>
                          )}
                          {offer?.sales_details?.to_firstname &&
                            offer?.sales_details?.to_lastname && (
                              <div className="text-gray-700 d-flex gap-2">
                                <span className="fw-bold text-break">
                                  {offer?.sales_details?.to_firstname +
                                    " " +
                                    offer?.sales_details?.to_lastname}
                                </span>
                              </div>
                            )}

                          <div className="text-gray-700 d-flex gap-2">
                            <span className="fw-bold d-flex flex-column">
                              {formik.values.to_address && (
                                <div>
                                  <div className="text-break">
                                    {formik.values.to_address?.split(",")?.[0]}
                                  </div>
                                  <div className="text-break">
                                    {formik.values.to_address?.split(",")?.[1]}
                                  </div>
                                </div>
                              )}

                              {(formik.values.to_zipcode ||
                                formik.values.to_city) && (
                                <span className="text-break">
                                  {formik.values.to_zipcode
                                    ? formik.values.to_zipcode + " "
                                    : ""}
                                  {formik.values.to_city
                                    ? formik.values.to_city
                                    : ""}
                                </span>
                              )}
                              {formik.values.to_countries_id && (
                                <span className="text-break">
                                  {
                                    countries?.filter(
                                      (item) =>
                                        item.id == formik.values.to_countries_id
                                    )?.[0]?.name
                                  }
                                </span>
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 gap-5 flex-column d-flex">
                      {/* Offer Date | Expiration Date */}
                      <div className="row d-flex align-items-center">
                        {/* Offer Date */}
                        <div className="col-6">
                          <label className="form-label">
                            {intl.formatMessage({
                              id: "OFFER_DATE_SELECT_LABEL",
                            })}
                          </label>
                          <DateTimePicker
                            disabledRes={
                              offer?.status && offer?.status !== "draft"
                            }
                            onDateChange={() => {}}
                            onCalendarClose={(date) => {
                              date && updateOfferDates("offer", date);
                            }}
                            selectedDate={
                              offer?.offer_date
                                ? moment(offer?.offer_date).toDate()
                                : undefined
                            }
                            hideTimeSelect
                            placeholder={intl.formatMessage({
                              id: "SELECT_DATE_PLACEHOLDER",
                            })}
                            noMaxDate
                          />
                        </div>

                        {/* Expiration Date */}
                        <div className="col-6">
                          <label className="form-label">
                            {intl.formatMessage({
                              id: "SALES_OFFER_EXPIRY_DATE_SELECT_LABEL",
                            })}
                          </label>
                          <DateTimePicker
                            disabledRes={
                              offer?.status && offer?.status !== "draft"
                            }
                            onDateChange={() => {}}
                            onCalendarClose={(date) => {
                              date && updateOfferDates("expiry", date);
                            }}
                            selectedDate={
                              offer?.expires_at
                                ? moment(offer?.expires_at).toDate()
                                : undefined
                            }
                            minDate={
                              offer?.offer_date
                                ? moment(offer?.offer_date).toDate()
                                : undefined
                            }
                            hideTimeSelect
                            noMaxDate
                          />
                        </div>
                      </div>

                      {/* Currency */}
                      <div className="row d-flex align-items-center">
                        <div className="col-12">
                          <label className="form-label">
                            {intl.formatMessage({
                              id: "SALES_OFFER_CURRENCY_SELECT_LABEL",
                            })}
                          </label>
                          <DropDown
                            id="offerCurrencyDropown"
                            items={SALES_OFFER_CURRENCY_TYPES}
                            displayFunc={(item) => {
                              return item.name;
                            }}
                            selectedItem={selectedCurrency}
                            onSelectItem={(item) => {
                              onSelectCurrency(item);
                              setSelectedCurrency(item);
                            }}
                            displayValue={selectedCurrency?.name}
                            hideSearch
                            disabled={
                              offer?.status && offer?.status !== "draft"
                            }
                          />
                        </div>
                      </div>

                      {/* Processor */}
                      <div className="mb-5">
                        <label className="form-label">
                          {intl.formatMessage({
                            id: "SALES_OFFER_EDIT_PROCESSOR_DROPDOWN_LABEL",
                          })}
                        </label>
                        <DynamicDropdown
                          id="users_dropdown"
                          isUsers
                          handleProcessorClick={handleProcessorClick}
                          selectedItem={formik.values.from_contact_person}
                          disabled={
                            offer?.status &&
                            offer?.status !== "draft" &&
                            offer?.status !== "open"
                          }
                        />
                      </div>

                      {/* Additional Details */}
                      {(!offer || offer?.status === "draft") && (
                        <div className="card-title m-0 d-flex justify-content-end align-items-end h-100">
                          <span
                            className="fw-bold text-primary m-0 fs-6 cursor-pointer"
                            onClick={() => {
                              setShowTaxEditModal(true);
                            }}
                          >
                            +{" "}
                            {intl.formatMessage({
                              id: "EVENT_REGISTRATION_ADDITIONAL_INFORMATION_TITLE",
                            })}
                          </span>
                        </div>
                      )}
                    </div>
                  </div>

                  {/* Offer Items Components */}
                  <AddOfferItems
                    offer={offer}
                    getOfferDetailsAPI={getOfferDetailsAPI}
                    offerId={offer?.id ? offer?.id : sharedOffer?.id}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditSalesOffer;
