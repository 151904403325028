import React from "react";
import { Helmet } from "react-helmet";
import Config from "../../config";
import { Redirect, Route, Switch } from "react-router-dom";
import { PageLink, PageTitle } from "../../../_metronic/layout/core";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { RootState } from "../../../setup";
import { UserType } from "../userManagement/types/getUsersListResponseType";
import { KTSVG } from "../../../_metronic/helpers";

const StripePage = () => {
  //   selectors
  const user = useSelector<RootState>(({ auth }) => auth.user) as UserType;

  const intl = useIntl();

  const stripeBreadCrumbs: Array<PageLink> = [
    {
      title: intl.formatMessage({ id: "MENU_STRIPE" }),
      path: "/payment_methods/overview",
      isSeparator: false,
      isActive: false,
    },
  ];
  // constants
  const stripePaymentURL =
    "https://billing.stripe.com/p/login/28o00F57K6hs1kQ288?prefilled_email=";

  return (
    <div>
      <Helmet>
        <title>{Config.APP_NAME}-Stripe</title>
      </Helmet>
      <Switch>
        <Route path="/payment_methods/overview">
          <PageTitle breadcrumbs={[...stripeBreadCrumbs]}>
            {intl.formatMessage({ id: "STRIPE_TITILE" })}
          </PageTitle>
          {/* stripe component */}
          <div
            className=" card-py
          "
          >
            <div className="">
              <div className="notice d-flex bg-light-warning rounded border-warning border border-dashed card-p">
                <span className="svg-icon svg-icon-2tx svg-icon-warning me-4">
                  <KTSVG
                    path="/media/icons/duotune/general/gen044.svg"
                    className="svg-icon-2tx svg-icon-warning me-4"
                  />
                </span>
                <div className="d-flex flex-stack flex-grow-1 flex-wrap flex-md-nowrap">
                  <div className="mb-3 mb-md-0 fw-semibold">
                    <h4 className="text-gray-900 fw-bolder">
                      {intl.formatMessage({ id: "PAYMENT_METHODS_TITLE" })}
                    </h4>
                    <div className="fs-6 text-gray-700 pe-7">
                      {intl.formatMessage({ id: "PAYMENT_METHODS_INFO" })}
                    </div>
                  </div>
                  <a
                    className="btn btn-light-primary px-6 align-self-center text-nowrap"
                    href={`${stripePaymentURL}${user.email}`}
                    target="_blank"
                  >
                    {intl.formatMessage({ id: "NEW_PAYMENT_TITLE" })}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </Route>

        <Redirect
          from="/payment_methods"
          exact={true}
          to="/payment_methods/overview"
        />
        <Redirect to="/payment_methods/overview" />
      </Switch>
    </div>
  );
};

export default StripePage;
