import React, { FC, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import * as Yup from "yup";
import { useIntl } from "react-intl";
import { useFormik } from "formik";
import clsx from "clsx";
import { addDeal } from "../redux/DealsAPI";
import DropDown from "../../../sharedComponents/dropdown/Dropdown";
import { PartnerType } from "../../partnerManagement/types/getPartnersListResponseType";
import SelectCustomerModal from "../../customerManagement/components/SelectCustomerModal";
import SelectPartnersModal from "../../../sharedComponents/SelectPartnersModal/SelectPartnersModal";
import { UserType } from "../../userManagement/types/getUsersListResponseType";
import { errorToast, successToast } from "../../../sharedComponents/toasts/Toasts";
import CloseButton from "../../../sharedComponents/Buttons/CloseButton";

interface Props {
  show: boolean;
  closeModal: () => void;
  getDealPhaseAPI: () => void;
  pipelineId: number;
}

const AddNewDealModal: FC<Props> = ({
  show,
  closeModal,
  getDealPhaseAPI,
  pipelineId,
}) => {
  const intl = useIntl();
  const ADD_NEW_DEAL_PARTNER_CONTACT_OPTIONS = JSON.parse(
    intl.formatMessage({ id: "ADD_NEW_DEAL_PARTNER_CONTACT_OPTIONS" })
  ) as {
    id: number;
    name: string;
  }[];
  //   state
  const [loadng, setLoading] = useState(false);
  const [selectedPartner, setSelectedPartner] = useState<PartnerType>();
  const [selectedContact, setSelectedContact] = useState<UserType>();
  const [openPartnerModal, setOpenPartnerModal] = useState(false);
  const [openContactModal, setOpenContactModal] = useState(false);
  const [selectType, setSelectType] = useState<{
    id: number;
    name: string;
  }>();

  const initialValues = {
    name: "",
    started_on: "",
    tags_id: undefined,
    partner_id: false,
    contact_id: false,
  };

  const AddNewDealSchema = Yup.object().shape({
    // name: Yup.string()
    //   .max(60, intl.formatMessage({ id: "MASTERDATA_60SYMBOLS_REQUIRED" }))
    //   .required(intl.formatMessage({ id: "PROJECTS_PIPELINE_NAME_REQUIRED" })),
    // partner_id: Yup.boolean().when("contact_id", {
    //   is: false,
    //   then: (schema) => schema.required(),
    //   otherwise: (schema) => schema.notRequired(),
    // }),
    partner_id: Yup.boolean().oneOf(
      [true],
      intl.formatMessage({ id: "DEAL_REQUIRED_FIELD_MESSAGE" })
    ),
    // contact_id: Yup.boolean().when("partner_id", {
    //   is: true,
    //   then: (schema) => schema.required(),
    //   otherwise: (schema) => schema.notRequired(),
    // }),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: AddNewDealSchema,
    enableReinitialize: true,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      if (pipelineId && (selectedContact?.id || selectedPartner?.id)) {
        setLoading(true);
        addDeal(
          pipelineId,
          values.name,
          selectedPartner?.id,
          selectedContact?.id
        )
          .then((data) => {
            closeModal();
            successToast(
              intl.formatMessage({ id: "DEAL_ADD_SUCCESS_MESSAGE" })
            );
          })
          .catch((err) => {
            if(err.response.status === 422){
              setStatus( intl.formatMessage({
                id: "DEAL_ADD_CONFLICT_FAILURE_MESSAGE",
              }))
            }else{
              err.response?.data?.errors?.name
              ? setStatus(err.response.data?.errors?.name)
              : setStatus(
                  intl.formatMessage({
                    id: "DEAL_ADD_FAILURE_MESSAGE",
                  })
                );
            }
          })
          .finally(() => {
            setLoading(false);
            getDealPhaseAPI();
          });
      }
    },
  });

  return (
    <>
      {openContactModal && (
        <SelectCustomerModal
          show={openContactModal}
          closeModal={() => {
            setSelectType(undefined);
            setOpenContactModal(false)
            formik.setFieldValue("partner_id",false)
          }}
          onSelectCustomers={(customers) => {
            // console.log(customers);
            setSelectedContact(customers[0]);
            setOpenContactModal(false);
          }}
          isSelectionModal={true}
          selectedContacts={selectedContact}
        />
      )}
      {openPartnerModal && (
        <SelectPartnersModal
          show={openPartnerModal}
          closeModal={() => {
            setSelectType(undefined);
            setOpenPartnerModal(false);
            formik.setFieldValue("partner_id",false)
          }}
          onSelectPartner={(partners) => {
            setOpenPartnerModal(false);
            setSelectedPartner(partners[0]);
          }}
          isSelectionModal={true}
        />
        // <></>
      )}
      <Modal
        show={show}
        centered
        contentClassName={loadng ? "pe-none" : "AddNew"}
        dialogClassName={"medium-size-modal"}
        backdrop="static"
        className={`kt-modal ${
          openContactModal || openPartnerModal ? "d-none" : "d-block"
        }`}
        onHide={closeModal}
      >
        <Modal.Header>
          <Modal.Title>
            {intl.formatMessage({ id: "DEAL_ADD_NEW_TITLE" })}
          </Modal.Title>
          <CloseButton onClose={closeModal} />
        </Modal.Header>
        <form
          className="form w-100 overflow-auto"
          onSubmit={formik.handleSubmit}
          noValidate
          id="kt_add_category_form"
        >
          <Modal.Body>
            {formik.status && (
              <div className="mb-10 alert alert-danger">
                <div className="alert-text font-weight-bold">
                  {formik.status}
                </div>
              </div>
            )}

            <div className="tab-field">
              <label className="d-flex align-items-center tab-field-label">
                <span className="">
                  {intl.formatMessage({
                    id: "DEAL_NAME_FIELD",
                  })}
                </span>
              </label>

              <input
                placeholder={intl.formatMessage({
                  id: "DEAL_NAME_FIELD_PLACEHOLDER",
                })}
                {...formik.getFieldProps("name")}
                className={clsx(
                  "form-control form-control-lg form-control-solid"
                )}
                name="name"
                autoComplete="off"
              />
              {formik.touched.name && formik.errors.name && (
                <div className="text-danger mt-2">
                  <span role="alert">{formik.errors.name}</span>
                </div>
              )}
            </div>

            <div className="tab-field">
              <label className="required tab-field-label">
                {intl.formatMessage({ id: "ADD_NEW_DEAL_NOTE" })}
              </label>
              <DropDown
                hideSearch
                id="users-titles-dropdown"
                items={ADD_NEW_DEAL_PARTNER_CONTACT_OPTIONS}
                displayFunc={(item) => item.name}
                displayValue={
                  ADD_NEW_DEAL_PARTNER_CONTACT_OPTIONS.find(
                    (option) => option.name === selectType?.name
                  )?.name || ""
                }
                onSelectItem={(item) => {
                  setSelectType(item);
                  if (item.name === "Organisation") {
                    setOpenPartnerModal(true);
                    setSelectedContact(undefined);
                  } else {
                    setOpenContactModal(true);
                    setSelectedPartner(undefined);
                  }
                  formik.setFieldValue("partner_id", !!item.name); // Update Formik state
                }}
                selectedItem={ADD_NEW_DEAL_PARTNER_CONTACT_OPTIONS.find(
                  (option) => option.name === selectType?.name
                )}
              />
              {formik.touched.partner_id && formik.errors.partner_id && (
                <div className="text-danger mt-2">
                  <span role="alert">{formik.errors.partner_id}</span>
                </div>
              )}
            </div>

            {selectedPartner && selectedPartner.id && (
              <div className="tab-field mb-0">
                <label className="tab-field-label">
                  {intl.formatMessage({
                    id: "ADD_NEW_DEAL_MODAL_PARTNER_TITLE",
                  })}
                </label>
                <div className="d-flex">
                  <input
                    placeholder={intl.formatMessage({
                      id: "ADD_DEAL_PARTNER_PLACEHOLDER",
                    })}
                    className={clsx(
                      "form-control form-control-lg form-control-solid"
                    )}
                    value={
                      `${selectedPartner?.name}${" "}${
                        (selectedPartner?.city &&
                          ", " + selectedPartner?.city + ", ") ||
                        ""
                      }${
                        (selectedPartner?.zip_code &&
                          selectedPartner?.zip_code) ||
                        ""
                      }` || ""
                    }
                    name="Partner"
                    autoComplete="off"
                    disabled
                  />
                </div>
              </div>
            )}
            {selectedContact && selectedContact.id && (
              <div className="tab-field mb-0">
                <label className="tab-field-label">
                  {intl.formatMessage({
                    id: "ADD_NEW_DEAL_MODAL_CONTACT_TITLE",
                  })}
                </label>
                <div className="d-flex">
                  <input
                    placeholder={intl.formatMessage({
                      id: "ADD_DEAL_CONTACT_PLACEHOLDER",
                    })}
                    className={clsx(
                      "form-control form-control-lg form-control-solid "
                    )}
                    value={
                      selectedContact
                        ? `${selectedContact.firstname} ${selectedContact.lastname}, ${selectedContact.email}` +
                          //@ts-ignore
                          (selectedContact?.personalAddress?.address_1
                            ? //@ts-ignore
                              `, ${selectedContact.personalAddress?.address_1}`
                            : "")
                        : ""
                    }
                    name="Contact"
                    autoComplete="off"
                    disabled
                  />
                </div>
              </div>
            )}
          </Modal.Body>

          <Modal.Footer className="justify-content-center">
            <Button
              variant="secondary"
              onClick={() => {
                closeModal();
              }}
            >
              {intl.formatMessage({ id: "CLOSE_BUTTON" })}
            </Button>
            <Button
              variant="primary"
              type="submit"
              disabled={formik.isSubmitting}
            >
              {!loadng && intl.formatMessage({ id: "SUBMIT_BUTTON" })}
              {loadng && (
                <span
                  className="indicator-progress"
                  style={{ display: "block" }}
                >
                  {intl.formatMessage({
                    id: "MASTERDATA_TAGS_LOADING_MESSAGE",
                  })}{" "}
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              )}
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

export default AddNewDealModal;
