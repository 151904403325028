import React, { FC, useState } from "react";
import { Modal } from "react-bootstrap";
import { TeamType } from "../../teams/types/TeamsResponseType";
import { useIntl } from "react-intl";
import TeamsList from "../../teams/components/TeamsList";
import CloseButton from "../../../sharedComponents/Buttons/CloseButton";

interface Props {
	show: boolean;
	closeModal: () => void;
	onSelectTeams: (teams: TeamType[]) => void;
	isSelectionModal?: boolean;
}

const SelectTeamsModal: FC<Props> = ({ show, closeModal, onSelectTeams, isSelectionModal }) => {
	const intl = useIntl();
	const [hideMainModal, setHideMainModal] = useState(false);
	return (
		<Modal
			show={show}
			centered
			dialogClassName="extra-large-size-modal"
			className={`kt-modal kt-modal-selection ${hideMainModal ? "d-none" : "d-block"}`}
			backdrop="static"
			onHide={closeModal}
		>
			<Modal.Header>
				<Modal.Title>{intl.formatMessage({ id: "DEAL_SELECT_TEAMS_TITLE" })}</Modal.Title>
				<CloseButton onClose={closeModal} />
			</Modal.Header>
			<div className="overflow-auto">
				<Modal.Body className={`${isSelectionModal ? "selections_modal " : ""} overflow-inherit`}>
					<TeamsList
						type="select"
						onSelectTeams={onSelectTeams}
						isSelectionModal={true}
						onHideModal={(value: boolean) => {
							setHideMainModal(value);
						}}
					/>
				</Modal.Body>
			</div>
		</Modal>
	);
};

export default SelectTeamsModal;
