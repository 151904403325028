import React, { FC, useEffect, useState } from "react";
import { ProductType } from "../types/ProductsResponseType";
import { useIntl } from "react-intl";
import { SVGICON } from "../../../../_metronic/helpers";
// images
import PlusIcon from "../../../../_metronic/assets/icons/plus.svg";
import DeleteIcon from "../../../../_metronic/assets/icons/delete.svg";
import EditIcon from "../../../../_metronic/assets/icons/edit.svg";
import { useSelector } from "react-redux";
import { RootState } from "../../../../setup";
import { GroupType } from "../../masterdata/types/GetGroupsListResponseType";
import {
  getProductTypesList,
  getProductUnits,
} from "../../masterdata/redux/ProductTypesAPI";
import { useDispatch } from "react-redux";
import { actions as masterActions } from "../../masterdata/redux/MasterdataRedux";
import { Button, Modal } from "react-bootstrap";
import DropDown from "../../../sharedComponents/dropdown/Dropdown";
import {
  createProductTypes,
  deleteProductTypes,
  updateProductTypes,
} from "../redux/ProductsAPI";
import { useGlobalModalContext } from "../../../sharedComponents/modals/ModalContext";
import { enablePermissionForCrud } from "../../../utils/PermisisionEnabledForResource";
import { RoleType } from "../../RolesAndPermissions/types/getRolesResponseType";
import {
  errorToast,
  successToast,
} from "../../../sharedComponents/toasts/Toasts";
import CloseButton from "../../../sharedComponents/Buttons/CloseButton";
import { useLang } from "../../../../_metronic/i18n/Metronici18n";

interface Props {
  product: ProductType;
  getProductDetailsAPI: () => void;
}
interface ModelComponentProps {
  modalProps: AddProductTypeModalProps;
  closeModal: () => void;
  getProductDetailsAPI: () => void;
  product: ProductType;
}

interface AddProductTypeModalProps {
  show: boolean;
  type?: string;
  pType?: any;
}

const AddProuctTypesModal: FC<ModelComponentProps> = ({
  modalProps,
  closeModal,
  getProductDetailsAPI,
  product,
}) => {
  const local = useLang();

  const productTypesList = useSelector<RootState>(
    ({ masterdata }) => masterdata.productTypesList
  ) as GroupType[];
  const { show, type, pType } = modalProps;
  const intl = useIntl();
  // state
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedProductTypes, setSelectedProductTypes] = useState<
    GroupType | undefined
  >();
  const [units, setUnits] = useState([]);
  const [errors, setErrors] = useState<any>({
    products_types_ids: null,
    values: null,
    units_id: null,
  });
  const [productTypeValues, setProductTypeValues] = useState<
    | {
        products_types_ids: number;
        values: number;
        units_id: number;
      }
    | undefined
  >();

  // const handleProductTypeSelect = (item: GroupType) => {
  //   const selectedItemsArr = selectedProductTypes;
  //   const index = selectedItemsArr.findIndex(
  //     (selectedItem) => selectedItem.id === item.id
  //   );
  //   // if doesnt exist push item
  //   if (index === -1) {
  //     setSelectedProductTypes(() => [...selectedProductTypes, item]);
  //   } else {
  //     //@ts-ignore
  //     setSelectedProductTypes((prevState) => {
  //       if (prevState) {
  //         prevState.splice(index, 1);
  //         return [...prevState];
  //       }
  //     });
  //   }
  // };

  useEffect(() => {
    if (type === "new") {
      setSelectedProductTypes(undefined);
      setProductTypeValues(undefined);
    } else {
      setSelectedProductTypes(pType);
      setProductTypeValues({
        products_types_ids: pType?.products_types_ids,
        values: pType?.value,
        units_id: pType?.units_id || null,
      });
    }
  }, [modalProps]);

  const handleSubmit = () => {
    if (type === "new") {
      setLoading(true);
      createProductTypes(product.id, [
        {
          products_types_ids: productTypeValues?.products_types_ids,
          values: productTypeValues?.values,
          units_id: productTypeValues?.units_id,
        },
      ])
        .then((data) => {
          successToast(
            intl.formatMessage({
              id: "PRODUCT_PRODUCTTYPES_ADD_SUCCESS_MESSAGE",
            })
          );
        })
        .catch((e) => {
          errorToast(
            intl.formatMessage({
              id: "PRODUCT_PRODUCTTYPES_ADD_FAILURE_MESSAGE",
            })
          );
        })
        .finally(() => {
          setLoading(false);
          getProductDetailsAPI();
          closeModal();
        });
    } else {
      updateProductTypes(pType?.products_products_type_id, [
        {
          values: productTypeValues?.values,
          units_id: productTypeValues?.units_id,
        },
      ])
        .then((data) => {
          successToast(
            intl.formatMessage({
              id: "PRODUCT_PRODUCTTYPES_UPDATE_SUCCESS_MESSAGE",
            })
          );
        })
        .catch((e) => {
          errorToast(
            intl.formatMessage({
              id: "PRODUCT_PRODUCTTYPES_UPDATE_FAILURE_MESSAGE",
            })
          );
        })
        .finally(() => {
          setLoading(false);
          getProductDetailsAPI();
          closeModal();
        });
    }
  };

  useEffect(() => {
    getProductUnits().then((data) => setUnits(data?.data?.data));
  }, []);

  // validations
  useEffect(() => {
    // @ts-ignore
    if (!productTypeValues?.values || productTypeValues?.values === "" ) {
      setErrors((prevErrors: any) => ({
        ...prevErrors,
        values: intl.formatMessage({
          id: "PRODUCTS_PRODUCTTYPES_VALUE_REQUIRED_VALIDATION",
        }),
      }));
    } else {
      setErrors((prevErrors: any) => ({
        ...prevErrors,
        values: null,
      }));
    }
    if(productTypeValues?.units_id === undefined || !selectedProductTypes?.unitDetails){
      setErrors((prevErrors: any) => ({
        ...prevErrors,
        units_id: intl.formatMessage({
          id: "PRODUCTS_PRODUCTTYPES_UNITS_REQUIRED_VALIDATION",
        }),
      }));
    } else {
      setErrors((prevErrors: any) => ({
        ...prevErrors,
        units_id: null
      }));
    }
  }, [productTypeValues, selectedProductTypes]);


  return (
    <Modal
      show={show}
      centered
      contentClassName={loading ? "pe-none" : "AddNew"}
      className="kt-modal"
      backdrop="static"
      onHide={closeModal}
    >
      <Modal.Header>
        <Modal.Title>
          {type === "edit"
            ? intl.formatMessage({
                id: "EDIT_PRODUCT_PRODUCT_TYPES",
              })
            : intl.formatMessage({
                id: "ADD_PRODUCT_PRODUCT_TYPES",
              })}
        </Modal.Title>
        <CloseButton onClose={closeModal} />
      </Modal.Header>

      <div className="">
        <Modal.Body className="overflow-inherit">
          <div className="fv-row tab-field">
            <label className="d-flex align-items-center tab-field-label">
              <span className="required">
                {intl.formatMessage({
                  id: "PRODUCT_PRODUCT_TYPE",
                })}
              </span>
            </label>
            <DropDown
              id="contactTypesDropdown"
              items={productTypesList}
              selectedItem={selectedProductTypes}
              // @ts-ignore
              onSelectItem={(item: GroupType) => {
                setSelectedProductTypes(item);
                setProductTypeValues({
                  products_types_ids: item.id,
                  values: pType?.value,
                  // @ts-ignore
                  units_id: item?.units_id || null,
                });
              }}
              displayValue={selectedProductTypes?.name}
              // @ts-ignore
              displayFunc={(item: GroupType) => {
                return item.name;
              }}
              disabled={type === "edit"}
            />
          </div>
          <div className="">
            {selectedProductTypes && (
              <>
              <div className="tab-field">
                <span className="text-muted tab-field-label">
                  {intl.formatMessage({ id: "PRODUCT_PRODUCTTYPE_INFO" })}
                </span>
                </div>
                <div className="tab-field">
                  <label className="required tab-field-label">
                    {intl.formatMessage({
                      id: "PRODUCTS_UNITS_DROPDOWN",
                    })}
                  </label>
                  <DropDown
                    hideSearch
                    id="units-dropdown"
                    items={units}
                    displayFunc={(item) => {
                      // @ts-ignore
                      return local === "de" ? item.german_unit_name : item.name;
                    }}
                    displayValue={
                      // @ts-ignore
                      selectedProductTypes?.unitDetails?.name
                        ? // @ts-ignore
                          // selectedProductTypes.unitDetails.name
                          (() => {
                            const unit = units.filter((item)=>{
                              if(type === "new"){
                                // @ts-ignore
                                return item.id === selectedProductTypes.unitDetails?.id
                              } else{
                                // @ts-ignore
                                if(selectedProductTypes.unitDetails?.units_id){
                                  // @ts-ignore
                                  return item.id === selectedProductTypes.unitDetails?.units_id
                                }
                                // @ts-ignore
                                else if (selectedProductTypes.unitDetails?.id){
                                  // @ts-ignore
                                  return item.id === selectedProductTypes.unitDetails?.id
                                }
                              }
                            });
                            // @ts-ignore
                            return local === "de" ? unit[0]?.german_unit_name : unit[0]?.name;
                          })() || ""
                        :
                        (() => {
                          const unit = units.filter((item, index)=>{
                            // @ts-ignore
                            return item.id === productTypeValues?.units_id
                          }
                          );
                          // @ts-ignore
                          return local === "de" ? unit[0]?.german_unit_name : unit[0]?.name;
                        })() || ""
                    }
                    onSelectItem={(item) => {
                      let obj = {
                        ...productTypeValues,
                        units_id: item?.id,
                      };

                      let selectedProductTypeObj = {
                        ...selectedProductTypes,
                        unitDetails: {
                          id: item?.id,
                          // @ts-ignore
                          name: local === "de" ? item.german_unit_name : item.name,
                          // @ts-ignore
                          abbreviation: item?.abbreviation,
                        },
                      };
                      // @ts-ignore
                      setProductTypeValues(obj);
                      // @ts-ignore
                      setSelectedProductTypes(selectedProductTypeObj);
                    }}
                    // @ts-ignore
                    selectedItem={units.find(
                      // @ts-ignore
                      (unit) => unit.id === productTypeValues?.units_id
                    )}
                  />
                  {errors?.units_id && (
                    <span className="text-danger mt-2">{errors?.units_id}</span>
                  )}
                </div>
                <div className="tab-field mb-0">
                  <label className="d-flex align-items-center tab-field-label">
                    <span className="required">
                      {intl.formatMessage({
                        id: "PRODUCT_VALUE",
                      })}
                    </span>
                  </label>
                  <input
                    type="text"
                    className="form-control form-control-lg form-control-solid"
                    required={true}
                    value={productTypeValues?.values}
                    autoComplete="off"
                    onChange={(e) => {
                      let obj = {
                        ...productTypeValues,
                        values: e.target.value,
                      };
                      //@ts-ignore
                      setProductTypeValues(obj);
                    }}
                  />
                  {errors?.values && (
                    <span className="text-danger mt-2">{errors?.values}</span>
                  )}
                </div>
              </>
            )}
          </div>
        </Modal.Body>
      </div>

      <Modal.Footer className="justify-content-center">
        <Button
          variant="secondary"
          onClick={() => {
            setSelectedProductTypes(undefined);
            setProductTypeValues(undefined);
            closeModal();
          }}
        >
          {intl.formatMessage({ id: "CLOSE_BUTTON" })}
        </Button>
        <Button
          type="submit"
          variant="primary"
          id="kt_customer_contact_submit"
          onClick={() => {
            if (!errors?.values && !errors?.units_id && productTypeValues !== undefined) {
              handleSubmit();
            }
          }}
        >
          {!loading && intl.formatMessage({ id: "SUBMIT_BUTTON" })}
          {loading && (
            <span className="indicator-progress" style={{ display: "block" }}>
              {intl.formatMessage({
                id: "CUSTOMER_MANAGEMENT_CONTACT_TYPES_LOADING_MESSAGE",
              })}{" "}
              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const ProductTypesSelect: FC<Props> = ({ product, getProductDetailsAPI }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const productTypesList = useSelector<RootState>(
    ({ masterdata }) => masterdata.productTypesList
  ) as GroupType[];
  const userRoleDetails = useSelector<RootState>(
    // @ts-ignore
    (state) => state.auth.roleDetails
  ) as RoleType;
  //@ts-ignore
  const crudPermission: any = enablePermissionForCrud(
    userRoleDetails,
    "products"
  );
  // context
  const {
    showDeleteConfirmModal,
    setDeleteModalLoading,
    hideDeleteConfirmModal,
  } = useGlobalModalContext();
  //   state
  const [showAddProductTypeModal, setShowAddProductTypeModal] =
    useState<AddProductTypeModalProps>({
      show: false,
    });

  const [units, setUnits] = useState([]);

  useEffect(() => {
    getProductTypesList().then(({ data: { data } }) => {
      dispatch(masterActions.setProductTypesList(data));
    });
  }, []);

  useEffect(() => {
    getProductUnits().then((data) => setUnits(data?.data?.data));
  }, []);

  const closeModal = () => {
    setShowAddProductTypeModal({
      show: false,
      type: "new",
      pType: undefined,
    });
  };

  const allProductTypesAdded = () => {
    let flag = true;
    productTypesList.forEach((pType) => {
      const index = product.products_types?.findIndex(
        // @ts-ignore
        (contactDetail) => contactDetail.id === pType.id
      );
      if (index === -1) {
        flag = false;
      }
    });
    return flag;
  };

  const deleteProductType = (pType: any) => {
    setDeleteModalLoading(true);
    deleteProductTypes(pType.products_products_type_id)
      .then((data) => {
        successToast(
          intl.formatMessage({
            id: "PRODUCT_PRODUCTTYPE_DELETE__SUCCESS_MESSAGE",
          })
        );
      })
      .catch(() => {
        errorToast(
          intl.formatMessage({
            id: "PRODUCT_PRODUCTTYPE_DELETE__FAILURE_MESSAGE",
          })
        );
      })
      .finally(() => {
        setDeleteModalLoading(false);
        hideDeleteConfirmModal();
        getProductDetailsAPI();
      });
  };

  return (
    <>
      <AddProuctTypesModal
        modalProps={showAddProductTypeModal}
        closeModal={closeModal}
        getProductDetailsAPI={getProductDetailsAPI}
        product={product}
      />
      <div className="card card-mt">
        <div className="card-header card-px d-flex flex-fill flex-row align-items-center justify-content-between">
          <div
            className="border-0 cursor-pointer"
            role="button"
            data-bs-toggle="collapse"
            data-bs-target="#kt_account_contact_types"
          >
            <div className="card-title m-0 flex-fill">
              <h3 className="fw-bolder m-0">
                {intl.formatMessage({ id: "PRODUCT_PRODUCTTYPES_HEADER" })}
              </h3>
            </div>
          </div>
          <div className="d-flex justify-content-end align-items-center">
            {crudPermission?.edit && (
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  if (allProductTypesAdded()) {
                    successToast(
                      intl.formatMessage({
                        id: "PRODUCT_PRODUCT_TYPES_SUCCESS_MESSAGE",
                      })
                    );
                  } else {
                    setShowAddProductTypeModal({
                      show: true,
                      type: "new",
                      pType: undefined,
                    });
                  }
                }}
              >
                <SVGICON src={PlusIcon} className="svg-icon-2" />
                {intl.formatMessage({
                  id: "PRODUCT_PRODUCTTYPES_ADD_CONTACT_BUTTON",
                })}
              </button>
            )}
          </div>
        </div>

        <div id="kt_account_contact_types" className="collapse show">
          <div className="card-body card-p">
            {(!product?.products_types ||
              product?.products_types.length === 0) && (
              <span className="fw-bold text-gray-600">
                {intl.formatMessage({
                  id: "PRODUCT_PRODUCT_TYPES_NO_PRODUCT_TYPES",
                })}
              </span>
            )}
            {product?.products_types?.map(
              (
                pType: {
                  products_products_type_id: number;
                  products_type_id: number;
                  name: string;
                  value: number;
                  units_id: number;
                },
                i
              ) => {
                return (
                  <div key={i}>
                    <div className="d-flex align-items-center justify-content-between">
                      <div>
                        <div className="fs-6 fw-bolder mb-1">{pType?.name}</div>
                        <div className="d-flex">
                          <span className="fw-bold text-gray-600">
                          {pType?.value}{" "}
                            {units?.map((unit) =>
                              // @ts-ignore
                              unit?.id === pType?.units_id ? unit.abbreviation : null
                            )}
                          </span>
                        </div>
                      </div>
                      {crudPermission?.edit && (
                        <div className="d-flex">
                          <button
                            className="btn btn-sm btn-icon btn-light btn-active-light-primary me-2"
                            onClick={() => {
                              setShowAddProductTypeModal({
                                show: true,
                                type: "edit",
                                pType,
                              });
                            }}
                          >
                            <SVGICON src={EditIcon} className="svg-icon-3" />
                          </button>
                          <button
                            className="btn btn-sm btn-icon btn-light btn-active-light-danger"
                            onClick={() => {
                              showDeleteConfirmModal(
                                intl.formatMessage({
                                  id: "PRODUCT_PRODUCTTYPE_DELETE_CONFIRM_MESSAGE",
                                }),
                                () => {
                                  deleteProductType(pType);
                                }
                              );
                            }}
                          >
                            <SVGICON src={DeleteIcon} className="svg-icon-3" />
                          </button>
                        </div>
                      )}
                    </div>
                    {i !== product?.products_types?.length - 1 && (
                      <div className="separator separator-dashed my-6"></div>
                    )}
                  </div>
                );
              }
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductTypesSelect;
