import React, { FC } from "react";
import { useIntl } from "react-intl";
import { Modal } from "react-bootstrap";
import SalesNotes from "../../SalesNotes/components/SalesNotes";
import CloseButton from "../../../../sharedComponents/Buttons/CloseButton";

interface Props {
  show: boolean;
  closeModal: () => void;
  isSelectionModal?: boolean;
  onHandleApply?: (id: number) => void;
  selectedNotes?: any;
}
const SalesNotesListModal: FC<Props> = ({
  show,
  closeModal,
  isSelectionModal,
  onHandleApply,
  selectedNotes,
}) => {
  const intl = useIntl();

  return (
    <Modal
      show={show}
      centered
      dialogClassName={"extra-large-size-modal"}
      onHide={closeModal}
    >
      <Modal.Header>
        <div className="d-flex flex-row flex-fill align-items-center justify-content-between  ">
          <Modal.Title>
            {intl.formatMessage({
              id: "MASTERDATA_SALES_NOTES_SELECTION_MODAL_TITLE",
            })}
          </Modal.Title>
          <CloseButton onClose={closeModal} />
        </div>
      </Modal.Header>
      <Modal.Body
        className={`${isSelectionModal ? "selections_modal" : ""} pt-0`}
      >
        <SalesNotes
          isSelectionModal={isSelectionModal}
          onHandleApply={onHandleApply}
          selectedItem={selectedNotes}
        />
      </Modal.Body>
    </Modal>
  );
};

export default SalesNotesListModal;
