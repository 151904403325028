/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useEffect, useState } from "react";
import * as Yup from "yup";

import { Modal, Button } from "react-bootstrap";
import { useIntl } from "react-intl";

// images
import PlusIcon from "../../../../_metronic/assets/icons/plus.svg";
import DeleteIcon from "../../../../_metronic/assets/icons/delete.svg";
import EditIcon from "../../../../_metronic/assets/icons/edit.svg";

// custom

import { SVGICON } from "../../../../_metronic/helpers";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../setup";
import { GroupType } from "../../masterdata/types/GetGroupsListResponseType";
import { useFormik } from "formik";
import DropDown from "../../../sharedComponents/dropdown/Dropdown";
import { getContactTypesList } from "../../masterdata/redux/ContactTypesAPI";
import { actions as masterdataActions } from "../../masterdata/redux";
import {
  addCustomerContact,
  deleteCustomerContact,
  updateCustomerContact,
} from "../redux";
import {
  ContactDetailType,
  CustomerType,
} from "../../auth/types/LoginResponseTypes";
import { useGlobalModalContext } from "../../../sharedComponents/modals/ModalContext";
import { enablePermissionForCrud } from "../../../utils/PermisisionEnabledForResource";
import { RoleType } from "../../RolesAndPermissions/types/getRolesResponseType";
import {
  errorToast,
  successToast,
} from "../../../sharedComponents/toasts/Toasts";
import CloseButton from "../../../sharedComponents/Buttons/CloseButton";

interface Props {
  customerDetails: CustomerType;
  getCustomerDetailsAPI: () => void;
}

interface ModelComponentProps {
  modalProps: AddContactTypeModalProps;
  closeModal: () => void;
  customerDetails: CustomerType;
  getCustomerDetailsAPI: () => void;
}

interface AddContactTypeModalProps {
  show: boolean;
  type?: string;
  contactDetail?: ContactDetailType;
}

interface AddCustomerContactFormValues {
  contactId: number | undefined;
  contactDescription: string;
}

const AddContactTypeModal: FC<ModelComponentProps> = ({
  modalProps,
  closeModal,
  customerDetails,
  getCustomerDetailsAPI,
}) => {
  const contactTypesList = useSelector<RootState>(
    ({ masterdata }) => masterdata.contactTypesList
  ) as GroupType[];
  const { show, type, contactDetail } = modalProps;
  const intl = useIntl();
  const userRoleDetails = useSelector<RootState>(
    // @ts-ignore
    (state) => state.auth.roleDetails
  ) as RoleType;
  //@ts-ignore
  const crudPermission: any = enablePermissionForCrud(
    userRoleDetails,
    "contacts"
  );

  // state
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedContactType, setSelectedContactType] = useState<GroupType>();

  const initialValues: AddCustomerContactFormValues = {
    contactId: type === "edit" ? contactDetail?.contact_detail_id : undefined,
    contactDescription: type === "edit" ? contactDetail?.description || "" : "",
  };

  const AddContactSchema = Yup.object().shape({
    contactId: Yup.number().required(
      intl.formatMessage({
        id: "CUSTOMER_CONTACT_TYPES_CONTACT_VALIDATION_MESSAGE",
      })
    ),
    contactDescription: Yup.string()
      .max(
        60,
        intl.formatMessage({
          id: "CUSTOMER_CONTACT_TYPES_DESCRIPTION_MAX_VALIDATION_MESSAGE",
        })
      )
      .required(
        intl.formatMessage({
          id: "CUSTOMER_CONTACT_TYPES_DESCRIPTION_REQUIRED_VALIDATION_MESSAGE",
        })
      ),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: AddContactSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      if (customerDetails?.personalInformation?.id && values.contactId) {
        if (type === "edit") {
          updateCustomerContact(
            customerDetails?.personalInformation?.id,
            values.contactId,
            values.contactDescription
          )
            .then(() => {
              successToast(
                intl.formatMessage({
                  id: "CUSTOMER_MANAGEMENT_CONTACT_SUCCESS_MESSAGE",
                })
              );
              getCustomerDetailsAPI();
            })
            .catch(() => {
              errorToast(
                intl.formatMessage({
                  id: "CUSTOMER_MANAGEMENT_CONTACT_FAILURE_MESSAGE",
                })
              );
            })
            .finally(() => {
              setSelectedContactType(undefined);
              closeModal();
              setLoading(false);
            });
        } else {
          addCustomerContact(
            customerDetails?.personalInformation?.id,
            values.contactId,
            values.contactDescription
          )
            .then(() => {
              successToast(
                intl.formatMessage({
                  id: "CUSTOMER_MANAGEMENT_ADD_CONTACT_SUCCESS_MESSAGE",
                })
              );
              getCustomerDetailsAPI();
            })
            .catch(() => {
              errorToast(
                intl.formatMessage({
                  id: "CUSTOMER_MANAGEMENT_ADD_CONTACT_FAILURE_MESSAGE",
                })
              );
            })
            .finally(() => {
              setSelectedContactType(undefined);
              closeModal();
              setLoading(false);
              formik.values.contactDescription = "";
            });
        }
      }
    },
  });

  const getDropDownItems = () => {
    const result: GroupType[] = [];
    contactTypesList.forEach((contactType) => {
      const index = customerDetails.contactDetails?.findIndex(
        (contactDetail) => contactDetail.contact_detail_id === contactType.id
      );
      if (index === -1) {
        result.push(contactType);
      }
    });
    return result;
  };

  return (
    <Modal
      show={show}
      centered
      contentClassName={loading ? "pe-none" : "AddNew"}
      className="kt-modal"
      backdrop="static"
      onHide={closeModal}
    >
      <Modal.Header>
        <Modal.Title>
          {type === "edit"
            ? intl.formatMessage({
                id: "CUSTOMER_MANAGEMENT_EDIT_CONTACT_TITLE",
              })
            : intl.formatMessage({
                id: "CUSTOMER_MANAGEMENT_ADD_CONTACT_TITLE",
              })}
        </Modal.Title>
        <CloseButton onClose={closeModal} />
      </Modal.Header>
      <form
        className="form w-100"
        onSubmit={formik.handleSubmit}
        noValidate
        id="kt_customer_contact_form"
      >
        <Modal.Body className="overflow-inherit">
          <div className="fv-row tab-field">
            <label className="d-flex align-items-center tab-field-label">
              <span className="required">
                {intl.formatMessage({
                  id: "CUSTOMER_MANAGEMENT_CONTACT_TYPE",
                })}
              </span>
            </label>
            <DropDown
              id="contactTypesDropdown"
              items={getDropDownItems()}
              selectedItem={selectedContactType}
              // @ts-ignore
              onSelectItem={(item: GroupType) => {
                formik.setFieldValue("contactId", item.id);
                setSelectedContactType(item);
              }}
              displayValue={
                type === "edit"
                  ? contactDetail?.contact_detail || ""
                  : selectedContactType?.name || ""
              }
              // @ts-ignore
              displayFunc={(item: GroupType) => {
                return item.name;
              }}
              disabled={type === "edit"}
            />
            {formik.touched.contactId && formik.errors.contactId && (
              <div className="text-danger">
                <span role="alert">{formik.errors.contactId}</span>
              </div>
            )}
          </div>

          <div className="fv-row tab-field mb-0">
            <label className="d-flex align-items-center tab-field-label">
              <span className="required">
                {intl.formatMessage({
                  id: "CUSTOMER_MANAGEMENT_CONTACT_DESCRIPTION",
                })}
              </span>
            </label>
            <input
              type="text"
              placeholder={""}
              className={"form-control form-control-lg form-control-solid"}
              autoComplete="off"
              {...formik.getFieldProps("contactDescription")}
              value={formik.values.contactDescription}
            />
            {formik.touched.contactDescription &&
              formik.errors.contactDescription && (
                <div className="text-danger">
                  <span role="alert">{formik.errors.contactDescription}</span>
                </div>
              )}
          </div>
        </Modal.Body>

        <Modal.Footer className="justify-content-center">
          <Button
            variant="secondary"
            onClick={() => {
              closeModal();
              setSelectedContactType(undefined);
              formik.values.contactDescription = "";
            }}
          >
            {intl.formatMessage({ id: "CLOSE_BUTTON" })}
          </Button>
          <Button
            type="submit"
            variant="primary"
            id="kt_customer_contact_submit"
          >
            {!loading && intl.formatMessage({ id: "SUBMIT_BUTTON" })}
            {loading && (
              <span className="indicator-progress" style={{ display: "block" }}>
                {intl.formatMessage({
                  id: "CUSTOMER_MANAGEMENT_CONTACT_TYPES_LOADING_MESSAGE",
                })}{" "}
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            )}
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

const CustomerContactTypes: React.FC<Props> = ({
  customerDetails,
  getCustomerDetailsAPI,
}) => {
  const dispatch = useDispatch();
  const contactTypesList = useSelector<RootState>(
    ({ masterdata }) => masterdata.contactTypesList
  ) as GroupType[];
  const userRoleDetails = useSelector<RootState>(
    // @ts-ignore
    (state) => state.auth.roleDetails
  ) as RoleType;
  //@ts-ignore
  const crudPermission: any = enablePermissionForCrud(
    userRoleDetails,
    "contacts"
  );
  const intl = useIntl();
  const {
    showDeleteConfirmModal,
    hideDeleteConfirmModal,
    setDeleteModalLoading,
  } = useGlobalModalContext();
  //   state
  const [showAddContactTypeModal, setShowAddContactTypeModal] =
    useState<AddContactTypeModalProps>({
      show: false,
    });

  // const [customer, setCustomer] = useState(customerDetails)

  useEffect(() => {
    getContactTypesList()
      .then(({ data: { data } }) => {
        dispatch(masterdataActions.setContactTypesList(data));
      })
      .catch((e) => {
        console.error(`ERROR: getContactTypesListAPI`, e);
      });
  }, []);

  // useEffect(() => {
  //   setCustomer(customerDetails)
  // }, [customerDetails])

  const closeModal = () => {
    setShowAddContactTypeModal({
      show: false,
      type: "",
      contactDetail: undefined,
    });
  };

  const deleteContact = (ContactDetail: ContactDetailType) => {
    showDeleteConfirmModal(
      intl.formatMessage({ id: "CUSTOMER_MANAGEMENT_CONTACT_DELETE_MESSAGE" }),
      () => {
        setDeleteModalLoading(true);
        deleteCustomerContact(
          customerDetails?.personalInformation?.id,
          ContactDetail.contact_detail_id
        )
          .then(() => {
            successToast(
              intl.formatMessage({
                id: "CUSTOMER_MANAGEMENT_CONTACT_DELETE_SUCCESS_MESSAGE",
              })
            );
            getCustomerDetailsAPI();
          })
          .catch(() => {
            errorToast(
              intl.formatMessage({
                id: "CUSTOMER_MANAGEMENT_CONTACT_DELETE_FAILURE_MESSAGE",
              })
            );
          })
          .finally(() => {
            setDeleteModalLoading(false);
            hideDeleteConfirmModal();
          });
      }
    );
  };

  const allContactsAdded = () => {
    let flag = true;
    contactTypesList.forEach((contactType) => {
      const index = customerDetails.contactDetails?.findIndex(
        (contactDetail) => contactDetail.contact_detail_id === contactType.id
      );
      if (index === -1) {
        flag = false;
      }
    });
    return flag;
  };

  return (
    <>
      <AddContactTypeModal
        modalProps={showAddContactTypeModal}
        closeModal={closeModal}
        customerDetails={customerDetails}
        getCustomerDetailsAPI={getCustomerDetailsAPI}
      />
      <div className="card card-flush border-0">
        <div className="card-header p-0" style={{ minHeight: 0 }}>
          <div className="card-title m-0">
            <h3 className="fw-bolder m-0">
              {intl.formatMessage({
                id: "CUSTOMER_MANAGEMENT_CONTACT_TYPES_TITLE",
              })}
            </h3>
          </div>

          <div className="card-toolbar m-0">
            {crudPermission?.edit && (
              <button
                type="button"
                className="btn btn-sm btn-icon btn-primary"
                onClick={() => {
                  if (allContactsAdded()) {
                    successToast(
                      intl.formatMessage({
                        id: "CUSTOMER_CONTACT_TYPES_SUCCESS_MESSAGE",
                      })
                    );
                  } else {
                    setShowAddContactTypeModal({
                      show: true,
                      type: "new",
                      contactDetail: undefined,
                    });
                  }
                }}
              >
                <SVGICON src={PlusIcon} className="svg-icon-2" />
                {/* {intl.formatMessage({id: 'CUSTOMER_MANAGEMENT_ADD_CONTACT_TITLE'})} */}
              </button>
            )}
          </div>
        </div>

        <div className="card-body card-pt px-0">
          {(!customerDetails?.contactDetails ||
            customerDetails?.contactDetails.length === 0) && (
            <span className="fw-bold text-gray-600">
              {intl.formatMessage({
                id: "CUSTOMER_MANAGEMENT_CONTACT_TYPES_NO_CONTACTS",
              })}
            </span>
          )}
          {customerDetails?.contactDetails?.map((contactDetail, i) => {
            return (
              <div key={i}>
                <div className="d-flex align-items-center justify-content-between">
                  <div>
                    <div className="fs-6 fw-bolder mb-1">
                      {contactDetail.contact_detail}
                    </div>
                    <div className="fw-bold text-gray-600">
                      {contactDetail.description}
                    </div>
                  </div>
                  <div className="d-flex">
                    {crudPermission?.edit && (
                      <button
                        className="btn btn-sm btn-icon btn-light btn-active-light-primary me-1"
                        onClick={() => {
                          setShowAddContactTypeModal({
                            show: true,
                            type: "edit",
                            contactDetail,
                          });
                        }}
                      >
                        <SVGICON src={EditIcon} className="svg-icon-3" />
                      </button>
                    )}
                    {crudPermission?.edit && (
                      <button
                        className="btn btn-sm btn-icon btn-light btn-active-light-primary"
                        onClick={() => {
                          deleteContact(contactDetail);
                        }}
                      >
                        <SVGICON src={DeleteIcon} className="svg-icon-3" />
                      </button>
                    )}
                  </div>
                </div>
                {i !== customerDetails?.contactDetails?.length - 1 && (
                  <div className="separator separator-dashed my-4"></div>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default CustomerContactTypes;
