import { FC } from "preact/compat";

// Icons
import { SVGICON } from "../../../../_metronic/helpers";
import DeleteIcon from "../../../../_metronic/assets/icons/delete.svg";
import EditIcon from "../../../../_metronic/assets/icons/edit.svg";
import DragIcon from "../../../../_metronic/assets/icons/drag.svg";

import { MessageCardProps } from "../types/SystemMessagesPropsTypes";
import { useIntl } from "react-intl";
import { enablePermissionForCrud } from "../../../utils/PermisisionEnabledForResource";
import { RootState } from "../../../../setup";
import { useSelector } from "react-redux";
import { RoleType } from "../../RolesAndPermissions/types/getRolesResponseType";

const MessageCard: FC<MessageCardProps> = ({
  message,
  onChange,
  checked,
  editOnClick,
  deleteOnClick,
}) => {
  const intl = useIntl();
  const userRoleDetails = useSelector<RootState>(
    // @ts-ignore
    (state) => state.auth.roleDetails
  ) as RoleType;
//@ts-ignore
 const crudPermission:any = (enablePermissionForCrud(userRoleDetails, "system-messages"))
  return (
    <div className="card list-item no-drag">
      <div className="d-flex w-100 align-items-center justify-content-between no-drag">
        <div className="d-flex align-items-center gap-3.5">
         {crudPermission?.delete &&  <div className="form-check form-check-sm form-check-custom form-check-solid no-drag">
            <input
              onChange={onChange}
              className="form-check-input widget-9-check"
              type="checkbox"
              checked={checked}
            />
          </div>}
        
          <div
            className={`d-flex flex-row gap-2 mx-5 flex-wrap no-drag`}
            role="button"
            onClick={editOnClick}
          >
            {/* <span className="d-flex align-items-center gap-1.5 leading-none fw-semibold text-sm text-gray-900">
                {message.workflow}
              </span> */}
            <span
              className=" fw-bold flex-wrap no-drag"
              // style={{
              //   fontSize: ".8125rem",
              //   lineHeight: "1.125rem",
              // }}
            >
              {message.message}
            </span>
            {message?.is_default === 1 && (
              <span className="badge badge-primary">
                {intl.formatMessage({
                  id: "DEFAULT_TAG",
                })}
              </span>
            )}
          </div>
        </div>
        {(crudPermission?.edit || crudPermission?.delete) && <div className="d-flex">
         {crudPermission?.edit &&  <button
            className="btn btn-sm btn-icon btn-light btn-active-light-primary me-2 no-drag"
            onClick={editOnClick}
          >
            <SVGICON src={EditIcon} className="svg-icon-3 no-drag" />
          </button>}
         {crudPermission?.delete &&  <button
            className="btn btn-sm btn-icon btn-light btn-active-light-danger no-drag"
            onClick={deleteOnClick}
          >
            <SVGICON src={DeleteIcon} className="svg-icon-3 no-drag" />
          </button>}
        </div>}
      </div>
    </div>
  );
};

export default MessageCard;
