import axios from "axios";
import { CalendarResponseType } from "./CalendarEventsResponseType";

export function getEvents(user_id: number, google_id: any) {
  return axios.get<CalendarResponseType>(
    `/googles/calendar/events/${google_id}/${user_id}`
  );
}

export function createEvent(
  user_id: number,
  google_id: any,
  summary: string,
  description: string,
  location: string,
  start_date_time: string,
  end_date_time: string,
  timezone: string
) {
  return axios.post(`/googles/calendar/events/store/${google_id}/${user_id}`, {
    summary,
    description,
    location,
    start_date_time,
    end_date_time,
    timezone,
  });
}

export function updateEvent(
  user_id: number,
  google_id: any,
  event_id: string,
  summary: string,
  description: string,
  location: string,
  start_date_time: string,
  end_date_time: string,
  timezone: string
) {
  return axios.post(
    `googles/calendar/events/update/${user_id}/${google_id}/${event_id}`,
    {
      summary,
      description,
      location,
      start_date_time,
      end_date_time,
      timezone,
    }
  );
}

export function deleteEvent(
  user_id: number,
  google_id: any,
  event_id: string
) {
  return axios.delete(
    `googles/calendar/events/delete/${user_id}/${google_id}/${event_id}`
  );
}
