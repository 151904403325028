import React, { FC, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { SVGICON } from "../../../../_metronic/helpers";
// images
import AddUserIcon from "../../../../_metronic/assets/icons/add-user.svg";
import { useSelector } from "react-redux";
import { RootState } from "../../../../setup";
import { GroupType } from "../../masterdata/types/GetGroupsListResponseType";

import { useDispatch } from "react-redux";
import { actions as masterActions } from "../../masterdata/redux/MasterdataRedux";
import { Button, Modal } from "react-bootstrap";
import DropDown from "../../../sharedComponents/dropdown/Dropdown";
import {
  errorToast,
  successToast,
} from "../../../sharedComponents/toasts/Toasts";
import { getContactTitlesList } from "../../masterdata/redux/ContactTitlesAPI";
import { AddUserSymbol, UserSymbol } from "../../tasks/components/UserSymbol";
import SelectCustomerModal from "../../customerManagement/components/SelectCustomerModal";
import { Assignee } from "../../tasks/types/TasksResponseType";
import { UserType } from "../../userManagement/types/getUsersListResponseType";
import {
  createDealContactTitle,
  updateDealContactTitle,
} from "../redux/DealsAPI";
import CloseButton from "../../../sharedComponents/Buttons/CloseButton";

interface ModelComponentProps {
  modalProps: AddProductTypeModalProps;
  deal_Id?: any;
  closeModal: () => void;
  getDealPhaseAPI?: () => void;
  setDataLoading: (value: boolean) => void;
  //   getProductDetailsAPI: () => void;
  //   product: ProductType;
}

interface AddProductTypeModalProps {
  show: boolean;
  type?: string;
  title?: any;
  //   pType?: any;
}

const AddContactTitlesModal: FC<ModelComponentProps> = ({
  modalProps,
  deal_Id,
  getDealPhaseAPI,
  closeModal,
  setDataLoading,
}) => {
  const contactTitlesList = useSelector<RootState>(
    ({ masterdata }) => masterdata.contactTitlesList
  ) as any[];

  const { show, type, title } = modalProps;
  const intl = useIntl();
  // state
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useDispatch();
  const [selectedContactTitle, setSelectedContactTitle] = useState<any>();
  const [showContactsModal, setShowContactsModal] = useState<boolean>(false);
  const [selectedContacts, setSelectedContacts] = useState<any>([]);
  //   const [units, setUnits] = useState([]);
  const [errors, setErrors] = useState<any>({
    contacts_titles_id: null,
    contacts_id: null,
  });

  const [touched, setTouched] = useState({
    titleValue: false,
    contacts: false,
  });

  const handleOnSelectCustomers = (customers: any[]) => {
    setSelectedContacts(customers);
    setShowContactsModal(false);
  };

  useEffect(() => {
    getContactTitlesList().then(({ data: { data } }) => {
      dispatch(masterActions.setContactTitlesList(data));
    });
  }, []);

  useEffect(() => {
    if (type === "new") {
      setSelectedContactTitle(undefined);
      setSelectedContacts([]);
    } else {
      setSelectedContactTitle(title);
    }
  }, [modalProps]);

  const removeUserhandle = (id: number) => {
    setSelectedContacts((prevContacts: any[]) =>
      prevContacts.filter((remainingContacts) => remainingContacts.id !== id)
    );
  };

  const handleSubmit = () => {
    if (type === "new") {
      setDataLoading?.(true);
      createDealContactTitle(deal_Id, [
        ...selectedContacts?.map((contact: { id: any }) => {
          return {
            contacts_titles_id: selectedContactTitle?.id,
            contacts_id: contact?.id,
          };
        }),
      ])
        .then((data) => {
          getDealPhaseAPI?.();
          setTimeout(() => {
            successToast(
              intl.formatMessage({
                id: "CONTACT_TITLE_ADD_SUCCESS_MESSAGE",
              })
            );
          }, 500);
        })
        .catch((e) => {
          errorToast(
            intl.formatMessage({
              id: "CONTACT_TITLE_ADD_FAILURE_MESSAGE",
            })
          );
        })
        .finally(() => {
          setDataLoading?.(false);
          closeModal();
        });
    } else {
      updateDealContactTitle(
        selectedContactTitle?.deals_contacts_titles_id,
        selectedContactTitle?.contacts_titles_id,
        selectedContacts[0]?.id
      )
        .then((data) => {
          successToast(
            intl.formatMessage({
              id: "CONTACT_TITLE_UPDATE_SUCCESS_MESSAGE",
            })
          );
        })
        .catch((e) => {
          errorToast(
            intl.formatMessage({
              id: "CONTACT_TITLE_UPDATE_FAILURE_MESSAGE",
            })
          );
          console.error(e?.response?.data?.message);
        })
        .finally(() => {
          setLoading(false);
          getDealPhaseAPI?.();
          closeModal();
        });
    }
  };

  //validations
  useEffect(() => {
    // @ts-ignore
    setErrors((prevErrors: any) => ({
      ...prevErrors,
      titleValue: selectedContactTitle ? null : prevErrors.titleValue,
      contactValue:
        selectedContacts.length > 0 ? null : prevErrors.contactValue,
    }));
  }, [selectedContactTitle, selectedContacts]);

  return (
    <>
      {showContactsModal && (
        <SelectCustomerModal
          show={showContactsModal}
          closeModal={() => setShowContactsModal(false)}
          onSelectCustomers={(customers) => {
            //@ts-ignore
            handleOnSelectCustomers(customers);
          }}
          isSelectionModal={true}
          selectMultiple
          selectedContacts={selectedContacts}
        />
      )}
      <Modal
        show={show}
        centered
        contentClassName={loading ? "pe-none" : "AddNew"}
        backdrop="static"
        className="kt-modal"
        onHide={closeModal}
      >
        <Modal.Header>
          <Modal.Title>
            {type === "edit"
              ? intl.formatMessage({
                  id: "DEAL_EDIT_CONTACT_TITLES",
                })
              : intl.formatMessage({
                  id: "DEAL_ADD_CONTACT_TITLES",
                })}
          </Modal.Title>
          <CloseButton onClose={closeModal} />
        </Modal.Header>

        <div className="">
          <Modal.Body className="overflow-inherit">
            <div>
              {/* contact titles from master data */}
              <div className="fv-row tab-field">
                <label className="d-flex align-items-center tab-field-label">
                  <span className="required">
                    {intl.formatMessage({
                      id: "DEAL_CONTACT_TITLE",
                    })}
                  </span>
                </label>
                <DropDown
                  id="contactTypesDropdown"
                  items={contactTitlesList}
                  selectedItem={selectedContactTitle}
                  // @ts-ignore
                  onSelectItem={(item: GroupType) => {
                    setSelectedContactTitle(item);
                    setTouched((prev) => ({ ...prev, titleValue: true }));
                  }}
                  displayValue={
                    selectedContactTitle?.name ||
                    selectedContactTitle?.contact_title_name
                  }
                  // @ts-ignore
                  displayFunc={(item: GroupType) => {
                    return item.name;
                  }}
                  //disabled={type === "edit"}
                />
                {errors?.titleValue && (
                  <span className="text-danger mt-2">{errors?.titleValue}</span>
                )}
              </div>
              {/* Add contacts for deal */}
              <div className="row tab-field-label mb-0">
                <label className="form-label required tab-field-label">
                  {intl.formatMessage({
                    id: "DEAL_CONTACTS",
                  })}
                </label>
                <div className="col-1 py-3">
                  <label className="form-label">
                    <SVGICON src={AddUserIcon} className="svg-icon-1 me-0" />
                  </label>
                </div>
                <div className="col-11">
                  <div className="d-flex justify-content-start">
                    <div className="symbol-group symbol-hover flex-nowrap">
                      {selectedContacts?.map(
                        (
                          data: UserType | Assignee,
                          i: React.Key | null | undefined
                        ) => (
                          <React.Fragment key={i}>
                            <UserSymbol
                              data={data}
                              handleRemove={() => removeUserhandle(data?.id)}
                            />
                          </React.Fragment>
                        )
                      )}
                      <AddUserSymbol
                        tooltipText={intl.formatMessage({
                          id: "ADD_DEAL_CONTACTS",
                        })}
                        onClick={() => {
                          setShowContactsModal(true);
                        }}
                      />
                    </div>
                  </div>
                </div>
                {errors?.contactValue && (
                  <span className="text-danger mt-2">
                    {errors?.contactValue}
                  </span>
                )}
              </div>
            </div>
          </Modal.Body>
        </div>

        <Modal.Footer className="justify-content-center">
          <Button
            variant="secondary"
            onClick={() => {
              setSelectedContactTitle(undefined);
              setSelectedContacts([]);
              closeModal();
            }}
          >
            {intl.formatMessage({ id: "CLOSE_BUTTON" })}
          </Button>
          <Button
            type="submit"
            variant="primary"
            id="kt_customer_contact_submit"
            onClick={() => {
              if (
                selectedContacts?.length > 0 &&
                selectedContactTitle !== undefined
              ) {
                handleSubmit();
                setErrors((prevErrors: any) => ({
                  ...prevErrors,
                  titleValue: null,
                  contactValue: null,
                }));
              } else if (selectedContactTitle === undefined) {
                setErrors((prevErrors: any) => ({
                  ...prevErrors,
                  titleValue: intl.formatMessage({
                    id: "CONTACT_TITLE_REQUIRED_MESSAGE",
                  }),
                }));
              } else if (selectedContacts?.length === 0) {
                setErrors((prevErrors: any) => ({
                  ...prevErrors,
                  contactValue: intl.formatMessage({
                    id: "CONTACT_REQUIRED_MESSAGE",
                  }),
                }));
              }
            }}
          >
            {!loading && intl.formatMessage({ id: "SUBMIT_BUTTON" })}
            {loading && (
              <span className="indicator-progress" style={{ display: "block" }}>
                {intl.formatMessage({
                  id: "CUSTOMER_MANAGEMENT_CONTACT_TYPES_LOADING_MESSAGE",
                })}{" "}
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddContactTitlesModal;
