import { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// images
import DeleteIcon from "../../../../_metronic/assets/icons/delete.svg";
import EditIcon from "../../../../_metronic/assets/icons/edit.svg";
import PlusIcon from "../../../../_metronic/assets/icons/plus.svg";
import SearchIcon from "../../../../_metronic/assets/icons/search.svg";

// custom
import { SVGICON } from "../../../../_metronic/helpers";
import { useGlobalModalContext } from "../../../sharedComponents/modals/ModalContext";
import {
  deleteIndustryGroup,
  deleteIndustrySector,
  getIndustryGroups,
} from "../redux/PartnersMasterdataAPI";
import {
  IndustryGroupType,
  IndustrySectorType,
} from "../types/getIndustryGroupsResponseType";
import AddNewIndustryGroupModal from "./AddNewIndustryGroupModal";
import AddNewIndustrySectorModal from "./AddNewIndustrySectorModal";
import DisplayIndustryGroupModal from "./DisplayIndustryGroupModal";

import { useIntl } from "react-intl";
import { actions as masterdataActions } from "../../masterdata/redux";
import { RootState } from "../../../../setup";
import { RoleType } from "../../RolesAndPermissions/types/getRolesResponseType";
import { enablePermissionForCrud } from "../../../utils/PermisisionEnabledForResource";
import NoItemsFound from "../../../sharedComponents/NoItemsFound/NoItemsFound";
import {
  errorToast,
  successToast,
} from "../../../sharedComponents/toasts/Toasts";

interface IndustriesAndSectorsProps {}

interface IndustrySectorModalProps {
  show: boolean;
  industryGroup: IndustryGroupType | undefined;
}

interface IndustryGroupModalProps {
  show: boolean;
  type: "edit" | "new";
  industryGroup: IndustryGroupType | undefined;
}

const IndustriesAndSectors: FC<IndustriesAndSectorsProps> = () => {
  const dispatch = useDispatch();
  const intl = useIntl();

  // state
  const [industryGroups, setIndustryGroups] = useState<IndustryGroupType[]>([]);
  const userRoleDetails = useSelector<RootState>(
    // @ts-ignore
    (state) => state.auth.roleDetails
  ) as RoleType;
  //@ts-ignore
  const crudPermission: any = enablePermissionForCrud(
    userRoleDetails,
    "industries_sectors"
  );
  const [displayIndustryGroups, setDisplayIndustryGroups] = useState<
    IndustryGroupType[]
  >([]);
  const [searchText, setSearchText] = useState<string>("");
  const [showIndustrySectorModalProps, setShowIndustrySectorModalProps] =
    useState<IndustrySectorModalProps>({
      show: false,
      industryGroup: undefined,
    });
  const [
    showDisplayIndustrySectorModalProps,
    setShowDisplayIndustrySectorModalProps,
  ] = useState<IndustrySectorModalProps>({
    show: false,
    industryGroup: undefined,
  });
  const [showIndustryGroupModalProps, setShowIndustryGroupModalProps] =
    useState<IndustryGroupModalProps>({
      show: false,
      type: "new",
      industryGroup: undefined,
    });
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // context
  const {
    showDeleteConfirmModal,
    hideDeleteConfirmModal,
    setDeleteModalLoading,
  } = useGlobalModalContext();

  const updateDisplayCategoriesList = () => {
    const updatedCategoriesList = industryGroups.filter((industryGroup) => {
      if (
        (industryGroup.name || "")
          .toLocaleLowerCase()
          .includes((searchText || "").toLocaleLowerCase())
      ) {
        return true;
      }
      return false;
    });
    // save updatedlist to display
    setDisplayIndustryGroups(updatedCategoriesList);
  };

  const getIndustryGroupsAPI = () => {
    setIsLoading(true);
    getIndustryGroups()
      .then(({ data: { data = [] } }) => {
        setIndustryGroups([...data]);
        dispatch(masterdataActions.setIndustryGroupsList(data));
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getIndustryGroupsAPI();

    return () => {
      setIndustryGroups([]);
    };
  }, []);

  useEffect(() => {
    updateDisplayCategoriesList();
  }, [industryGroups, searchText]);

  const onSearchTextChange = (text: string) => {
    setSearchText((text || "").trim());
  };

  const deleteSelectedIndustryGroup = (industryGroup: IndustryGroupType) => {
    setDeleteModalLoading(true);
    deleteIndustryGroup(industryGroup.id)
      .then(() => {
        successToast(
          intl.formatMessage({
            id: "PARTNERS_INDUSTRY_GROUP_DELETE_SUCCESS_MESSAGE",
          })
        );
      })
      .catch(() => {
        errorToast(
          intl.formatMessage({
            id: "PARTNERS_INDUSTRY_GROUP_DELETE_ERROR_MESSAGE",
          })
        );
      })
      .finally(() => {
        setDeleteModalLoading(false);
        hideDeleteConfirmModal();
        getIndustryGroupsAPI();
      });
  };

  const deleteSelectedIndustrySector = (
    industryGroupSector: IndustrySectorType
  ) => {
    setDeleteModalLoading(true);
    deleteIndustrySector(industryGroupSector.id)
      .then(() => {
        successToast(
          intl.formatMessage({
            id: "PARTNERS_INDUSTRY_SECTOR_DELETE_SUCCESS_MESSAGE",
          })
        );
      })
      .catch(() => {
        errorToast(
          intl.formatMessage({
            id: "PARTNERS_INDUSTRY_SECTOR_DELETE_ERROR_MESSAGE",
          })
        );
      })
      .finally(() => {
        setDeleteModalLoading(false);
        hideDeleteConfirmModal();
        getIndustryGroupsAPI();
      });
  };

  return (
    <div>
      {showIndustryGroupModalProps.show && (
        <AddNewIndustryGroupModal
          modalProps={showIndustryGroupModalProps}
          closeModal={() => {
            setShowIndustryGroupModalProps({
              show: false,
              type: "new",
              industryGroup: undefined,
            });
          }}
          getIndustryGroupsAPI={getIndustryGroupsAPI}
        />
      )}

      {showIndustrySectorModalProps.show && (
        <AddNewIndustrySectorModal
          modalProps={showIndustrySectorModalProps}
          closeModal={() => {
            setShowIndustrySectorModalProps({
              show: false,
              industryGroup: undefined,
            });
          }}
          getIndustryGroupsAPI={getIndustryGroupsAPI}
        />
      )}

      {showDisplayIndustrySectorModalProps.show && (
        <DisplayIndustryGroupModal
          // @ts-ignore
          modalProps={showDisplayIndustrySectorModalProps}
          closeModal={() => {
            setShowDisplayIndustrySectorModalProps({
              show: false,
              industryGroup: undefined,
            });
          }}
          getIndustryGroupsAPI={getIndustryGroupsAPI}
        />
      )}

      {/* search sectors */}
      <div className="card card-search d-flex flex-row justify-content-between">
        <div className="d-flex align-items-center position-relative">
          <span className="position-absolute" style={{ left: "10px" }}>
            <SVGICON className="svg-icon svg-icon-1" src={SearchIcon} />
          </span>
          <input
            type="text"
            className="form-control form-control-md form-control-solid min-w-150px min-w-md-200px ps-11"
            placeholder={intl.formatMessage({
              id: "PARTNERS_INDUSTRY_SEARCH_PLACEHOLDER",
            })}
            onChange={(e) => {
              onSearchTextChange(e.target.value);
            }}
          />
        </div>

        {/* add new category button */}
        {crudPermission?.create && (
          <button
            className="btn  btn-primary"
            onClick={() => {
              setShowIndustryGroupModalProps({
                show: true,
                type: "new",
                industryGroup: undefined,
              });
            }}
          >
            <SVGICON src={PlusIcon} className="svg-icon-2" />
            {intl.formatMessage({ id: "PARTNERS_INDUSTRY_ADD_NEW_GROUP" })}
          </button>
        )}
      </div>

      <div className="mt-18px card-mb">
        <div className="row mx-0 g-18px">
          {displayIndustryGroups.map((industryGroup, i) => {
            const displaySectors = (industryGroup.sectors || []).slice(0, 4);
            const remainingSectors =
              (industryGroup.sectors || []).length - displaySectors.length;
            return (
              <div className="col-md-6 industry-sector-card" key={i}>
                <div className="card h-100 card-p card-flush">
                  <div className="card-header card-header-search p-0 d-flex mb-2">
                    <div className="card-title">
                      <h2>{industryGroup.name}</h2>
                    </div>
                    <div className="card-toolbar my-0">
                      <div>
                        {crudPermission?.edit && (
                          <button
                            className="btn btn-icon btn-light btn-active-light-primary btn-sm me-2"
                            onClick={() => {
                              setShowIndustryGroupModalProps({
                                show: true,
                                type: "edit",
                                industryGroup: industryGroup,
                              });
                            }}
                          >
                            <SVGICON src={EditIcon} className="svg-icon-3" />
                          </button>
                        )}
                        {crudPermission?.delete && (
                          <button
                            className="btn btn-icon btn-light btn-active-light-primary btn-sm me-2"
                            onClick={() => {
                              showDeleteConfirmModal(
                                intl.formatMessage({
                                  id: "PARTNERS_INDUSTRY_GROUP_DELETE_CONFIRMATION_MESSAGE",
                                }),
                                () => {
                                  deleteSelectedIndustryGroup(industryGroup);
                                },
                                intl.formatMessage({ id: "COMMON_YES_DELETE" })
                              );
                            }}
                          >
                            <SVGICON src={DeleteIcon} className="svg-icon-3" />
                          </button>
                        )}
                        {crudPermission?.edit && (
                          <button
                            className="btn btn-icon btn-primary btn-sm"
                            onClick={() => {
                              setShowIndustrySectorModalProps({
                                show: true,
                                industryGroup: industryGroup,
                              });
                            }}
                          >
                            <SVGICON src={PlusIcon} className="svg-icon-3" />
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="card-body p-0">
                    {(!industryGroup.sectors ||
                      industryGroup.sectors.length === 0) && (
                      <div className="fw-bold text-gray-600 mb-5">
                        {intl.formatMessage({
                          id: "PARTNERS_INDUSTRY_GROUP_SECTORS_EMPTY_MESSAGE",
                        })}
                      </div>
                    )}

                    <div className="d-flex flex-column text-gray-600">
                      {displaySectors.map((industryGroupSector, j) => {
                        return (
                          <div
                            className="d-flex align-items-start pb-3"
                            key={j}
                          >
                            <div
                              role="button"
                              onClick={() => {
                                showDeleteConfirmModal(
                                  intl.formatMessage({
                                    id: "PARTNERS_INDUSTRY_SECTOR_DELETE_CONFIRMATION_MESSAGE",
                                  }),
                                  () => {
                                    deleteSelectedIndustrySector(
                                      industryGroupSector
                                    );
                                  },
                                  intl.formatMessage({
                                    id: "COMMON_YES_DELETE",
                                  })
                                );
                              }}
                            >
                              {crudPermission?.edit && (
                                <SVGICON
                                  src={DeleteIcon}
                                  className="svg-icon-2 svg-icon-danger me-2"
                                />
                              )}
                            </div>
                            <span className="fs-6 text-gray-700 text-break">
                              {industryGroupSector.name}
                            </span>
                          </div>
                        );
                      })}

                      {remainingSectors > 0 && (
                        <div className="d-flex align-items-center text-primary fw-bold">
                          <span className="me-2"></span>
                          <em
                            role={"button"}
                            onClick={() => {
                              setShowDisplayIndustrySectorModalProps({
                                show: true,
                                industryGroup,
                              });
                            }}
                          >
                            {`...  `}
                            {`and ${remainingSectors} more`}
                          </em>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
          {/* no data */}
          {!isLoading && displayIndustryGroups.length === 0 && (
            <table className="col-12 d-flex justify-content-center align-items-center mt-2">
              <tbody>
                <NoItemsFound languageKey="NO_ITEMS_FOUND" />
              </tbody>
            </table>
          )}
        </div>
      </div>
    </div>
  );
};

export default IndustriesAndSectors;
