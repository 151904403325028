import React, { FC } from "react";
import { useIntl } from "react-intl";
// import {useSelector} from 'react-redux'
import { useHistory } from "react-router-dom";
// import {RootState} from '../../../../setup'

// images
import MenuIcon from "../../../../_metronic/assets/icons/menu.svg";

// custom
import { SVGICON } from "../../../../_metronic/helpers";
import ActionsDropdown from "../../../sharedComponents/ActionsDropdown/ActionsDropdown";
import { useGlobalModalContext } from "../../../sharedComponents/modals/ModalContext";
import { deletePartner } from "../redux";
import { PartnerType } from "../types/getPartnersListResponseType";
import { useSelector } from "react-redux";
import { RootState } from "../../../../setup";
import { RoleType } from "../../RolesAndPermissions/types/getRolesResponseType";
import { enablePermissionForCrud } from "../../../utils/PermisisionEnabledForResource";
import { errorToast, successToast } from "../../../sharedComponents/toasts/Toasts";

interface Props {
  partner: PartnerType;
  getPartnersListAPI: () => void;
  setCheckedPartners?: (value : any) => void;
}

const PartnersListActionsDropdown: FC<Props> = ({
  partner,
  getPartnersListAPI,
  setCheckedPartners
}) => {
  const history = useHistory();
  const intl = useIntl();
  const userRoleDetails = useSelector<RootState>(
    // @ts-ignore
    (state) => state.auth.roleDetails
  ) as RoleType;

  const crudPermission: any = enablePermissionForCrud(
    userRoleDetails,
    "partners"
  );

  const {
    showDeleteConfirmModal,
    hideDeleteConfirmModal,
    setDeleteModalLoading,
  } = useGlobalModalContext();

  const onEditPartnerClick = () => {
    history.push(`/crm/organisation/edit`, {
      partnerDetails: partner,
    });
  };

  const deleteSelectedUser = () => {
    setDeleteModalLoading(true);
    deletePartner(partner.id)
      .then(() => {
        successToast(
          intl.formatMessage({ id: "PARTNERS_DELETE_SUCCESS_MESSAGE" })
        );
      })
      .catch((e) => {
        if (e.response?.status=== 409) {
          errorToast(
            intl.formatMessage({ id: "PARTNERS_CONFLICT_DELETE_FAILURE_MESSAGE" })
          );
        }else{
          errorToast(
            intl.formatMessage({ id: "PARTNERS_DELETE_FAILURE_MESSAGE" })
          );
        }
      })
      .finally(() => {
        setDeleteModalLoading(false);
        hideDeleteConfirmModal();
        getPartnersListAPI?.();
        setCheckedPartners?.([])
      });
  };

  const dropdownOptions = [
    ...(crudPermission?.edit
      ? [
          {
            name: intl.formatMessage({ id: "COMMON_EDIT" }),
            onClick: onEditPartnerClick,
          },
        ]
      : []),
    ...(crudPermission?.delete
      ? [
          {
            name: intl.formatMessage({ id: "COMMON_DELETE" }),
            onClick: () => {
              showDeleteConfirmModal(
                intl.formatMessage({
                  id: "PARTNERS_DELETE_CONFIRMATION_MESSAGE",
                }),
                deleteSelectedUser
              );
            },
            className: "text-danger",
          },
        ]
      : []),
  ];

  if (dropdownOptions.length > 0) {
    return (
      <div className="dropdown d-flex flex-row justify-content-end">
        <ActionsDropdown options={dropdownOptions} />
      </div>
    );
  } else {
    return null;
  }
};

export default PartnersListActionsDropdown;
