import React, { useEffect, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import "../CalendarPage.scss";
import { useSelector } from "react-redux";
import { RootState } from "../../../../setup";
import {
  UserType,
  connectedAccountType,
} from "../../userManagement/types/getUsersListResponseType";
import { getEvents } from "../redux/CalendarAPI";
import AddEventModal from "./AddEventModal";
import { useIntl } from "react-intl";
import { EventType } from "../redux/CalendarEventsResponseType";
import EventDetailsModal from "./EventDetailsModal";
import { googleAccountRefreshToken } from "../../userManagement/redux";

export interface CalendarEventType {
  id: string;
  title: string;
  start: string;
  end: string;
  summary: string;
  location: string;
}

const CalendarView = () => {
  const intl = useIntl();
  // selector
  const userDetails = useSelector<RootState>(
    ({ auth }) => auth.user
  ) as UserType;

  //   state
  const [loading, setLoading] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [allEvents, setAllEvents] = useState<CalendarEventType[]>([]);
  const userData = useSelector<RootState>(({ auth }) => auth.user) as UserType;
  const [openEventModal, setOpenEventModal] = useState<{
    show: boolean;
    event: CalendarEventType | undefined;
  }>({
    show: false,
    event: undefined,
  });

  const handleGoogleRefresh = () => {
    googleAccountRefreshToken(
      userDetails.connected_accounts.google[0]?.id,
      userDetails.id
    );
  };

  const getAllEvents = () => {
    const res: React.SetStateAction<any[]> = [];
    getEvents(
      userDetails.id,
      userDetails.connected_accounts.google?.[0]?.id || null
    )
      .then(({ data: { calendar_events } }) => {
        if (calendar_events && calendar_events.length > 0) {
          calendar_events.map((event) => {
            let obj: CalendarEventType = {
              id: event.id,
              title: event.description,
              start: event.start.dateTime,
              end: event.end.dateTime,
              summary: event.summary,
              location: event.location,
            };
            res.push(obj);
          });
          setAllEvents(res);
        } else {
          handleGoogleRefresh();
        }
      })
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getAllEvents();
  }, []);

  return (
    <>
      {showAddModal && (
        <AddEventModal
          show={showAddModal}
          closeModal={() => {
            setShowAddModal(false);
          }}
          getAllEvents={getAllEvents}
          type="new"
        />
      )}
      {openEventModal.show && openEventModal.event && (
        <EventDetailsModal
          show={openEventModal.show}
          event={openEventModal.event}
          closeModal={() => {
            setOpenEventModal({
              show: false,
              event: undefined,
            });
          }}
          getAllEvents={getAllEvents}
          user={userDetails}
        />
      )}
      <div
        className="card-mt noBottomBorder"
        style={{
          borderRadius: 0,
        }}
      >
        <div className="d-flex justify-content-end align-items-center">
          <div className="fs-3 fw-bold">
            {/* {intl.formatMessage({ id: "CALENDAR_TITLE" })} */}
          </div>
          {
            // userDetails?.connected_accounts?.google[0]?.id &&

            <div>
              <button
                className="btn btn-primary card-mb"
                onClick={() => {
                  setShowAddModal(true);
                }}
              >
                {intl.formatMessage({ id: "CALENDAR_ADD_EVENT_TITLE" })}
              </button>
            </div>
          }
        </div>
      </div>
      <div className="card card-body card-p card-mb">
        <FullCalendar
          plugins={[dayGridPlugin, timeGridPlugin]}
          initialView="dayGridMonth"
          eventTimeFormat={{
            hour: "numeric",
            minute: "2-digit",
            meridiem: "short",
          }}
          headerToolbar={{
            center: "title",
            left: "prev,next today",
            end: "dayGridMonth,timeGridWeek,timeGridDay",
          }}
          events={allEvents}
          displayEventEnd={true}
          themeSystem="bootstrap5"
          eventClick={(event) => {
            const res = allEvents.filter(
              (val) => val.id == event.event._def.publicId
            )[0];
            setOpenEventModal({
              show: true,
              event: res,
            });
          }}
          dayMaxEventRows={1}
          dayMaxEvents={1}
          firstDay={1}
        />
      </div>
    </>
  );
};

export default CalendarView;
