import React, { FC, useState } from "react";
import { useHistory } from "react-router-dom";
import { useIntl } from "react-intl";

import {
  errorToast,
  successToast,
} from "../../../../sharedComponents/toasts/Toasts";
import { useGlobalModalContext } from "../../../../sharedComponents/modals/ModalContext";
import ActionsDropdown from "../../../../sharedComponents/ActionsDropdown/ActionsDropdown";

import InvoicePaymentModal from "./InvoicePaymentModal";

import { deleteInvoice, forceDeleteInvoice, cancelInvoice } from "../redux";
import { InvoiceType } from "../types/InvoicesTypes";

interface Props {
  invoice: InvoiceType;
  tabActiveIndex: number;
  status: string;
  itemsPerPage: number;
  activePage: number;
  invoicesSearchText: string;
  paginatedItems: InvoiceType[];
  getInvoicesTrashListAPI: () => void;
  handleRestoreInvoice: (invoice: InvoiceType) => void;
  getInvoicesListAPI: (
    status?: string,
    invoicesSearchText?: string,
    itemsPerPage?: number,
    activePage?: number
  ) => void;
}

const InvoicesListActionsDropDown: FC<Props> = ({
  invoice,
  tabActiveIndex,
  status,
  itemsPerPage,
  activePage,
  invoicesSearchText,
  paginatedItems,
  getInvoicesTrashListAPI,
  handleRestoreInvoice,
  getInvoicesListAPI,
}) => {
  const history = useHistory();
  const {
    showDeleteConfirmModal,
    hideDeleteConfirmModal,
    setDeleteModalLoading,
  } = useGlobalModalContext();

  const intl = useIntl();

  const [showpaymentModal, setShowPaymentModal] = useState<boolean>(false);

  const onEditClick = () => {
    history.push(`/sales/invoices/edit`, { invoice });
  };

  const deleteExistingInvoice = (id: number) => {
    setDeleteModalLoading(true);
    deleteInvoice(id)
      .then(() => {
        successToast(
          intl.formatMessage({
            id: "INVOICE_SINGLE_INVOICE_DELETE_SUCCESS_MESSAGE",
          })
        );
      })
      .catch(() => {
        errorToast(
          intl.formatMessage({
            id: "INVOICE_SINGLE_INVOICE_DELETE_SUCCESS_MESSAGE",
          })
        );
      })
      .finally(() => {
        setDeleteModalLoading(false);
        hideDeleteConfirmModal();
        getInvoicesListAPI?.(
          status,
          invoicesSearchText,
          itemsPerPage,
          paginatedItems?.length === 1 && activePage - 1 > 1
            ? activePage - 1
            : activePage
        );
      });
  };

  const forceDeleteExistingInvoice = (id: number) => {
    setDeleteModalLoading(true);
    forceDeleteInvoice(id)
      .then(() => {
        successToast(
          intl.formatMessage({
            id: "INVOICE_SINGLE_INVOICE_DELETE_SUCCESS_MESSAGE",
          })
        );
      })
      .catch(() => {
        errorToast(
          intl.formatMessage({
            id: "INVOICE_SINGLE_INVOICE_FAILURE_MESSAGE",
          })
        );
      })
      .finally(() => {
        setDeleteModalLoading(false);
        hideDeleteConfirmModal();
        getInvoicesTrashListAPI?.();
      });
  };

  const dropdownOptions = [
    ...(tabActiveIndex === 1
      ? [
          {
            name: intl.formatMessage({ id: "EDIT_BUTTON" }),
            onClick: onEditClick,
          },
          {
            name: intl.formatMessage({ id: "DELETE_BUTTON" }),
            onClick: () => {
              showDeleteConfirmModal(
                intl.formatMessage({
                  id: "INVOICE_DELETE_CONFIRMATION_MESSAGE",
                }),
                () => {
                  deleteExistingInvoice(invoice?.id);
                }
              );
            },
            className: "text-danger",
          },
        ]
      : []),
    ...(tabActiveIndex === 7
      ? [
          {
            name: intl.formatMessage({ id: "COMMON_RESTORE" }),
            onClick: () => {
              handleRestoreInvoice(invoice);
            },
          },
          {
            name: intl.formatMessage({ id: "COMMON_DELETE_PERMANENT" }),
            onClick: () => {
              showDeleteConfirmModal(
                intl.formatMessage({
                  id: "INVOICE_SINGLE_DELETE_CONFIRM_MESSAGE",
                }),
                () => {
                  forceDeleteExistingInvoice(invoice?.id);
                }
              );
            },
            className: "text-danger",
          },
        ]
      : []),
    ...(invoice?.status === "open" ||
    invoice?.status === "partiallyPaid" ||
    invoice?.status === "overDue"
      ? [
          {
            name: intl.formatMessage({
              id: "PAYMENTS",
            }),
            onClick: () => {
              setShowPaymentModal(true);
            },
          },
        ]
      : []),
    ...(invoice?.status === "open" ||
    invoice?.status === "paid" ||
    invoice?.status === "partiallyPaid" ||
    invoice?.status === "overDue"
      ? [
          {
            name: intl.formatMessage({
              id: "CANCEL",
            }),
            onClick: () => {
              cancelInvoice(invoice?.id)
                .then(() => {
                  successToast(
                    intl.formatMessage({ id: "INVOICE_UPDATE_SUCCESS_MESSAGE" })
                  );
                })
                .catch((error) => {
                  errorToast(
                    intl.formatMessage({ id: "INVOICE_UPDATE_FAILURE_MESSAGE" })
                  );
                })
                .finally(() => {
                  getInvoicesListAPI();
                });
            },
          },
        ]
      : []),
    // ...((invoice?.status === "open" ||
    //   invoice?.status === "paid" ||
    //   invoice?.status === "partiallyPaid" ||
    //   invoice?.status === "overDue" ||
    //   invoice?.status === "cancel") &&
    // // @ts-ignore
    // invoice?.offer_number
    //   ? [
    //       {
    //         name: intl.formatMessage({
    //           id: "GO_TO_OFFERS",
    //         }),
    //         onClick: () => {
    //           history.push(`/sales/offers/edit`, {
    //             offer: {
    //               // @ts-ignore
    //               id: invoice?.offer_id,
    //               sales_details: {
    //                 // @ts-ignore
    //                 offer_layout_id: invoice?.offer_layout_id,
    //               },
    //             },
    //           });
    //         },
    //       },
    //     ]
    //   : []),
  ];

  if (dropdownOptions?.length > 0) {
    return (
      <>
        {showpaymentModal && (
          <InvoicePaymentModal
            show={showpaymentModal}
            closeModal={() => {
              setShowPaymentModal(false);
            }}
            invoice={invoice}
            getInvoicesListAPI={getInvoicesListAPI}
          />
        )}

        <div className="dropdown d-flex flex-row justify-content-end">
          <ActionsDropdown options={dropdownOptions} />
        </div>
      </>
    );
  } else {
    return null;
  }
};

export default InvoicesListActionsDropDown;
