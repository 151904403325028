import React, { FC, useEffect, useRef, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";

// custom
// import Editor from '../../../sharedComponents/textEditor/TextEditor'
import clsx from "clsx";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { RootState } from "../../../../setup";
import { CountryType } from "../../masterdata/types/GetCountriesListResponseTypes";
import DropDown from "../../../sharedComponents/dropdown/Dropdown";
import { PartnerType } from "../types/getPartnersListResponseType";
import { updatePartnerGenericDetails } from "../redux";
import { enablePermissionForCrud } from "../../../utils/PermisisionEnabledForResource";
import { RoleType } from "../../RolesAndPermissions/types/getRolesResponseType";
import {
  errorToast,
  successToast,
} from "../../../sharedComponents/toasts/Toasts";

interface Props {
  partner: PartnerType;
  getPartnerDetailsAPI: () => void;
}

const PartnerGeneralDetails: FC<Props> = ({
  partner,
  getPartnerDetailsAPI,
}) => {
  const countries = useSelector<RootState>(
    ({ masterdata }) => masterdata.countriesList
  ) as CountryType[];

  const userRoleDetails = useSelector<RootState>(
    // @ts-ignore
    (state) => state.auth.roleDetails
  ) as RoleType;

  const crudPermission: any = enablePermissionForCrud(
    userRoleDetails,
    "partners"
  );

  const intl = useIntl();
  const textareaRef = useRef(null);

  // state

  const [loading, setLoading] = useState<boolean>(false);
  const [titleLength, setTitleLength] = useState<number>(1);
  const [selectedCountry, setSelectedCountry] = useState<CountryType>();
  const [width, setWidth] = useState({});

  const PostGeneralDetailsSchema = Yup.object().shape({
    name: Yup.string().required(
      intl.formatMessage({
        id: "PARTNERS_GENERAL_DETAILS_FIELD_COMPANYNAME_VALIDATION_ERROR",
      })
    ),
    zip_code: Yup.string()
      .matches(
        /^\d+$/,
        intl.formatMessage({
          id: "CUSTOMER_ADDRESS_ZIP_CODE_NUMERIC_MESSAGE",
        })
      )
      .max(
        10,
        intl.formatMessage({
          id: "CUSTOMER_ADDRESS_ZIP_CODE_VALIDATION_MESSAGE",
        })
      )
      .nullable(),
  });

  const initialValues = {
    name: partner.name || "",
    address_1: partner?.address_1 || "",
    address_2: partner?.address_2 || "",
    zip_code: partner?.zip_code || undefined,
    city: partner?.city || "",
    country_id: partner?.country_id || undefined,
    email: partner?.email || "",
    website_url: partner?.website || "",
    telephone: partner?.telefone || "",
  };

  const formik = useFormik({
    initialValues,
    validationSchema: PostGeneralDetailsSchema,
    enableReinitialize: true,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      if (partner?.id && values.name) {
        setLoading(true);
        setStatus(null);
        updatePartnerGenericDetails(
          partner.id,
          values.name,
          values.address_1,
          values.address_2,
          values.city,
          values.zip_code || null,
          values.country_id || null,
          values.email,
          values.website_url,
          values.telephone
        )
          .then(() => {
            successToast(
              intl.formatMessage({
                id: "PARTNERS_GENERAL_DETAILS_UPDATE_SUCCESS",
              })
            );
          })
          .catch((e) => {
            if (e.response?.status === 422) {
              setStatus(
                intl.formatMessage({
                  id: "NAME_ALREADY_EXISTS",
                })
              );
              errorToast(
                intl.formatMessage({
                  id: "PARTNERS_GENERAL_DETAILS_UPDATE_FAILURE",
                })
              );
            } else {
              errorToast(
                intl.formatMessage({
                  id: "PARTNERS_GENERAL_DETAILS_UPDATE_FAILURE",
                })
              );
            }
          })
          .finally(() => {
            setLoading(false);
            getPartnerDetailsAPI();
          });
      }
    },
  });

  useEffect(() => {
    handleKeyUp(partner.name || "");
    countries.forEach((country) => {
      if (country.id === partner?.country_id) {
        formik.setFieldValue("country_id", country.id);
        setSelectedCountry(country);
      }
    });
  }, []);

  const handleKeyUp = (value: string) => {
    const res = Math.ceil(value.length / 90);
    if (res <= 1) {
      setTitleLength(1);
    } else {
      setTitleLength(res);
    }
  };

  const handleResize = () => {
    setWidth({ width: window.innerWidth });
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    //@ts-ignore
    textareaRef.current.style.height = "0px";
    //@ts-ignore
    const scrollHeight = textareaRef.current.scrollHeight;
    //@ts-ignore
    textareaRef.current.style.height = scrollHeight + 3 + "px";
  }, [handleKeyUp, width]);

  return (
    <div className={`card ${!crudPermission?.edit && "pe-none"}`}>
      <div className="card-header card-px">
        <div className="card-title">
          <h3 className="fw-bolder m-0">
            {intl.formatMessage({ id: "PARTNERS_GENERAL_DETAILS_TITLE" })}
          </h3>
        </div>
      </div>
      <div className={clsx("card-body card-p", loading ? "pe-none" : "")}>
        {/* Title */}
        <div className="fv-row tab-field">
          <label className="required fw-bold fs-6 mb-2">
            {intl.formatMessage({
              id: "PARTNERS_GENERAL_DETAILS_FIELD_COMPANYNAME",
            })}
          </label>
          <textarea
            ref={textareaRef}
            cols={90}
            rows={titleLength}
            className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
            autoComplete="off"
            placeholder=""
            {...formik.getFieldProps("name")}
            style={{ resize: "none" }}
            onKeyUp={() => handleKeyUp(formik.values.name)}
          ></textarea>
          {formik.touched.name && formik.errors.name && (
            <div className="text-danger mt-2">
              <span role="alert">{formik.errors.name}</span>
            </div>
          )}
          {formik.status && (
            <div className="text-danger mt-2">
              <span role="alert">{formik.status}</span>
            </div>
          )}
        </div>

        {/* Address 1 */}
        <div className="fv-row tab-field">
          <label className="fw-bold fs-6 mb-2">
            {intl.formatMessage({
              id: "PARTNERS_GENERAL_DETAILS_FIELD_ADDRESS1",
            })}
          </label>
          <input
            placeholder={""}
            className={clsx("form-control form-control-lg form-control-solid")}
            {...formik.getFieldProps("address_1")}
            autoComplete="off"
          />
        </div>

        {/* Address 2 */}
        <div className="fv-row tab-field">
          <label className="fw-bold fs-6 mb-2">
            {intl.formatMessage({
              id: "PARTNERS_GENERAL_DETAILS_FIELD_ADDRESS2",
            })}
          </label>
          <input
            placeholder={""}
            className={clsx("form-control form-control-lg form-control-solid")}
            {...formik.getFieldProps("address_2")}
            autoComplete="off"
          />
        </div>

        <div className="row">
          {/* city */}
          <div className="fv-row tab-field col-lg-6">
            <label className="fw-bold fs-6 mb-2">
              {intl.formatMessage({
                id: "PARTNERS_GENERAL_DETAILS_FIELD_CITY",
              })}
            </label>
            <input
              placeholder={""}
              className={clsx(
                "form-control form-control-lg form-control-solid"
              )}
              {...formik.getFieldProps("city")}
              autoComplete="off"
            />
          </div>

          {/* zip code */}
          <div className="fv-row tab-field col-lg-6">
            <label className="fw-bold fs-6 mb-2">
              {intl.formatMessage({
                id: "PARTNERS_GENERAL_DETAILS_FIELD_ZIPCODE",
              })}
            </label>
            <input
              type="text"
              placeholder={""}
              className={clsx(
                "form-control form-control-lg form-control-solid"
              )}
              {...formik.getFieldProps("zip_code")}
              autoComplete="off"
            />
            {formik.touched.zip_code && formik.errors.zip_code && (
              <div className="text-danger mt-2">
                <span role="alert">{formik.errors.zip_code}</span>
              </div>
            )}
          </div>
        </div>

        {/* Country */}
        <div className="fv-row tab-field">
          <label className="fw-bold fs-6 mb-2">
            {intl.formatMessage({
              id: "PARTNERS_GENERAL_DETAILS_FIELD_COUNTRY",
            })}
          </label>
          <DropDown
            id="countriesDropDown"
            items={countries}
            selectedItem={selectedCountry}
            // @ts-ignore
            onSelectItem={(item: CountryType) => {
              formik.setFieldValue("country_id", item.id);
              setSelectedCountry(item);
            }}
            displayValue={selectedCountry?.name || ""}
            // @ts-ignore
            displayFunc={(item: CountryType) => {
              return item.emoji + " " + item.name;
            }}
          />
        </div>

        <div className="fv-row tab-field">
          <label className="fw-bold fs-6 mb-2">
            {intl.formatMessage({
              id: "PARTNERS_GENERAL_DETAILS_FIELD_EMAIL",
            })}
          </label>
          <input
            type="text"
            autoComplete="off"
            {...formik.getFieldProps("email")}
            className={clsx("form-control form-control-lg form-control-solid")}
          />
        </div>
        <div className="fv-row tab-field">
          <label className="fw-bold fs-6 mb-2">
            {intl.formatMessage({
              id: "PARTNERS_GENERAL_DETAILS_FIELD_WEBSITE_URL",
            })}
          </label>
          <input
            type="text"
            autoComplete="off"
            {...formik.getFieldProps("website_url")}
            className={clsx("form-control form-control-lg form-control-solid")}
          />
        </div>
        <div className="fv-row tab-field">
          <label className="fw-bold fs-6 mb-2">
            {intl.formatMessage({
              id: "PARTNERS_GENERAL_DETAILS_FIELD_TELEPHONE",
            })}
          </label>
          <input
            type="text"
            autoComplete="off"
            {...formik.getFieldProps("telephone")}
            className={clsx("form-control form-control-lg form-control-solid")}
          />
        </div>
        {/* submit button */}
        <div className="d-flex justify-content-end ">
          {crudPermission?.edit && (
            <button
              className="btn btn-primary min-w-100px"
              onClick={() => {
                formik.handleSubmit();
              }}
            >
              {!loading &&
                intl.formatMessage({
                  id: "PARTNERS_GENERAL_DETAILS_SAVE_BUTTON",
                })}
              {loading && (
                <span
                  className="indicator-progress"
                  style={{ display: "block" }}
                >
                  {intl.formatMessage({
                    id: "PARTNERS_GENERAL_DETAILS_SAVE_LOADING",
                  })}{" "}
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              )}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default PartnerGeneralDetails;
