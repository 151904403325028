/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../setup";
import { useDispatch } from "react-redux";
import DropDown from "../../../sharedComponents/dropdown/Dropdown";
import { useIntl } from "react-intl";
import { GroupType } from "../../masterdata/types/GetGroupsListResponseType";
import { Button, Modal } from "react-bootstrap";
import { actions } from "../redux";

// import Constants from '../../../config/Constants'
import { LabelType } from "../../masterdata/types/GetLabelsListResponseType";
import {
  IndustryGroupType,
  IndustrySectorType,
} from "../../partnersMasterData/types/getIndustryGroupsResponseType";
import { CountryType } from "../../masterdata/types/GetCountriesListResponseTypes";
import clsx from "clsx";
import { PartnersFilterType } from "../types/getPartnersListResponseType";
import CloseButton from "../../../sharedComponents/Buttons/CloseButton";

interface Props {
  show: boolean;
  getPartnersListAPI: () => void;
  closeModal: () => void;
  loading?: boolean;
}

const PartnersListFilterModal: FC<Props> = ({
  getPartnersListAPI,
  show,
  closeModal,
  loading,
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  // selectors
  const filters = useSelector<RootState>(
    ({ partners }) => partners.filters
  ) as PartnersFilterType;
  const countriesList = useSelector<RootState>(
    ({ masterdata }) => masterdata.countriesList
  ) as CountryType[];
  const labelsList = useSelector<RootState>(
    ({ masterdata }) => masterdata.labelsList
  ) as LabelType[];
  const industryGroupsList = useSelector<RootState>(
    ({ masterdata }) => masterdata.industryGroupsList
  ) as IndustryGroupType[];

  // state
  const [selectedLabels, setSelectedLabels] = useState<LabelType[]>(
    filters.labels || []
  );
  const [selectedSectors, setSelectedSectors] = useState<IndustrySectorType[]>(
    filters.sectors || []
  );
  const [selectedCountry, setSelectedCountry] = useState<CountryType>(
    filters.country
  );
  const [city, setCity] = useState<string>(filters.city || "");
  const [zipCode, setZipCode] = useState<string>(filters.zip_code || "");

  const handleOnSelectItem = (
    item: GroupType,
    type: "sector" | "group" | "category" | "tag" | "label"
  ) => {
    const selectedItemsArr =
      type === "sector" ? selectedSectors : selectedLabels;
    const index = selectedItemsArr.findIndex(
      (selectedItem) => selectedItem.id === item.id
    );

    // if doesnt exist push item
    if (index === -1) {
      type === "sector" && setSelectedSectors(() => [...selectedSectors, item]);

      type === "label" && setSelectedLabels(() => [...selectedLabels, item]);
    }
    // if already exists remove item
    else {
      type === "sector" &&
        setSelectedSectors((prevState) => {
          prevState.splice(index, 1);
          return [...prevState];
        });
      type === "label" &&
        setSelectedLabels((prevState) => {
          prevState.splice(index, 1);
          return [...prevState];
        });
    }
  };

  const flattenIndustryGroups = (industryGroups: IndustryGroupType[] = []) => {
    const sectors: any = [];
    industryGroups.forEach((industryGroup) => {
      if (industryGroup?.sectors?.length > 0) {
        sectors.push({
          id: industryGroup.id,
          name: industryGroup.name,

          isGroupLabel: true,
        });
      }
      (industryGroup.sectors || []).forEach((industryGroupSector) => {
        // sectors.push(industryGroupSector.name + '(' + industryGroup.name + ')')
        sectors.push({
          id: industryGroupSector.id,
          name: industryGroupSector.name,
        });
      });
    });
    return sectors;
  };

  const industrySectors = flattenIndustryGroups(industryGroupsList);

  return (
    <Modal
      show={show}
      centered
      dialogClassName="medium-size-modal"
      className="kt-modal"
      contentClassName={loading ? "pe-none" : ""}
      backdrop="static"
    >
      <Modal.Header>
        <Modal.Title>{intl.formatMessage({ id: "FILTER_BUTTON" })}</Modal.Title>
        <div className="d-flex justify-content-end align-items-center">
          <button
            className="btn btn-sm btn-primary me-2"
            onClick={() => {
              // dispatch(actions.setFilters({} as PartnersFilterType));
              // closeModal();
              setCity("");
              setZipCode("");
              // @ts-ignore
              setSelectedCountry(undefined);
              setSelectedLabels([]);
            }}
          >
            {intl.formatMessage({ id: "RESET_BUTTON" })}
          </button>
          <CloseButton onClose={closeModal} />
        </div>
      </Modal.Header>
      <div className="">
        <Modal.Body className="overflow-inherit">
          {/* city  & zipcode*/}
          <div className="row">
            {/* city */}
            <div className="fv-row tab-field col-lg-6">
              <label className="tab-field-label">
                {intl.formatMessage({
                  id: "FORM_VALIDATION_FIELD_LABEL_CITY",
                })}
              </label>
              <input
                placeholder={""}
                className={clsx(
                  "form-control form-control-lg form-control-solid"
                )}
                onChange={(e) => {
                  setCity(e.target.value);
                }}
                autoComplete="off"
                value={city}
              />
            </div>

            {/* zip code */}
            <div className="fv-row tab-field col-lg-6">
              <label className="tab-field-label">
                {intl.formatMessage({
                  id: "FORM_VALIDATION_FIELD_LABEL_ZIPCODE",
                })}
              </label>
              <input
                placeholder={""}
                className={clsx(
                  "form-control form-control-lg form-control-solid"
                )}
                onChange={(e) => {
                  setZipCode(e.target.value);
                }}
                autoComplete="off"
                value={zipCode}
              />
            </div>
          </div>

          {/* country */}
          <div className="tab-field">
            <label className="form-label tab-field-label">
              {intl.formatMessage({
                id: "FORM_VALIDATION_FIELD_LABEL_COUNTRY",
              })}
            </label>
            <DropDown
              id="countriesDropDown"
              items={countriesList}
              selectedItem={selectedCountry}
              // @ts-ignore
              onSelectItem={(item: CountryType) => {
                setSelectedCountry(item);
              }}
              displayValue={selectedCountry?.name || ""}
              // @ts-ignore
              displayFunc={(item: CountryType) => {
                return item.emoji + " " + item.name;
              }}
            />
          </div>

          {/* Labels */}
          <div className="tab-field mb-0">
            <label className="form-label tab-field-label">
              {intl.formatMessage({
                id: "PARTNERS_LIST_TABLE_FILTER_LABELS_FIELD",
              })}
            </label>
            <DropDown
              id="partners-filter-labels-dropdown"
              hideSearch
              multiSelect
              items={labelsList}
              displayFunc={(item) => item.name}
              onSelectItem={(item) => {
                //   @ts-ignore
                handleOnSelectItem(item, "label");
              }}
              selectedItems={selectedLabels}
            />
          </div>

          {/* sectors */}
          {/* <div className="tab-field">
            <label className="form-label tab-field-label">
              {intl.formatMessage({
                id: "PARTNERS_LIST_TABLE_FILTER_SECTORS_FIELD",
              })}
            </label>
            <DropDown
              id="partner-sectors-dropdown"
              // hideSearch
              multiSelect
              items={industrySectors}
              // @ts-ignore
              displayFunc={(item) => item.name}
              onSelectItem={(item) => {
                //   @ts-ignore
                handleOnSelectItem(item, "sector");
              }}
              selectedItems={selectedSectors}
            />
          </div> */}
        </Modal.Body>
      </div>
      <Modal.Footer className="justify-content-center">
        <Button variant="secondary" onClick={closeModal}>
          {intl.formatMessage({ id: "CLOSE_BUTTON" })}
        </Button>
        <Button
          className="btn btn-primary"
          onClick={() => {
            // if (
            //   (selectedLabels && selectedLabels.length > 0) ||
            //   (selectedSectors && selectedSectors.length > 0) ||
            //   selectedCountry ||
            //   city ||
            //   zipCode
            // ) {
            dispatch(
              actions.setFilters({
                zip_code: zipCode,
                city: city,
                country: selectedCountry,
                labels: selectedLabels,
                sectors: selectedSectors,
              })
            );
            // }
            closeModal();
          }}
        >
          <span className="indicator-label">
            {intl.formatMessage({ id: "SUBMIT_BUTTON" })}
          </span>
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default PartnersListFilterModal;
