import React, { FC, useEffect, useState } from "react";
import { DealType } from "../types/DealResponseType";
import { useIntl } from "react-intl";
import {
  FilemanagerDataType,
  FileType,
} from "../../filemanager/types/getFilemanagerDataResponseType";
import { getDealProjectFiles, getFile, getFilemanagerData } from "../../filemanager/redux";
import { useSelector } from "react-redux";
import { RootState } from "../../../../setup";
import { bytesToSize } from "../../../utils/BytesToSize";
import moment from "moment";
import VideoIcon from "../../../../_metronic/assets/icons/video.svg";
import AudioIcon from "../../../../_metronic/assets/icons/audio.svg";
import PDFIcon from "../../../../_metronic/assets/icons/pdf.svg";
import FileIcon from "../../../../_metronic/assets/icons/defaultFile.svg";
import CSVIcon from "../../../../_metronic/assets/icons/Csv.svg";
import WORDIcon from "../../../../_metronic/assets/icons/word-icon.png";
import PPTIcon from "../../../../_metronic/assets/icons/ppt.svg";
import PPTXIcon from "../../../../_metronic/assets/icons/pptx.svg";
import TXTIcon from "../../../../_metronic/assets/icons/txt-icon.png";
import XLSX from "../../../../_metronic/assets/icons/xlsx.svg";
import SimpleLoader from "../../../sharedComponents/Loader/SimpleLoader";
import ProductFilePreviewModal from "../../products/components/ProductFilePreviewModal";
// import Lightbox from 'lightbox-react';
// import 'lightbox-react/style.css';

interface Props {
  deal: DealType;
  pipeline_id: number;
  pipelineName: string;
}

const DealProjectFiles: FC<Props> = ({ deal, pipeline_id ,pipelineName}) => {
  // selector
  const intl = useIntl();
  const pipelineList = useSelector<RootState>(
    // @ts-ignore
    ({ deals }) => deals.dealPipelines
  ) as any[];

  //   state
  const [files, setFiles] = useState<FileType[]>([]);
  const [imageFiles, setImageFiles] = useState<FileType[]>([]);
  const [loading, setLoading] = useState(false);
  const [showPreviewModal, setShowPreviewModal] = useState<boolean>(false);
  const [previewFileURL, setPreviewFileURL] = useState<any>();
  const [previewFileExtension, setPreviewFileExtension] = useState<any>();
  const [previewLoading, setPreviewLoading] = useState<string | undefined>();
  const [currentImageIndex, setCurrentImageIndex] = useState<number>(0);
  const [isOpen, setIsOpen] = useState(false)

  const getAllFiles = () => {
    getDealProjectFiles(deal?.id,pipelineName)
      .then(({ data }) => {
       setFiles(data?.files)
       const filteredImages = data?.files.filter(isImageFile);
       setImageFiles(filteredImages);
      })
      .catch((e) => {})
      .finally(() => {
        setTimeout(()=>{
          setLoading(false)
        },800)
      });
  };

  const closePreviewModal = () => {
    setShowPreviewModal(false);
    // handleTaskFilePreview(false);
  };

  const isImageFile = (file: FileType) => {
    const imageExtensions = [
      "jpeg", "jpg", "png", "gif", "bmp", "tiff", "tif", "heif", "heic", "webp",
      "psd", "svg", "eps", "ai", "ico", "cr2", "cr3", "nef", "arw", "raf",
      "orf", "rw2", "pef", "exr", "indd", "jp2", "j2k",
    ];

    const fileExtension = file?.type?.toLowerCase();
    return imageExtensions.includes(fileExtension);
  };


  function getFileExtensionFromUrl(url: string) {
    const match = url?.match(/\.([^.]+)$/);
    return match ? match[1] : null;
  }


  const handlePreviewURL = (fileUrl: any) => {
    //@ts-ignore
    setPreviewLoading(fileUrl.file_path); //@ts-ignore
    const fileExtension = getFileExtensionFromUrl(fileUrl.file_path);
    setPreviewFileExtension(fileExtension);
    getFile(fileUrl.id)
      .then((res: { data: { fileDetails: { file_url: any } } }) => {
        let fileObjURL = (res && res.data.fileDetails.file_url) || undefined;
        // handleTaskFilePreview(true);
        setPreviewFileURL(fileObjURL);
        setShowPreviewModal(true)
        // if(isImageFile(fileUrl)){
        //   setIsOpen(true)
        // }else{
        //   setShowPreviewModal(true)
        // }
      })
      .catch((err: any) => {})
      .finally(() => {
        setPreviewLoading(undefined);
      });
  };

  const getIconForFileType = (file: any) => {
    switch (file?.type) {
      case "pdf":
        return PDFIcon;
      case "mp4":
      case "m4v":
        return VideoIcon;
      case "mp3":
        return AudioIcon;
      case "txt":
        return TXTIcon
      case "xlsx":
        return XLSX;
      case "doc":
      case "docx":
        return WORDIcon;
      case "csv":
        return CSVIcon;
      case "ppt":
        return PPTIcon;
      case "pptx":
        return PPTXIcon;
      case "jpeg":
      case "jpg":
      case "png":
      case "gif":
      case "bmp":
      case "tiff":
      case "tif":
      case "heif":
      case "heic":
      case "webp":
      case "psd":
      case "svg":
      case "eps":
      case "ai":
      case "ico":
      case "cr2":
      case "cr3":
      case "nef":
      case "arw":
      case "raf":
      case "orf":
      case "rw2":
      case "pef":
      case "exr":
      case "indd":
      case "jp2":
      case "j2k":
        return file?.file_path; 
      default:
        return FileIcon;  
    }
  };

  useEffect(() => {
    setLoading(true);
    getAllFiles();
  }, []);

  const handleImageClick = (index: number) => {
    setCurrentImageIndex(index);
    setIsOpen(true);
  };

  return (
    <>
    <div
      className="card p-0"
      style={{
        flex: 1,
      }}
    >
      <div className="card-header">
        <div className="card-title">
          <h2>{intl.formatMessage({ id: "DEAL_PROJECT_FILES_TAB" })}</h2>
        </div>
      </div>
      <div className="card-body">
        {loading && (
          <div>
            <SimpleLoader />
          </div>
        )}
        {!loading && files && files.length > 0 && (
          <>
            <div className="files-grid">
              {files.map((file, i) => {
                const isImage = isImageFile(file);
                return (
                  <div
                    onClick={()=>{
                      // if (isImage) {
                      //   // Getting the corresponding index in imageFiles
                      //   const imageIndex = imageFiles.findIndex(
                      //     (image) => image.file_path === file.file_path
                      //   );
              
                      //   if (imageIndex !== -1) {
                      //     handleImageClick(imageIndex); // Opening lightbox for images
                      //   }
                      // } else {
                      //   handlePreviewURL(file); // Opening preview modal for non-image files
                      // }
                      handlePreviewURL(file); 
                    }}
                    key={`file-${i}`}
                    className="d-flex flex-column justify-content-center align-items-center border rounded bg-hover-light-primary py-5 px-2" role="button"
                  >
                    <div className="d-flex align-items-center flex-column">
                      <img
                        src={
                          getIconForFileType(file)
                        }
                        alt=""
                        height={"60px"}
                        width={"60px"}
                        style={{objectFit: "contain"}}
                      />
                      <div className="d-flex flex-column align-items-center p-3 pt-0">
                        <span
                          role="button"
                          className="text-primary fw-semibold text-truncate"
                          style={{maxWidth: "150px"}}
                        >
                          {file.name}
                        </span>
                        <span className="text-gray-700">
                          {bytesToSize(file.size)}{" "}
                        </span>
                        <span className="text-gray-700">
                          {moment(file.created_at).format(
                            "MMM Do YYYY, h:mm a"
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </>
        )}
        <>
          {!loading && files && files.length === 0 && (
            <div className="text-muted fs-6 d-flex align-items-center justify-content-center">
              {intl.formatMessage({ id: "NO_DATA_FOUND" })}
            </div>
          )}
        </>
      </div>
    </div>
    {/* {isOpen && (
        <Lightbox
          mainSrc={imageFiles[currentImageIndex]?.file_path}
          nextSrc={imageFiles[(currentImageIndex + 1) % imageFiles.length]?.file_path}
          prevSrc={
            imageFiles[(currentImageIndex + imageFiles.length - 1) % imageFiles.length]
              ?.file_path
          }
          onCloseRequest={() => {
            setCurrentImageIndex(0)
            setIsOpen(false)}}
          onMovePrevRequest={() =>
            setCurrentImageIndex((currentImageIndex + imageFiles.length - 1) %imageFiles.length)
          }
          onMoveNextRequest={() =>
            setCurrentImageIndex((currentImageIndex + 1) % imageFiles.length)
          }
        />
      )} */}
    {previewFileURL && (
        <div>
          <ProductFilePreviewModal
            show={showPreviewModal}
            previewFileURL={previewFileURL}
            previewFileExtension={previewFileExtension}
            closeModal={closePreviewModal}
            showCloseButton
          />
        </div>
      )}
    </>
  );
};

export default DealProjectFiles;
