import React, { FC } from "react";
import { useIntl } from "react-intl";
import { Button, Modal } from "react-bootstrap";
import TextInput from "../../../../sharedComponents/TextInput/TextInput";
import CloseButton from "../../../../sharedComponents/Buttons/CloseButton";

interface Props {
  show: boolean;
  closeModal: () => void;
  formik: any;
}

const UpdateTaxModal: FC<Props> = ({ show, closeModal, formik }) => {
  const intl = useIntl();

  return (
    <>
      <Modal
        show={show}
        centered
        dialogClassName="large-size-modal"
        className="kt-modal"
        backdrop="static"
        onHide={closeModal}
      >
        <Modal.Header>
          <Modal.Title>
            {intl.formatMessage({
              id: "SALES_OFFER_EDIT_ADDITIONAL_INFO_MODAL_TITLE",
            })}
          </Modal.Title>
          <CloseButton onClose={closeModal} />
        </Modal.Header>
        <Modal.Body className="p-0 m-0">
          <div className="px-10 py-6">
            <div className="d-flex flex-column gap-6">
              <div className="w-100">
                <div className="mb-5">
                  <label className="form-label">
                    {intl.formatMessage({
                      id: "SALES_OFFER_CREDITOR_NUMBER_LABEL",
                    })}
                  </label>
                  <TextInput
                    value={formik.values.from_creditor_number || ""}
                    onChange={(value) => {
                      formik.setFieldValue("from_creditor_number", value);
                    }}
                  />
                </div>
                <div className="mb-5">
                  <label className="form-label">
                    {intl.formatMessage({
                      id: "SALES_OFFER_TAX_ID_LABEL",
                    })}
                  </label>
                  <TextInput
                    value={formik.values.from_tax_id || ""}
                    onChange={(value) => {
                      formik.setFieldValue("from_tax_id", value);
                    }}
                  />
                </div>
                <div className="mb-5">
                  <label className="form-label">
                    {intl.formatMessage({
                      id: "SALES_OFFER_TAX_NUMBER_LABEl",
                    })}
                  </label>
                  <TextInput
                    value={formik.values.from_tax_number || ""}
                    onChange={(value) => {
                      formik.setFieldValue("from_tax_number", value);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer className="justify-content-center p-2">
          <Button variant="secondary" onClick={closeModal}>
            {intl.formatMessage({ id: "CLOSE_BUTTON" })}
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              formik.handleSubmit();
            }}
          >
            {intl.formatMessage({ id: "UPDATE" })}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default UpdateTaxModal;
