import { FC, useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { LayoutSplashScreen } from "../../../../_metronic/layout/core";
import * as auth from "./AuthRedux";
import { getClientToken } from "./AuthCRUD";
import { RootState } from "../../../../setup";
import { getCountriesList } from "../../masterdata/redux/MasterdataAPI";
import { useDispatch } from "react-redux";
import { actions as masterdataActions } from "../../masterdata/redux";

const mapState = (state: RootState) => ({ auth: state.auth });
const connector = connect(mapState, auth.actions);
type PropsFromRedux = ConnectedProps<typeof connector>;

const AuthInit: FC<PropsFromRedux> = (props) => {
  // const SSettings = useSelector<RootState>(
  //   ({masterdata}) => masterdata.systemSettingsList
  // ) as SystemSettings
  const dispatch = useDispatch();
  const [showSplashScreen, setShowSplashScreen] = useState(true);
  const getClientTokenAPI = () => {
    getClientToken()
      .then(({ data: { clientDetails } }) => {
        // dispatch(auth.actions.setClientDetails(clientDetails))
        sessionStorage.setItem("clientDetails_id", clientDetails.id.toString());
        sessionStorage.setItem("clientDetails_secret", clientDetails.secret);
      })
      .catch((e) => {
        console.error("ERROR: getClientToken", e);
      })
      .finally(() => {
        setShowSplashScreen(false);
      });
  };
  // We should request user by authToken before rendering the application
  useEffect(() => {
    // get client tokens API Call

    getClientTokenAPI();
    getCountriesList().then(({ data: { countries } }) => {
      dispatch(masterdataActions.setCountriesList(countries));
    });
    // eslint-disable-next-line
  }, []);

  return showSplashScreen ? <LayoutSplashScreen /> : <>{props.children}</>;
};

export default connector(AuthInit);
