import React, { FC, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";

// components
import {
  errorToast,
  successToast,
} from "../../../sharedComponents/toasts/Toasts";
import DropDown from "../../../sharedComponents/dropdown/Dropdown";
import { RootState } from "../../../../setup";
import { enablePermissionForCrud } from "../../../utils/PermisisionEnabledForResource";

// Types
import { RoleType } from "../../RolesAndPermissions/types/getRolesResponseType";

// apis
import {
  updateDisplayInMobile,
  addPhaseRoles,
} from "../redux/PipelinesAPI";

interface Props {
  activePhase: any;
  getPipelineDetailsById: () => void;
}

const PipelinePhaseRoles: FC<Props> = ({
  activePhase,
  getPipelineDetailsById,
}) => {
  const intl = useIntl();

  const userRoleDetails = useSelector<RootState>(
    // @ts-ignore
    (state) => state.auth.roleDetails
  ) as RoleType;
  //@ts-ignore
  const crudPermission: any = enablePermissionForCrud(
    userRoleDetails,
    "pipelines"
  );
  const rolesList = useSelector<RootState>(
    //   @ts-ignore
    ({ rolesAndPermissionsManagement }) => rolesAndPermissionsManagement?.roles
  ) as RoleType[];

  const [roleLoading, setRoleLoading] = useState(false);
  const [selectedRole, setSelectedRole] = useState<RoleType[]>(
    activePhase?.roles || []
  );

  const handleOnBlur = () => {
    const ids = selectedRole.map((selectedItem) => {
      return selectedItem?.id;
    });
    if (activePhase?.id) {
      setRoleLoading(true);
      addPhaseRoles(activePhase?.id, ids || [])
        .then(() => {
          successToast(
            intl.formatMessage({
              id: "PROJECTS_PIPELINE_ROLE_UPDATE_SUCCES_MESSAGE",
            })
          );
        })
        .catch(() => {
          errorToast(
            intl.formatMessage({
              id: "PROJECTS_PIPELINE_ROLE_UPDATE_FAILURE_MESSAGE",
            })
          );
        })
        .finally(() => {
          getPipelineDetailsById?.();
          setRoleLoading(false);
        });
    }
  };

  const handleIsDisplayMobile = (isMobile: number) => {
    updateDisplayInMobile(activePhase?.id, isMobile)
      .then(() => {
        successToast(
          intl.formatMessage({
            id: "PROJECTS_PIPELINE_PHASE_SUCCESS_MESSAGE",
          })
        );
      })
      .catch(() => {
        errorToast(
          intl.formatMessage({
            id: "PROJECTS_PIPELINE_PHASE_FAILURE_MESSAGE",
          })
        );
      })
      .finally(() => {
        getPipelineDetailsById?.();
      });
  };


  const handleOnSelectItem = (item: RoleType) => {
    const selectedItemsArr = selectedRole || [];
    const index = selectedItemsArr.findIndex(
      (selectedItem) => selectedItem?.id === item?.id
    );
    // if doesnt exist push item
    if (index === -1) {
      setSelectedRole(() => [...selectedRole, item]);
    }
    // if already exists remove item
    else {
      setSelectedRole((prevState) => {
        prevState.splice(index, 1);
        return [...prevState];
      });
    }
  };

  useEffect(()=>{
    setSelectedRole(activePhase?.roles)
  },[activePhase])

  return (
    <div className="card">
      <div className="card-header card-px">
        <div className="card-title">
          <h2>{intl.formatMessage({ id: "PIPELINE_PHASE_ROLES_CARD_TITLE" })}</h2>
        </div>
      </div>
      <div className={`card-body card-p ${!crudPermission?.edit && "pe-none"}`}>
        {/* questionirre */}
        {/* Roles dropdown */}
        <div className="position-relative tab-field">
          <label className="tab-field-label d-flex align-items-center flex-wrap">
            {intl.formatMessage({ id: "ADD_ROLE_MODAL_FIELD_NAME" })}
          </label>
          <div className="d-flex ">
            <div
              style={{
                flex: 1,
              }}
              className="position-relative"
            >
              <DropDown
                id="phaseRoleTypeDropdown"
                hideSearch
                multiSelect
                items={rolesList.filter((role) => role.id !== 1) || []}
                selectedItems={selectedRole}
                onSelectItem={(item) => {
                  //   @ts-ignore
                  crudPermission?.edit && handleOnSelectItem(item);
                }}
                displayFunc={(item) => {
                  return item.name;
                }}
                disabled={!crudPermission?.edit}
                onMultiSelectBlur={() => {
                  crudPermission?.edit && handleOnBlur();
                }}
              />
            </div>

            {/* Save Button */}
            {/* {crudPermission?.edit && (
              <div>
                <button
                  className="btn  btn-light btn-active-light-primary btn-lg  ms-2"
                  onClick={() => {
                    handleOnBlur();
                  }}
                >
                  {!roleLoading && intl.formatMessage({ id: "SAVE_BUTTON" })}
                  {roleLoading && (
                    <span
                      className="indicator-progress"
                      style={{ display: "block" }}
                    >
                      {intl.formatMessage({
                        id: "MASTERDATA_TAGS_LOADING_MESSAGE",
                      })}{" "}
                      <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                    </span>
                  )}
                </button>
              </div>
            )} */}
          </div>
        </div>
        {/* selected roles message */}
        <div className="firstPhaseTab">
          <p className="text-info">
            {selectedRole.length > 0
              ? `${intl.formatMessage({
                  id: "EDIT_PIPELINE_ROLE_INFO_MESSAGE1",
                })} ${selectedRole
                  .map((role) => role.name)
                  .join(", ")} ${intl.formatMessage({
                  id: "EDIT_PIPELINE_USERS_INFO",
                })}`
              : intl.formatMessage({
                  id: "EDIT_PIPELINE_ROLE_INFO_MESSAGE2",
                })}
          </p>
        </div>

        <div className="d-flex flex-column">
          <div className="d-flex flex-stack">
            <div className="fw-bold">
              {intl.formatMessage({
                id: "PIPELINE_PROJECTS_QUESTION2",
              })}
            </div>
            <div className="form-check form-switch form-switch-sm form-check-custom form-check-solid ms-4">
              <input
                className="form-check-input"
                type="checkbox"
                name="notifications"
                value=""
                checked={activePhase?.display_in_mobile === 1 ? true : false}
                style={{ width: "45px", height: "22px" }}
                onChange={(e) => {
                  handleIsDisplayMobile(e.target.checked ? 1 : 0);
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PipelinePhaseRoles;
