import { useFormik } from "formik";
import React, { FC, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import * as Yup from "yup";
import { Button, Modal } from "react-bootstrap";
import DropDown from "../../../sharedComponents/dropdown/Dropdown";
import { SystemMessageType } from "../../masterdata/types/SystemMessagesResponseType";
import { getSystemMessages } from "../../masterdata/redux/SystemMessagesAPI";
import { ApprovalModalProps } from "../types/DealPropsType";
import CloseButton from "../../../sharedComponents/Buttons/CloseButton";

interface Props {
  modalProps: ApprovalModalProps;
  closeModal: () => void;
  onSubmit: (is_approved: number, notes: string) => void;
}

const AcceptDealModal: FC<Props> = ({ modalProps, onSubmit, closeModal }) => {
  // selector
  const intl = useIntl();
  const DEALS_ENDPHASE_OTHER_REASON = JSON.parse(
    intl.formatMessage({ id: "DEALS_ENDPHASE_OTHER_REASON" })
  ) as { id: number; name: string; title: string }[];

  //   state
  const [approveReasons, setApproveReasons] = useState<SystemMessageType[]>([]);
  const [rejectReasons, setRejectReasons] = useState<SystemMessageType[]>([]);

  const initialValues = {
    status: "",
    message: "",
    otherMessage: "",
  };

  const acceptApprovalSchema = Yup.object().shape({
    status: Yup.string().required(
      intl.formatMessage({
        id: "PHASE_APPROVALS_STATUS_REQUIRED_MESSAGE",
      })
    ),
    message: Yup.string().required(
      intl.formatMessage({
        id: "MASTERDATA_SYSTEM_MESSAGE_MESSAGE_REQUIRED_MESSAGE",
      })
    ),
    otherMessage: Yup.string()
      .ensure()
      .when("message", {
        is: intl.formatMessage({
          id: "DROPDOWN_OTHERS_MESSAGE",
        }),
        then: Yup.string().required(
          intl.formatMessage({
            id: "MASTERDATA_SYSTEM_MESSAGE_MESSAGE_REQUIRED_MESSAGE",
          })
        ),
      }),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: acceptApprovalSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      const isApproved = values.status === "phase_approvals" ? 1 : 0;
      const messageToSubmit =
        values.message !==
        intl.formatMessage({
          id: "DROPDOWN_OTHERS_MESSAGE",
        })
          ? values.message
          : values.otherMessage;
      onSubmit(isApproved, messageToSubmit);
    },
  });

  useEffect(() => {
    getSystemMessages()
      .then(({ data: { data } }) => {
        const res = data;
        if (res && res.length > 0) {
          let obj: SystemMessageType = {
            id: res.length + 1,
            workflow: "",
            message: intl.formatMessage({
              id: "DROPDOWN_OTHERS_MESSAGE",
            }),
            display_order: 0,
            created_at: "",
            updated_at: "",
          };
          setApproveReasons([
            ...res?.filter((item) => item.workflow === "phase_approvals"),
            obj,
          ]);
          setRejectReasons([
            ...res?.filter((item) => item.workflow === "phase_rejections"),
            obj,
          ]);

          // Setting default initial values on Component mount
          formik.setFieldValue("status", "phase_approvals");
          formik.setFieldValue(
            "message",
            res?.find(
              (item) =>
                item.workflow === "phase_approvals" && item.is_default === 1
            )?.message
          );
        }
      })
      .catch(() => {});
  }, []);

  return (
    <Modal
      show={modalProps.show}
      centered
      dialogClassName="medium-size-modal"
      className="kt-modal"
      backdrop="static"
      onHide={closeModal}
    >
      <Modal.Header>
        <Modal.Title>
          {intl.formatMessage({ id: "ACCEPT_APPROVAL_MODAL_TITLE" })}
        </Modal.Title>
        <CloseButton onClose={closeModal} />
      </Modal.Header>

      <Modal.Body className="overflow-inherit">
        <div>
          {formik.status && (
            <div className="mb-10 alert alert-danger">
              <div className="alert-text font-weight-bold">{formik.status}</div>
            </div>
          )}
          {!modalProps.is_primary && (
            <div className="alert alert-warning fs-5 mb-8" role="alert">
              {intl.formatMessage({
                id: "ACCEPT_APPROVAL_SECONDARY_APPROVER_MESSAGE",
              })}
            </div>
          )}
          {/* Approve or Reject Radio button */}
          <div className="fv-row tab-field">
            <label className="d-flex align-items-center tab-field-label">
              <span className="required">
                {intl.formatMessage({
                  id: "PHASE_APPROVALS_MODAL_FIELD_STATUS",
                })}
              </span>
            </label>
            <div className="d-flex">
              {DEALS_ENDPHASE_OTHER_REASON?.slice(1, 3)?.map(
                (option: { name: string; title: string }, index) => (
                  <div className="form-check me-4" key={index}>
                    <input
                      className="form-check-input"
                      {...formik.getFieldProps("status")}
                      type="radio"
                      id={option.name}
                      checked={
                        formik.values.status === option.name ? true : false
                      }
                      onChange={() => {
                        formik.setFieldValue("status", option.name);
                        formik.setFieldValue(
                          "message",
                          [...approveReasons, ...rejectReasons].find((item) => {
                            return (
                              option.name === item.workflow &&
                              item?.is_default === 1
                            );
                          })?.message || ""
                        );
                        formik.setFieldValue("otherMessage", "");
                      }}
                    />
                    <label className="form-check-label" htmlFor={option.name}>
                      {option.name === "phase_approvals"
                        ? intl.formatMessage({ id: "COMMON_APPROVE" })
                        : option.name === "phase_rejections" &&
                          intl.formatMessage({ id: "COMMON_REJECT" })}
                    </label>
                  </div>
                )
              )}
            </div>
            {formik.touched.status && formik.errors.status && (
              <div className="text-danger mt-2">
                <span role="alert">{formik.errors.status}</span>
              </div>
            )}
          </div>

          {/* Phase Approvals */}
          {formik.values.status === "phase_approvals" && (
            <div className="fv-row tab-field">
              <label className="d-flex align-items-center tab-field-label">
                <span className="required">
                  {intl.formatMessage({
                    id: "PHASE_APPROVALS_MODAL_FIELD_APPROVE",
                  })}
                </span>
              </label>
              <DropDown
                hideSearch
                id="approval-messages"
                //   @ts-ignore
                items={approveReasons}
                displayFunc={(item) => {
                  // @ts-ignore
                  return item.message;
                }}
                displayValue={
                  approveReasons.find(
                    (TITLES_OPTION) =>
                      TITLES_OPTION.message === formik.values.message
                  )?.message || ""
                }
                onSelectItem={(item) => {
                  //@ts-ignore
                  formik.setFieldValue("message", item.message);
                }} //@ts-ignore
                selectedItem={
                  approveReasons.find(
                    (TITLES_OPTION) =>
                      TITLES_OPTION.message === formik.values.message
                  )?.message || ""
                }
              />
              {formik.touched.message && formik.errors.message && (
                <div className="text-danger">
                  <span role="alert">{formik.errors.message}</span>
                </div>
              )}
            </div>
          )}

          {/* Phase Rejections */}
          {formik.values.status === "phase_rejections" && (
            <div className="fv-row tab-field">
              <label className="d-flex align-items-center tab-field-label">
                <span className="required">
                  {intl.formatMessage({
                    id: "PHASE_APPROVALS_MODAL_FIELD_REJECT",
                  })}
                </span>
              </label>
              <DropDown
                hideSearch
                id="rejection-messages"
                //   @ts-ignore
                items={rejectReasons}
                displayFunc={(item) => {
                  // @ts-ignore
                  return item.message;
                }}
                displayValue={
                  rejectReasons.find(
                    (TITLES_OPTION) =>
                      TITLES_OPTION.message === formik.values.message
                  )?.message || ""
                }
                onSelectItem={(item) => {
                  //@ts-ignore
                  formik.setFieldValue("message", item.message);
                }}
                //@ts-ignore
                selectedItem={rejectReasons.find(
                  (TITLES_OPTION) =>
                    TITLES_OPTION.message === formik.values.message
                )}
              />
              {formik.touched.message && formik.errors.message && (
                <div className="text-danger">
                  <span role="alert">{formik.errors.message}</span>
                </div>
              )}
            </div>
          )}

          {/* Other Message */}
          {formik.values.message ===
            intl.formatMessage({
              id: "DROPDOWN_OTHERS_MESSAGE",
            }) && (
            <div className="fv-row mt-4 tab-field mb-0">
              <label className="d-flex align-items-center tab-field-label">
                <span className="required">
                  {intl.formatMessage({
                    id: "MASTERDATA_SYSTEM_MESSAGE_ADD_MESSAGE_TITLE",
                  })}
                </span>
              </label>
              <textarea
                rows={3}
                className="form-control form-control-lg form-control-solid"
                {...formik.getFieldProps("otherMessage")}
                style={{ resize: "none" }}
              ></textarea>
              {formik.touched.otherMessage && formik.errors.otherMessage && (
                <div className="text-danger mt-2">
                  <span role="alert">{formik.errors.otherMessage}</span>
                </div>
              )}
            </div>
          )}
        </div>
      </Modal.Body>

      <Modal.Footer className="justify-content-center">
        <Button variant="secondary" onClick={closeModal}>
          {intl.formatMessage({ id: "CLOSE_BUTTON" })}
        </Button>
        <Button variant="primary" onClick={() => formik.handleSubmit()}>
          {intl.formatMessage({ id: "SUBMIT_BUTTON" })}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AcceptDealModal;
