import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
// @ts-ignore
import DragSortableList from "react-drag-sortable";
import _ from "lodash";
import { FC } from "preact/compat";
import { SystemMessageType } from "../types/SystemMessagesResponseType";
import {
  deleteMultipleSystemMessages,
  deleteSystemMessage,
  getSystemMessages,
  sortSystemMessages,
} from "../redux/SystemMessagesAPI";
import { SVGICON } from "../../../../_metronic/helpers";

// images
import PlusIcon from "../../../../_metronic/assets/icons/plus.svg";
import SearchIcon from "../../../../_metronic/assets/icons/search.svg";
import { useGlobalModalContext } from "../../../sharedComponents/modals/ModalContext";
import Constants from "../../../config/Constants";
import Pagination from "../../../sharedComponents/pagination/Pagination";
import SimpleLoader from "../../../sharedComponents/Loader/SimpleLoader";

import MessagesAddModal from "./SystemMessagesAddModal";
import MessageCard from "./SystemMessagesCard";
import { MessagesModalProps } from "../types/SystemMessagesPropsTypes";
import Tabs from "../../../sharedComponents/tabs/Tabs";

import { SortedObject } from "../types/SystemMessagesPropsTypes";
import { sortGroupsParams } from "../types/request";
import { RootState } from "../../../../setup";
import { useSelector } from "react-redux";
import { RoleType } from "../../RolesAndPermissions/types/getRolesResponseType";
import { enablePermissionForCrud } from "../../../utils/PermisisionEnabledForResource";
import ScrollToTop from "../../../sharedComponents/ScrollToTop/ScrollToTop";
import NoItemsFound from "../../../sharedComponents/NoItemsFound/NoItemsFound";
import {
  errorToast,
  successToast,
} from "../../../sharedComponents/toasts/Toasts";

const MessageLoader: FC = () => {
  return (
    <table className="d-flex justify-content-center align-items-center mt-2">
      <tbody>
        <tr>
          <td valign="top" colSpan={7} className="dataTables_empty">
            <div className="d-flex flex-column flex-center">
              <SimpleLoader />
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

const EmptyTab: FC = () => {
  return (
    <table className="d-flex justify-content-center align-items-center mt-2">
      <tbody>
        <NoItemsFound languageKey="NO_ITEMS_FOUND" />
      </tbody>
    </table>
  );
};

const SystemMessages = () => {
  // selector
  const intl = useIntl();
  const MASTERDATA_SYSTEM_MESSAGES_OPTIONS = JSON.parse(
    intl.formatMessage({ id: "MASTERDATA_SYSTEM_MESSAGES_OPTIONS" })
  ) as { id: number; name: string; title: string }[];

  // state
  const [messages, setMessages] = useState<SystemMessageType[]>([]);
  const [displayMessagesList, setDisplayMessagesList] = useState<
    SystemMessageType[]
  >([]);
  const [loading, setLoading] = useState(false);
  const [checkedMessages, setCheckedMessages] = useState<SystemMessageType[]>(
    []
  );
  const [searchText, setSearchText] = useState<string>("");
  const [checkAllMessages, setCheckAllMessages] = useState<boolean>(false);
  const [itemsPerPage, setItemsPerPage] = useState(
    Constants.defaultItemsPerPageCount
  );
  const [activePage, setActivePage] = useState<number>(1);
  const [openAddModal, setOpenAddModal] = useState<MessagesModalProps>({
    show: false,
  });
  const [activeTabIndex, setActiveTabIndex] = useState<number>(0);

  const userRoleDetails = useSelector<RootState>(
    // @ts-ignore
    (state) => state.auth.roleDetails
  ) as RoleType;
  //@ts-ignore
  const crudPermission: any = enablePermissionForCrud(
    userRoleDetails,
    "system-messages"
  );

  // context
  const {
    showDeleteConfirmModal,
    setDeleteModalLoading,
    hideDeleteConfirmModal,
  } = useGlobalModalContext();

  const getAllMessagesAPI = () => {
    getSystemMessages()
      .then(({ data: { data } }) => {
        setMessages(data);
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false);
        setCheckAllMessages(false)
      });
  };

  useEffect(() => {
    setLoading(true);
    getAllMessagesAPI();
  }, []);

  const onAllMessageCheckChange = () => {
    const updatedCheckAllFields = !checkAllMessages;
    setCheckAllMessages(updatedCheckAllFields);
    if (updatedCheckAllFields) {
      setCheckedMessages(displayMessagesList);
    } else {
      setCheckedMessages([]);
    }
  };

  const onSearchTextChange = (text: string) => {
    setSearchText((text || "").trim());
  };

  const onPageClick = (page: number) => {
    setActivePage(page);
  };

  const updateDisplayMessagesList = () => {
    const option = MASTERDATA_SYSTEM_MESSAGES_OPTIONS.filter(
      (data) => data.id === activeTabIndex + 1
    )[0].name;
    const updatedMessagesList = messages
      ?.filter((message) => message.workflow === option)
      ?.filter((message) => {
        if (
          (message.message || "")
            .toLocaleLowerCase()
            .includes((searchText || "").toLocaleLowerCase())
        ) {
          return true;
        }
        return false;
      });
    // save updatedlist to display
    setDisplayMessagesList(updatedMessagesList);
  };

  useEffect(() => {
    updateDisplayMessagesList();

    return () => {
      setDisplayMessagesList(messages);
    };
  }, [searchText, messages, activeTabIndex]);

  const onMessageCheckChange = (message: SystemMessageType) => {
    let checkedMessagesCopy = _.clone(checkedMessages);
    // check if already exists in the checked list
    const index = checkedMessagesCopy.findIndex(
      (checkedMessage) => checkedMessage.id === message.id
    );
    // if exists remove
    if (index > -1) {
      checkedMessagesCopy.splice(index, 1);
      setCheckAllMessages(false)
    } else {
      checkedMessagesCopy.push(message);
    }
    setCheckedMessages(checkedMessagesCopy);
  };

  const isFieldChecked = (message: SystemMessageType) => {
    const index = checkedMessages.findIndex(
      (checkedMessage) => checkedMessage.id === message.id
    );
    if (index > -1) {
      return true;
    }
    return false;
  };

  const deleteMessage = (message: SystemMessageType) => {
    setDeleteModalLoading(true);
    deleteSystemMessage(message.id)
      .then((data) => {
        successToast(
          intl.formatMessage({
            id: "MASTERDATA_SYSTEM_MESSAGE_DELETE_SUCCESS_MESSAGE",
          })
        );
      })
      .catch((err) => {
        errorToast(
          intl.formatMessage({
            id: "MASTERDATA_SYSTEM_MESSAGE_DELETE_FAILURE_MESSAGE",
          })
        );
      })
      .finally(() => {
        setDeleteModalLoading(false);
        hideDeleteConfirmModal();
        setCheckAllMessages(false);
        setCheckedMessages([]);
        // recall  get categories list API
        getAllMessagesAPI();
      });
  };

  const multipleDeleteMessage = () => {
    setDeleteModalLoading(true);
    const ids = checkedMessages.map((checkedField) => {
      return checkedField.id;
    });
    deleteMultipleSystemMessages(ids)
      .then(() => {
        successToast(
          intl.formatMessage({
            id: "MASTERDATA_SYSTEM_MESSAGE_DELETE_SUCCESS_MESSAGE",
          })
        );
        return;
      })
      .catch(() => {
        errorToast(
          intl.formatMessage({
            id: "MASTERDATA_SYSTEM_MESSAGE_DELETE_FAILURE_MESSAGE",
          })
        );
      })
      .finally(() => {
        setDeleteModalLoading(false);
        hideDeleteConfirmModal();
        getAllMessagesAPI();
        setCheckedMessages([]);
      });
  };

  const onTabChange = (index: number) => {
    if (index !== activeTabIndex) {
      setActiveTabIndex(index);
      setActivePage(1);
    }
  };

  const SystemMessageItem = (message: SystemMessageType, index: number) => {
    return (
      <MessageCard
        key={index}
        message={message}
        onChange={() => {
          onMessageCheckChange(message);
        }}
        editOnClick={() => {
          setOpenAddModal({
            show: true,
            type: "edit",
            message,
          });
        }}
        checked={isFieldChecked(message)}
        deleteOnClick={() => {
          showDeleteConfirmModal(
            intl.formatMessage({
              id: "MASTERDATA_SYSTEM_MESSAGE_DELETE_CONFIRM_MESSAGE",
            }),
            () => {
              deleteMessage(message);
            }
          );
        }}
      />
    );
  };

  const onSystemMessagesSortChange = (sortedList: Array<SortedObject>) => {
    const newSortedSystemMessagesArr: sortGroupsParams = [];
    sortedList.forEach((sortedListItem, i) => {
      newSortedSystemMessagesArr.push({
        id: sortedListItem.systemMessage.id,
        newposition: startIndex + i + 1,
      });
    });
    // sort existing systemMessages API Call
    sortSystemMessages(newSortedSystemMessagesArr)
      .then(() => {})
      .catch(() => {})
      .finally(() => {
        getAllMessagesAPI();
      });
  };

  // get paginated records
  const startIndex = itemsPerPage * (activePage - 1);
  const paginatedMessages =
    displayMessagesList && displayMessagesList.length > 10
      ? displayMessagesList.slice(startIndex, startIndex + itemsPerPage)
      : displayMessagesList;

  useEffect(() => {
    if (paginatedMessages.length === 0 && displayMessagesList.length > 0) {
      setActivePage((prev) => prev - 1);
    }
  }, [paginatedMessages]);

  useEffect(() => {
    ScrollToTop();
  }, [activePage]);

  // draggable systemMessages list
  const list = paginatedMessages
    ? paginatedMessages.map((message, i) => {
        return {
          content: (
            <div
              className={searchText || !crudPermission?.edit ? "no-drag" : ""}
            >
              {SystemMessageItem(message, i)}
            </div>
          ),
          systemMessage: message,
        };
      })
    : [];

  return (
    <>
      {openAddModal.show && (
        <MessagesAddModal
          modalProps={openAddModal}
          closeModal={() => {
            setOpenAddModal({
              show: false,
              type: "new",
              message: undefined,
            });
          }}
          activeTab={
            MASTERDATA_SYSTEM_MESSAGES_OPTIONS.filter(
              (data) => data.id === activeTabIndex + 1
            )[0].name
          }
          getAllMessagesAPI={getAllMessagesAPI}
        />
      )}
      {/* Search System Messages */}
      <div className="card card-search d-flex flex-row justify-content-between">
        <div className="d-flex flex-row search-container">
          {crudPermission?.delete && (
            <div className="form-check form-check-sm form-check-custom form-check-solid ">
              <input
                onChange={onAllMessageCheckChange}
                className="form-check-input widget-9-check"
                type="checkbox"
                checked={checkAllMessages}
              />
            </div>
          )}
          <div className="d-flex align-items-center position-relative ms-6">
            <span className="position-absolute" style={{ left: "10px" }}>
              <SVGICON className="svg-icon svg-icon-1" src={SearchIcon} />
            </span>
            <input
              type="text"
              className="form-control form-control-md form-control-solid min-w-150px min-w-md-200px ps-11"
              placeholder={intl.formatMessage({
                id: "MASTERDATA_CATEGORY_SEARCH_PLACEHOLDER",
              })}
              onChange={(e) => {
                onSearchTextChange(e.target.value);
              }}
            />
          </div>
        </div>

        {/* add new category button */}
        <div className="d-flex align-items-center">
          {checkedMessages.length > 0 ? (
            <>
              <span className="text-dark text-hover-primary fs-6 fw-bolder mx-4">
                {checkedMessages.length}{" "}
                {intl.formatMessage({ id: "SELECTED" })}
              </span>
              {
                <button
                  className="btn btn-danger d-flex align-items-center"
                  onClick={() => {
                    showDeleteConfirmModal(
                      intl.formatMessage({
                        id: "MASTERDATA_SYSTEM_MESSAGE_DELETE_CONFIRM_MESSAGE",
                      }),
                      () => {
                        multipleDeleteMessage();
                      }
                    );
                  }}
                >
                  <i className={"bi bi-trash3-fill fs-6"}></i>
                  {intl.formatMessage({ id: "DELETE_BUTTON" })}
                </button>
              }
            </>
          ) : (
            <>
              {crudPermission?.create && (
                <button
                  className="btn  btn-primary"
                  onClick={() => {
                    setOpenAddModal({
                      show: true,
                      type: "new",
                    });
                  }}
                >
                  <SVGICON src={PlusIcon} className="svg-icon-2" />
                  {intl.formatMessage({
                    id: "SYSTEM_MESSAGES_ADD_NEW_BUTTON",
                  })}
                </button>
              )}
            </>
          )}
        </div>
      </div>

      <div className="card card-px list-item pb-0">
        <Tabs
          tabs={[
            MASTERDATA_SYSTEM_MESSAGES_OPTIONS.filter(
              (data) => data.name === "end_phase"
            )[0].title,
            MASTERDATA_SYSTEM_MESSAGES_OPTIONS.filter(
              (data) => data.name === "phase_approvals"
            )[0].title,
            MASTERDATA_SYSTEM_MESSAGES_OPTIONS.filter(
              (data) => data.name === "phase_rejections"
            )[0].title,
          ]}
          activeIndex={activeTabIndex}
          onActiveIndexChange={(index) => {
            onTabChange(index);
          }}
        />
      </div>

      <DragSortableList
        items={list}
        // @ts-ignore
        // onSort={(sortedList, dropEvent) => {
        //   onSystemMessagesSortChange(sortedList);
        // }}
        // type="vertical"
      />

      {loading && paginatedMessages?.length === 0 && <MessageLoader />}
      {!loading && paginatedMessages?.length === 0 && <EmptyTab />}

      {/* Pagination */}
      {displayMessagesList?.length > 0 && (
        <div className="card card-my list-item-pagination">
          <Pagination
            totalPages={Math.ceil(displayMessagesList.length / itemsPerPage)}
            activePage={
              Math.ceil(displayMessagesList.length / itemsPerPage) === 1
                ? 1
                : activePage
            }
            onPageClick={onPageClick}
            noCard
            itemsPerPage={itemsPerPage}
            showItemsPerPage
            setItemsPerPage={(count) => {
              setItemsPerPage(count);
              setActivePage(1);
            }}
          />
        </div>
      )}
    </>
  );
};

export default SystemMessages;
