// import moment from 'moment'
import React, { FC, useEffect, useState } from "react";
import { useIntl } from "react-intl";

// images
import BlankAvatar from "../../../../_metronic/assets/icons/blankAvatar.svg";
// import Constants from "../../../config/Constants";
import { deleteConfirmAlert } from "../../../sharedComponents/Alert";
// import SelectFileModal from "../../../sharedComponents/selectFileModal/selectFileModal";
import {
  errorToast,
  successToast,
} from "../../../sharedComponents/toasts/Toasts";
import { PartnerType } from "../types/getPartnersListResponseType";
// import ImageEditor from "../../../sharedComponents/imageEditor/ImageEditor";
import { deletePartnerLogo, updatePartnerLogo } from "../redux";
import { enablePermissionForCrud } from "../../../utils/PermisisionEnabledForResource";
import { RootState } from "../../../../setup";
import { useSelector } from "react-redux";
import { SVGICON } from "../../../../_metronic/helpers";
import CloseIcon from "../../../../_metronic/assets/icons/close.svg";
import { RoleType } from "../../RolesAndPermissions/types/getRolesResponseType";
// import {FileType} from '../../filemanager/types/getFilemanagerDataResponseType'

// custom

interface Props {
  partner: PartnerType;
  getPartnerDetailsAPI: () => void;
}

interface ImageEditorModalProps {
  show: boolean;
  imageUrl: string;
  id: number;
}

const PartnerLogoSquare: FC<Props> = ({ partner, getPartnerDetailsAPI }) => {
  const intl = useIntl();

  // state

  // const [showFileSelectModal, setShowFileSelectModal] =
  //   useState<boolean>(false);
  // const [showImageEditorModal, setShowImageEditorModal] =
  //   useState<ImageEditorModalProps>({
  //     show: false,
  //     imageUrl: "",
  //     id: 0,
  //   });
  const userRoleDetails = useSelector<RootState>(
    // @ts-ignore
    (state) => state.auth.roleDetails
  ) as RoleType;

  const crudPermission: any = enablePermissionForCrud(
    userRoleDetails,
    "partners"
  );
  const [loading, setLoading] = useState(false);
  const [porfilePhotoUrl, setProfilePhotoUrl] = useState<any>();

  // const onFileSelect = (file: any) => {
  //   setShowFileSelectModal(false);
  //   setShowImageEditorModal({
  //     show: true,
  //     imageUrl: file.file_path,
  //     id: file.id,
  //   });
  // };

  useEffect(() => {
    setProfilePhotoUrl(partner?.logo_url);
  }, [partner]);

  const removePartnerLogo = () => {
    deletePartnerLogo(partner.id)
      .then(() => {
        successToast(
          intl.formatMessage({
            id: "MASTERDATA_PARTNERS_LOGO_DELETE_SUCCESS",
          })
        );
      })
      .catch(() => {
        errorToast(
          intl.formatMessage({
            id: "MASTERDATA_PARTNERS_LOGO_DELETE_FAILURE",
          })
        );
      })
      .finally(() => {
        getPartnerDetailsAPI();
      });
  };

  // const closeImageEditorModal = () => {
  //   setShowImageEditorModal({
  //     show: false,
  //     imageUrl: "",
  //     id: 0,
  //   });
  // };

  const onImageSubmit = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLoading(true);
    const { files } = event.target;
    // console.log(files);
    const selectedFiles = files as FileList;
    setProfilePhotoUrl(URL.createObjectURL(selectedFiles?.[0]));
    const formData = new FormData();
    formData.append("file", selectedFiles?.[0]);
    formData.append("type", "logo_square");
    formData.append("store_type", "Partners-logo-square");
    updatePartnerLogo(partner.id, formData)
      .then(() => {
        successToast(
          intl.formatMessage({
            id: "MASTERDATA_SALES_LAYOUT_LOGO_UPDATE_SUCCESS",
          })
        );
      })
      .catch(() => {
        errorToast(
          intl.formatMessage({
            id: "MASTERDATA_SALES_LAYOUT_LOGO_UPDATE_FAILURE",
          })
        );
      })
      .finally(() => {
        setLoading(false);
        getPartnerDetailsAPI();
        event.target.value = "";
      });
  };

  return (
    <div className={`card ${!crudPermission?.edit && "pe-none"}`}>
      {/* {showFileSelectModal && (
        <SelectFileModal
          show={showFileSelectModal}
          closeModal={() => {
            setShowFileSelectModal(false);
          }}
          onSelectFile={onFileSelect}
          allowedMimetypes={Constants.mimeTypes.image}
          isMultiple={false}
          isSelectionModal={true}
        />
      )} */}

      {/* {showImageEditorModal.show && (
        <ImageEditor
          show={showImageEditorModal.show}
          closeModal={closeImageEditorModal}
          imageUrl={showImageEditorModal.imageUrl}
          imageId={showImageEditorModal.id}
          type={"square"}
          // finalAPI={getLogosAPI}
          onSubmit={onImageSubmit}
          loading={loading}
        />
      )} */}

      <div className="card-header card-px">
        <div className="card-title">
          <h3 className="fw-bolder m-0">
            {intl.formatMessage({ id: "PARTNERS_LOGO_SQUARE_TITLE" })}
          </h3>
        </div>
      </div>

      <div className="card-body card-p">
        <div className="d-flex justify-content-center mb-3">
          <div className="image-input image-input-outline">
            <img
              // src={mainFile?.file.file_path || event.event_image_url || BlankAvatar}
              src={porfilePhotoUrl ? porfilePhotoUrl : BlankAvatar}
              alt="img"
              style={{
                objectFit: "contain",
              }}
              className="image-input-wrapper w-100px h-100px"
              draggable={false}
            />

            {crudPermission?.edit && (
              <label
                className="position-absolute btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow-sm"
                style={{
                  right: -10,
                  bottom: -10,
                  zIndex: 99,
                  borderRadius: "50px",
                  height: "25px",
                  width: "25px",
                }}
                data-kt-image-input-action="change"
                data-bs-toggle="tooltip"
                title=""
                data-bs-original-title="Change Logo"
                htmlFor="file"
              >
                <input
                  type="file"
                  id="file"
                  accept="image/jpeg,image/jpg,image/png"
                  multiple={false}
                  data-original-title="upload photos"
                  style={{ display: "none" }}
                  onChange={onImageSubmit}
                />
                <i className="bi bi-pencil-fill fs-9"></i>
              </label>
            )}
            {porfilePhotoUrl ? (
              <div
                className="position-absolute btn btn-icon btn-circle btn-active-color-danger w-25px h-25px bg-body shadow-sm"
                style={{
                  right: -10,
                  bottom: -10,
                  zIndex: 99,
                  borderRadius: "50px",
                  height: "25px",
                  width: "25px",
                }}
                role={"button"}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  deleteConfirmAlert(
                    intl.formatMessage({
                      id: "CUSTOMER_PROFILE_CONFIRM_DELETE_MESSAGE",
                    }),
                    (data) => {
                      if (data === "Yes") removePartnerLogo();
                    }
                  );
                }}
              >
                <SVGICON
                  src={CloseIcon}
                  className="svg-icon svg-icon-1 svg-icon-grey-800"
                />
              </div>
            ) : null}
          </div>
        </div>
        <div className="text-muted fs-7 text-center ">
          {intl.formatMessage({
            id: "MASTERDATA_SALES_LAYOUT_LOGO_ALLOWED_MESSAGE",
          })}
        </div>
      </div>
    </div>
  );
};

export default PartnerLogoSquare;
