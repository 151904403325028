import React, { useEffect, useState } from "react";
import CompanyLogo from "./CompanyLogo";
import { getCompanySettings } from "../redux/CompanySettingsAPi";
import CompanyGeneralDetails from "./CompanyGeneralDetails";

const CompanyGeneralSettings = () => {
  const [companySettings, setCompanySettings] = useState<any>();

  const getCompanySettingsAPI = () => {
    getCompanySettings().then((data) => {
      setCompanySettings(data?.data?.data);
    });
  };

  useEffect(() => {
    getCompanySettingsAPI();
  }, []);

  return (
    <div className="card-my">
      <div className="d-block">
        <div className="row mx-0 g-25px">
          <div className="col-md-4 kt-card">
            <CompanyLogo companySettings={companySettings} />
          </div>
          <div className="col-md-8 kt-card">
            <CompanyGeneralDetails partner={companySettings} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyGeneralSettings;
