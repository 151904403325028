import React, { useEffect, useRef } from "react";

interface DynamicWidthInputProps {
	type: string;
	className?: string;
	factor?: number;
	value: string;
	onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
	onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
	placeholder?: string;
	style?: React.CSSProperties;
}

const DynamicWidthInput: React.FC<DynamicWidthInputProps> = ({
	type = "text",
	className = "",
	factor = 10,
	value,
	onChange,
	onBlur,
	placeholder,
	style,
	...props
}) => {
	const inputRef = useRef<HTMLInputElement>(null);

	// Resize the input based on its content length
	const resize = () => {
		if (inputRef.current) {
			const calculatedWidth = Math.max((value.length + 1) * factor, 50);
			inputRef.current.style.width = `${calculatedWidth}px`;
		}
	};

	useEffect(() => {
		resize(); // Initial resize
	}, [value]); // Resize whenever the value changes

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		resize();
		if (onChange) {
			onChange(e); // Call the parent onChange handler
		}
	};

	const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
		if (onBlur) {
			onBlur(e); // Call the parent onBlur handler
		}
	};

	const defaultStyles: React.CSSProperties = {
		minWidth: "50px",
		maxWidth: "99.99%",
		transition: "width 0.25s",
		textAlign: "left",
	};

	return (
		<input
			{...props}
			type={type}
			ref={inputRef}
			value={value}
			className={`resizable-input ${className}`}
			onChange={handleChange}
			onBlur={handleBlur}
			placeholder={placeholder}
			style={{
				...defaultStyles,
				...style,
			}}
		/>
	);
};

export default DynamicWidthInput;
