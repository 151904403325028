/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Tooltip } from "react-tooltip";
import clsx from "clsx";
import _ from "lodash";

// css
import "../Tasks.scss";

import { SVGICON } from "../../../../_metronic/helpers";
import CloseIcon from "../../../../_metronic/assets/icons/close.svg";
import { UserSymbol } from "./UserSymbol";
import { AddDealType } from "../types/TasksResponseType";

// Define types for props
type UserType = {
  id: string;
  name: string;
};

type FilterTagProps = {
  keyName: string;
  value: string | null | UserType[];
  onRemove: (key: string) => void;
  tooltipMessage?: string;
};

type ResponsiblesFilterTagProps = {
  usersList: UserType[];
  responsibles: string[];
  onRemove: (key: string) => void;
  removeUserHandle: (users_id: number) => void;
  keyName: string;
  toolTipMessage?: string;
};

type DealsFilterTagProps = {
  dealsList: AddDealType[];
  removeDealHandle: (deals_id: number) => void;
  keyName: string;
  toolTipMessage?: string;
};

// FilterTag component
export const FilterTag: React.FC<FilterTagProps> = ({
  keyName,
  value,
  onRemove,
  tooltipMessage,
}) => {
  if (!value) return null;

  if (!tooltipMessage) {
    return (
      <div className="col-auto px-0">
        <div
          id={`filter-${keyName}`}
          className={clsx(
            "d-flex align-items-center",
            "btn btn-light-primary btn-active-light-primary btn-sm",
            "text-primary ms-2 px-3"
          )}
          style={{ borderRadius: "20px" }}
        >
          <div role="button" onClick={() => onRemove(keyName)}>
            <SVGICON src={CloseIcon} className="svg-icon-3 me-1" />
          </div>
          {/* @ts-ignore */}
          {value}
        </div>
      </div>
    );
  }

  return (
    <div
      className={clsx(`toolip_container_filter_${keyName}`, "col-auto px-0")}
      style={{ position: "relative", zIndex: 100 }}
      role="button"
      onMouseOver={() => {
        const tooltipEle = document.getElementById(
          `role-user-tooltip-filter-${keyName}`
        );
        if (tooltipEle) {
          tooltipEle.style.display = "flex";
        }
      }}
      onMouseOut={() => {
        const tooltipEle = document.getElementById(
          `role-user-tooltip-filter-${keyName}`
        );
        if (tooltipEle) {
          tooltipEle.style.display = "none";
        }
      }}
    >
      <div
        id={`filter-${keyName}`}
        className={clsx(
          "d-flex align-items-center",
          "btn btn-light-primary btn-active-light-primary btn-sm",
          "text-primary ms-2 px-3"
        )}
        style={{ borderRadius: "20px" }}
      >
        <div role="button" onClick={() => onRemove(keyName)}>
          <SVGICON src={CloseIcon} className="svg-icon-3" />
        </div>
        {/* @ts-ignore */}
        {value}
      </div>
      <Tooltip
        style={{
          height: "fit-content",
        }}
        id={`role-user-tooltip-filter-${keyName}`}
        anchorSelect={`.toolip_container_filter_${keyName}`}
      >
        {tooltipMessage}
      </Tooltip>
    </div>
  );
};

// ResponsiblesFilterTag component for handling "responsibles"
export const ResponsiblesFilterTag: React.FC<ResponsiblesFilterTagProps> = ({
  usersList,
  responsibles,
  onRemove,
  removeUserHandle,
  keyName,
  toolTipMessage,
}) => {
  return (
    <div
      className={clsx(`toolip_container_filter_${keyName}`, "col-auto px-0")}
      style={{ position: "relative", zIndex: 100 }}
      role="button"
      onMouseOver={() => {
        const tooltipEle = document.getElementById(
          `role-user-tooltip-filter-${keyName}`
        );
        if (tooltipEle) {
          tooltipEle.style.display = "flex";
        }
      }}
      onMouseOut={() => {
        const tooltipEle = document.getElementById(
          `role-user-tooltip-filter-${keyName}`
        );
        if (tooltipEle) {
          tooltipEle.style.display = "none";
        }
      }}
    >
      <div
        id={`filter-${keyName}`}
        className={clsx(
          "d-flex align-items-center",
          "btn btn-light-primary btn-active-light-primary btn-sm",
          "text-primary ms-2 px-3 py-1"
        )}
        style={{ borderRadius: "20px" }}
      >
        <div role="button" onClick={() => onRemove(keyName)}>
          <SVGICON src={CloseIcon} className="svg-icon-3" />
        </div>
        <div className="symbol-group symbol-hover flex-nowrap">
          {usersList
            .filter((user) => responsibles.includes(user.id))
            .map((data, i) => (
              // @ts-ignore
              <UserSymbol key={i} data={data} handleRemove={removeUserHandle} />
            ))}
        </div>
      </div>
      <Tooltip
        style={{
          height: "fit-content",
        }}
        id={`role-user-tooltip-filter-${keyName}`}
        anchorSelect={`.toolip_container_filter_${keyName}`}
      >
        {toolTipMessage}
      </Tooltip>
    </div>
  );
};

export const DealsFilterTag: React.FC<DealsFilterTagProps> = ({
  dealsList,
  removeDealHandle,
  keyName,
  toolTipMessage,
}) => {
  return (
    <>
      {dealsList.map((data, i) => (
        <div
          className={clsx(
            `toolip_container_filter_${keyName}`,
            "col-auto px-0"
          )}
          style={{ position: "relative", zIndex: 100 }}
          role="button"
          onMouseOver={() => {
            const tooltipEle = document.getElementById(
              `role-user-tooltip-filter-${keyName}`
            );
            if (tooltipEle) {
              tooltipEle.style.display = "flex";
            }
          }}
          onMouseOut={() => {
            const tooltipEle = document.getElementById(
              `role-user-tooltip-filter-${keyName}`
            );
            if (tooltipEle) {
              tooltipEle.style.display = "none";
            }
          }}
        >
          <div
            id={`filter-${keyName}`}
            className={clsx(
              "d-flex align-items-center",
              "btn btn-light-primary btn-active-light-primary btn-sm",
              "text-primary ms-2 px-3"
            )}
            style={{ borderRadius: "20px" }}
          >
            <div role="button" onClick={() => removeDealHandle(data.id)}>
              <SVGICON src={CloseIcon} className="svg-icon-3 me-0" />
            </div>
            <div className="symbol-group symbol-hover flex-nowrap ms-2">
              <>{data.name}</>
            </div>
          </div>
          <Tooltip
            style={{
              height: "fit-content",
            }}
            id={`role-user-tooltip-filter-${keyName}`}
            anchorSelect={`.toolip_container_filter_${keyName}`}
          >
            {toolTipMessage}
          </Tooltip>
        </div>
      ))}
    </>
  );
};
