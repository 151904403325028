import React, { FC, useEffect, useState } from "react";
import _ from "lodash";
import clsx from "clsx";
import { useIntl } from "react-intl";
import { DndProvider } from "react-dnd";
import { Tooltip } from "react-tooltip";
import { useDispatch, useSelector } from "react-redux";
import { HTML5Backend } from "react-dnd-html5-backend";

// styles
import "react-tooltip/dist/react-tooltip.css";
import "../DealsPage.scss";

// images
import PlusIcon from "../../../../_metronic/assets/icons/plus.svg";
import KanBanIcon from "../../../../_metronic/assets/icons/kanban.svg";
import ListIcon from "../../../../_metronic/assets/icons/list.svg";
import SearchIcon from "../../../../_metronic/assets/icons/search.svg";
import { SVGICON } from "../../../../_metronic/helpers";
import InfoIcon from "../../../../_metronic/assets/icons/info.svg";
import SVG from "react-inlinesvg";

// types
import { Props, ErrorProps } from "../types/DealPropsType";
import { DealPipelineDetailsType } from "../types/DealResponseType";
import { PipelineType } from "../../pipelines/types/PipelinesResponseType";
import { UserType } from "../../userManagement/types/getUsersListResponseType";

// Shared Components
import {
  errorToast,
  successToast,
} from "../../../sharedComponents/toasts/Toasts";
import SimpleLoader from "../../../sharedComponents/Loader/SimpleLoader";

import ErrorModal from "./ErrorModal";
import MovableDeal from "./DealCard";
import PhaseColumn from "./PhaseColumn";
import DealsListView from "./DealsListView";
import AddNewDealModal from "./AddNewDealModal";
import PipelineDetailsModal from "./PipelineDetailsModal";
import EndPhaseReasonModal from "./EndPhaseReasonModal";
import EndPhaseTransitionModal from "./EndPhaseTransitionModal";

// redux
import { RootState } from "../../../../setup";
import {
  changePhase,
  dealMoveToPipeline,
  getDealPipelineDetailsById,
} from "../redux/DealsAPI";
import { actions } from "../redux/DealsRedux";
import { RoleType } from "../../RolesAndPermissions/types/getRolesResponseType";
import { enablePermissionForCrud } from "../../../utils/PermisisionEnabledForResource";
import NoItemsFound from "../../../sharedComponents/NoItemsFound/NoItemsFound";

const DealsList: FC<Props> = ({ pipelineId }) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const pipelineList = useSelector<RootState>(
    // @ts-ignore
    ({ pipelines }) => pipelines.pipelinesList
  ) as PipelineType[];

  const userData = useSelector<RootState>(({ auth }) => auth.user) as UserType;

  const DEAL_VIEW_TYPES = JSON.parse(
    intl.formatMessage({ id: "DEAL_VIEW_TYPES" })
  ) as {
    id: number;
    name: string;
  }[];

  const userRoleDetails = useSelector<RootState>(
    // @ts-ignore
    (state) => state.auth.roleDetails
  ) as RoleType;
  //@ts-ignore
  const crudPermission: any = enablePermissionForCrud(userRoleDetails, "deals");

  //   state
  const [pipeline, setPipeline] = useState<DealPipelineDetailsType[]>([]);
  const [loading, setLoading] = useState(false);
  // eslint-disable-next-line
  const [items, setItems] = useState();
  const [currDealView, setCurrDealView] = useState<{
    id: number;
    name: string;
  }>(DEAL_VIEW_TYPES[1]);
  const [showAddModal, setShowAddModal] = useState(false);
  const [ApiInProgress, setApiInProgress] = useState(false)
  const [showErrorModal, setShowErrorModal] = useState<ErrorProps>({
    show: false,
    dealDetails: undefined,
  });
  const [showEndPhaseModal, setShowEndPhaseModal] = useState<{
    show: boolean;
    from_phase_info: DealPipelineDetailsType | undefined;
    to_phase_info: DealPipelineDetailsType | undefined;
    pIndex: number;
    dIndex: number;
    hIndex: number;
    loader?: boolean;
  }>({
    show: false,
    from_phase_info: undefined,
    to_phase_info: undefined,
    pIndex: 0,
    dIndex: 0,
    hIndex: 0,
    loader: false,
  });
  const [endReasonModal, setEndReasonModa] = useState<{
    show: boolean;
    value: number | undefined;
    loader?: boolean;
  }>({
    show: false,
    value: undefined,
    loader: false,
  });
  const [openPipelineModal, setOpenPipelineModal] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [displayPipeline, setDisplayPipeline] =
    useState<DealPipelineDetailsType[]>();

  const getDealPhaseAPI = () => {
    getDealPipelineDetailsById(pipelineId)
      .then(({ data: { pipeline_deals } }) => {
        const result: DealPipelineDetailsType[] = [];
        pipeline_deals.map((val, index) => {
          if (
            val.condition !== "end" ||
            (val.condition === "end" && val.display_order)
          ) {
            result.push(val);
          }
          if (pipeline_deals.length - 1 === index) {
            result.push(
              pipeline_deals.filter((data) => data.condition === "end")[0]
            );
          }
        });
        setPipeline(result);
        dispatch(actions.setDealsDetails(result));
      })
      .catch((e) => {})
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (pipelineId) {
      setLoading(true);
      getDealPhaseAPI();
      setCurrDealView(DEAL_VIEW_TYPES[1]);
    }
    dispatch(actions.setPhaseTransitionDetails([]));
  }, [pipelineId, pipelineList]);

  useEffect(() => {
    return () => {
      dispatch(
        actions.setDealFilterDetails({
          Responsible_Persons: [],
          status: "",
          partner: {},
          contact: {},
          tags: undefined,
        })
      );
    };
  }, []);

  const handlePhaseTransition = (
    from_phase_info: DealPipelineDetailsType,
    to_phase_info: DealPipelineDetailsType,
    pIndex: number,
    dIndex: number,
    hIndex: number
  ) => {
    // deal information
    const dealDetails = pipeline
      ?.filter((pipeline) => pipeline.phase_id === pIndex)[0]
      .deals?.filter((deal) => deal?.id === dIndex)[0];
    const isBackward =
      from_phase_info?.display_order && to_phase_info?.display_order
        ? Number(from_phase_info.display_order) >
          Number(to_phase_info.display_order)
        : from_phase_info?.display_order &&
            from_phase_info?.condition === "end" && // Manual End phase
            to_phase_info?.condition === "end" // End phase
          ? false
          : from_phase_info?.condition === "end"
            ? true
            : to_phase_info?.condition === "start"
              ? true
              : false;

    if (isBackward) {
      if (pIndex !== hIndex) {
        if (
          to_phase_info?.condition === "end" &&
          to_phase_info.display_order !== null
        ) {
          setEndReasonModa({
            ...endReasonModal,
            show: true,
            loader: false,
          });
          setShowEndPhaseModal({
            show: false,
            from_phase_info,
            to_phase_info,
            pIndex,
            dIndex,
            hIndex,
            loader: false,
          });
        } else {
          setApiInProgress(true)
          changePhase(dealDetails?.id, pIndex, hIndex, null, 1)
            .then((data) => {
              successToast(
                intl.formatMessage({ id: "DEALS_MOVE_SUCCESS_MESSAGE" })
              );
            })
            .catch((e) => {
              errorToast(e?.response?.data?.message);
            })
            .finally(() => {
              getDealPhaseAPI();
              setTimeout(()=>{
                setApiInProgress(false)
              },800)
            });
        }
      }
    } else {
      if (
        to_phase_info?.condition === "end" &&
        dealDetails?.can_be_move &&
        to_phase_info.display_order === null
      ) {
        setEndReasonModa({
          ...endReasonModal,
          show: true,
          loader: false,
        });
        setShowEndPhaseModal({
          show: false,
          from_phase_info,
          to_phase_info,
          pIndex,
          dIndex,
          hIndex,
          loader: false,
        });
      } else if (
        to_phase_info?.condition === "end" &&
        dealDetails?.can_be_move &&
        to_phase_info.display_order !== null
      ) {
        setShowEndPhaseModal({
          show: false,
          from_phase_info,
          to_phase_info,
          pIndex,
          dIndex,
          hIndex,
          loader: false,
        });
        setEndReasonModa({
          show: true,
          value: undefined,
          loader: false,
        });
      } else {
        if (pIndex !== hIndex) {
          if (!dealDetails?.can_be_move) {
            if (pIndex !== hIndex) {
              dispatch(actions.setPhaseTransitionDetails([pIndex, hIndex]));
            }
            setShowErrorModal({
              show: true,
              dealDetails: dealDetails,
            });
          } else {
            if (pIndex !== hIndex) {
              // @ts-ignore
              handleDealTransition(dealDetails?.id, pIndex, hIndex);
            }
          }
        }
      }
    }
  };

  const handleDealTransition = (id: any, pIndex: any, hIndex: any) => {
    setApiInProgress(true)
    changePhase(id, pIndex, hIndex)
      .then((data) => {
        successToast(intl.formatMessage({ id: "DEALS_MOVE_SUCCESS_MESSAGE" }));
      })
      .catch((e) => {
        errorToast(intl.formatMessage({ id: "DEALS_MOVE_FAILURE_MESSAGE" }));
      })
      .finally(() => {
        getDealPhaseAPI();
        setTimeout(()=>{
          setApiInProgress(false)
        },800)
      });
  };

  const moveDealHandler = (pIndex: number, dIndex: number, hIndex: number) => {
    // phase information
    const from_phase_info = pipeline?.filter(
      (pipeline) => pipeline.phase_id === pIndex
    )[0];
    const to_phase_info = pipeline?.filter(
      (pipeline) => pipeline.phase_id === hIndex
    )[0];
    // if (from_phase_info.condition !== "end") {
    handlePhaseTransition(
      // @ts-ignore
      from_phase_info,
      to_phase_info,
      pIndex,
      dIndex,
      hIndex
    );
    // }
  };

  const handleDealMovetoAnotherPipeline = (
    id: number,
    pId: number,
    message?: string
  ) => {
    dealMoveToPipeline(id, pId)
      .then((data) => {
        setShowEndPhaseModal({
          ...showEndPhaseModal,
          show: false,
          loader: false,
        });
        successToast(
          intl.formatMessage({
            id: "DEAL_MOVE_ANOTHER_PIPELINE_SUCCESS_MESSAGE",
          })
        );
      })
      .catch((e) => {
        errorToast(
          intl.formatMessage({
            id: "DEAL_MOVE_ANOTHER_PIPELINE_FAILURE_MESSAGE",
          })
        );
      })
      .finally(() => {
        getDealPhaseAPI();
      });
  };

  const onSearchText = (value: string) => {
    setSearchText((value || "").trim());
  };

  const updatedPipelineSearchData = () => {
    const duplicatePipeline: DealPipelineDetailsType[] = [];

    const tempPipeline = _.cloneDeep(pipeline);
    if (tempPipeline && tempPipeline.length > 0)
      tempPipeline.map((phase) => {
        const duplicatePhase = _.cloneDeep(phase);
        const tempPhaseArray = _.cloneDeep(phase);
        tempPhaseArray.deals = [];
        phase.deals.map((deal) => {
          if (
            deal.name
              .toLocaleLowerCase()
              .includes(searchText.toLocaleLowerCase()) &&
            ((phase.condition !== "end" && deal.status === "inProgress") ||
              (phase.condition === "end" &&
                ((phase.display_order === null && deal.status === "finished") ||
                  (phase.display_order !== null &&
                    deal.status === "inProgress"))))
          ) {
            tempPhaseArray?.deals?.push(deal);
          } else {
            const dealIndex = duplicatePhase.deals.findIndex(
              (dDeal) => dDeal.id === deal.id
            );
            duplicatePhase.deals.splice(dealIndex, 1);
          }
        });
        duplicatePipeline.push(duplicatePhase);
      });
    if (searchText && searchText.length > 0) {
      setDisplayPipeline(duplicatePipeline);
    } else {
      setDisplayPipeline(pipeline);
    }
  };

  useEffect(() => {
    updatedPipelineSearchData();

    return () => {
      setDisplayPipeline(pipeline);
    };
  }, [searchText, pipeline]);

  return (
    <>
      {showAddModal && (
        <AddNewDealModal
          show={showAddModal}
          closeModal={() => {
            setShowAddModal(false);
          }}
          getDealPhaseAPI={getDealPhaseAPI}
          pipelineId={pipelineId}
        />
      )}
      {showErrorModal.show && (
        <ErrorModal
          show={showErrorModal.show}
          closeModal={() => {
            setShowErrorModal({
              show: false,
              dealDetails: undefined,
            });
          }}
          type={"listModal"}
          tempModalProps={showErrorModal}
        />
      )}

      {openPipelineModal && (
        <PipelineDetailsModal
          show={openPipelineModal}
          closeModal={() => {
            setOpenPipelineModal(false);
          }}
          pipeline_id={pipelineId}
        />
      )}

      {showEndPhaseModal.show && (
        <EndPhaseTransitionModal
          show={showEndPhaseModal.show}
          closeModal={() => {
            setShowEndPhaseModal({
              ...showEndPhaseModal,
              show: false,
              loader: false,
            });
          }}
          submittingEndPhaseTransition={showEndPhaseModal.loader}
          onSubmitHandle={(value) => {
            setShowEndPhaseModal({
              ...showEndPhaseModal,
              show: false,
              loader: true,
            });
            setEndReasonModa({
              show: false,
              value: value,
              loader: false,
            });
            if (value) {
              const dealDetails = pipeline
                ?.filter(
                  (pipeline) => pipeline.phase_id === showEndPhaseModal.pIndex
                )[0]
                .deals?.filter(
                  (deal) => deal.id === showEndPhaseModal.dIndex
                )?.[0];
              if (dealDetails?.id) {
                handleDealMovetoAnotherPipeline(dealDetails.id, value);
              }
            }
          }}
          id={pipelineId}
        />
      )}
      {endReasonModal.show && (
        <EndPhaseReasonModal
          show={endReasonModal.show}
          closeModal={() => {
            setEndReasonModa({
              ...endReasonModal,
              show: false,
              loader: false,
            });
          }}
          submittingEndPhaseReason={endReasonModal.loader}
          onSubmit={(reason: string) => {
            setEndReasonModa({
              ...endReasonModal,
              loader: true,
            });
            if (showEndPhaseModal.pIndex !== showEndPhaseModal.hIndex) {
              const dealDetails = pipeline
                ?.filter(
                  (pipeline) => pipeline.phase_id === showEndPhaseModal.pIndex
                )[0]
                .deals.filter(
                  (deal) => deal.id === showEndPhaseModal.dIndex
                )?.[0];

              const isBackward =
                showEndPhaseModal?.from_phase_info?.display_order &&
                showEndPhaseModal?.to_phase_info?.display_order
                  ? Number(showEndPhaseModal?.from_phase_info.display_order) >
                    Number(showEndPhaseModal?.to_phase_info.display_order)
                  : showEndPhaseModal?.from_phase_info?.display_order &&
                      showEndPhaseModal?.from_phase_info?.condition === "end" && // Manual End phase
                      showEndPhaseModal?.to_phase_info?.condition === "end" // End phase
                    ? false
                    : showEndPhaseModal?.from_phase_info?.condition === "end"
                      ? true
                      : showEndPhaseModal?.to_phase_info?.condition === "start"
                        ? true
                        : false;

              if (dealDetails?.id) {
                setApiInProgress(true)
                changePhase(
                  dealDetails?.id,
                  showEndPhaseModal.pIndex,
                  showEndPhaseModal.hIndex,
                  reason,
                  isBackward ? 1 : 0
                )
                  .then(() => {
                    successToast(
                      intl.formatMessage({
                        id: "DEALS_MOVE_SUCCESS_MESSAGE",
                      })
                    );
                    setEndReasonModa({
                      ...endReasonModal,
                      show: false,
                      loader: false,
                    });
                    setShowEndPhaseModal({
                      ...showEndPhaseModal,
                      show: true,
                      loader: false,
                    });
                  })
                  .catch((e) => {
                    errorToast(
                      intl.formatMessage({
                        id: "DEALS_MOVE_FAILURE_MESSAGE",
                      })
                    );
                  })
                  .finally(() => {
                    getDealPhaseAPI();
                    setEndReasonModa({
                      ...endReasonModal,
                      show: false,
                      loader: false,
                    });
                    setTimeout(()=>{
                      setApiInProgress(false)
                    },800)
                  });
              }
            }
          }}
        />
      )}
      <DndProvider backend={HTML5Backend}>
        <div className={`${ApiInProgress && "disabled-deals_list"}`}>
          {/* search deals */}
          <div className="card card-search d-flex flex-row justify-content-between">
            <div className="d-flex flex-row">
              <div className="d-flex align-items-center position-relative">
                <span className="position-absolute" style={{ left: "10px" }}>
                  <SVGICON className="svg-icon svg-icon-1" src={SearchIcon} />
                </span>
                <input
                  type="text"
                  className="form-control form-control-md form-control-solid min-w-150px min-w-md-200px ps-11"
                  placeholder={intl.formatMessage({
                    id: "DEALS_SEARCH_PLACEHOLDER",
                  })}
                  onChange={(e) => {
                    onSearchText(e.target.value);
                  }}
                />
              </div>
              <div
                style={{
                  position: "relative",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setOpenPipelineModal(true);
                }}
                className="d-flex align-items-center"
              >
                <span
                  data-tooltip-id="my-tooltip"
                  data-tooltip-content={intl.formatMessage({
                    id: "PIPELINE_INFO",
                  })}
                  data-tooltip-place="top"
                >
                  <SVG src={InfoIcon} className="svg-icon mx-2" />
                </span>

                <Tooltip
                  id="my-tooltip"
                  place="top"
                  style={{
                    color: "black",
                    backgroundColor: "#e9ecef",
                    zIndex: 999,
                    whiteSpace: "normal",
                    maxWidth: "250px",
                    wordWrap: "break-word",
                    height: "fit-content",
                  }}
                  border="1px solid green"
                />
              </div>
            </div>
            {/* add new button */}
            <div className="d-flex align-items-center justify-content-between">
              {crudPermission?.create && (
                <button
                  className="btn btn-primary me-4"
                  onClick={() => {
                    setShowAddModal(true);
                  }}
                >
                  <SVGICON src={PlusIcon} className="svg-icon-2" />
                  {intl.formatMessage({ id: "DEALS_ADD_NEW_BUTTON" })}
                </button>
              )}
              <button
                className={clsx("btn btn-light btn-active-light-primary px-0")}
                style={{ width: "40px" }}
                onClick={() => {
                  const res =
                    DEAL_VIEW_TYPES.filter(
                      (view) => view.id !== currDealView.id
                    )?.[0] || [];
                  if (res && res.id) {
                    setCurrDealView(res);
                  }
                }}
              >
                <SVGICON
                  src={currDealView.id === 1 ? KanBanIcon : ListIcon}
                  className="svg-icon-2 me-0"
                />
              </button>
            </div>
          </div>
          {!loading && pipeline?.length === 0 && (
            <table className="d-flex justify-content-center align-items-center mt-2">
              <tbody>
                <NoItemsFound languageKey="NO_ITEMS_FOUND" />
              </tbody>
            </table>
          )}
          {loading ? (
            <SimpleLoader height="60vh" />
          ) : currDealView && currDealView.id === 2 ? (
            <div>
              {displayPipeline && displayPipeline?.length > 0 && (
                <div
                  className={clsx(
                    "pb-0 d-flex bg-white card card-my card-pt gap-2 flex-row py-4 px-6 boxShadowNone overflow-auto"
                  )}
                >
                  {displayPipeline?.map((phase, i) => {
                    return (
                      <div className="card card-body p-0 dealColumn" key={i}>
                        <PhaseColumn
                          title={phase.name}
                          className={"column phase-column"}
                          index={phase.phase_id}
                          count={
                            phase.condition !== "end" ||
                            (phase.condition === "end" &&
                              phase.display_order !== null)
                              ? phase.deals.filter(
                                  (data) => data.status === "inProgress"
                                ).length
                              : phase.deals.filter(
                                  (data) =>
                                    data.status === "finished" &&
                                    phase.display_order === undefined
                                ).length
                          }
                          phase={phase}
                          userData={userData}
                        >
                          <>
                            {phase.deals &&
                              phase.deals.length > 0 &&
                              phase?.deals.map((item, index) => {
                                if (
                                  (item.status === "inProgress" &&
                                    phase.condition !== "end") ||
                                  (item.status === "finished" &&
                                    phase.condition === "end" &&
                                    phase.display_order === null) ||
                                  (item.status === "inProgress" &&
                                    phase.condition === "end" &&
                                    phase.display_order !== null)
                                ) {
                                  const displayUsers = (
                                    item.responsible_users || []
                                  ).slice(0, 3);
                                  const remainingUsers =
                                    (item.responsible_users || []).length -
                                    displayUsers.length;
                                  return (
                                    <MovableDeal
                                      key={index}
                                      name={item.name}
                                      currentPhaseId={phase.phase_id}
                                      currentPhaseName={phase.name}
                                      setItems={setItems}
                                      index={index}
                                      moveDealHandler={moveDealHandler}
                                      getDealPhaseAPI={getDealPhaseAPI}
                                      item={item}
                                      displayUsers={displayUsers}
                                      remainingUsers={remainingUsers}
                                      view={currDealView}
                                      currentPhase={phase}
                                      userData={userData}
                                    />
                                  );
                                }
                              })}
                          </>
                        </PhaseColumn>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          ) : (
            <div>
              <DealsListView phases={displayPipeline || []} pId={pipelineId} />
            </div>
          )}
        </div>
      </DndProvider>
    </>
  );
};

export default DealsList;
