import axios from "axios";
import { GenericResponseType } from "../../../../types/GenericReponse";
import {
  OfferByFiltersResponseType,
  OfferByIdResponseType,
} from "../types/OfferTypes";

// get salesOffers
export function getOffers(
  status: "all" | "draft" | "open" | "accept " | "reject",
  search_keyword?: string,
  limit?: number,
  page?: number
) {
  let url = `/sales/offers?status=${status}`;
  if (search_keyword) {
    url += `&search_keyword=${search_keyword}`;
  }
  if (page) {
    url += `&page=${page}`;
  }
  if (limit) {
    url += `&limit=${limit}`;
  } else {
    url += `&limit=50`;
  }
  return axios.get<OfferByFiltersResponseType>(url);
}

//get trash list
export function getSalesOffersTrashList() {
  return axios.get<OfferByFiltersResponseType>("/sales/offers/retrieve");
}

//get offer by id
export function getOfferDetails(offer_id: number) {
  return axios.get<OfferByIdResponseType>(`/sales/offers/${offer_id}`);
}

/*
 * create offer inside the edit
 * Not using this API
 * since to create offer we first need to create sale
 */
// export function createOffer(offer_id: number) {
//   return axios.post(`/sales/offers/create/${offer_id}`);
// }

// update offer date
export function updateOfferDate(
  offer_id: number,
  date: string,
  type: "expiration_date" | "offer_date"
) {
  return axios.post(`/sales/offers/dates/${offer_id}`, {
    type,
    date,
  });
}

export function updateOfferNotes(
  offer_id: number,
  type: "footer_notes" | "header_notes" | "subject",
  notes: string
) {
  const payload: any = { type };
  if (type === "subject") {
    payload.subject = notes;
  } else {
    payload.notes = notes;
  }
  return axios.post<any>(`/sales/offers/notes/${offer_id}`, payload);
}

//delete offer by id
export function deleteOffer(id: number) {
  return axios.delete<GenericResponseType>(`/sales/offers/${id}`);
}

// massdelete offers
export function massDeleteOffers(offer_id: number[]) {
  return axios.post<GenericResponseType>(`/sales/offers/massDelete`, {
    offer_id,
  });
}

// forcedelete single offer
export function forceDeleteOffer(id: number) {
  return axios.post<GenericResponseType>(`/sales/offers/forceDelete/${id}`);
}

// mass force delete
export function massForceDeleteOffers(offer_id: number[]) {
  return axios.post<GenericResponseType>(`/sales/offers/massForceDelete`, {
    offer_id,
  });
}

// restore offer
export function restoreOffers(id: number) {
  return axios.post<GenericResponseType>(`/sales/offers/restore/${id}`);
}

// mass restore
export function massRestoreOffers(offer_id: number[]) {
  return axios.post<GenericResponseType>("sales/offers/massRestore", {
    offer_id,
  });
}

// WorkFlow APIs

// finalize offer
export function offerFinaliseOffer(offer_id: number) {
  return axios.post<any>(`sales/offers/finalise/${offer_id}`);
}

// accept offer
export function offerAcceptOffer(offer_id: number) {
  return axios.post<any>(`sales/offers/accept/${offer_id}`);
}

// reject offer
export function offerRejectOffer(offer_id: number) {
  return axios.post<any>(`sales/offers/reject/${offer_id}`);
}

// go to invoice from offer
export function offerCreateInvoice(offer_id: number) {
  return axios.post<any>(`sales/offers/create/invoice/${offer_id}`);
}
