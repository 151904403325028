import React, { FC } from "react";
import { Modal } from "react-bootstrap";
import { useIntl } from "react-intl";
import Constants from "../../config/Constants";

// custom
import { FileType } from "../../modules/filemanager/types/getFilemanagerDataResponseType";
import CloseButton from "../Buttons/CloseButton";

interface Props {
  show: boolean;
  file: FileType | undefined;
  closeModal: () => void;
}

const DisplayFileModal: FC<Props> = ({ show, file, closeModal }) => {
  // const [numPages, setNumPages] = useState(null)
  // const [pageNumber, setPageNumber] = useState(1)
  const intl = useIntl();
  // @ts-ignore
  // function onDocumentLoadSuccess({numPages}) {
  //   setNumPages(numPages)
  // }

  return (
    <Modal
      show={show}
      centered
      dialogClassName="medium-size-modal"
      backdrop="static"
    >
      <Modal.Header>
        <div className="d-flex flex-row flex-fill align-items-center justify-content-between">
          <Modal.Title>
            {file?.name || intl.formatMessage({ id: "File" })}
          </Modal.Title>
          <CloseButton onClose={closeModal} />
        </div>
      </Modal.Header>
      <Modal.Body>
        <div>
          {file ? (
            <div>
              {/* image file */}
              {Constants.mimeTypes.image.includes(file.type) && (
                <div
                  className="d-flex flex-row justify-content-center"
                  style={{ maxHeight: "500px" }}
                >
                  <img
                    src={file.file_path}
                    style={{ objectFit: "contain" }}
                    alt=""
                  />
                </div>
              )}
              {/* document file */}
              {Constants.mimeTypes.document.includes(file.type) && (
                <div
                  className="d-flex flex-row justify-content-center"
                  style={{ maxHeight: "500px" }}
                >
                  <object
                    data="http://www.africau.edu/images/default/sample.pdf"
                    type="application/pdf"
                    width="100%"
                    height="500"
                  >
                    <iframe
                      src="http://www.africau.edu/images/default/sample.pdf"
                      width="100%"
                      height="500"
                      title={file.name}
                    >
                      <p>
                        {intl.formatMessage({
                          id: "FILEMANAGER_BROWSER_PDF_MESSAGE",
                        })}
                        <a href="https://example.com/test.pdf">
                          {intl.formatMessage({
                            id: "FILEMANAGER_DOWNLOAD_PDF_BUTTON",
                          })}
                        </a>
                        .
                      </p>
                    </iframe>
                  </object>
                </div>
              )}
              {/* audio file */}
              {Constants.mimeTypes.audio.includes(file.type) && (
                <div
                  className="d-flex flex-row justify-content-center"
                  style={{ maxHeight: "500px" }}
                >
                  <audio controls autoPlay>
                    <source src={file.file_path} />
                    {intl.formatMessage({
                      id: "FILEMANAGER_BROWSER_AUDIO_MESSAGE",
                    })}
                  </audio>
                </div>
              )}
              {/* video file */}
              {Constants.mimeTypes.video.includes(file.type) && (
                <div
                  className="d-flex flex-row justify-content-center"
                  style={{ maxHeight: "500px" }}
                >
                  <video
                    controls
                    autoPlay
                    style={{ height: "100%", width: "100%" }}
                  >
                    <source src={file.file_path} />
                    {intl.formatMessage({
                      id: "FILEMANAGER_BROWSER_VIDEO_MESSAGE",
                    })}
                  </video>
                </div>
              )}
            </div>
          ) : (
            <span>
              {intl.formatMessage({
                id: "FILEMANAGER_BROWSER_DISPLAY_FAILURE_MESSAGE",
              })}
            </span>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default DisplayFileModal;
