import { useFormik } from "formik";
import _ from "lodash";
import React, { FC, useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import * as Yup from "yup";

// custom
import { useIntl } from "react-intl";
import Checkbox from "../../../sharedComponents/Checkbox/Checkbox";
import { ResourceType } from "../types/getResourcesListResponseType";
import { RoleType } from "../types/getRolesResponseType";

import { PermissionType } from "../../userManagement/types/getPermissionsResponseType";
import { updateRolesAndPermissions } from "../redux";
import RolesAndPermissionsCard from "./RolesAndPermissionsCard";
import { successToast } from "../../../sharedComponents/toasts/Toasts";
import CloseButton from "../../../sharedComponents/Buttons/CloseButton";
// import { useSelector } from "react-redux";
// import { RootState } from "../../../../setup";
// import { UserType } from "../../userManagement/types/getUsersListResponseType";
// import LegalTextDetailsList from '../../legalTexts/components/LegalTextDetailsList'

interface ModalProps {
  show: boolean;
  role: RoleType;
}

interface Props {
  modalProps: ModalProps;
  closeModal: () => void;
  getRolesListAPI: () => void;
}

const EditRoleModal: FC<Props> = ({
  modalProps,
  closeModal,
  getRolesListAPI,
}) => {
  // const dispatch = useDispatch()
  // const history = useHistory()
  const intl = useIntl();

  const { show, role } = modalProps;
  // const userData = useSelector<RootState>(({ auth }) => auth.user) as UserType;

  const RESOURCE_PERMISSION_TYPES = JSON.parse(
    intl.formatMessage({ id: "RESOURCE_PERMISSION_TYPES" })
  ) as PermissionType[];
  let RESOURCES_TYPES = JSON.parse(
    intl.formatMessage({ id: "RESOURCES_TYPES" })
  ) as ResourceType[];

  const getRolePermissions = () => {
    const obj = {};
    // initialize object
    RESOURCES_TYPES.forEach((resource) => {
      // @ts-ignore
      obj[resource.id] = [];
    });
    role.resources?.forEach((resource) => {
      // @ts-ignore
      obj[resource.resource_id]?.push(resource.permission_id);
    });
    return obj;
  };

  // state
  const [rolePermissions, setrolePermissions] =
    useState<any>(getRolePermissions());
  const [loading, setLoading] = useState<boolean>(false);
  const [selectAll, setSelectAll] = useState(false);
  const [toggles, setToggles] = useState<any>(
    // getIsLegalTechEnabled()
    {}
  );
  const [subToggles, setSubToggles] = useState<any>({});

  const tasksIds = [28];
  const projectIds = [19];
  const contactManagementIds = [7, 5, 3, 25, 26,2];
  const productManagementIds = [13, 21, 24];
  const userManagementIds = [1,11, 4];
  const fileManagerIds = [6];
  const archivedDealsIds = [31];
  const editorIds = [16, 12, 15, 22]; // editor (i.e. masterdata) (2 and 20 resource type -> we have commented)
  const settingsIds = [ 29, 18];

  // formik
  const initialValues = {
    name: role.name || "",
  };
  const EditRoleModalSchema = Yup.object().shape({
    name: Yup.string().required(
      intl.formatMessage({ id: "ROLES_PERMISSIONS_EDIT_ROLE_NAME_VALIDATION" })
    ),
  });
  const formik = useFormik({
    initialValues,
    validationSchema: EditRoleModalSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      const payload = {
        roles_resources: [],
        name: values.name,
      };
      // role_id:1 super admin
      if (role?.id !== 1) {
        Object.keys(rolePermissions).forEach((key) => {
          // @ts-ignore
          payload.roles_resources.push({
            // @ts-ignore
            resource_id: key,
            // @ts-ignore
            permissions_id: rolePermissions[key],
          });
        });
      }

      updateRolesAndPermissions(role.id, payload)
        .then(() => {
          successToast(
            intl.formatMessage({
              id: "ROLES_PERMISSIONS_UPDATE_SUCCESS_MESSAGE",
            })
          );
          // getrolesAndPermissionsAPI()
        })
        .catch(() => {
          setStatus(
            intl.formatMessage({ id: "ROLES_PERMISSIONS_UPDATE_ERROR_MESSAGE" })
          );
        })
        .finally(() => {
          getRolesListAPI();
          setLoading(false);
          closeModal();
        });
    },
  });

  const getSelectAllStatus = () => {
    if (role?.id === 1) {
      return true;
    } else {
      return Object.values(rolePermissions)?.every(
        (item: any) => item.length === 4
      );
    }
  };

  const onSelectAllCheckChange = () => {
    setSelectAll(!selectAll);
    if (!selectAll) {
      // slect all permissions
      const permission_ids = RESOURCE_PERMISSION_TYPES.map(
        (permission) => permission.id
      );
      const user_permissions: any = {};
      RESOURCES_TYPES.forEach((resource) => {
        user_permissions[resource.id] = permission_ids;
      });
      // setLegalTechEnabled(true);
      setrolePermissions(user_permissions);
      setToggles({
        ...toggles,
        tasks: true,
        project: true,
        contactManagement: true,
        productManagement: true,
        userManagement: true,
        fileManager: true,
        archivedDeals: true,
        management: true,
        editor: true,
        settings: true,
      });
      let obj: any = {};
      Object.keys(user_permissions)?.forEach((item) => {
        obj[item] = true;
      });
      setSubToggles(obj);
    } else {
      const obj = {};
      // initialize object
      RESOURCES_TYPES.forEach((resource) => {
        // @ts-ignore
        obj[resource.id] = [];
      });
      setrolePermissions(obj);
      setToggles({});
      setSubToggles({});
      // setrolePermissions(getRolePermissions());
    }
  };

  const updaterolePermissions = (
    resource_id: number,
    permission_id: number
  ) => {
    const rolePermissionsCopy = _.clone(rolePermissions);
    // check if already existing
    const index = (rolePermissions[resource_id] || []).findIndex(
      (id: number) => {
        return id === permission_id;
      }
    );
    // donot exist push the item
    if (index === -1) {
      rolePermissionsCopy[resource_id] = [
        ...(rolePermissionsCopy[resource_id] || []),
        permission_id,
      ];
    }
    // if already exists remove item
    else {
      // is select all is checked, remove check
      selectAll && setSelectAll(false);
      // if read is removed remove all other permissions
      if (permission_id === 1) {
        rolePermissionsCopy[resource_id] = [];
      }
      // remove the unselected permission
      else {
        const ab = _.clone(rolePermissionsCopy[resource_id]);
        ab.splice(index, 1);
        rolePermissionsCopy[resource_id] = ab;
      }
    }

    setrolePermissions(rolePermissionsCopy);
  };

  const handleOnToggle = (bool: boolean, type: string) => {
    setToggles({
      ...toggles,
      [type]: bool,
    });

    if (type === "tasks" && !bool) {
      let copy = _.clone(rolePermissions);

      tasksIds.forEach((item: number) => {
        copy[item] = [];
      });

      setrolePermissions(copy);
    }

    if (type === "project" && !bool) {
      let copy = _.clone(rolePermissions);

      projectIds.forEach((item: number) => {
        copy[item] = [];
      });

      setrolePermissions(copy);
    }
    if (type === "contactManagement" && !bool) {
      let copy = _.clone(rolePermissions);

      contactManagementIds.forEach((item: number) => {
        copy[item] = [];
      });

      setrolePermissions(copy);
    }
    if (type === "productManagement" && !bool) {
      let copy = _.clone(rolePermissions);

      productManagementIds.forEach((item: number) => {
        copy[item] = [];
      });

      setrolePermissions(copy);
    }
    if (type === "userManagement" && !bool) {
      let copy = _.clone(rolePermissions);

      userManagementIds.forEach((item: number) => {
        copy[item] = [];
      });

      setrolePermissions(copy);
    }
    if (type === "fileManager" && !bool) {
      let copy = _.clone(rolePermissions);

      fileManagerIds.forEach((item: number) => {
        copy[item] = [];
      });

      setrolePermissions(copy);
    }
    if (type === "archivedDeals" && !bool) {
      let copy = _.clone(rolePermissions);

      archivedDealsIds.forEach((item: number) => {
        copy[item] = [];
      });

      setrolePermissions(copy);
    }

    if (type === "editor" && !bool) {
      let copy = _.clone(rolePermissions);

      editorIds.forEach((item: number) => {
        copy[item] = [];
      });

      setrolePermissions(copy);
    }

    if (type === "settings" && !bool) {
      let copy = _.clone(rolePermissions);

      settingsIds.forEach((item: number) => {
        copy[item] = [];
      });

      setrolePermissions(copy);
    }
  };

  const handleOnSubToggle = (bool: boolean, type: string) => {
    setSubToggles({
      ...subToggles,
      [type]: bool,
    });
  };

  const isSubItemsChecked = (type: string) => {
    const dataIds = {
      tasks: tasksIds,
      project: projectIds,
      contactManagement: contactManagementIds,
      productManagement: productManagementIds,
      userManagement: userManagementIds,
      fileManager: fileManagerIds,
      archivedDeals: archivedDealsIds,
      editor: editorIds,
      settings: settingsIds,
    };
    //@ts-ignore
    const items = dataIds[type];

    return items?.some((item: any) => rolePermissions[item]?.length > 0);
  };

  useEffect(() => {
    setToggles({
      ...toggles,
      tasks: isSubItemsChecked("tasks"),
      project: isSubItemsChecked("project"),
      contactManagement: isSubItemsChecked("contactManagement"),
      productManagement: isSubItemsChecked("productManagement"),
      userManagement: isSubItemsChecked("userManagement"),
      fileManager: isSubItemsChecked("fileManager"),
      archivedDeals: isSubItemsChecked("archivedDeals"),
      editor: isSubItemsChecked("editor"),
      settings: isSubItemsChecked("settings"),
    });
  }, [rolePermissions]);

  return (
    <Modal
      show={show}
      centered
      dialogClassName="large-size-modal"
      className="kt-modal"
      contentClassName={loading ? "pe-none" : ""}
      backdrop="static"
      onHide={closeModal}
    >
      <Modal.Header>
        <Modal.Title>
          {intl.formatMessage({ id: "ROLES_PERMISSIONS_EDIT_ROLE_TITLE" })}
        </Modal.Title>
        <CloseButton onClose={closeModal} />
      </Modal.Header>
      <form
        className="form w-100 overflow-auto"
        onSubmit={formik.handleSubmit}
        noValidate
        id="kt_add_post_basic_form"
      >
        <Modal.Body className="overflow-inherit">
          <div>
            {formik.status && (
              <div className="mb-10 alert alert-danger">
                <div className="alert-text font-weight-bold">
                  {formik.status}
                </div>
              </div>
            )}
            <div>
              <div className="mt-2 tab-field">
                <label className="required tab-field-label">
                  {intl.formatMessage({ id: "ROLES_PERMISSIONS_ROLE_NAME" })}
                </label>
                <input
                  {...formik.getFieldProps("name")}
                  placeholder={""}
                  className={"form-control form-control-lg form-control-solid"}
                  autoComplete="off"
                  // non editable for super-admin
                  disabled={role.id === 1}
                />
                {formik.touched.name && formik.errors.name && (
                  <div className="text-danger">
                    <span role="alert">{formik.errors.name}</span>
                  </div>
                )}
              </div>

              <div className="fv-row card-mt">
                <label className="fs-5 fw-bold mb-2">
                  {intl.formatMessage({ id: "ROLES_PERMISSIONS_HEADER" })}
                </label>
                <div className="table-responsive">
                  <table className="table align-middle table-row-dashed fs-6 gy-5 mb-0">
                    <tbody className="text-gray-600 fw-semibold">
                      <tr>
                        <td className="text-gray-800">
                          {intl.formatMessage({
                            id: "ROLES_PERMISSIONS_SUPER_ADMIN_ACCESS",
                          })}
                          <i
                            className="fas fa-exclamation-circle ms-1 fs-7"
                            data-bs-toggle="tooltip"
                            aria-label="Allows a full access to the system"
                            data-kt-initialized="1"
                          ></i>
                        </td>
                        <td className="position-relative w-75px">
                          <label className="form-check form-check-sm form-check-custom form-check-solid me-9">
                            <Checkbox
                              checked={getSelectAllStatus()}
                              disabled={role?.id === 1}
                              onChange={onSelectAllCheckChange}
                            />
                            <span
                              className="form-check-label position-absolute "
                              style={{ top: "17px", right: "-26px" }}
                            >
                              {intl.formatMessage({
                                id: "ROLES_PERMISSIONS_SELECT_ALL",
                              })}
                            </span>
                          </label>
                        </td>
                        <td style={{ width: "400px" }}></td>
                      </tr>

                      {/* tasks */}
                      <RolesAndPermissionsCard
                        type={"tasks"}
                        toggles={toggles}
                        handleOnToggle={handleOnToggle}
                        ids={tasksIds}
                        subToggles={subToggles}
                        rolePermissions={rolePermissions}
                        handleOnSubToggle={handleOnSubToggle}
                        updaterolePermissions={updaterolePermissions}
                        role={role}
                      />
                      {/* tasks  */}

                      {/* project */}
                      <RolesAndPermissionsCard
                        type={"project"}
                        toggles={toggles}
                        handleOnToggle={handleOnToggle}
                        ids={projectIds}
                        subToggles={subToggles}
                        rolePermissions={rolePermissions}
                        handleOnSubToggle={handleOnSubToggle}
                        updaterolePermissions={updaterolePermissions}
                        role={role}
                      />
                      {/* project  */}

                      {/* contactManagement */}
                      <RolesAndPermissionsCard
                        type={"contactManagement"}
                        toggles={toggles}
                        handleOnToggle={handleOnToggle}
                        ids={contactManagementIds}
                        subToggles={subToggles}
                        rolePermissions={rolePermissions}
                        handleOnSubToggle={handleOnSubToggle}
                        updaterolePermissions={updaterolePermissions}
                        role={role}
                      />
                      {/* contactManagement  */}

                      {/* productManagement */}
                      <RolesAndPermissionsCard
                        type={"productManagement"}
                        toggles={toggles}
                        handleOnToggle={handleOnToggle}
                        ids={productManagementIds}
                        subToggles={subToggles}
                        rolePermissions={rolePermissions}
                        handleOnSubToggle={handleOnSubToggle}
                        updaterolePermissions={updaterolePermissions}
                        role={role}
                      />
                      {/* productManagement  */}

                      {/* userManagement */}
                      <RolesAndPermissionsCard
                        type={"userManagement"}
                        toggles={toggles}
                        handleOnToggle={handleOnToggle}
                        ids={userManagementIds}
                        subToggles={subToggles}
                        rolePermissions={rolePermissions}
                        handleOnSubToggle={handleOnSubToggle}
                        updaterolePermissions={updaterolePermissions}
                        role={role}
                      />
                      {/* userManagement  */}

                      {/* fileManager */}
                      <RolesAndPermissionsCard
                        type={"fileManager"}
                        toggles={toggles}
                        handleOnToggle={handleOnToggle}
                        ids={fileManagerIds}
                        subToggles={subToggles}
                        rolePermissions={rolePermissions}
                        handleOnSubToggle={handleOnSubToggle}
                        updaterolePermissions={updaterolePermissions}
                        role={role}
                      />
                      {/* fileManager  */}

                      {/* archivedDeals */}
                      <RolesAndPermissionsCard
                        type={"archivedDeals"}
                        toggles={toggles}
                        handleOnToggle={handleOnToggle}
                        ids={archivedDealsIds}
                        subToggles={subToggles}
                        rolePermissions={rolePermissions}
                        handleOnSubToggle={handleOnSubToggle}
                        updaterolePermissions={updaterolePermissions}
                        role={role}
                      />
                      {/* archivedDeals  */}

                      {/* editor */}
                      <RolesAndPermissionsCard
                        type={"editor"}
                        toggles={toggles}
                        handleOnToggle={handleOnToggle}
                        ids={editorIds}
                        subToggles={subToggles}
                        rolePermissions={rolePermissions}
                        handleOnSubToggle={handleOnSubToggle}
                        updaterolePermissions={updaterolePermissions}
                        role={role}
                      />
                      {/* editor  */}

                      {/* settings */}
                      <RolesAndPermissionsCard
                        type={"settings"}
                        toggles={toggles}
                        handleOnToggle={handleOnToggle}
                        ids={settingsIds}
                        subToggles={subToggles}
                        rolePermissions={rolePermissions}
                        handleOnSubToggle={handleOnSubToggle}
                        updaterolePermissions={updaterolePermissions}
                        role={role}
                      />
                      {/* settings  */}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer className="justify-content-center">
          <Button variant="secondary" onClick={closeModal}>
            {intl.formatMessage({ id: "CLOSE_BUTTON" })}
          </Button>
          {/* hide submit for super-admin roleid:1 */}
          {role.id !== 1 && (
            <Button
              variant="primary"
              onClick={() => {
                formik.handleSubmit();
              }}
            >
              {!loading && intl.formatMessage({ id: "SUBMIT_BUTTON" })}
              {loading && (
                <span
                  className="indicator-progress"
                  style={{ display: "block" }}
                >
                  {intl.formatMessage({
                    id: "WEBSITE_GENERAL_LOADING_MESSAGE",
                  })}{" "}
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              )}
            </Button>
          )}
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default EditRoleModal;
