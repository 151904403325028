import React, { FC } from "react";
import FilterIcon from "../../../_metronic/assets/icons/filter.svg";
import { SVGICON } from "../../../_metronic/helpers";
import clsx from "clsx";

interface Props {
  onClick?: () => void;
  className?: string;
}

const FilterButton: FC<Props> = ({ onClick, className }) => {
  return (
    <button
      type="button"
      className={clsx("btn btn-light btn-active-light-primary px-0", className)}
      style={{ width: "40px" }}
      onClick={onClick}
    >
      <SVGICON src={FilterIcon} className="svg-icon-2 me-0" />
    </button>
  );
};

export default FilterButton;
