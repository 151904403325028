import React, { FC } from "react";
import { UserType } from "../types/getUsersListResponseType";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";

interface ConnectUserTeams {
  userDetails: UserType;
  getUserDetailsAPI: () => void;
}
const UserTeamDetails: FC<ConnectUserTeams> = ({
  userDetails,
  getUserDetailsAPI,
}) => {
  const intl = useIntl();
  const history = useHistory();
  return (
    <div>
      <div className="card card-my">
        <div
          className="card-header card-px cursor-pointer d-flex justify-content-between"
          role="button"
          data-bs-toggle="collapse"
          data-bs-target="#kt_account_product_legalText-details_user_teams"
          aria-expanded="true"
          aria-controls="kt_account_product_legalText-details"
        >
          <div className="card-title m-0 flex-fill">
            <h3 className="fw-bolder m-0">
              {intl.formatMessage({ id: "USER_MANAGEMENT_TEAMS_TITLE" })}
            </h3>
          </div>
        </div>

        <div
          id="kt_account_product_legalText-details_user_teams"
          className=" collapse show"
        >
          {userDetails &&
          userDetails.teams_details &&
          userDetails.teams_details.length > 0 ? (
            <div
              className="d-inline-flex align-items-center flex-row w-100 card-p gap-4 text-hover-primary"
              role="button"
            >
              {userDetails.teams_details.map((team) => (
                <div
                  key={team.id}
                  className="badge badge-light fw-bolder text-uppercase badge-light-success"
                  onClick={() => {
                    history.push("/system/teams");
                  }}
                >
                  {team.name}
                </div>
              ))}
            </div>
          ) : (
            <div className="notice d-flex bg-light-warning rounded border-warning border border-dashed card-p">
              <span className="svg-icon svg-icon-2tx svg-icon-warning me-4">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    opacity="0.3"
                    d="M20.5543 4.37824L12.1798 2.02473C12.0626 1.99176 11.9376 1.99176 11.8203 2.02473L3.44572 4.37824C3.18118 4.45258 3 4.6807 3 4.93945V13.569C3 14.6914 3.48509 15.8404 4.4417 16.984C5.17231 17.8575 6.18314 18.7345 7.446 19.5909C9.56752 21.0295 11.6566 21.912 11.7445 21.9488C11.8258 21.9829 11.9129 22 12.0001 22C12.0872 22 12.1744 21.983 12.2557 21.9488C12.3435 21.912 14.4326 21.0295 16.5541 19.5909C17.8169 18.7345 18.8277 17.8575 19.5584 16.984C20.515 15.8404 21 14.6914 21 13.569V4.93945C21 4.6807 20.8189 4.45258 20.5543 4.37824Z"
                    fill="currentColor"
                  ></path>
                  <path
                    d="M10.5606 11.3042L9.57283 10.3018C9.28174 10.0065 8.80522 10.0065 8.51412 10.3018C8.22897 10.5912 8.22897 11.0559 8.51412 11.3452L10.4182 13.2773C10.8099 13.6747 11.451 13.6747 11.8427 13.2773L15.4859 9.58051C15.771 9.29117 15.771 8.82648 15.4859 8.53714C15.1948 8.24176 14.7183 8.24176 14.4272 8.53714L11.7002 11.3042C11.3869 11.6221 10.874 11.6221 10.5606 11.3042Z"
                    fill="currentColor"
                  ></path>
                </svg>
              </span>
              <div className="d-flex flex-stack flex-grow-1 flex-wrap flex-md-nowrap">
                <div className="mb-3 mb-md-0 fw-semibold">
                  <h4 className="text-gray-900 fw-bold">
                    {intl.formatMessage({ id: "USER_NO_TEAM_MESSAGE" })}
                  </h4>
                  <div className="fs-6 text-gray-700 pe-7">
                    {intl.formatMessage({ id: "USER_TEAM_INFO" })}
                  </div>
                </div>
                <button
                  className="btn btn-light-primary px-6 align-self-center text-nowrap"
                  onClick={() => {
                    history.push("/system/teams");
                  }}
                >
                  {intl.formatMessage({ id: "USER_TEAM_ADD_BUTTON" })}
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default UserTeamDetails;
