import React, { FC } from "react";
import CloseIcon from "../../../_metronic/assets/icons/close.svg";
import { SVGICON } from "../../../_metronic/helpers";
import clsx from "clsx";

interface Props {
  onClose?: () => void;
  className?: string;
}

const CloseButton: FC<Props> = ({ onClose, className }) => {
  return (
    <button
      className={clsx(
        "btn btn-sm btn-icon btn-active-light-primary close-btn",
        className ? className : ""
      )}
      onClick={onClose}
    >
      <SVGICON src={CloseIcon} className="svg-icon-2x" />
    </button>
  );
};

export default CloseButton;
