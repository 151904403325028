import React, { FC } from "react";
import { useIntl } from "react-intl";

// custom
import { useGlobalModalContext } from "../../../sharedComponents/modals/ModalContext";

import ActionsDropdown from "../../../sharedComponents/ActionsDropdown/ActionsDropdown";
import { DealType } from "../../deals/types/DealResponseType";
import { forceDeleteDeal, restoreDeal } from "../redux/ArchiveAPI";
import { RootState } from "../../../../setup";
import { useSelector } from "react-redux";
import { RoleType } from "../../RolesAndPermissions/types/getRolesResponseType";
import { enablePermissionForCrud } from "../../../utils/PermisisionEnabledForResource";
import { errorToast, successToast } from "../../../sharedComponents/toasts/Toasts";

interface Props {
  dealDetails: DealType;
  getArchivedDealsListAPI?: () => void;
}

const ArchivedListActionsDropdown: FC<Props> = ({
  dealDetails,
  getArchivedDealsListAPI,
}) => {
  const intl = useIntl();
  const userRoleDetails = useSelector<RootState>(
    // @ts-ignore
    (state) => state.auth.roleDetails
  ) as RoleType;
  //@ts-ignore
  const crudPermission: any = enablePermissionForCrud(
    userRoleDetails,
    "archived-deals"
  );

  const {
    showDeleteConfirmModal,
    hideDeleteConfirmModal,
    setDeleteModalLoading,
  } = useGlobalModalContext();

  const onRestoreDealClick = () => {
    restoreDeal(dealDetails.id)
      .then(() => {
        successToast(
          intl.formatMessage({ id: "ARCHIVED_RESTORE_SUCCESS_MESSAGE" })
        );
      })
      .catch(() => {
        errorToast(
          intl.formatMessage({ id: "ARCHIVED_RESTORE_FAILURE_MESSAGE" })
        );
      })
      .finally(() => {
        getArchivedDealsListAPI?.();
        hideDeleteConfirmModal()
      });
  };

  const onDeleteDeleteClick = () => {
    setDeleteModalLoading(true);
    forceDeleteDeal(dealDetails.id)
      .then(() => {
        successToast(
          intl.formatMessage({ id: "ARCHIVED_DELETE_SUCCESS_MESSAGE" })
        );
      })
      .catch(() => {
        errorToast(
          intl.formatMessage({ id: "ARCHIVED_DELETE_FAILURE_MESSAGE" })
        );
      })
      .finally(() => {
        setDeleteModalLoading(false);
        hideDeleteConfirmModal();
        getArchivedDealsListAPI?.();
      });
  };

  const dropdownOptions = [
    ...(crudPermission?.edit
      ? [
          {
            name: intl.formatMessage({ id: "ARCHIVED_DEAL_RESTORE" }),
            onClick: () => {
              showDeleteConfirmModal(
                intl.formatMessage({ id: "ARCHIVED_RESTORE_CONFIRM_MESSAGE" }),
                () => {
                  onRestoreDealClick();
                }, intl.formatMessage({ id: "COMMON_YES_RESTORE" })
              );
            },
          },
        ]
      : []),
    ...(crudPermission?.delete
      ? [
          {
            name: intl.formatMessage({ id: "COMMON_DELETE" }),
            onClick: () => {
              showDeleteConfirmModal(
                intl.formatMessage({ id: "ARCHIVED_DELETE_CONFIRM_MESSAGE" }),
                () => {
                  onDeleteDeleteClick();
                }
              );
            },
            className: "text-danger",
          },
        ]
      : []),
  ];

  if (dropdownOptions.length > 0) {
    return (
      <div className="dropdown d-flex flex-row justify-content-end">
        <ActionsDropdown options={dropdownOptions} />
      </div>
    );
  } else {
    return null;
  }
};

export default ArchivedListActionsDropdown;
