import axios from "axios";
import {
  InvoicesByFiltersResponseType,
  InvoiceResponseType,
} from "../types/InvoicesTypes";
import { GenericResponseType } from "../../../../types/GenericReponse";

// get invoices
export function getInvoices(
  status: string,
  search_keyword?: string,
  limit?: number,
  page?: number,
  month?: number,
  year?: number
) {
  return axios.post<InvoicesByFiltersResponseType>("/sales/invoices/filter", {
    search_keyword,
    limit,
    page,
    status,
    month,
    year,
  });
}

// get trash list
export function getInvoicesTrashList() {
  return axios.get<InvoicesByFiltersResponseType>(`/sales/invoices/retrieve`);
}

// get invoice by id
export function getInvoiceDetails(invoice_id: number) {
  return axios.get<InvoiceResponseType>(`/sales/invoices/${invoice_id}`);
}

// create new invoice
export function createInvoice(data: any) {
  return axios.post<any>("/sales/invoices", data);
}

// update invoice Details
export function invoiceUpdate(
  invoice_id: number,
  update_type: string,
  data: string | null,
  date?: string
) {
  let payload: any = { update_type };
  if (update_type === "invoice_date") {
    payload.invoice_date = data;
  }
  if (update_type === "invoice_to") {
    payload.invoice_to = data;
  }
  if (update_type === "invoice_from") {
    payload.invoice_from = data;
  }
  if (update_type === "payment_target_interval") {
    payload.payment_target_interval = data;
    payload.payment_target = date;
  }
  if (update_type === "payment_target_count") {
    payload.payment_target_count = data;
    payload.payment_target = date;
  }
  if (update_type === "payment_target") {
    payload.payment_target = data;
  }
  if (update_type === "subject") {
    payload.subject = data;
  }
  if (update_type === "header_notes") {
    payload.header_notes = data;
  }
  if (update_type === "footer_notes") {
    payload.footer_notes = data;
  }
  if (update_type === "processor") {
    payload.users_id = data;
  }
  return axios.post<InvoiceResponseType>(
    `/sales/invoices/update/${invoice_id}`,
    payload
  );
}

// delete invoice by id
export function deleteInvoice(id: number) {
  return axios.delete<GenericResponseType>(`/sales/invoices/${id}`);
}

// mass delete invoices
export function massDeleteInvoices(sales_invoice_id: number[]) {
  return axios.post<GenericResponseType>(`/sales/invoices/massDelete`, {
    sales_invoice_id,
  });
}

// forcedelete single invoice
export function forceDeleteInvoice(id: number) {
  return axios.post<GenericResponseType>(`/sales/invoices/forceDelete/${id}`);
}

// mass force delete
export function massForceDeleteInvoices(sales_invoice_id: number[]) {
  return axios.post<GenericResponseType>(`/sales/invoices/massForceDelete`, {
    sales_invoice_id,
  });
}

// restore invoice
export function restoreInvoice(id: number) {
  return axios.post<GenericResponseType>(`/sales/invoices/restore/${id}`);
}

// mass restore
export function massRestoreInvoices(sales_invoice_id: number[]) {
  return axios.post<GenericResponseType>("/sales/invoices/massRestore", {
    sales_invoice_id,
  });
}

// WorkFlow APIs
// finalize invoice
export function finalizeInovice(invoice_id: number) {
  return axios.post<any>(`/sales/invoices/finalise/${invoice_id}`);
}

// cancel invoice
export function cancelInvoice(invoice_id: number) {
  return axios.post<any>(`/sales/invoices/cancel/${invoice_id}`);
}

// Payment APIs
// add invoice payment(same api for both single payment and partial payment)
export function addPayments(invoice_id: number, data: any) {
  return axios.post<any>(`/sales/invoices/finish/${invoice_id}`, data);
}

// delete invoice payment position
export function deletePosition(invoice_id: number) {
  return axios.post<any>(`/sales/invoices/payments/delete/${invoice_id}`);
}
